import { ModuleTokens } from './services-base.constant';
import { StandardService, BillingPeriod } from '@app/common/settings';

export class ServicesBaseState {
  entities: ServicesEntityEntities;

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
  }
}

export class ServicesEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ServiceEntityNormalized; };
}

export class ServicesEntityNormalizedData {
  entities: ServicesEntityEntities;
  result: string[];
}

// TODO: interface
export class ServiceEntityLight {
  clientId: string;
  id: string;
  clientZoneStatus: string;
  standardService: StandardService;
}

export class ServiceEntityBase extends ServiceEntityLight {
  serviceType: string;
  serviceName: string;
  serviceIdentification: string;
  oneTimePrice: number;
  monthPriceDiscounted: number;
  activationDate: string;
  billingPeriod: BillingPeriod;
}

export class ServiceEntityNormalized extends ServiceEntityBase {
}

// TODO: interface
export class ServiceEntity extends ServiceEntityBase {
  _isKvm?: boolean;
}
