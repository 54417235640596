import * as tslib_1 from "tslib";
import { ModuleTokens } from './server-park-access-base.constant';
var ServerParkAccessBaseState = /** @class */ (function () {
    function ServerParkAccessBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
    }
    return ServerParkAccessBaseState;
}());
export { ServerParkAccessBaseState };
var ServerParkAccessEntityEntities = /** @class */ (function () {
    function ServerParkAccessEntityEntities() {
    }
    return ServerParkAccessEntityEntities;
}());
export { ServerParkAccessEntityEntities };
ModuleTokens.Name;
var ServerParkAccessEntityNormalizedData = /** @class */ (function () {
    function ServerParkAccessEntityNormalizedData() {
    }
    return ServerParkAccessEntityNormalizedData;
}());
export { ServerParkAccessEntityNormalizedData };
var ServerParkAccessUserEntityBase = /** @class */ (function () {
    function ServerParkAccessUserEntityBase() {
    }
    return ServerParkAccessUserEntityBase;
}());
export { ServerParkAccessUserEntityBase };
var ServerParkAccessUserEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(ServerParkAccessUserEntityNormalized, _super);
    function ServerParkAccessUserEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerParkAccessUserEntityNormalized;
}(ServerParkAccessUserEntityBase));
export { ServerParkAccessUserEntityNormalized };
var ServerParkAccessUserEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ServerParkAccessUserEntity, _super);
    function ServerParkAccessUserEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerParkAccessUserEntity;
}(ServerParkAccessUserEntityBase));
export { ServerParkAccessUserEntity };
