import * as tslib_1 from "tslib";
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom, filter } from 'rxjs/operators';
import { ticketAddFormState } from './ticket-add.selector';
import { ActionTypes, AddRequest } from './ticket-add.action';
import { ModuleTokens } from './ticket-add.constant';
var TicketAddContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketAddContainer, _super);
    function TicketAddContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Form States
        _this.ticketAddFormState$ = _this._store.pipe(select(ticketAddFormState));
        // # Event Stream
        _this.onAdd$ = new Subject();
        // # Data
        // -- sync
        _this.addRequestKey = ActionTypes.AddRequest;
        _this.addFailKey = ActionTypes.AddFail;
        _this.uploadInstanceId = ModuleTokens.Name;
        // # Action Streams
        _this._addAction$ = _this.onAdd$.pipe(withLatestFrom(_this.ticketAddFormState$.pipe(map(function (s) { return s.isValid; }))), filter(function (_a) {
            var _ = _a[0], isValid = _a[1];
            return !!isValid;
        }), map(function () { return new AddRequest(); }));
        merge(_this._addAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    return TicketAddContainer;
}(BaseClass));
export { TicketAddContainer };
