import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './invoices-base.constant';
import { reducer } from './invoices-base.reducer';
import { InvoicesBaseEffect } from './invoices-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ InvoicesBaseEffect ])
  ]
})
export class InvoicesBaseModule {

}
