import { normalize, denormalize } from 'normalizr';
import { clientServicesListSchema } from '@app/schemas';
import { ModuleTokens } from './client-services-base.constant';
export function normalizeList(data) {
    return normalize(data, clientServicesListSchema);
}
export function denormalizeList(ids, invoicesEntities) {
    var _a;
    return denormalize(ids, clientServicesListSchema, (_a = {},
        _a[ModuleTokens.Name] = invoicesEntities,
        _a));
}
