import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  Observable,
  EMPTY,
  throwError,
  BehaviorSubject,
  of
} from 'rxjs';
import {
  catchError,
  tap,
  filter,
  first,
  concatMap,
  mergeMap,
  delay,
  map
} from 'rxjs/operators';
import { State } from '@app/models';
import {
  SetActive,
  SetInactive,
  ActionTypes
} from './recaptcha.action';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  private _recaptchaToken$ = new BehaviorSubject<string>(null);
  private _blocking = false;

  constructor(
    private _store: Store<State>,
    private _actions$: Actions,
    private _http: HttpClient
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.error.error.code === 405 || err.error.error.code === 406) {

          if (!this._blocking) {
            this._blocking = true;
            this._recaptchaToken$.next(null);

            this._store.dispatch(new SetActive());

            return this._actions$.pipe(
              ofType<SetInactive>(ActionTypes.SetInactive),
              mergeMap(({ payload }) => {
                request = request.clone({
                  setHeaders: {
                    'g-recaptcha-response': payload
                  }
                });

                return next
                  .handle(request)
                  .pipe(
                    catchError((errInner: HttpErrorResponse) => {
                      if (errInner.error.error.code === 406) {
                        this._blocking = true;
                        this._store.dispatch(new SetActive());

                        return EMPTY;
                      } else {
                        this._blocking = false;
                        return throwError(errInner);
                      }
                    }),
                    map((res: any) => {
                      if (res.status && res.status === 200) {
                        this._recaptchaToken$.next(payload);
                        this._blocking = false;
                      }

                      return res;
                    })
                  );
              })
            );

          } else {
            return this._recaptchaToken$.pipe(
              filter((token) => !!token),
              first(),
              mergeMap(() => next.handle(request))
            );
          }

        } else {
          return throwError(err);
        }
      })
    );
  }
}

export const recaptchaInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RecaptchaInterceptor,
  multi: true
};
