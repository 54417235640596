import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vshcz-key-chip',
  templateUrl: './key-chip.component.html',
  styleUrls: ['./key-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyChipComponent {
 }
