import * as tslib_1 from "tslib";
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { combineLatest, Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isAuthenticated, activeUserClientId, activeUserClientUsers } from '../../auth-base.selector';
import { SetActiveClient, InvalidateAuthorization } from '../../auth-base.action';
var NoClientUserDialogContainer = /** @class */ (function (_super) {
    tslib_1.__extends(NoClientUserDialogContainer, _super);
    function NoClientUserDialogContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onSetClientId$ = new Subject();
        _this.onLogout$ = new Subject();
        // # Data
        // -- async
        _this._isAuthenticated$ = _this._store.pipe(select(isAuthenticated));
        _this.activeClientId$ = _this._store.pipe(select(activeUserClientId));
        _this.userCompanies$ = _this._store.pipe(select(activeUserClientUsers));
        _this.dialogOpen$ = combineLatest(_this._isAuthenticated$, _this.activeClientId$).pipe(map(function (_a) {
            var isAuth = _a[0], id = _a[1];
            return (isAuth && id === undefined);
        }));
        // # Action Streams
        _this._setClientIdAction$ = _this.onSetClientId$.pipe(map(function (id) { return new SetActiveClient(id); }));
        _this._logoutAction$ = _this.onLogout$.pipe(map(function (id) { return new InvalidateAuthorization(); }));
        // # Action Dispatcher
        merge(_this._setClientIdAction$, _this._logoutAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        _super.prototype.ngOnInit.call(_this);
        return _this;
    }
    return NoClientUserDialogContainer;
}(BaseClass));
export { NoClientUserDialogContainer };
