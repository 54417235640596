import { EventEmitter } from '@angular/core';
var WatchTicketComponent = /** @class */ (function () {
    function WatchTicketComponent() {
        this.copyListContainsUserEmail = false;
        this.loading = false;
        this.clicked = new EventEmitter();
    }
    Object.defineProperty(WatchTicketComponent.prototype, "ticketCopyList", {
        set: function (v) {
            if (v && v.length) {
                this.copyListContainsUserEmail = v.includes(this.authUserEmail);
            }
            else {
                this.copyListContainsUserEmail = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return WatchTicketComponent;
}());
export { WatchTicketComponent };
