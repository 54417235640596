import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './clients-base.constant';
import { ClientsBaseState } from './clients-base.model';

const initialState = new ClientsBaseState();

export function reducer(
  state = initialState,
  actions: any
): ClientsBaseState {
  return applyEntities<ClientsBaseState>(
    state,
    [ ModuleTokens.Name ],
    actions
  );
}
