import { InvoiceEntityLight } from '../invoices-base';
import { ModuleTokens } from './payments-base.constant';

export class PaymentsState {
  entities: PaymentsEntityEntities;
  list: string[];

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { },
    };
    this.list = [];
  }
}

export class PaymentsEntityEntities {
  [ModuleTokens.Name]: { [id: string]: PaymentEntityNormalized; };
}

export class PaymentsEntityNormalizedData {
  entities: PaymentsEntityEntities;
  result: string[];
}

export class PaymentEntityLight {
 id: string;
 clientId: string;
 status: string;
 type: string;
 amount: number;
 currencyId: string;
 variableSymbol: number;
 created: string;
}

export class PaymentEntityBase extends PaymentEntityLight {
  specificSymbol: number;
  senderBankAccountNumber: string;
  senderBankAccountName: string;
  receiverBankAccountNumber: string;
  creditChangeVat: number;
}

export class PaymentEntityNormalized extends PaymentEntityBase {
  connectedInvoiceList: string[];
}

export class PaymentEntity extends PaymentEntityBase {
  connectedInvoiceList: InvoiceEntityLight[];
}
