import * as tslib_1 from "tslib";
import { HttpEventType } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, concat } from 'rxjs';
import { switchMap, withLatestFrom, map, catchError, mergeMap, concatMap, first, bufferTime, filter, takeUntil, } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { SnackService } from '@app/common/snack';
import { authActiveUserClientId } from '@app/base/auth-base';
import { ActionTypes, UploadSingleLocalSuccess, UploadSingleFail, UploadEmpty, UploadProgress, UploadAllLocalSuccess, MarkQueueItemAsDone, ClearInstanceQueue } from './files.action';
import { queueFiles, uploadedFiles } from './files.selector';
import { FilesApi } from './files.api';
var FilesEffect = /** @class */ (function () {
    function FilesEffect(_api, _store, _actions$, _snack, _errorTranslation) {
        var _this = this;
        this._api = _api;
        this._store = _store;
        this._actions$ = _actions$;
        this._snack = _snack;
        this._errorTranslation = _errorTranslation;
        this._onUploadRequest$ = this._actions$.pipe(ofType(ActionTypes.UploadRequest));
        this._onRefusedToQueue$ = this._actions$.pipe(ofType(ActionTypes.RefusedToQueue), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._snack.translatedWarning$(payload === 'size'
                ? 'common.files.maxSize'
                : 'common.files.maxCount', 'common.close');
        }));
        // no files in query, send upload empty action
        this._onUploadRequestEmpty$ = this._onUploadRequest$.pipe(switchMap(function (_a) {
            var payload = _a.payload;
            return _this._store.pipe(select(queueFiles(payload)), first(), filter(function (files) { return files.length === 0; }), map(function () { return new UploadEmpty({ instanceId: payload }); }));
        }));
        // there are files in queury, proceed with upload
        this._onUploadRequestProceed$ = this._onUploadRequest$.pipe(switchMap(function (_a) {
            var payload = _a.payload;
            return _this._store.pipe(select(queueFiles(payload)), first(), filter(function (files) { return files.length > 0; }), mergeMap(function (queue) { return queue; }), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), concatMap(function (_a) {
                var queueItem = _a[0], clientId = _a[1];
                return concat(of(new MarkQueueItemAsDone({
                    instanceId: payload,
                    uuid: queueItem.uuid
                })), _this._api
                    .upload$(queueItem.meta, clientId, true)
                    .pipe(filter(function (event) { return event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response; }), bufferTime(50), filter(function (events) { return events.length > 0; }), map(function (events) {
                    var event = events[events.length - 1];
                    if (event.type === HttpEventType.UploadProgress) {
                        return new UploadProgress(event.loaded / event.total);
                    }
                    else {
                        return new UploadSingleLocalSuccess({
                            instanceId: payload,
                            fileId: event.body.fileId
                        });
                    }
                }), catchError(function (err) { return _this._errorTranslation
                    .get$(err)
                    .pipe(map(function (data) { return new UploadSingleFail({
                    fileUuid: queueItem.uuid,
                    instanceId: payload,
                }, data); })); })));
            }), takeUntil(_this._actions$.pipe(ofType(ActionTypes.UploadSingleFail), filter(function (action) { return action.payload.instanceId === payload; }))));
        }));
        this._onUploadAllDone$ = this._actions$.pipe(ofType(ActionTypes.UploadSingleLocalSuccess), 
        // switch to queue everytime single uploade succeeds
        switchMap(function (_a) {
            var payload = _a.payload;
            return _this._store.pipe(select(queueFiles(payload.instanceId)), 
            // only take the first result
            first(), 
            // check if all items in queue are done
            filter(function (queue) { return queue.filter(function (item) { return !item.done; }).length === 0; }), 
            // select uploaded files
            withLatestFrom(_this._store.pipe(select(uploadedFiles(payload.instanceId)))), 
            // dispatch result with them
            map(function (_a) {
                var _ = _a[0], fileIds = _a[1];
                return new UploadAllLocalSuccess({
                    fileIds: fileIds,
                    instanceId: payload.instanceId
                });
            }));
        }));
        // clear queue after all files were uploaded
        this._onUploadAllSuccessClearQueue$ = this._actions$.pipe(ofType(ActionTypes.UploadAllLocalSuccess), map(function (_a) {
            var payload = _a.payload;
            return new ClearInstanceQueue(payload.instanceId);
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffect.prototype, "_onRefusedToQueue$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffect.prototype, "_onUploadRequestEmpty$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffect.prototype, "_onUploadRequestProceed$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffect.prototype, "_onUploadAllDone$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffect.prototype, "_onUploadAllSuccessClearQueue$", void 0);
    return FilesEffect;
}());
export { FilesEffect };
