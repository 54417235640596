/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tickets-list.container.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "@angular/material/core";
import * as i16 from "../ticket-item/ticket-item.component.ngfactory";
import * as i17 from "../ticket-item/ticket-item.component";
import * as i18 from "@angular/common";
import * as i19 from "../../../../../node_modules/@angular/cdk/scrolling/typings/index.ngfactory";
import * as i20 from "@angular/cdk-experimental/scrolling";
import * as i21 from "./tickets-list.container";
import * as i22 from "@ngrx/store";
import * as i23 from "ngx-clipboard";
import * as i24 from "../snack/snack.service";
var styles_TicketsListContainer = [i0.styles];
var RenderType_TicketsListContainer = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketsListContainer, data: {} });
export { RenderType_TicketsListContainer as RenderType_TicketsListContainer };
function View_TicketsListContainer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "__empty-state"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-h1  __empty-state_title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["translate", "ticketList.emptyStateTitle"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["translate", "ticketList.emptyStateDescPreLink"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.onResetFilter$.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["translate", "ticketList.emptyStateDescLink"]], null, null, null, null, null)), i1.ɵdid(9, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["translate", "ticketList.emptyStateDescPostLink"]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "__empty-state_image"], ["src", "https://cdn.dribbble.com/users/1121009/screenshots/5270889/dribbble_23.jpg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "ticketList.emptyStateTitle"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "ticketList.emptyStateDescPreLink"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "ticketList.emptyStateDescLink"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "ticketList.emptyStateDescPostLink"; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; _ck(_v, 0, 0, currVal_0); }); }
function View_TicketsListContainer_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "button", [["class", "__share"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.copyLinkToClipboard(_v.parent.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 147456, null, 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["class", "__share_icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["filter_none"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("common.copyToUrl")); _ck(_v, 2, 0, currVal_2); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
function View_TicketsListContainer_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "__item-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "__item mat-ripple"], ["href", "#"], ["matRipple", ""]], [[2, "is-highlighted", null], [2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.selected.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i15.MatRipple, [i1.ElementRef, i1.NgZone, i5.Platform, [2, i15.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "vshcz-ticket-item", [], null, null, null, i16.View_TicketItemComponent_0, i16.RenderType_TicketItemComponent)), i1.ɵdid(4, 49152, null, 0, i17.TicketItemComponent, [], { data: [0, "data"], more: [1, "more"] }, null), i1.ɵpod(5, { num: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketsListContainer_4)), i1.ɵdid(8, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _v.context.$implicit; var currVal_3 = i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 6).transform("ticketsCommon.numMore", _ck(_v, 5, 0, (_v.context.$implicit.publicMessageCount - 1)))); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.showActions; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id === _co.selectedId); var currVal_1 = i1.ɵnov(_v, 2).unbounded; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TicketsListContainer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "cdk-virtual-scroll-viewport", [["autosize", ""], ["class", "__viewport cdk-virtual-scroll-viewport"]], [[4, "height", "px"], [2, "cdk-virtual-scroll-orientation-horizontal", null], [2, "cdk-virtual-scroll-orientation-vertical", null]], null, null, i19.View_CdkVirtualScrollViewport_0, i19.RenderType_CdkVirtualScrollViewport)), i1.ɵprd(6144, null, i10.CdkScrollable, null, [i10.CdkVirtualScrollViewport]), i1.ɵdid(2, 540672, null, 0, i20.CdkAutoSizeVirtualScroll, [], null, null), i1.ɵprd(1024, null, i10.VIRTUAL_SCROLL_STRATEGY, i20._autoSizeVirtualScrollStrategyFactory, [i20.CdkAutoSizeVirtualScroll]), i1.ɵdid(4, 245760, null, 0, i10.CdkVirtualScrollViewport, [i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone, [2, i10.VIRTUAL_SCROLL_STRATEGY], [2, i11.Directionality], i10.ScrollDispatcher], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_TicketsListContainer_3)), i1.ɵdid(6, 409600, null, 0, i10.CdkVirtualForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers, [1, i10.CdkVirtualScrollViewport], i1.NgZone], { cdkVirtualForOf: [0, "cdkVirtualForOf"], cdkVirtualForTrackBy: [1, "cdkVirtualForTrackBy"] }, null), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.list$)); var currVal_4 = _co.trackBy; _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; var currVal_1 = (i1.ɵnov(_v, 4).orientation === "horizontal"); var currVal_2 = (i1.ɵnov(_v, 4).orientation !== "horizontal"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TicketsListContainer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_TicketsListContainer_1)), i1.ɵdid(1, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["itemsRef", 2]], null, 0, null, View_TicketsListContainer_2))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.activeFilters$))) == null) ? null : tmp_0_0.length) && !(((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.list$))) == null) ? null : tmp_0_0.length)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TicketsListContainer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-tickets-list", [], null, null, null, View_TicketsListContainer_0, RenderType_TicketsListContainer)), i1.ɵdid(1, 245760, null, 0, i21.TicketsListContainer, [i22.Store, i23.ClipboardService, i24.SnackService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketsListContainerNgFactory = i1.ɵccf("vshcz-tickets-list", i21.TicketsListContainer, View_TicketsListContainer_Host_0, { height: "height", selectedId: "selectedId", showActions: "showActions" }, { selected: "selected" }, []);
export { TicketsListContainerNgFactory as TicketsListContainerNgFactory };
