import * as tslib_1 from "tslib";
import { filter, map } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DialogClose } from '@zerops/fe/dialog';
import { DialogKey } from './bulk-payment-invoices-dialog.constant';
import { InvoicesBaseActionTypes, PaymentIntentTypes } from '@app/base/invoices-base';
var PaymentInvoicesDialogEffect = /** @class */ (function () {
    function PaymentInvoicesDialogEffect(_actions$) {
        this._actions$ = _actions$;
        this.onConfirmPaymentCloseDialog$ = this._actions$.pipe(ofType(InvoicesBaseActionTypes.ConfirmPaymentLocalSuccess), filter(function (_a) {
            var payload = _a.payload;
            return payload.paymentIntentType === PaymentIntentTypes.Invoice;
        }), map(function () { return new DialogClose(DialogKey); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentInvoicesDialogEffect.prototype, "onConfirmPaymentCloseDialog$", void 0);
    return PaymentInvoicesDialogEffect;
}());
export { PaymentInvoicesDialogEffect };
