<div class="__list">

  <mat-list dense *ngIf="(data$ | async) as data">
    <mat-list-item
      class="__list-item"
      *ngFor="let item of data">

      {{ item }}

      <button
        class="__remove"
        (click)="!!onRemove$.next(item)"
        type="button"
        mat-icon-button>
        <mat-icon class="__remove-icon">highlight_off</mat-icon>
      </button>

    </mat-list-item>
  </mat-list>

  <ng-container *ngIf="!(data$ | async)?.length">
    <p
      class="__empty-state"
      translate="ticketCopyList.empty">
    </p>
  </ng-container>

</div>

<div class="__divider">
  <mat-divider></mat-divider>
</div>

<vshcz-form
  #formRef
  (submit)="onCheck$.next()">

  <vshcz-email-form
    class="__mail-form"
    [showHint]="false"
    [showPlaceholder]="false"
    floatLabel="always"
    [translations]="transKeys"
    [state]="formState$ | async">
  </vshcz-email-form>

  <vshcz-progress-error
    [errorCloseLabel]="'common.close' | translate"
    [errorRetryLabel]="'common.retry' | translate"
    [errorShowDetailsLabel]="'common.showMore' | translate"
    [errorHideDetailsLabel]="'common.hideMore' | translate"
    [errorKey]="addErrorKey$ | async"
    denseButton
    [progressKey]="addCheckRequestKey$ | async"
    (retry)="formRef.triggerSubmit()">
    <button
      color="primary"
      type="submit"
      class="__add-button"
      mat-raised-button>
      <mat-icon>add_circle_outline</mat-icon>
      <span translate="ticketCopyList.addButton"></span>
    </button>
  </vshcz-progress-error>

</vshcz-form>

<zps-dialog [open]="secureCommunicationDialogOpen$ | async">
  <div class="__secure-communication-dialog">

    <button
      class="__secure-communication-dialog_close"
      mat-icon-button
      type="button"
      [zpsNgrxDialogClose]="checkRequestKey">
      <mat-icon>close</mat-icon>
    </button>

    <vshcz-content-title
      size="big"
      position="left"
      translate="ticketCopyList.securedCommunication">
    </vshcz-content-title>

    <p
      class="__secure-communication-dialog_desc"
      [innerHTML]="'ticketCopyList.secureDialog'
        | translate: { company: companyName$ | async }">
    </p>

    <vshcz-progress-error
      class="__secure-communication-dialog_progress"
      [errorCloseLabel]="'common.close' | translate"
      [errorRetryLabel]="'common.retry' | translate"
      [errorShowDetailsLabel]="'common.showMore' | translate"
      [errorHideDetailsLabel]="'common.hideMore' | translate"
      [errorKey]="addErrorKey$ | async"
      [progressKey]="addRequestKey$ | async"
      (retry)="formRef.triggerSubmit()">
      <button
        color="primary"
        type="button"
        (click)="onAdd$.next()"
        class="__add-button"
        mat-raised-button>
        <mat-icon>add_circle_outline</mat-icon>
        <span translate="ticketCopyList.addAnyway"></span>
      </button>
    </vshcz-progress-error>

  </div>
</zps-dialog>
