import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModuleTokens } from './files.constant';
import { FilesState } from './files.model';

export const slice = createFeatureSelector<FilesState>(ModuleTokens.Name);

export const queueFiles = (
  instanceId: string
) => createSelector(
  slice,
  (s) => s.queue[instanceId]
    ? s.queue[instanceId]
    : []
);

export const uploadedFiles = (
  instanceId: string
) => createSelector(
  slice,
  (s) => s.uploadedFiles[instanceId]
    ? s.uploadedFiles[instanceId]
    : []
);
