import { Action } from '@ngrx/store';
import { StateUpdateFns } from 'ngrx-forms';
import {
  NgrxFormExtensionModel,
  HasNgrxForm
} from '@app/common/ngrx-forms-extensions';
import {
  SearchFormValue,
  SearchFormState
} from './search.model';
 import { formKey } from './search.constant';

const formModel = new NgrxFormExtensionModel<SearchFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<SearchFormValue> = {},
  updateFns: StateUpdateFns<SearchFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      keyword: undefined
    },
    defaultValues,
    updateFns
  )
});

export const applyFormReducer = <S extends HasNgrxForm>(
  state: S,
  action: Action,
  id: string,
  defaultSettings?: StateUpdateFns<SearchFormValue>
) => formModel.reduceAction(state, action, id, defaultSettings);

export const markAsSubmitted = (id: string, state: any) => formModel.markAsSubmitted(id, state);
export const markAsUnsubmitted = (id: string, state: any) => formModel.markAsUnsubmitted(id, state);
export const formSelector = (id: string) => formModel.selectState<SearchFormState>(id);
export const formValueSelector = (id: string) => formModel.selectValue(id);
export const updateGroupAction = (
  id: string,
  value: Partial<SearchFormValue>
) => formModel.updateGroupValue(id, value);
