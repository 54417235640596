import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PopoverModule } from '@app/common/popover';
import { ModuleTokens } from './tickets-base.constant';
import { reducer } from './tickets-base.reducer';
import { TicketsBaseEffect } from './tickets-base.effect';
import { TicketsBaseNotAuthorizedEffect } from './tickets-base-not-authorized.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([
      TicketsBaseEffect,
      TicketsBaseNotAuthorizedEffect
    ]),
    PopoverModule
  ]
})
export class TicketsBaseModule {

}
