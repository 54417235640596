import { Component } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { Store, select } from '@ngrx/store';
import { map, tap, filter, takeUntil, mergeMap, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { State } from '@app/models';
import { DialogKey } from './domain-routing-info-dialog.constant';
import { SetTicketTriggerState, TicketTriggerOpen } from '../tickets-trigger';

@Component({
  selector: 'vshcz-domain-routing-info-dialog',
  templateUrl: './domain-routing-info-dialog.container.html',
  styleUrls: [ './domain-routing-info-dialog.container.scss' ],
})
export class DomainRoutingInfoDialogContainer extends BaseClass {

  // # Event Stream
  onOpenTicketTrigger$ = new Subject<void>();

  // # Data
  // sync
  dialogKey = DialogKey;
  isCzDomain = false;

  // async
  open$ = this._store.pipe(
    select(getDialogState(this.dialogKey)),
    map(({ state }) => !!state)
  );

  domainName$ = this._store.pipe(
    select(getDialogState(this.dialogKey)),
    filter(({ meta }) =>  !!meta),
    delay(0),
    tap(({ meta }) => meta.endsWith('.cz.') ? this.isCzDomain = true : this.isCzDomain = false),
    map(({ meta }) => meta)
  );

  // # Action Streams
  private _openTicketTriggerAction$ = this.onOpenTicketTrigger$.pipe(
    mergeMap(() => [
      new SetTicketTriggerState({
        state: 1,
        meta: 'CLOUDDNS_ISSUE'
      }),
      new TicketTriggerOpen()
    ])
  );

  constructor(private _store: Store<State>) {
    super();

    this._openTicketTriggerAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }
}
