import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TicketRatingState } from './ticket-rating.model';
import { ModuleTokens } from './ticket-rating.constant';
import { formSelector, formValueSelector } from './ticket-rating.forms';

export const slice = createFeatureSelector<TicketRatingState>(ModuleTokens.Name);

export const formState = createSelector(
  slice,
  formSelector(ModuleTokens.Name)
);

export const formValue = createSelector(
  slice,
  formValueSelector(ModuleTokens.Name)
);
