import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KvmConsoleConnectionApi {
  constructor(private _http: HttpClient) { }

  kvmKeepalive$(key: string) {
    return this._http.put<{ success: boolean; }>(
      `/api/public/server/${key}/kvm-keepalive`,
      {}
    );
  }

}
