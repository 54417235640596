import { NgModule } from '@angular/core';

import { WrapComponent } from './wrap.component';

@NgModule({
  declarations: [ WrapComponent ],
  exports: [ WrapComponent ]
})
export class WrapModule {

}
