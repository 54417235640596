import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientsBaseState } from './clients-base.model';
import { ModuleTokens } from './clients-base.constant';

export const slice = createFeatureSelector<ClientsBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);
