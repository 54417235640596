import { ServiceCodes } from '@app/base/services-base';

export class SettingsState {
  countryList: Country[];
  currencyList: Currency[];
  languageList: Language[];
  billingPeriodList: BillingPeriod[];
  standardServiceList: StandardService[];

  constructor() {
    this.countryList = [];
    this.currencyList = [];
    this.languageList = [];
    this.billingPeriodList = [];
    this.standardServiceList = [];
  }
}

interface Name { [key: string]: string; }

export class SettingsLoad$ApiResponse extends SettingsState { }

export class Country {
  id: string;
  name: Name;
  inEu: boolean;
  vatRate: number;
}

export class Currency {
  id: string;
  symbol: string;
  displaySymbolBefore: boolean;
  roundDigits: number;
}

export class Language {
  id: string;
  name: string;
}

export class BillingPeriod {
  id: string;
  created: string;
  name: Name;
  discount: number;
  monthLength: number;
}

export class StandardService {
  id: string;
  code: ServiceCodes;
  isActive: boolean;
}
