import * as tslib_1 from "tslib";
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { progressByKey } from '@zerops/fe/ngrx';
import { getDialogState, DialogOpen } from '@zerops/fe/dialog';
import { Subject, Observable } from 'rxjs';
import { map, withLatestFrom, takeUntil, switchMap, filter, tap } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { TicketsBaseRateRequest, TicketsBaseActionTypes } from '@app/base/tickets-base';
import { scrollToErrorInput } from 'utils';
import { formState } from './ticket-rating.selector';
var TicketRatingContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketRatingContainer, _super);
    function TicketRatingContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Form States
        _this.formState$ = _this._store.pipe(select(formState));
        // # Event Streams
        _this.onRate$ = new Subject();
        // # Data
        // -- sync
        _this.rateRequestKey = TicketsBaseActionTypes.RateRequest;
        _this.rateErrorKey = TicketsBaseActionTypes.RateFail;
        _this.stars = new Array(4);
        _this.ratingsMap = {};
        // -- async
        _this.rateRequestKey$ = _this.id$.pipe(map(function (id) { return _this.rateRequestKey + ":" + id; }));
        _this.rateErrroKey$ = _this.id$.pipe(map(function (id) { return _this.rateErrorKey + ":" + id; }));
        _this.dialogOpen$ = _this._store.pipe(select(getDialogState(_this.rateRequestKey)), map(function (s) { return s.state; }));
        _this.rateRunning$ = _this.rateRequestKey$.pipe(switchMap(function (key) { return _this._store.pipe(select(progressByKey(key))); }), map(function (p) { return !!p; }), 
        // only show running in button when dialog is closed
        withLatestFrom(_this.dialogOpen$), filter(function (_a) {
            var _ = _a[0], open = _a[1];
            return !open;
        }), map(function (_a) {
            var r = _a[0];
            return r;
        }));
        // # Action Streams
        _this._rateAction$ = _this.onRate$.pipe(withLatestFrom(_this.id$), tap(function (_a) {
            var rating = _a[0], id = _a[1];
            return rating ? (_this.ratingsMap[id] = rating) : undefined;
        }), map(function (_a) {
            var rating = _a[0], id = _a[1];
            return !rating || (rating && rating === 4)
                ? new TicketsBaseRateRequest({ id: id, rating: rating })
                : new DialogOpen(_this.rateRequestKey);
        }));
        _this.scrollToError = scrollToErrorInput;
        // # Data Resolvers
        _this.id$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (d) { return _this.id = d; });
        // # Action Dispatcher
        _this._rateAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    TicketRatingContainer.prototype.resetRating = function () {
        delete this.ratingsMap[this.id];
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketRatingContainer.prototype, "id$", void 0);
    return TicketRatingContainer;
}(BaseClass));
export { TicketRatingContainer };
