import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomainStatusComponent } from './domain-status.component';

@NgModule({
  declarations: [ DomainStatusComponent ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [ DomainStatusComponent ]
})
export class DomainStatusModule {
}
