import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CLOUDDNS_RECEIVER_ID } from '@app/common/clouddns-websockets';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DomainsBaseApi = /** @class */ (function () {
    function DomainsBaseApi(_http) {
        this._http = _http;
    }
    DomainsBaseApi.prototype.list$ = function (clientId) {
        return this._http.post("/clouddns/domain/search", {
            receiverId: CLOUDDNS_RECEIVER_ID,
            search: [
                {
                    name: 'clientId',
                    operator: 'eq',
                    value: clientId
                }
            ],
            limit: 10000
        });
    };
    DomainsBaseApi.prototype.registerSearchForEntity$ = function (id, clientId) {
        return this._http.post("/clouddns/domain/search", {
            receiverId: CLOUDDNS_RECEIVER_ID,
            subscriptionName: 'clouddnsRecords',
            search: [
                {
                    name: 'clientId',
                    operator: 'eq',
                    value: clientId
                },
                {
                    name: 'id',
                    operator: 'eq',
                    value: id
                }
            ]
        });
    };
    DomainsBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/clouddns/domain/" + id);
    };
    DomainsBaseApi.prototype.publish$ = function (id, soaTtl) {
        return this._http.put("/clouddns/domain/" + id + "/publish", { soaTtl: soaTtl });
    };
    // TODO: interface
    DomainsBaseApi.prototype.add$ = function (data, clientId) {
        return this._http.post("/clouddns/domain", tslib_1.__assign({}, data, { clientId: clientId }));
    };
    DomainsBaseApi.prototype.delete$ = function (id) {
        return this._http.delete("/clouddns/domain/" + id);
    };
    DomainsBaseApi.prototype.restore$ = function (id) {
        return this._http.put("/clouddns/domain/" + id + "/recover", {});
    };
    DomainsBaseApi.prototype.deactivate$ = function (id) {
        return this._http.put("/clouddns/domain/" + id + "/deactivate", {});
    };
    DomainsBaseApi.prototype.activate$ = function (id) {
        return this._http.put("/clouddns/domain/" + id + "/activate", {});
    };
    DomainsBaseApi.prototype.resetChanges$ = function (id) {
        return this._http.put("/clouddns/domain/" + id + "/reset-changes", {});
    };
    DomainsBaseApi.prototype.soaRequest$ = function (id) {
        return this._http.get("/clouddns/domain/" + id + "/record-soa");
    };
    DomainsBaseApi.prototype.soaUpdate$ = function (id, payload) {
        return this._http.put("/clouddns/domain/" + id + "/record-soa", payload);
    };
    DomainsBaseApi.prototype.getDnssecKey$ = function (id) {
        return this._http.get("/clouddns/domain/" + id + "/public-key");
    };
    DomainsBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function DomainsBaseApi_Factory() { return new DomainsBaseApi(i0.inject(i1.HttpClient)); }, token: DomainsBaseApi, providedIn: "root" });
    return DomainsBaseApi;
}());
export { DomainsBaseApi };
