import * as tslib_1 from "tslib";
import { ModuleTokens } from './client-services-base.constant';
var ClientServicesBaseState = /** @class */ (function () {
    function ClientServicesBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
        this.list = [];
    }
    return ClientServicesBaseState;
}());
export { ClientServicesBaseState };
var ClientServicesEntityEntities = /** @class */ (function () {
    function ClientServicesEntityEntities() {
    }
    return ClientServicesEntityEntities;
}());
export { ClientServicesEntityEntities };
ModuleTokens.Name;
var ClientServiceEntityNormalizedData = /** @class */ (function () {
    function ClientServiceEntityNormalizedData() {
    }
    return ClientServiceEntityNormalizedData;
}());
export { ClientServiceEntityNormalizedData };
var ClientServiceEntityLight = /** @class */ (function () {
    function ClientServiceEntityLight() {
    }
    return ClientServiceEntityLight;
}());
export { ClientServiceEntityLight };
var ClientServiceEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ClientServiceEntityBase, _super);
    function ClientServiceEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientServiceEntityBase;
}(ClientServiceEntityLight));
export { ClientServiceEntityBase };
