import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { ClientServiceStatuses } from '@app/base/client-services-base';

@Component({
  selector: 'vshcz-client-service-status',
  templateUrl: './client-service-status.component.html',
  styleUrls: [ './client-service-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientServiceStatusComponent {

  statuses = ClientServiceStatuses;

  @Input()
  tooltip: string;

  @Input()
  status: ClientServiceStatuses;
}
