import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, switchMap, withLatestFrom, catchError, mergeMap } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '../auth-base';
import { ActionTypes, ListLocalSuccess, ListFail, EntityLocalSuccess, EntityFail, GraphLocalSuccess, GraphFail, IpRangeLocalSuccess, IpRangeFail } from './servers-base.action';
import { ServersBaseApi } from './servers-base.api';
var ServersBaseEffect = /** @class */ (function () {
    function ServersBaseEffect(_actions$, _store, _api, _errorTranslation) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._errorTranslation = _errorTranslation;
        this._onEntityRequest$ = this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        }));
        this._onGraphRequest$ = this._actions$.pipe(ofType(ActionTypes.GraphRequest), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .graph$(payload.id, payload.graphType, payload.graphInterval)
                .pipe(map(function (response) { return new GraphLocalSuccess(response.serverGraphList, payload.id, payload.graphType); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new GraphFail(data, payload.id); })); }));
        }));
        this._onListRequest$ = this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], clientId = _a[1];
            return _this._api
                .list$(clientId)
                .pipe(map(function (_a) {
                var serverList = _a.serverList;
                return new ListLocalSuccess(serverList);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        this._onIpRangeRequest$ = this._actions$.pipe(ofType(ActionTypes.IpRangeRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .ipRange$(payload)
                .pipe(map(function (response) { return new IpRangeLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new IpRangeFail(data); })); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersBaseEffect.prototype, "_onEntityRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersBaseEffect.prototype, "_onGraphRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersBaseEffect.prototype, "_onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersBaseEffect.prototype, "_onIpRangeRequest$", void 0);
    return ServersBaseEffect;
}());
export { ServersBaseEffect };
