import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { TicketsEntityLight, TicketsEntity, TicketTopicEntity } from './tickets-base.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TicketsBaseApi = /** @class */ (function () {
    function TicketsBaseApi(_http) {
        this._http = _http;
    }
    TicketsBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/ticket");
    };
    TicketsBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/api/public/ticket/" + id);
    };
    TicketsBaseApi.prototype.message$ = function (id, messageText, extId, attachments) {
        if (attachments === void 0) { attachments = []; }
        return this._http.post("/api/public/ticket/" + id + "/message", {
            messageText: messageText,
            attachments: attachments,
            extId: extId
        });
    };
    TicketsBaseApi.prototype.attachment$ = function (id) {
        return this._http.get("/api/public/ticket-message-attachment/" + id);
    };
    TicketsBaseApi.prototype.unlock$ = function (id) {
        return this._http.put("/api/public/ticket/" + id + "/unlock", {});
    };
    TicketsBaseApi.prototype.approvePriceOffer$ = function (id, lastAdminTicketMessageId, clientId) {
        return this._http.put("/api/public/ticket/" + id + "/confirm-price-offer", {
            lastAdminTicketMessageId: lastAdminTicketMessageId,
            clientId: clientId
        });
    };
    TicketsBaseApi.prototype.rejectPriceOffer$ = function (id, clientId, messageText) {
        return this._http.put("/api/public/ticket/" + id + "/reject-price-offer", {
            messageText: messageText,
            clientId: clientId
        });
    };
    TicketsBaseApi.prototype.confirmRealizationDate$ = function (id, clientId) {
        return this._http.put("/api/public/ticket/" + id + "/confirm-realization-date", {
            clientId: clientId
        });
    };
    TicketsBaseApi.prototype.rejectRealizationDate$ = function (id, clientId, messageText) {
        return this._http.put("/api/public/ticket/" + id + "/reject-realization-date", {
            messageText: messageText,
            clientId: clientId
        });
    };
    TicketsBaseApi.prototype.updateCopyList$ = function (id, copyList) {
        return this._http.put("/api/public/ticket/" + id + "/copy-list", { copyList: copyList });
    };
    TicketsBaseApi.prototype.ticketTopicList$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/ticket-topic");
    };
    TicketsBaseApi.prototype.rate$ = function (id, clientId, rating) {
        return this._http.put("/api/public/ticket/" + id + "/rate", tslib_1.__assign({ clientId: clientId }, rating));
    };
    TicketsBaseApi.prototype.authorize$ = function (id, clientId) {
        return this._http.put("api/public/ticket/" + id + "/authorize", {
            clientId: clientId ? clientId : undefined
        });
    };
    TicketsBaseApi.prototype.secureCommunicationCheck$ = function (email, clientId) {
        return this._http.post("/api/public/user/secure-communication-check", { email: email, clientId: clientId });
    };
    TicketsBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function TicketsBaseApi_Factory() { return new TicketsBaseApi(i0.inject(i1.HttpClient)); }, token: TicketsBaseApi, providedIn: "root" });
    return TicketsBaseApi;
}());
export { TicketsBaseApi };
