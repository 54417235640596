import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './payments-base.constant';
import { PaymentsState } from './payments-base.model';
import {
  Actions,
  ActionTypes
} from './payments-base.action';

const initialState = new PaymentsState();

export function reducer(
  state = initialState,
  action: Actions
): PaymentsState {
  state = applyEntities<PaymentsState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === ActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  return state;
}
