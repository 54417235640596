import { UploadSelectItem } from '@app/common/upload';

export interface FileUploadSelectItem extends UploadSelectItem {
  uuid?: string;
  done?: boolean;
  progress?: number;
}

export class FilesState {
  queue: {
    [key: string]: FileUploadSelectItem[];
  };
  uploadedFiles: {
    [key: string]: string[];
  };

  constructor() {
    this.queue = {};
    this.uploadedFiles = {};
  }
}

export class FileRawPayload {
  instanceId: string;
  files: UploadSelectItem[];
}

export class FilePayload {
  instanceId: string;
  files: FileUploadSelectItem[];
}
