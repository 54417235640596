import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClouddnsWebsocketsState } from './clouddns-websockets.model';
import { ModuleTokens } from './clouddns-websockets.constant';

export const slice = createFeatureSelector<ClouddnsWebsocketsState>(ModuleTokens.Name);

export const reconnecting = createSelector(
  slice,
  (s) => s.reconnecting
);
