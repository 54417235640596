import { Actions, ActionTypes } from './ticket-authorization.action';
import { TicketAuthorizationState } from './ticket-authorization.model';
import { applyFormReducer } from './ticket-authorization.forms';
import { ModuleTokens } from './ticket-authorization.constant';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

const initialState = new TicketAuthorizationState();

export function reducer(
  state = initialState,
  action: Actions
): TicketAuthorizationState {
  state = applyFormReducer(
    state,
    action,
    ModuleTokens.Name,
    {
      clientId: validate(required)
    }
  );

  // reset
  if (action.type === ActionTypes.AuthorizeLocalSuccess) {
    return { ...initialState };
  }

  return state;
}
