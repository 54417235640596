import { Action } from '@zerops/fe/core';
import { ProgressAction, ProgressActionMeta, ErrorsActionMeta, ErrorsAction, ApiError } from '@zerops/fe/ngrx/zerops-fe-ngrx';

export enum ActionTypes {
  KvmKeepaliveRequest = '[Kvm Console Connection] KVM Keepalive Request',
  KvmKeepaliveLocalSuccess = '[Kvm Console Connection] KVM Keepalive Local Success',
  KvmKeepaliveFail = '[Kvm Console Connection] KVM Keepalive Fail'
}

export class KvmKeepaliveRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmKeepaliveRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.KvmKeepaliveFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.KvmKeepaliveRequest,
      type: 'local'
    }
  };

  constructor(public payload: string) {}
}

export class KvmKeepaliveLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.KvmKeepaliveLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmKeepaliveRequest
  };
}

export class KvmKeepaliveFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmKeepaliveFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmKeepaliveRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.KvmKeepaliveFail,
        type: 'local',
        data
      }
    };
  }
}

export type KvmConsoleConnectionActions
  = KvmKeepaliveRequest
  | KvmKeepaliveLocalSuccess
  | KvmKeepaliveFail;
