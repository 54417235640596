import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from '../avatar';
import { CompanySelectComponent } from './company-select.component';
import { ScrollableModule } from '../scrollable';

@NgModule({
  declarations: [ CompanySelectComponent ],
  imports: [
    CommonModule,
    MatListModule,
    TruncateModule,
    TranslateModule.forChild(),
    AvatarModule,
    ScrollableModule
  ],
  exports: [ CompanySelectComponent ]
})
export class CompanySelectModule {
}
