import { EventEmitter, TemplateRef } from '@angular/core';
import { PopoverComponent } from '@app/common/popover';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
var FilterPopButtonGroupDirective = /** @class */ (function () {
    function FilterPopButtonGroupDirective() {
    }
    return FilterPopButtonGroupDirective;
}());
export { FilterPopButtonGroupDirective };
var FilterPopButtonItemDirective = /** @class */ (function () {
    function FilterPopButtonItemDirective() {
    }
    return FilterPopButtonItemDirective;
}());
export { FilterPopButtonItemDirective };
var FilterPopButtonComponent = /** @class */ (function () {
    function FilterPopButtonComponent() {
        this.extras = 0;
        this.maxSize = 2;
        this.reset = new EventEmitter();
    }
    Object.defineProperty(FilterPopButtonComponent.prototype, "disableReset", {
        get: function () { return this._disableReset; },
        set: function (v) {
            this._disableReset = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopButtonComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (v) {
            var sliced = v.slice().slice()
                .splice(0, this.maxSize);
            this.extras = v.length - sliced.length;
            this._items = sliced;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopButtonComponent.prototype, "groupTemplate", {
        get: function () { return this._groupTemplate; },
        set: function (v) {
            this._groupTemplate = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopButtonComponent.prototype, "itemTemplate", {
        get: function () { return this._itemTemplate; },
        set: function (v) {
            this._itemTemplate = v;
        },
        enumerable: true,
        configurable: true
    });
    return FilterPopButtonComponent;
}());
export { FilterPopButtonComponent };
