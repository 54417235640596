<span
  class="__sticker"
  [class.has-sticker-only]="stickerOnly"
  [matTooltip]="'ticketState.' + state | translate"
  [matTooltipDisabled]="!stickerOnly"
  [ngClass]="{
    'is-close': state === 'CLOSED',
    'is-planned': state === 'PLANNED',
    'is-resolved': state === 'RESOLVED',
    'is-accepted': state === 'ACCEPTED',
    'is-in-realisation': state === 'IN_REALISATION',
    'is-waiting-for-client': state === 'WAITING_FOR_CLIENT',
    'is-waiting-for-authorisation': state === 'WAITING_AUTHORIZATION'
  }">
</span>
<span *ngIf="!stickerOnly" [translate]="'ticketState.' + state"></span>
