<div
  fxLayout="row wrap"
  fxLayoutAlign="center center">

  <span
    *ngIf="!!title"
    class="__title">
    {{ title }}
  </span>

  <div fxFlex="100" class="u-text--center">
    <vshcz-avatar
      class="__avatar"
      size="60"
      source="gravatar"
      [mail]="mail">
    </vshcz-avatar>
  </div>

  <div fxFlex="100">
    <div class="__content">

      <h4 *ngIf="!!name" class="__name">{{ name }}</h4>

      <a
        *ngIf="mail"
        class="__mail"
        [href]="'mailto:' + mail">
        {{ mail }}
      </a>

      <span class="__phone" *ngIf="!!phone">
        +{{ phonePrefix + '' + phone }}
      </span>

      <span
        *ngIf="!!text"
        class="__text">
        {{ text }}
      </span>

    </div>
  </div>

</div>
