import { UserInfoFormTranslations } from './user-info-form.model';

const _prefix = 'user-info-form_';

export const DEFAULT_TRANSLATION_KEYS: UserInfoFormTranslations = {
  labels: {
    firstName: _prefix + 'labels_firstName',
    lastName: _prefix + 'labels_lastName',
    phoneNumber: _prefix + 'labels_phoneNumber',
    smsNotifications: _prefix + 'labels_smsNotifications',
    language: _prefix + 'labels_language'
  },
  errors: {
    firstName: {
      required: _prefix + 'errors_firstName_required'
    },
    lastName: {
      required: _prefix + 'errors_lastName_required'
    },
    phoneNumber: {
      required: _prefix + 'errors_phoneNumber_required'
    }
  }
};
