/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-client-user-dialog.container.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@zerops/fe/dialog/zerops-fe-dialog.ngfactory";
import * as i3 from "@zerops/fe/dialog";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../common/company-select/company-select.component.ngfactory";
import * as i8 from "../../../../common/company-select/company-select.component";
import * as i9 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "./no-client-user-dialog.container";
import * as i17 from "@ngrx/store";
var styles_NoClientUserDialogContainer = [i0.styles];
var RenderType_NoClientUserDialogContainer = i1.ɵcrt({ encapsulation: 0, styles: styles_NoClientUserDialogContainer, data: {} });
export { RenderType_NoClientUserDialogContainer as RenderType_NoClientUserDialogContainer };
export function View_NoClientUserDialogContainer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "zps-dialog", [], null, null, null, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.DialogComponent, [i4.MatDialog], { open: [0, "open"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 18, "div", [["class", "__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "__title"], ["translate", "loginCompanySelect.title"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "__desc"], ["translate", "loginCompanySelect.desc"]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "vshcz-company-select", [], null, [[null, "companySelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("companySelect" === en)) {
        var pd_0 = (_co.onSetClientId$.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CompanySelectComponent_0, i7.RenderType_CompanySelectComponent)), i1.ɵdid(9, 49152, null, 0, i8.CompanySelectComponent, [], { activeId: [0, "activeId"], clientUserList: [1, "clientUserList"] }, { companySelect: "companySelect" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "c-or"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "c-or__text"], ["translate", "common.or"]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 6, "button", [["class", "__button"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout$.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(18, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["exit_to_app"])), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "span", [["translate", "loginCompanySelect.logout"]], null, null, null, null, null)), i1.ɵdid(21, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dialogOpen$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "loginCompanySelect.title"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "loginCompanySelect.desc"; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.activeClientId$)); var currVal_4 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 11).transform(_co.userCompanies$)); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = "common.or"; _ck(_v, 14, 0, currVal_5); _ck(_v, 18, 0); var currVal_10 = "loginCompanySelect.logout"; _ck(_v, 21, 0, currVal_10); }, function (_ck, _v) { var currVal_6 = (i1.ɵnov(_v, 16).disabled || null); var currVal_7 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 18).inline; var currVal_9 = (((i1.ɵnov(_v, 18).color !== "primary") && (i1.ɵnov(_v, 18).color !== "accent")) && (i1.ɵnov(_v, 18).color !== "warn")); _ck(_v, 17, 0, currVal_8, currVal_9); }); }
export function View_NoClientUserDialogContainer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-no-client-user-dialog", [], null, null, null, View_NoClientUserDialogContainer_0, RenderType_NoClientUserDialogContainer)), i1.ɵdid(1, 245760, null, 0, i16.NoClientUserDialogContainer, [i17.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoClientUserDialogContainerNgFactory = i1.ɵccf("vshcz-no-client-user-dialog", i16.NoClientUserDialogContainer, View_NoClientUserDialogContainer_Host_0, {}, {}, []);
export { NoClientUserDialogContainerNgFactory as NoClientUserDialogContainerNgFactory };
