import { applyEntities, ResetActionTypes } from '@zerops/fe/core';
// direct import because of Circular dependency
import {
  ActionTypes as TicketAddActionTypes,
  Actions as TicketAddActions
} from '@app/common/ticket-add/ticket-add.action';
import {
  Actions as TicketAuthorizeActions,
  ActionTypes as TicketAuthorizationActionTypes
} from '@app/common/ticket-authorization/ticket-authorization.action';
import { TicketsBaseState } from './tickets-base.model';
import { ModuleTokens } from './tickets-base.constant';
import { Actions, ActionTypes } from './tickets-base.action';

const initialState = new TicketsBaseState();

export function reducer(
  state = initialState,
  action: Actions | TicketAddActions | TicketAuthorizeActions
): TicketsBaseState {
  state = applyEntities<TicketsBaseState>(
    state,
    [ ModuleTokens.Name, ModuleTokens.Messages ],
    action
  );

  switch (action.type) {
    case ActionTypes.TicketTopicListLocalSuccess:
      return {
        ...state,
        ticketTopics: action.payload
      };

    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        list: action.payload.result
      };

    case TicketAddActionTypes.AddLocalSuccess:
      return {
        ...state,
        list: [
          ...action.payload.result,
          ...state.list
        ]
      };

    case TicketAuthorizationActionTypes.AuthorizeLocalSuccess:
      if (!!action.meta) {
        return {
          ...state,
          list: [ ...state.list ].filter((ticketId) => ticketId !== action.payload)
        };

      } else {
        return state;
      }

  }

  if (action.type as any === ResetActionTypes.Reset) {
    return state = {
      ...initialState
    };
  }

  return state;
}
