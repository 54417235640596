<div class="__content" *ngIf="active">

  <h4
    class="mat-h4  __title"
    translate="ticketRating.title">
  </h4>

  <p
    class="mat-body  __text"
    translate="ticketRating.desc">
  </p>

  <div class="__buttons">
    <vshcz-progress-item
      class="__progress-item"
      [spinnerSize]="20"
      [active]="rateRunning$ | async">

      <button
        mat-icon-button
        type="button"
        class="__rating-button is-red"
        (click)="onRate$.next(1)">
        <mat-icon>sentiment_very_dissatisfied</mat-icon>
      </button>

      <button
        mat-icon-button
        type="button"
        class="__rating-button is-orange"
        (click)="onRate$.next(2)">
        <mat-icon>sentiment_neutral</mat-icon>
      </button>

      <button
        mat-icon-button
        type="button"
        class="__rating-button is-light-green"
        (click)="onRate$.next(3)">
        <mat-icon>sentiment_satisfied</mat-icon>
      </button>

      <button
        mat-icon-button
        type="button"
        class="__rating-button is-green"
        (click)="onRate$.next(4)">
        <mat-icon>sentiment_very_satisfied</mat-icon>
      </button>

    </vshcz-progress-item>
  </div>

</div>

<zps-dialog class="__dialog" [open]="dialogOpen$ | async">
  <!-- TODO: component -->
  <vshcz-form
    class="__dialog_content"
    #formRef
    [state]="formState$ | async"
    (submit)="onRate$.next()"
    (submitError)="scrollToError($event.offset, $event.element)">

    <vshcz-content-title
      class="__dialog-title"
      size="small"
      position="left">
      {{ 'ticketRating.badRatingHeading' | translate }}
    </vshcz-content-title>

    <vshcz-form-field>
      <vshcz-star-field
        class="__dialog-stars"
        [title]="'ticketRating.speedTitle' | translate"
        [ngrxFormControlState]="(formState$ | async)?.controls.speed">
      </vshcz-star-field>
    </vshcz-form-field>

    <vshcz-form-field>
      <vshcz-star-field
        class="__dialog-stars"
        [title]="'ticketRating.expertiseTitle' | translate"
        [ngrxFormControlState]="(formState$ | async)?.controls.expertise">
      </vshcz-star-field>
    </vshcz-form-field>

    <vshcz-form-field>
      <vshcz-star-field
        class="__dialog-stars"
        [title]="'ticketRating.communicationTitle' | translate"
        [ngrxFormControlState]="(formState$ | async)?.controls.communication">
      </vshcz-star-field>
    </vshcz-form-field>

    <vshcz-form-field>
      <mat-form-field>
        <textarea
          [placeholder]="'ticketRating.messagePlaceholder' | translate"
          rows="5"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="(formState$ | async)?.controls.message"
          matInput></textarea>
      </mat-form-field>
    </vshcz-form-field>

    <vshcz-progress-error
      [errorCloseLabel]="'common.close' | translate"
      [errorRetryLabel]="'common.retry' | translate"
      [errorShowDetailsLabel]="'common.showMore' | translate"
      [errorHideDetailsLabel]="'common.hideMore' | translate"
      [errorKey]="rateErrroKey$ | async"
      (retry)="formRef.triggerSubmit()"
      [progressKey]="rateRequestKey$ | async">
      <button
        mat-raised-button
        color="accent"
        type="submit"
        class="__dialog_confirm-button"
        [class.is-disabled]="!(formState$ | async)?.controls?.speed?.value
          || !(formState$ | async)?.controls?.expertise?.value
          || !(formState$ | async)?.controls?.communication?.value">
        <mat-icon>check_circle_outline</mat-icon><span translate="ticketRating.sendRating"></span>
      </button>
    </vshcz-progress-error>

    <button
      mat-icon-button
      type="button"
      [zpsNgrxDialogClose]="rateRequestKey"
      (click)="resetRating()"
      class="__dialog_close">
      <mat-icon>close</mat-icon>
    </button>

  </vshcz-form>
</zps-dialog>
