import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TicketAddApi = /** @class */ (function () {
    function TicketAddApi(_http) {
        this._http = _http;
    }
    TicketAddApi.prototype.add$ = function (data, clientId) {
        return this._http.post("/api/public/ticket", tslib_1.__assign({}, this._cleanupAdd$Data(data), { clientId: clientId }));
    };
    TicketAddApi.prototype._cleanupAdd$Data = function (data) {
        return tslib_1.__assign({}, data, { attachments: data.attachments
                ? data.attachments
                : [] });
    };
    TicketAddApi.ngInjectableDef = i0.defineInjectable({ factory: function TicketAddApi_Factory() { return new TicketAddApi(i0.inject(i1.HttpClient)); }, token: TicketAddApi, providedIn: "root" });
    return TicketAddApi;
}());
export { TicketAddApi };
