<!-- canceled -->
<ng-container *ngIf="status === invoiceStatuses.Canceled">

  <span
    class="__text"
    [innerHTML]="'invoiceItem.created' | translate">
  </span>

  <strong
    class="__state  is-canceled"
    [innerHTML]="created | date: 'mediumDate'">
  </strong>

</ng-container>

<!-- paid -->
<ng-container *ngIf="status === invoiceStatuses.Paid">

  <span
    class="__text"
    [innerHTML]="'invoiceItem.paid' | translate">
  </span>

  <strong
    class="__state  is-paid"
    [innerHTML]="paidDate | date: 'mediumDate'">
  </strong>

</ng-container>

<!-- payment overdue -->
<ng-container *ngIf="status === invoiceStatuses.PaymentOverdue">

  <strong
    class="__state  is-overdue"
    [innerHTML]="'invoiceItem.daysNumber' | translate: { days: daysOverdue }">
  </strong>

  <span
    class="__text"
    [innerHTML]="'invoiceItem.overdue' | translate">
  </span>

</ng-container>

<!-- waiting for payment -->
<ng-container *ngIf="status === invoiceStatuses.WaitingForPayment">

  <ng-container>

    <span
      class="__text"
      [innerHTML]="'invoiceItem.dueIn' | translate">
    </span>

    <strong
      class="__state  is-waiting"
      [innerHTML]="'invoiceItem.daysNumber' | translate: { days: diffDays }">
    </strong>

  </ng-container>

  <strong
    *ngIf="diffDays === 0"
    class="__state  is-waiting"
    translate="invoiceItem.today">
  </strong>

</ng-container>
