/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../popover/popover.component.ngfactory";
import * as i5 from "../popover/popover.component";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i9 from "@angular/material/card";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./confirm.component";
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
function View_ConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.confirmIcon; _ck(_v, 2, 0, currVal_2); }); }
export function View_ConfirmComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { popRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "vshcz-popover", [["hasBackdrop", ""], ["horizontalAlign", "center"], ["verticalAlign", "below"]], null, null, null, i4.View_PopoverComponent_0, i4.RenderType_PopoverComponent)), i1.ɵdid(2, 245760, [[1, 4], ["popRef", 4]], 0, i5.PopoverComponent, [i6.FocusTrapFactory, [2, i7.DOCUMENT]], { horizontalAlign: [0, "horizontalAlign"], verticalAlign: [1, "verticalAlign"], hasBackdrop: [2, "hasBackdrop"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 13, "mat-card", [["class", "__pop  mat-elevation-z7 mat-card"]], null, null, null, i8.View_MatCard_0, i8.RenderType_MatCard)), i1.ɵdid(4, 49152, null, 0, i9.MatCard, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "h3", [["class", "mat-h3"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵncd(0, 0), (_l()(), i1.ɵeld(7, 0, null, 0, 9, "mat-card-actions", [["align", "end"], ["class", "__actions mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i9.MatCardActions, [], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.confirm.emit();
        var pd_0 = (i1.ɵnov(_v, 2).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i6.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.close.emit();
        var pd_0 = (i1.ɵnov(_v, 2).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i6.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(16, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center"; var currVal_1 = "below"; var currVal_2 = ""; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = "end"; _ck(_v, 8, 0, currVal_5); var currVal_8 = _co.confirmColor; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.confirmIcon; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.heading; _ck(_v, 5, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 8).align === "end"); _ck(_v, 7, 0, currVal_4); var currVal_6 = (i1.ɵnov(_v, 10).disabled || null); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_10 = _co.confirmButton; _ck(_v, 13, 0, currVal_10); var currVal_11 = (i1.ɵnov(_v, 15).disabled || null); var currVal_12 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_11, currVal_12); var currVal_13 = _co.closeButton; _ck(_v, 16, 0, currVal_13); }); }
export function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i14.ConfirmComponent, [], null, null)], null, null); }
var ConfirmComponentNgFactory = i1.ɵccf("vshcz-confirm", i14.ConfirmComponent, View_ConfirmComponent_Host_0, { confirmButton: "confirmButton", confirmIcon: "confirmIcon", confirmColor: "confirmColor", closeButton: "closeButton", heading: "heading" }, { confirm: "confirm", close: "close" }, ["*"]);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
