<div
  class="__item_primary"
  fxLayout="row"
  fxLayoutAlign="space-between center">

  <div
    fxLayout="row"
    fxLayoutAlign="center center">

    <span class="__name" [innerHTML]="name | htmlEntities">
    </span>

    <vshcz-client-service-status
      class="__status"
      [status]="clientZoneStatus"
      [tooltip]="'clientServiceStatuses.tooltips.' + clientZoneStatus | translate">
    </vshcz-client-service-status>

  </div>

  <!-- <vshcz-price
    [priceSize]="priceSize"
    [symbolSize]="symbolSize"
    [amount]="serviceType === individualOneTime ? oneTimePrice : monthPriceDiscounted"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap">
  </vshcz-price> -->

</div>

<span
  class="__item_secondary"
  fxLayout="row"
  fxLayoutAlign="space-between center">

  <div>

    <span
      class="__identification"
      [innerHTML]="identification">
    </span>

    <ng-container *ngIf="activationDate">

      &nbsp;<strong>&sdot;</strong>&nbsp;

      <span
        class="__date"
        [innerHTML]="'clientServiceItem.installed' | translate: { date: activationDate | date: 'mediumDate' }">
      </span>

    </ng-container>

  </div>
<!--
  <span
    *ngIf="serviceType !== individualOneTime
      && billingPeriod?.name
      && languageId"
    class="__billing-period-name">
    {{ billingPeriod.name[languageId] }}
  </span> -->

</span>
