var ContentTitleComponent = /** @class */ (function () {
    function ContentTitleComponent() {
    }
    Object.defineProperty(ContentTitleComponent.prototype, "textAlignStyle", {
        get: function () {
            return this.position;
        },
        enumerable: true,
        configurable: true
    });
    return ContentTitleComponent;
}());
export { ContentTitleComponent };
