import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { FormsModule } from '@app/common/forms';
import { RealizationDateRejectFormComponent } from './realization-date-reject-form.component';

@NgModule({
  declarations: [ RealizationDateRejectFormComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslateModule.forChild(),
    NgrxFormsModule,
    NgrxFormsExtensionsModule,
    FormsModule
  ],
  exports: [ RealizationDateRejectFormComponent ]
})
export class RealizationDateRejectFormModule {

}
