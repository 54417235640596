import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFnsDateAdapter } from './date-fns-adapter.adapter';
import { MAT_DATE_FNS_DATE_FORMATS } from './date-fns-adapter.formats';

@NgModule({
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_DATE_FNS_DATE_FORMATS
    }
  ]
})
export class DateFnsAdapterModule {
}
