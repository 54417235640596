import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import { SnackService } from '@app/common/snack';
import { switchMap, map, catchError, filter, first } from 'rxjs/operators';
import omit from 'lodash-es/omit';
import { ErrorTranslationService } from '@app/services';
import { DomainRecordBaseApi } from './domain-record-base.api';
import { getDomainEntityById } from './domains-base.selector';
import { ActionTypes, AddFail, AddLocalSuccess, EntityLocalSuccess, EntityFail, UpdateLocalSuccess, UpdateFail, DeleteLocalSuccess, DeleteFail, RestoreLocalSuccess, RestoreFail } from './domain-record-base.action';
var DomainRecordBaseEffect = /** @class */ (function () {
    function DomainRecordBaseEffect(_actions$, _store, _api, _errorTranslation, _snack) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._errorTranslation = _errorTranslation;
        this._snack = _snack;
        this._onAddRequest$ = this._actions$.pipe(ofType(ActionTypes.AddRequest), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var type = _a.type, domainId = _a.domainId, data = _a.data;
            return _this._store.pipe(select(getDomainEntityById(domainId)), filter(function (d) { return !!d; }), first(), map(function (domain) { return ({ domain: domain, type: type, data: data }); }));
        }), switchMap(function (_a) {
            var type = _a.type, domain = _a.domain, data = _a.data;
            return _this._api
                .add$(type, domain.id, _this._normalizeFormData(data, domain))
                .pipe(map(function (result) { return new AddLocalSuccess(result.id); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new AddFail(errData); })); }));
        }));
        this._onUpdateRequest$ = this._actions$.pipe(ofType(ActionTypes.UpdateRequest), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var type = _a.type, id = _a.id, domainId = _a.domainId, data = _a.data;
            return _this._store.pipe(select(getDomainEntityById(domainId)), filter(function (d) { return !!d; }), first(), map(function (domain) { return ({ domain: domain, id: id, type: type, data: data }); }));
        }), switchMap(function (_a) {
            var type = _a.type, id = _a.id, data = _a.data, domain = _a.domain;
            return _this._api
                .update$(type, id, _this._normalizeFormData(data, domain))
                .pipe(map(function () { return new UpdateLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new UpdateFail(errData); })); }));
        }));
        // delete request
        this._onDeleteRequest$ = this._actions$.pipe(ofType(ActionTypes.DeleteRequest), map(function (action) { return action.payload; }), switchMap(function (id) {
            return _this._api
                .delete$(id)
                .pipe(map(function () { return new DeleteLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new DeleteFail(errData, id); })); }));
        }));
        // delete fail snack
        this._onDeleteFailSnack$ = this._actions$.pipe(ofType(ActionTypes.DeleteFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.DeleteFail); }));
        // delete success snack
        this._onDeleteSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.DeleteLocalSuccess), switchMap(function () { return _this._snack.translatedWarning$('domainsBase.deleteRecordSuccessSnack', 'common.close'); }));
        // restore request
        this._onRestoreRequest$ = this._actions$.pipe(ofType(ActionTypes.RestoreRequest), map(function (action) { return action.payload; }), switchMap(function (id) {
            return _this._api
                .restore$(id)
                .pipe(map(function () { return new RestoreLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new RestoreFail(errData, id); })); }));
        }));
        // restore fail snack
        this._onRestoreFailSnack$ = this._actions$.pipe(ofType(ActionTypes.RestoreFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.RestoreFail); }));
        // restore success snack
        this._onRestoreSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.RestoreLocalSuccess), switchMap(function () { return _this._snack.translatedSuccess$('domainsBase.restoreRecordSuccessSnack', 'common.close'); }));
        // entity request
        this._onEntityRequest$ = this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload.id, payload.type)
                .pipe(map(function (data) { return new EntityLocalSuccess(data); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new EntityFail(errData, payload.id); })); }));
        }));
    }
    // data.name is filled only if we are adding a subdomain
    // otherwise we can use domain name
    DomainRecordBaseEffect.prototype._normalizeFormData = function (
    // TODO: shared interface with form payload base
    formData, domain) {
        var trimmedName = formData.name ? formData.name.trim() : undefined;
        return tslib_1.__assign({}, omit(formData, 'subdomain'), { name: trimmedName
                ? "" + (trimmedName.endsWith('.') ? trimmedName : (trimmedName + '.')) + domain.domainName
                : domain.domainName });
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onAddRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onUpdateRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onDeleteRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onDeleteFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onDeleteSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onRestoreRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onRestoreFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onRestoreSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainRecordBaseEffect.prototype, "_onEntityRequest$", void 0);
    return DomainRecordBaseEffect;
}());
export { DomainRecordBaseEffect };
