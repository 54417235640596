/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ws-reconnect.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./ws-reconnect.component";
var styles_WsReconnectComponent = [i0.styles];
var RenderType_WsReconnectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WsReconnectComponent, data: { "animation": [{ type: 7, name: "reconnecting", definitions: [{ type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1, transform: "translate3d(0, 0, 0)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translate3d(0, 10px, 0)" }, offset: null }, timings: 120 }], options: null }], options: {} }, { type: 7, name: "reconnected", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translate3d(0, -10px, 0)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translate3d(0, 0px, 0)" }, offset: null }, timings: 170 }], options: null }], options: {} }, { type: 7, name: "wrap", definitions: [{ type: 0, name: "0", styles: { type: 6, styles: { backgroundColor: "#4cb75f" }, offset: null }, options: undefined }, { type: 0, name: "1", styles: { type: 6, styles: { backgroundColor: "#ea6c6c" }, offset: null }, options: undefined }, { type: 1, expr: "1 => 0", animation: [{ type: 4, styles: null, timings: "150ms 100ms" }], options: null }], options: {} }] } });
export { RenderType_WsReconnectComponent as RenderType_WsReconnectComponent };
function View_WsReconnectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "__reconnecting"]], [[24, "@reconnecting", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "__title"], ["translate", "wsReconnect.reconnecting"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "__note"], ["translate", "wsReconnect.outdated"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-spinner", [["class", "__spinner mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(8, 49152, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_1 = "wsReconnect.reconnecting"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "wsReconnect.outdated"; _ck(_v, 6, 0, currVal_2); var currVal_6 = 25; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 8)._noopAnimations; var currVal_4 = i1.ɵnov(_v, 8).diameter; var currVal_5 = i1.ɵnov(_v, 8).diameter; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }); }
function View_WsReconnectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "__success"], ["translate", "wsReconnect.connected"]], [[24, "@reconnected", 0]], null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_1 = "wsReconnect.connected"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_WsReconnectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "__wrap"]], [[24, "@wrap", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WsReconnectComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WsReconnectComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.state === _co.states.reconnecting); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.state === _co.states.reconnected); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 0, 0, currVal_0); }); }
export function View_WsReconnectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-ws-reconnect", [], null, null, null, View_WsReconnectComponent_0, RenderType_WsReconnectComponent)), i1.ɵdid(1, 49152, null, 0, i8.WsReconnectComponent, [], null, null)], null, null); }
var WsReconnectComponentNgFactory = i1.ɵccf("vshcz-ws-reconnect", i8.WsReconnectComponent, View_WsReconnectComponent_Host_0, { state: "state" }, {}, []);
export { WsReconnectComponentNgFactory as WsReconnectComponentNgFactory };
