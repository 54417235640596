import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxStripeModule } from 'ngx-stripe';
import { ContentTitleModule } from '../content-title';
import { PriceModule } from '../price';
import { InvoiceItemModule } from '../invoice-item';
import { ScrollableModule } from '../scrollable';
import { BulkPaymentInvoicesDialogContainer } from './bulk-payment-invoices-dialog.container';
import { BulkPaymentPriceModule } from '../bulk-payment-price';
import { ProgressErrorModule } from '@app/common/progress-error';
import { PaymentInvoicesDialogEffect } from './bulk-payment-invoices-dialog.effect';

@NgModule({
  declarations: [ BulkPaymentInvoicesDialogContainer ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ PaymentInvoicesDialogEffect ]),
    FlexLayoutModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    DialogModule,
    TranslateModule.forChild(),
    NgxQRCodeModule,
    NgxStripeModule,
    ContentTitleModule,
    PriceModule,
    InvoiceItemModule,
    ScrollableModule,
    BulkPaymentPriceModule,
    ProgressErrorModule
  ],
  exports: [ BulkPaymentInvoicesDialogContainer ]
})
export class BulkPaymentInvoicesDialogModule {}
