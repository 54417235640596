import { coerceNumberProperty } from '@angular/cdk/coercion';
var PriceComponent = /** @class */ (function () {
    function PriceComponent() {
        this.mode = 'bold-price';
        this._priceSize = 20;
        this._symbolSize = 15;
    }
    Object.defineProperty(PriceComponent.prototype, "amount", {
        get: function () {
            return this._amount;
        },
        set: function (v) {
            this._amount = coerceNumberProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "priceSize", {
        get: function () {
            return this._priceSize;
        },
        set: function (v) {
            this._priceSize = coerceNumberProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "symbolSize", {
        get: function () {
            return this._symbolSize;
        },
        set: function (v) {
            this._symbolSize = coerceNumberProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    return PriceComponent;
}());
export { PriceComponent };
