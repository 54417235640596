<span
  [ngClass]="{
    'is-paid': status === invoiceStatuses.Paid,
    'is-waiting-for-payment': status === invoiceStatuses.WaitingForPayment,
    'is-canceled': status === invoiceStatuses.Canceled,
    'is-payment-overdue': status === invoiceStatuses.PaymentOverdue
  }"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  matTooltipPosition="after"
  matTooltipClass="c-search-tooltip"
  matTooltipShowDelay="400"
  class="__status-icon">
</span>
