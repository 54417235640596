import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ErrorTranslationService } from 'app/services';
import { KvmConsoleConnectionApi } from './kvm-console-connection.api';
import { ActionTypes, KvmKeepaliveFail, KvmKeepaliveLocalSuccess } from './kvm-console-connection.action';
var KvmConsoleConnectionEffect = /** @class */ (function () {
    function KvmConsoleConnectionEffect(_actions$, _api, _errorTranslation) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._errorTranslation = _errorTranslation;
        this._onKvmKeepaliveRequest$ = this._actions$.pipe(ofType(ActionTypes.KvmKeepaliveRequest), switchMap(function (action) { return _this._api
            .kvmKeepalive$(action.payload)
            .pipe(map(function () { return new KvmKeepaliveLocalSuccess(); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (message) { return new KvmKeepaliveFail(message); })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], KvmConsoleConnectionEffect.prototype, "_onKvmKeepaliveRequest$", void 0);
    return KvmConsoleConnectionEffect;
}());
export { KvmConsoleConnectionEffect };
