import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'vshcz-entity-badge',
  templateUrl: './entity-badge.component.html',
  styleUrls: ['./entity-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityBadgeComponent {
  @Input()
  entity: string;
}
