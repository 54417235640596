import { createFeatureSelector, createSelector } from '@ngrx/store';
import uniq from 'lodash-es/uniq';
// intentionally explicit because of dependency cycle errors
import {
  entities as clientUserEntities
} from '../client-user-base/client-user-base.selector';
import {
  ModuleTokens as ClientUserBaseModuleTokens
} from '../client-user-base/client-user-base.constant';
import {
  clientsBaseEntities,
  ClientsBaseModuleTokens,
  denormalizeClientEntity,
  ClientEntity
} from '@app/base/clients-base';
import {
  servicesEntities,
  ServicesBaseModuleTokens,
  ServiceEntity,
  ServiceCodes
} from '@app/base/services-base';
import {
  usersEntities,
  denormalizeUserEntity,
  UserEntity
} from '@app/base/users-base';
import { ModuleTokens } from './auth-base.constant';
import { AuthBaseState } from './auth-base.model';
import { isKvm } from '../servers-base/servers-base.utils';

interface ServiceGroup {
  key: string;
  items: ServiceEntity[];
}

export const slice = createFeatureSelector<AuthBaseState>(ModuleTokens.Name);

export const isAuthenticated = createSelector(slice, (s) => s.authorized);

export const userId = createSelector(slice, (s) => s.userId);

export const authorType = createSelector(slice, (s) => s.authorType);

export const cloudDnsRoleCode = createSelector(slice, (s) => s.cloudDnsRoleCode);

export const activeUserClientId = createSelector(slice, (s) => s.clientUserId);

export const referer = createSelector(slice, (s) => s.referer);

export const activeUserNormalizedClient = createSelector(
  activeUserClientId,
  clientsBaseEntities,
  (id, ents) => ents && ents[ClientsBaseModuleTokens.Name]
    ? ents[ClientsBaseModuleTokens.Name][id]
    : undefined
);

export const activeUserClient = createSelector(
  activeUserClientId,
  clientsBaseEntities,
  servicesEntities,
  (id, ents, servicesEnt) => {
    const activeUserClientObject = denormalizeClientEntity(
      id,
      ents[ClientsBaseModuleTokens.Name],
      servicesEnt[ServicesBaseModuleTokens.Name]
    ) as ClientEntity;
    return activeUserClientObject;
  }
);

export const activeUserServices = createSelector(
  activeUserClient,
  (client) => client ? client.connectedServiceList : []
);

const allManaged = [
'MANAGED_SERVER',
'CLOUD_MANAGED_SERVER',
'MANAGED_CLUSTER',
'M1_MANAGED_SERVER',
'M2_MANAGED_SERVER',
'MANAGED_WINDOWS_SERVER',
'MANAGED_WINDOWS_CLUSTER',
'MANAGED_WINDOWS_BASIC',
'MANAGED_WINDOWS_CLOUD',
'AWS_MANAGED_SERVER'
];

const managedAliasMap = {
  MANAGED_SERVER: 'managed-server',
  M1_MANAGED_SERVER: 'm1-managed-server',
  M2_MANAGED_SERVER: 'm2-managed-server',
  MANAGED_WINDOWS_SERVER: 'windows-managed-server',
  MANAGED_WINDOWS_CLUSTER: 'windows-managed-cluster',
  MANAGED_WINDOWS_BASIC: 'windows-managed-basic',
  MANAGED_WINDOWS_CLOUD: 'windows-managed-cloud',
  CLOUD_MANAGED_SERVER: 'cloud-managed-server',
  MANAGED_CLUSTER: 'managed-cluster',
  AWS_MANAGED_SERVER: 'aws-managed-server'
};

export const activeManagedServicesAliases = createSelector(
  activeUserClient,
  (client) => {
    if (!client || !client.connectedServiceList) { return []; }

    const s = client
      .connectedServiceList
      .filter((item) => !![
          'ACTIVE',
          'WAITING_FOR_TERMINATION'
        ].includes(item.clientZoneStatus)
      )
      .filter((item) => item.standardService && item.serviceType === 'STANDARD')
      .filter((item) => allManaged.includes(item.standardService.code))
      .map((item) => item.standardService.code)
      .map((item) => managedAliasMap[item]);

    return uniq(s);
  }
);

export const activeUserServicesActiveGrouped = createSelector(
  activeUserClient,
  (client) => {
    const serviceMap: { [key: string]: number; } = {};

    if (!client || !client.connectedServiceList) { return []; }

    return client
      .connectedServiceList
      .filter((item) => !![
          'ACTIVE',
          'WAITING_FOR_TERMINATION'
        ].includes(item.clientZoneStatus)
      )
      .filter((item) => item.standardService && item.serviceType === 'STANDARD')
      .reduce((arr: ServiceGroup[], item) => {

        const code = allManaged.includes(item.standardService.code)
          ? ServiceCodes.allManaged
          : item.standardService.code as ServiceCodes;

        if (serviceMap[code] === undefined) {
          arr.push({
            key: code,
            items: []
          });

          serviceMap[code] = arr.length - 1;
        }

        arr[serviceMap[code]].items = [
          ...arr[serviceMap[code]].items,
          {
            ...item,
            _isKvm: isKvm(code)
          }
        ];

        return arr;
      }, []);

  }
);

export const identity = createSelector(
  isAuthenticated,
  userId,
  clientUserEntities,
  clientsBaseEntities,
  usersEntities,
  servicesEntities,
  (auth, id, clientUserEnt, clientsEnt, usersEnt, servicesEnt) => {
    if (!auth || !id) { return undefined; }

    return denormalizeUserEntity(
      id,
      usersEnt.usersBase,
      clientUserEnt[ClientUserBaseModuleTokens.Name],
      clientsEnt[ClientsBaseModuleTokens.Name],
      servicesEnt[ServicesBaseModuleTokens.Name]
    ) as UserEntity;
  }
);

export const activeUserClientUsers = createSelector(
  identity,
  idnt => idnt ? idnt.clientUserList : []
);

export const activeClientUser = createSelector(
  activeUserClientId,
  identity,
  (cId, user) => {

    if (!cId || !user) { return undefined; }

    const index = user.clientUserList.findIndex((item) => item.clientId === cId);

    return !!index || index === 0
      ? user.clientUserList[index]
      : undefined;
  }
);
