<div
  class="__wrap"
  [class.is-full]="full">
  <svg
    class="__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.73 23.36">

    <path
      class="c-logo__v  c-logo__letter"
      d="M4.94 18.27L0 5.72h4.21l2.83 7.95L9.9 5.72h4.24L9.2 18.27H4.94z"
    />

    <path
      class="c-logo__s  c-logo__letter"
      d="M14.64 17.3l1.67-2.76a7.8 7.8 0 0 0 4.41 1.66c1.2 0 1.69-.39 1.69-1s-1-.78-2.15-1c-2.14-.34-5.15-.84-5.15-4.08C15.11 8 17 6 20.49 6a8.81 8.81 0 0 1 5.25 1.67l-1.53 2.65A5.63 5.63 0 0 0 20.52 9c-.94 0-1.59.39-1.59.88s.68.65 2 .89c2.13.39 5.3.91 5.3 4.31 0 2.39-2.1 4.16-5.71 4.16a9.37 9.37 0 0 1-5.88-1.92"
      transform="translate(-.58 -.64)"
    />

    <g
      class="__group"
      @letters
      *ngIf="full">

      <g class="__letter-group">
        <path
          class="c-logo__h  c-logo__letter"
          d="M36.74 18.91V11.6c0-1.53-.8-2-2.07-2a3.1 3.1 0 0 0-2.42 1.22v8.11h-4V1.57h4v6.32A5.67 5.67 0 0 1 36.59 6c2.86 0 4.16 1.62 4.16 3.93v8.94z"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__o  c-logo__letter"
          d="M52.35 12.62a2.77 2.77 0 0 0-2.76-3 2.74 2.74 0 0 0-2.7 3c0 1.69 1 3.06 2.7 3.06a2.77 2.77 0 0 0 2.76-3.06m-9.59 0A6.47 6.47 0 0 1 49.59 6a6.46 6.46 0 0 1 6.86 6.58 6.48 6.48 0 0 1-6.86 6.6 6.49 6.49 0 0 1-6.83-6.6"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__s  c-logo__letter"
          d="M57.37 17.3L59 14.54a7.8 7.8 0 0 0 4.41 1.66c1.2 0 1.69-.39 1.69-1s-1-.78-2.15-1c-2.14-.34-5.15-.84-5.15-4.08C57.84 8 59.74 6 63.22 6a8.83 8.83 0 0 1 5.25 1.67l-1.54 2.65A5.58 5.58 0 0 0 63.25 9c-.94 0-1.59.39-1.59.88s.68.65 2 .89c2.13.39 5.3.91 5.3 4.31 0 2.39-2.1 4.16-5.71 4.16a9.37 9.37 0 0 1-5.88-1.92"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__t  c-logo__letter"
          d="M71.8 15.53V9.84h-2.08V6.36h2.08V2.93h4v3.43h2.54v3.48h-2.56v4.57a1.13 1.13 0 0 0 1.11 1.27 1.56 1.56 0 0 0 1-.31l.78 3a4.45 4.45 0 0 1-3 .83c-2.54 0-3.92-1.27-3.92-3.69"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__i  c-logo__letter"
          d="M80.47 6.36h4v12.55h-4zm-.29-3.43a2.29 2.29 0 0 1 4.58 0 2.29 2.29 0 1 1-4.58 0"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__n  c-logo__letter"
          d="M95.77 18.91v-7.25a1.82 1.82 0 0 0-2.06-2.08 3.11 3.11 0 0 0-2.44 1.22v8.11h-4V6.36h4v1.53A5.67 5.67 0 0 1 95.61 6c2.86 0 4.16 1.67 4.16 4v8.89z"
          transform="translate(-.58 -.64)"
        />
      </g>

      <g class="__letter-group">
        <path
          class="c-logo__g  c-logo__letter"
          d="M111.07 14.05v-3.36a3.22 3.22 0 0 0-2.39-1.11 2.57 2.57 0 0 0-2.75 2.78 2.58 2.58 0 0 0 2.75 2.81 3.23 3.23 0 0 0 2.39-1.12m-8.75 8.08l1.74-2.86a5.06 5.06 0 0 0 3.82 1.46c1.32 0 3.19-.55 3.19-2.89v-1a4.73 4.73 0 0 1-3.71 1.82c-3.07 0-5.54-2.11-5.54-6.34S104.21 6 107.36 6a4.62 4.62 0 0 1 3.71 1.8V6.36h4v11.43c0 5.12-4 6.21-7.17 6.21a8.31 8.31 0 0 1-5.56-1.87"
          transform="translate(-.58 -.64)"
        />
      </g>

    </g>

    <g
      class="__tilde-group"
      [@tilde]="full ? 'full' : 'condensed'">
      <path
        class="c-logo__tilde"
        d="M125.4 14.65a2.92 2.92 0 0 1-1.4-.31 8.6 8.6 0 0 1-1.11-.67l-1-.68a1.77 1.77 0 0 0-1-.31 1.52 1.52 0 0 0-1 .39 3.9 3.9 0 0 0-.88 1.12l-2-1.53a6.59 6.59 0 0 1 2-2.08 3.7 3.7 0 0 1 2-.6 3 3 0 0 1 1.41.32 8.6 8.6 0 0 1 1.11.67l1 .68a1.79 1.79 0 0 0 1 .31 1.53 1.53 0 0 0 1-.39 4.27 4.27 0 0 0 .87-1.12l2 1.53a6.58 6.58 0 0 1-1.94 2.08 3.72 3.72 0 0 1-2 .59"
        transform="translate(-.58 -.64)"
      />
    </g>

  </svg>
</div>
