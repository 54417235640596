/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-dates-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./invoice-dates-info.component";
var styles_InvoiceDatesInfoComponent = [i0.styles];
var RenderType_InvoiceDatesInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceDatesInfoComponent, data: {} });
export { RenderType_InvoiceDatesInfoComponent as RenderType_InvoiceDatesInfoComponent };
function View_InvoiceDatesInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [["class", "__state  is-canceled"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoiceItem.created")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.created, "mediumDate")); _ck(_v, 3, 0, currVal_1); }); }
function View_InvoiceDatesInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [["class", "__state  is-paid"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoiceItem.paid")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.paidDate, "mediumDate")); _ck(_v, 3, 0, currVal_1); }); }
function View_InvoiceDatesInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [["class", "__state  is-overdue"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(2, { days: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("invoiceItem.daysNumber", _ck(_v, 2, 0, _co.daysOverdue))); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("invoiceItem.overdue")); _ck(_v, 4, 0, currVal_1); }); }
function View_InvoiceDatesInfoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [["class", "__state  is-waiting"], ["translate", "invoiceItem.today"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "invoiceItem.today"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_InvoiceDatesInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [["class", "__state  is-waiting"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(5, { days: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceDatesInfoComponent_5)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.diffDays === 0); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("invoiceItem.dueIn")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform("invoiceItem.daysNumber", _ck(_v, 5, 0, _co.diffDays))); _ck(_v, 4, 0, currVal_1); }); }
export function View_InvoiceDatesInfoComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceDatesInfoComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceDatesInfoComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceDatesInfoComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceDatesInfoComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.status === _co.invoiceStatuses.Canceled); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.status === _co.invoiceStatuses.Paid); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.status === _co.invoiceStatuses.PaymentOverdue); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.status === _co.invoiceStatuses.WaitingForPayment); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_InvoiceDatesInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-invoice-dates-info", [], null, null, null, View_InvoiceDatesInfoComponent_0, RenderType_InvoiceDatesInfoComponent)), i1.ɵdid(1, 49152, null, 0, i4.InvoiceDatesInfoComponent, [], null, null)], null, null); }
var InvoiceDatesInfoComponentNgFactory = i1.ɵccf("vshcz-invoice-dates-info", i4.InvoiceDatesInfoComponent, View_InvoiceDatesInfoComponent_Host_0, { created: "created", dueDate: "dueDate", paidDate: "paidDate", daysOverdue: "daysOverdue", status: "status" }, {}, []);
export { InvoiceDatesInfoComponentNgFactory as InvoiceDatesInfoComponentNgFactory };
