import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import orderBy from 'lodash-es/orderBy';
import { TicketStatuses, TicketStatusesPriorityMap } from '@app/common/tickets';
import { ModuleTokens } from './tickets-base.constant';
import { denormalizeEntity } from './tickets-base.utils';
import { denormalizeList } from './tickets-base.utils';
export var slice = createFeatureSelector(ModuleTokens.Name);
export var entities = createSelector(slice, function (s) { return s.entities; });
export var listIds = createSelector(slice, function (s) { return s.list; });
export var getById = function (id) { return createSelector(entities, function (ents) {
    if (!ents) {
        return undefined;
    }
    var ent = denormalizeEntity(id, ents[ModuleTokens.Name], ents[ModuleTokens.Messages]);
    if (ent) {
        return tslib_1.__assign({}, ent, { ticketMessageList: orderBy(ent.ticketMessageList, ['created'], ['asc']) });
    }
    else {
        return ent;
    }
}); };
var openStatuses = [
    // TicketStatuses.Planned,
    TicketStatuses.Resolved,
    TicketStatuses.Accepted,
    TicketStatuses.InRealisation,
    TicketStatuses.WaitingForClient,
    TicketStatuses.WaitingForAuthorisation
];
export var openedShortlist = function (num) {
    if (num === void 0) { num = 3; }
    return createSelector(listIds, entities, function (ids, ents) {
        var data = denormalizeList(ids, ents[ModuleTokens.Name], ents[ModuleTokens.Messages]);
        var orderedData = orderBy(data, [function (t) { return TicketStatusesPriorityMap[t.clientZoneStatus]; }, 'lastUpdate'], ['desc', 'desc']);
        return orderedData
            .filter(function (item) { return openStatuses.includes(item.clientZoneStatus); })
            .slice(0, num);
    });
};
export var ticketTopicList = createSelector(slice, function (s) { return s.ticketTopics; });
export var ticketTopicById = function (id) { return createSelector(ticketTopicList, function (list) { return list.find(function (topic) { return topic.id === id; }); }); };
export var ticketTopicListGrouped = createSelector(slice, function (s) {
    var map = {};
    return s.ticketTopics
        .reduce(function (arr, item) {
        if (map[item.group] === undefined) {
            map[item.group] = arr.length;
            arr.push({
                key: item.group,
                items: []
            });
        }
        arr[map[item.group]].items.push(item);
        return arr;
    }, []);
});
