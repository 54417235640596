import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { PermissionService } from '@zerops/fe/permission';
import { map, filter, takeUntil, delay } from 'rxjs/operators';
import { merge, Subject, combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { Go } from '@app/common/ngrx-router';
import { authActiveUserNormalizedClient, SetActiveClient, authActiveUserClientUsers, authActiveUserClientId, Roles } from '@app/base/auth-base';
import { clientUserListEntities } from '@app/base/client-user-base';
var CompanyCardContainer = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyCardContainer, _super);
    function CompanyCardContainer(_store, _permission) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._permission = _permission;
        // # Event Streams
        _this.onRoute$ = new Subject();
        _this.onChangeClient$ = new Subject();
        // # Data
        // -- async
        _this.company$ = _this._store.pipe(select(authActiveUserNormalizedClient), filter(function (c) { return !!c; }));
        _this.avatarGroupData$ = _this._store.pipe(select(clientUserListEntities), filter(function (data) { return !!(data && data.length); }), map(function (data) { return data.map(function (itm) { return ({
            title: itm.fullName,
            id: itm.id,
            mail: itm.email
        }); }); }));
        _this.activeCompanyId$ = _this._store.pipe(select(authActiveUserClientId));
        _this.userCompanies$ = _this._store.pipe(select(authActiveUserClientUsers), map(function (d) { return orderBy(d, [function (itm) { return itm.client.companyName; }], ['asc']); }));
        _this.isManager$ = _this._permission.authorize({
            only: [Roles.Manager]
        });
        // -- angular
        _this.mode = 'horizontal';
        _this.companySelectToggle = new EventEmitter();
        _this.contentUpdated = new EventEmitter();
        _this.contentClicked = new EventEmitter();
        _this._routeAction$ = _this.onRoute$.pipe(map(function (path) { return new Go({ path: path }); }));
        _this._changeClientAction = _this.onChangeClient$.pipe(map(function (id) { return new SetActiveClient(id, true); }));
        // emit that content changed so menu can resize the pop
        combineLatest(_this.company$)
            // delay to account for component's 'mode'
            .pipe(takeUntil(_this._ngOnDestroy$), delay(0))
            .subscribe(function () { return _this.contentUpdated.emit(); });
        merge(_this._routeAction$, _this._changeClientAction)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    return CompanyCardContainer;
}(BaseClass));
export { CompanyCardContainer };
