import { registerLocaleData } from '@angular/common';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import localeCs from '@angular/common/locales/cs';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@zerops/fe/permission';
import { filter, map, pairwise, debounce } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { Roles, isAuthenticated, authActiveClientUser } from '@app/base/auth-base';
import { ClientUserRoles } from '@app/base/client-user-base';
import { activeUserClientId } from '@app/base/auth-base/auth-base.selector';
import { environment } from 'environments/environment';
import { sentryErrorProvider } from './services';
import { TRANSLATIONS } from './app.translations';
registerLocaleData(localeCs);
var PROD_PROVIDERS = !environment.production
    ? []
    : sentryErrorProvider.slice();
var ɵ0 = {
    appearance: 'outline'
}, ɵ1 = {
    baseTitle: 'vshosting'
};
var AppModule = /** @class */ (function () {
    function AppModule(_router, _translate, _permissions, _store, _document) {
        var _this = this;
        this._router = _router;
        this._translate = _translate;
        this._permissions = _permissions;
        this._store = _store;
        this._document = _document;
        this._document.body.classList.add('app-release-' + environment.release);
        var availableTranslations = ['en', 'cs', 'hu', 'de'];
        // add global translations
        this._translate.setTranslation('en', TRANSLATIONS.en);
        this._translate.setTranslation('cs', TRANSLATIONS.cs);
        this._translate.setTranslation('hu', TRANSLATIONS.hu);
        this._translate.setTranslation('de', TRANSLATIONS.de);
        // use translation, later, if user is logged in or
        // authorized from existing token, language will be
        // switched to the one user has set in profile
        var defaultLang = availableTranslations.includes(this._translate.getBrowserLang())
            ? this._translate.getBrowserLang()
            : 'en';
        this._translate.use(defaultLang);
        // # Permissions Setup
        // base roles
        this._permissions.define(Roles.Authorized, function () { return combineLatest(_this._store.pipe(select(isAuthenticated)), _this._store.pipe(select(activeUserClientId)))
            .pipe(map(function (_a) {
            var authenticated = _a[0], id = _a[1];
            return !!authenticated && !!id;
        })); });
        this._permissions.define(Roles.Unauthorized, function () { return combineLatest(_this._store.pipe(select(isAuthenticated)), _this._store.pipe(select(activeUserClientId)))
            .pipe(map(function (_a) {
            var authenticated = _a[0], id = _a[1];
            return !authenticated || !id;
        })); });
        // user roles
        this._permissions.define(Roles.Manager, function () { return _this._store.pipe(select(authActiveClientUser), map(function (userClient) { return userClient && userClient.roleCode === ClientUserRoles.Manager; })); });
        this._permissions.define(Roles.Technical, function () { return _this._store.pipe(select(authActiveClientUser), map(function (userClient) { return userClient && userClient.roleCode === ClientUserRoles.TechnicalUser; })); });
        this._permissions.define(Roles.Financial, function () { return _this._store.pipe(select(authActiveClientUser), map(function (userClient) { return userClient && userClient.roleCode === ClientUserRoles.FinancialUser; })); });
        // -- special
        this._permissions.define(Roles.ManagerWithSPAccess, function () { return _this._store.pipe(select(authActiveClientUser), map(function (userClient) { return userClient
            && userClient.roleCode === ClientUserRoles.Manager
            && userClient.client.hasAccessToServerPark; })); });
        this._permissions.define(Roles.AuthorizedToSendTickets, function () { return _this._store.pipe(select(authActiveClientUser), map(function (user) { return !!user && !!user.authorizedToSendTickets; })); });
        // # Various
        // scroll to top after route change
        this._router.events
            .pipe(map(function (event) { return event; }), filter(function (event) { return event instanceof ActivationStart; }), pairwise(), debounce(function () { return _this._router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })); }))
            .subscribe(function (_a) {
            var previous = _a[0], next = _a[1];
            var previousRouterState = getRouterState(previous);
            var previousPage = previousRouterState.params.page
                ? parseInt(previousRouterState.params.page, 10)
                : undefined;
            var nextRouterState = getRouterState(next);
            var nextPage = nextRouterState.params.page
                ? parseInt(nextRouterState.params.page, 10)
                : undefined;
            // do not scroll when changing query params of records
            if (isRoute('domains/detail/:id/records', nextRouterState.path)
                && isRoute('domains/detail/:id/records', previousRouterState.path)) {
                if (!isEmpty(nextRouterState.params) && (nextPage === previousPage || (!previousPage && !nextPage))) {
                    return;
                }
                // params on new state are empty and previously they were not
                if (isEmpty(nextRouterState.params) && !isEmpty(previousRouterState.params)) {
                    return;
                }
            }
            window.scrollTo(0, 0);
            setTimeout(function () {
                window.scrollTo(0, 0);
            });
        });
    }
    return AppModule;
}());
export { AppModule };
function isRoute(route, path) {
    if (Array.isArray(route)) {
        return route.indexOf(path) > -1;
    }
    else if (route instanceof RegExp) {
        return route.test(path);
    }
    else {
        return path === route;
    }
}
function getRouterState(event) {
    var route = event.snapshot;
    var path = [];
    var params = route.params, queryParams = route.queryParams, data = route.data;
    while (route.parent) {
        if (route.routeConfig && route.routeConfig.path) {
            path.push(route.routeConfig.path);
        }
        route = route.parent;
    }
    return { params: params, queryParams: queryParams, data: data, path: path.reverse().join('/') };
}
export { ɵ0, ɵ1 };
