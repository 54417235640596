import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UploadTypes, UploadSelectItem } from './upload.model';

@Component({
  selector: 'vshcz-upload',
  templateUrl: './upload.component.html',
  styleUrls: [ './upload.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadComponent {
  files: UploadSelectItem[] = [];
  isDragover = false;

  @Input()
  set multiple(v) {
    this._multiple = coerceBooleanProperty(v);
  }
  get multiple() {
    return this._multiple;
  }

  @Input()
  set disable(v) {
    this._disable = coerceBooleanProperty(v);
  }
  get disable() {
    return this._disable;
  }

  @Input()
  imageAcceptExtensions = '.jpg, .jpeg, .png';

  @Input()
  set accept(val) {
    switch (val) {
      case 'image':
        this._accept = this.imageAcceptExtensions;
        break;

      case 'video':
        this._accept = 'video/*';
        break;

      case 'gif':
        this._accept = '.gif';
        break;

      case 'imagevideo':
        this._accept = this.imageAcceptExtensions + ', video/*';
        break;

      default:
        this._accept = '*';
        break;
    }
  }

  get accept() {
    return this._accept;
  }

  @ViewChild('fileInput')
  fileInput: ElementRef;

  @Output()
  select = new EventEmitter<UploadSelectItem[]>(false);

  private _accept: string;
  private _multiple: boolean;
  private _disable: boolean;

  onClick(e) {
    e.preventDefault();

    if (!this.disable) {
      this.fileInput.nativeElement.click();
    }
  }

  drop(e: DragEvent) {
    e.preventDefault();
    if (!this.disable) {
      if (!e.dataTransfer || !e.dataTransfer.files.length) {
        return;
      }

      this._proccessFiles(e.dataTransfer.files);
    }
  }

  dragenter(e) {
    e.preventDefault();

    this.isDragover = true;
  }

  dragover(e) {
    e.preventDefault();

    this.isDragover = true;
  }

  dragleave(e) {
    e.preventDefault();

    this.isDragover = false;
  }

  onFileChange(el) {
    this._proccessFiles(el.target.files);
  }

  private _proccessFiles(files) {
    const that = this;

    const promises: Promise<any>[] = [];

    for (let index = 0; index < files.length; index++) {
      const file: File = files[index];

      const promise = new Promise((resolve) => {
        const data = {
          dataUrl: URL.createObjectURL(file),
          type: this._getType(file),
          meta: file,
          height: undefined,
          width: undefined,
          thumbnails: []
        };

        if (data.type === 'image') {
          const i = new Image();

          i.onload = () => {
            data.height = i.height;
            data.width = i.width;

            that.files.push(data);
            resolve();
          };

          i.src = data.dataUrl;
        } else if (data.type === 'video') {
          const v = document.createElement('video');
          v.addEventListener('loadedmetadata', function() {
            data.width = this.videoWidth;
            data.height = this.videoHeight;

            that.files.push(data);

            resolve();
          });

          v.src = data.dataUrl;

        } else {
          that.files.push(data);
          resolve();
        }
      });

      promises.push(promise);

    }

    Promise.all(promises).then(() => {
      this.select.emit(this.files);

      this.files = [];

      this.fileInput.nativeElement.value = '';

    });

  }

  private _getType(file: File): UploadTypes {
    if (file.type === 'image/gif')  {
      return 'gif';
    }

    const typeBeforeSlash = file.type.substr(0, file.type.indexOf('/'));
    if (typeBeforeSlash === 'video') {
      return 'video';
    }

    if (typeBeforeSlash === 'image') {
      return 'image';
    }

    return 'file';
  }

}
