import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TicketStateModule } from '@app/common/ticket-state';
import { TicketMessageModule } from '@app/common/ticket-message';
import { TicketItemComponent } from './ticket-item.component';

@NgModule({
  declarations: [ TicketItemComponent ],
  imports: [
    CommonModule,
    TruncateModule,
    TicketStateModule,
    TicketMessageModule
  ],
  exports: [ TicketItemComponent ]
})
export class TicketItemModule { }
