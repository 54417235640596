import { normalize, denormalize } from 'normalizr';
import orderBy from 'lodash-es/orderBy';
import { clientUserListSchema, clientUserSchema } from '@app/schemas';
import { ClientsBaseModuleTokens } from '@app/base/clients-base';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import { ModuleTokens as UsersBaseModuleTokens } from '@app/base/users-base/users-base.constant';
import { ModuleTokens } from './client-user-base.constant';
export function normalizeList(data) {
    return normalize(data, clientUserListSchema);
}
export function denormalizeEntity(id, clientUserEnts, usersEnts, clientsEnts, servicesEnts) {
    var _a;
    return denormalize(id, clientUserSchema, (_a = {},
        _a[ModuleTokens.Name] = clientUserEnts,
        _a[UsersBaseModuleTokens.Name] = usersEnts,
        _a[ClientsBaseModuleTokens.Name] = clientsEnts,
        _a[ServicesBaseModuleTokens.Name] = servicesEnts,
        _a));
}
export function denormalizeList(ids, clientUserEntities, usersEnts, clientsEnts, servicesEnts) {
    var _a;
    return denormalize(ids, clientUserListSchema, (_a = {},
        _a[ModuleTokens.Name] = clientUserEntities,
        _a[UsersBaseModuleTokens.Name] = usersEnts,
        _a[ClientsBaseModuleTokens.Name] = clientsEnts,
        _a[ServicesBaseModuleTokens.Name] = servicesEnts,
        _a));
}
var USER_STATUS_ORDER_MAP = {
    WAITING_AUTHORIZATION: 1,
    ACTIVE: 2,
    BEING_DELETED: 3
};
export function sortList(data) {
    return orderBy(data, [
        function (item) { return USER_STATUS_ORDER_MAP[item.status]; },
        'fullName'
    ], [
        'asc',
        'asc'
    ]);
}
