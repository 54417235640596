import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientServiceStatuses } from '@app/base/client-services-base';
import { Currency, BillingPeriod } from '@app/common/settings';
import { HashMap } from 'utils';

@Component({
  selector: 'vshcz-client-service-item',
  templateUrl: './client-service-item.component.html',
  styleUrls: [ './client-service-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientServiceItemComponent {

  clientServiceStatuses = ClientServiceStatuses;
  individualOneTime = 'INDIVIDUAL_ONE_TIME';

  @Input()
  name: string;

  @Input()
  index: number;

  @Input()
  identification: string;

  @Input()
  currencyId: string;

  @Input()
  clientZoneStatus: ClientServiceStatuses;

  @Input()
  oneTimePrice: number;

  @Input()
  monthPriceDiscounted: number;

  @Input()
  activationDate: string;

  @Input()
  languageId: string;

  @Input()
  billingPeriod: BillingPeriod;

  @Input()
  serviceType: string;

  @Input()
  priceSize = 16;

  @Input()
  symbolSize = 14;

  @Input()
  currencyMap: HashMap<Currency>;
}
