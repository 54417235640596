import { version } from './version';

export const environment = {
  production: true,
  envName: 'devel',
  apiUrl: '',
  wsUrl: 'is-test-client.vsh-labs.cz',
  cloudDnsWsUrl: 'client.is.lxd',
  googleSiteKey: '6LdUw48UAAAAALnxsH_vUxMNlAVWrCvd-OS-g2nC',
  cdnAuthRedirectUrl: 'http://localhost:4002/authorize',
  cloudMailAuthRedirectUrl: 'http://localhost:4003/authorize',
  release: version,
  enableCDNS: true,
  enableCDN: false,
  enableCloudMail: false,
  forceDevWsProtocol: 'wss',
  mojeIdConnectRedirectUrl: 'http://localhost:1337/external-auth/mojeid/connect',
  mojeIdLoginRedirectUrl: 'http://localhost:1337/external-auth/mojeid/login',
  googleConnectRedirectUrl: 'http://localhost:1337/external-auth/google/connect',
  googleLoginRedirectUrl: 'http://localhost:1337/external-auth/google/login',
  stripePublishableKey: 'pk_test_51PHQflP3qvSvTb9oK1oljpEZ3020fGeQyMnY9rivkCBy0pA0h3CizqaWoezCP11mlRIVT2wt0TN0S0BIHbOkVsHk00u6c72V8X',
  appleIdConnectRedirectUrl: 'http://is-test-client.vsh-labs.cz/external-auth/apple/connect',
  appleIdLoginRedirectUrl: 'http://is-test-client.vsh-labs.cz/external-auth/apple/login'
};
