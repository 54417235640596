import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom, filter } from 'rxjs/operators';
import { State } from '@app/models';
import { ticketAddFormState } from './ticket-add.selector';
import { ActionTypes, AddRequest } from './ticket-add.action';
import { ModuleTokens } from './ticket-add.constant';

@Component({
  selector: 'vshcz-ticket-add',
  templateUrl: './ticket-add.container.html',
  styleUrls: [ './ticket-add.container.scss' ]
})
export class TicketAddContainer extends BaseClass {
  // # Form States
  ticketAddFormState$ = this._store.pipe(select(ticketAddFormState));

  // # Event Stream
  onAdd$ = new Subject<void>();

  // # Data
  // -- sync
  addRequestKey = ActionTypes.AddRequest;
  addFailKey = ActionTypes.AddFail;
  uploadInstanceId = ModuleTokens.Name;

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    withLatestFrom(this.ticketAddFormState$.pipe(map((s) => s.isValid))),
    filter(([ _, isValid ]) => !!isValid),
    map(() => new AddRequest())
  );

  constructor(private _store: Store<State>) {
    super();

    merge(this._addAction$)
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

}
