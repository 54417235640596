import * as tslib_1 from "tslib";
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyPriceOfferRejectFormReducer } from './modules';
import { TicketPriceOfferState } from './ticket-price-offer.model';
import { ModuleTokens } from './ticket-price-offer.constant';
var initialState = new TicketPriceOfferState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyPriceOfferRejectFormReducer(tslib_1.__assign({}, state), action, ModuleTokens.Name, {
        messageText: validate(required)
    });
    return state;
}
