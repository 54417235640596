import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueueingSubject } from 'queueing-subject';
import { environment } from 'environments/environment';
import { connect } from './websockets.utils';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsApi {

  status$: Observable<number>;
  messages$: Observable<string>;

  private _input = new QueueingSubject<string>();

  constructor(private _http: HttpClient) { }

  connect(token: string) {
    const endpoint = environment.production
      ? window.location.hostname
      : environment.wsUrl;

    const wsProtocol = !environment.production && environment.forceDevWsProtocol ?
      environment.forceDevWsProtocol
      : window.location.protocol === 'https:'
        ? 'wss'
        : 'ws';

    const { messages, connectionStatus } = connect(
      `${wsProtocol}://${endpoint}/api/public/web-socket/${token}`,
      this._input
    );

    this.messages$ = (messages as Observable<string>);
    this.status$ = connectionStatus;
  }

  send(value: string) {
    this._input.next(value);
  }

  auth$(accessToken: string) {
    return this._http.post<{ token: string; }>(
      '/api/public/web-socket/login',
      { accessToken }
    );
  }
}
