import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'vshcz-user',
  templateUrl: './user.component.html',
  styleUrls: [ './user.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent {
  @Input()
  fullName: string;

  @Input()
  mail: string;

  @Input()
  company: string;

  @Input()
  position: string;
}
