import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentsBaseApi = /** @class */ (function () {
    function PaymentsBaseApi(_http) {
        this._http = _http;
    }
    PaymentsBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/payment");
    };
    PaymentsBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/api/public/payment/" + id);
    };
    PaymentsBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function PaymentsBaseApi_Factory() { return new PaymentsBaseApi(i0.inject(i1.HttpClient)); }, token: PaymentsBaseApi, providedIn: "root" });
    return PaymentsBaseApi;
}());
export { PaymentsBaseApi };
