import * as tslib_1 from "tslib";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BaseClass } from '@zerops/fe/core';
import { of, Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import groupBy from 'lodash-es/groupBy';
import loMap from 'lodash-es/map';
import sortBy from 'lodash-es/sortBy';
import { Store, select } from '@ngrx/store';
import { TicketsListRemoveFromFilter, TicketsListResetFilter, TicketsListAddToFilter, ticketsListFilters } from '@app/common/tickets-list';
var TicketsFilterContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsFilterContainer, _super);
    function TicketsFilterContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onToggleFilter$ = new Subject();
        _this.onSelectAll$ = new Subject();
        _this.onResetFilter$ = new Subject();
        // # Data
        // -- sync
        _this.separatorKeysCodes = [ENTER, COMMA];
        // -- async
        // TODO: move to base
        _this.filters$ = of([
            { id: 'CLOSED', group: 'closed' },
            // { id: 'PLANNED', group: 'open' },
            { id: 'RESOLVED', group: 'open' },
            { id: 'ACCEPTED', group: 'open' },
            { id: 'IN_REALISATION', group: 'open' },
            { id: 'WAITING_FOR_CLIENT', group: 'open' },
            { id: 'WAITING_AUTHORIZATION', group: 'open' }
        ]);
        _this.groupedFiltersMap$ = _this.filters$.pipe(map(function (filters) { return groupBy(filters, 'group'); }));
        _this.groupedFilters$ = _this.groupedFiltersMap$.pipe(map(function (data) { return loMap(data, function (items, key) { return ({
            key: key,
            items: items.map(function (itm) { return itm.id; })
        }); }); }));
        _this.activeFilters$ = _this._store.pipe(select(ticketsListFilters));
        _this.activeGroup$ = _this.activeFilters$.pipe(withLatestFrom(_this.groupedFilters$), map(function (_a) {
            var activeFilters = _a[0], filters = _a[1];
            var matchingActiveFilter = filters.find(function (group) {
                return sortBy(group.items).toString() === sortBy(activeFilters).toString();
            });
            return matchingActiveFilter ? matchingActiveFilter.key : undefined;
        }));
        // -- angular
        _this.maxSize = 2;
        _this.popHorizontalAlignment = 'start';
        // # Action Streams
        _this._toggleFilterAction$ = _this.onToggleFilter$.pipe(map(function (selection) {
            if (selection.state !== true) {
                return new TicketsListRemoveFromFilter(selection.value);
            }
            return new TicketsListAddToFilter(selection.value);
        }));
        _this._selectAllAction$ = _this.onSelectAll$.pipe(map(function (items) { return new TicketsListAddToFilter(items, true); }));
        _this._resetFilterAction$ = _this.onResetFilter$.pipe(map(function () { return new TicketsListResetFilter(); }));
        // # Store Dispatcher
        merge(_this._toggleFilterAction$, _this._resetFilterAction$, _this._selectAllAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    TicketsFilterContainer.prototype.selectAll = function (items) {
        this.onSelectAll$.next(items.map(function (item) { return item.id; }));
    };
    return TicketsFilterContainer;
}(BaseClass));
export { TicketsFilterContainer };
