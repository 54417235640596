import { Actions, ActionTypes } from './websockets.action';
import { WebsocketsState } from './websockets.model';

const initialState = new WebsocketsState();

export function reducer(
  state = initialState,
  action: Actions
): WebsocketsState {

  switch (action.type) {

    case ActionTypes.Reconnect:
      return {
        ...state,
        reconnecting: true
      };

    case ActionTypes.Connected:
      return {
        ...state,
        reconnecting: false
      };
  }

  return state;
}
