import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Reset } from '@zerops/fe/core';
import { RemoveError } from '@zerops/fe/ngrx';
import { TranslateService } from '@ngx-translate/core';
import { map, tap, filter, switchMap, withLatestFrom, catchError, delay } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { Go } from '@app/common/ngrx-router';
import { SnackService } from '@app/common/snack';
import { ErrorTranslationService } from '@app/services';
import { ActionTypes, Authorize, InvalidateAuthorization, SetLanguage, SendPasswordChangeLocalSuccess, SendPasswordChangeFail, ResetReferer, SetReferer, ExternalAuthConnectFail, ExternalAuthConnectLocalSuccess, ExternalAuthConnectValidateLocalSuccess, ExternalAuthConnectValidateFail, ExternalAuthDeleteLocalSuccess, ExternalAuthDeleteFail, ExternalAuthLoginLocalSuccess, ExternalAuthLoginFail, ExternalAuthLoginValidateLocalSuccess, ExternalAuthLoginValidateFail } from './auth-base.action';
import { saveAuth, getStoredAuth, saveSelectedClient, getSelectedClient, removeStoredAuthAndClient, updateStoredEntities } from './auth-base.utils';
import { AuthBaseApi } from './auth-base.api';
import { identity, referer } from './auth-base.selector';
var AuthBaseEffect = /** @class */ (function () {
    function AuthBaseEffect(_actions$, _api, _store, _snack, _errorTranslation, _translate) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._store = _store;
        this._snack = _snack;
        this._errorTranslation = _errorTranslation;
        this._translate = _translate;
        this._onAuthorize$ = this._actions$.pipe(ofType(ActionTypes.Authorize));
        this._externalAuthConnectValidateSuccess$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectValidateLocalSuccess));
        this._externalAuthConnectValidateFail$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectValidateFail));
        this._externalAuthLoginValidateSuccess$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginValidateLocalSuccess));
        this._externalAuthLoginValidateFail$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginValidateFail));
        this._onExternalAuthDeleteSuccess$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthDeleteLocalSuccess));
        this._onCheckSavedToken$ = this._actions$.pipe(ofType(ActionTypes.CheckSavedToken), map(function () {
            var data = getStoredAuth();
            var clientId = getSelectedClient();
            // user was not logged in
            // or didn't select the clientId
            if (!data || !data.auth.accessToken || !clientId) {
                return new InvalidateAuthorization();
            }
            // authorize is handled inside "login-as-client"
            if (window.location.href.includes('qmsxauweag')) {
                return;
            }
            return new Authorize(data.entities, data.userId, data.auth, clientId, false);
        }), filter(function (action) { return !!action; }));
        // set referer when accessing page unauthorized
        // window.location.pathname is the only way to get the referer path at this point
        this._onCheckSavedTokenFailSetReferer$ = this._actions$.pipe(ofType(ActionTypes.CheckSavedToken), map(function () {
            var data = getStoredAuth();
            var clientId = getSelectedClient();
            // TODO: refactor .includes to some more system solution
            if ((!data || !data.auth.accessToken || !clientId)
                && !window.location.pathname.includes('login')
                && !window.location.pathname.includes('password-recovery')
                && !window.location.pathname.includes('forgotten-password')
                && !window.location.pathname.includes('invitation')) {
                return new SetReferer(window.location.pathname);
            }
        }), filter(function (action) { return !!action; }));
        this._onAuthorizeUpdateLocalStorage$ = this._onAuthorize$.pipe(tap(function (_a) {
            var payload = _a.payload;
            saveAuth(payload.auth, payload.entities, payload.userId);
            if (payload.clientId) {
                saveSelectedClient(payload.clientId);
            }
        }), withLatestFrom(this._store.pipe(select(referer))), map(function (_a) {
            var payload = _a[0].payload, refererPath = _a[1];
            return payload.redirect
                ? new Go({ path: refererPath ? [refererPath] : ['/dashboard'] })
                : undefined;
        }), filter(function (action) { return !!action; }));
        this._onAuthorizeSetLanguage$ = this._onAuthorize$.pipe(map(function (_a) {
            var payload = _a.payload;
            return new SetLanguage(payload.user.language.id);
        }));
        this._onAuthorizeSetSentryMeta$ = this._onAuthorize$.pipe(tap(function (_a) {
            var payload = _a.payload;
            Sentry.configureScope(function (scope) {
                scope.setUser({ 'email': payload.user.email });
            });
        }));
        this._onSetLanguage$ = this._actions$.pipe(ofType(ActionTypes.SetLanguage), tap(function (_a) {
            var payload = _a.payload;
            _this._translate.use(payload);
        }));
        this._onInvalidateAuthorizationRedirect$ = this._actions$.pipe(ofType(ActionTypes.InvalidateAuthorization), map(function (_a) {
            var payload = _a.payload;
            return payload
                ? new Go({ path: ['/login'] })
                : undefined;
        }), filter(function (action) { return !!action; }));
        // sent post to delete access token from REDIS then delete auth
        this._onInvalidateAuthorizationApiLogout$ = this._actions$.pipe(ofType(ActionTypes.InvalidateAuthorization), filter(function () { return !!getStoredAuth(); }), map(function () { return getStoredAuth().auth.accessToken; }), tap(function () { return removeStoredAuthAndClient(); }), switchMap(function (token) { return _this._api
            .logout$(token)
            .pipe(catchError(function () { return EMPTY; })); }));
        this._onInvalidateAuthorizationReset$ = this._actions$.pipe(ofType(ActionTypes.InvalidateAuthorization), map(function () { return new Reset(); }));
        this._onSetActiveClient$ = this._actions$.pipe(ofType(ActionTypes.SetActiveClient), tap(function (_a) {
            var payload = _a.payload;
            return saveSelectedClient(payload);
        }), withLatestFrom(this._store.pipe(select(referer))), map(function (_a) {
            var meta = _a[0].meta, refererPath = _a[1];
            var path = meta
                ? [refererPath ? refererPath : '/dashboard', { reload: true }]
                : [refererPath ? refererPath : '/dashboard'];
            return new Go({ path: path });
        }), filter(function (action) { return !!action; }));
        this._onSetActiveClientResetReferer$ = this._actions$.pipe(ofType(ActionTypes.SetActiveClient), map(function () { return new ResetReferer(); }));
        this._onSendPasswordChangeRequest$ = this._actions$.pipe(ofType(ActionTypes.SendPasswordChangeRequest), withLatestFrom(this._store.pipe(select(identity))), switchMap(function (_a) {
            var _ = _a[0], email = _a[1].email;
            return _this._api
                .recoverPassword$(email)
                .pipe(map(function () { return new SendPasswordChangeLocalSuccess(); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new SendPasswordChangeFail(data); })); }));
        }));
        this._onSendPasswordChangeFail$ = this._actions$.pipe(ofType(ActionTypes.SendPasswordChangeFail), switchMap(function (_a) {
            var errors = _a.errors;
            return _this._translate
                .get('common.close')
                .pipe((map(function (translation) {
                var er = errors.add;
                return {
                    close: translation,
                    message: er.data.message
                };
            })));
        }), switchMap(function (_a) {
            var close = _a.close, message = _a.message;
            return _this._snack
                .fail$(message, close)
                .pipe(map(function () { return new RemoveError(ActionTypes.SendPasswordChangeFail); }));
        }));
        this._onSendPasswordChangeSuccess$ = this._actions$.pipe(ofType(ActionTypes.SendPasswordChangeLocalSuccess), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'authBase.passwordChangeSuccessSnack'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.success$(translations['authBase.passwordChangeSuccessSnack'], translations['common.close']); })); }));
        // -- external auth
        // -- login
        this._onExternalAuthLoginRequest$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .externalAuthLogin$(payload)
                .pipe(map(function (_a) {
                var authUrl = _a.authUrl;
                return new ExternalAuthLoginLocalSuccess(authUrl);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ExternalAuthLoginFail(data); })); }));
        }));
        this._onExternalAuthLoginAuthRedirect$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginLocalSuccess), delay(100), tap(function (_a) {
            var payload = _a.payload;
            window.location.href = payload;
        }));
        this._onExternalAuthLoginFail$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginFail), switchMap(function (action) { return _this._snack.translatedFail$(action.payload.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ExternalAuthLoginFail); }));
        // -- login validate
        this._onExternalAuthLoginValidateRequest$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthLoginValidateRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .externalAuthLoginValidate$(payload.type, payload.code, payload.state)
                .pipe(map(function (res) { return new ExternalAuthLoginValidateLocalSuccess(res); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ExternalAuthLoginValidateFail(data); })); }));
        }));
        this._onExternalAuthLoginValidateSuccessUpdateLocalStorage$ = this._externalAuthLoginValidateSuccess$.pipe(tap(function (_a) {
            var payload = _a.payload;
            return updateStoredEntities(payload.entities);
        }));
        this._onExternalAuthLoginValidateSuccessAuthorize$ = this._externalAuthLoginValidateSuccess$.pipe(map(function (_a) {
            var payload = _a.payload;
            return new Authorize(payload.entities, payload.userId, payload.auth);
        }));
        this._onExternalAuthLoginValidateSuccessRedirect$ = this._externalAuthLoginValidateSuccess$.pipe(map(function () { return new Go({ path: ['/dashboard'] }); }));
        this._onExternalAuthLoginValidateFail$ = this._externalAuthLoginValidateFail$.pipe(switchMap(function (action) { return _this._snack.translatedFail$(action.payload.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ExternalAuthLoginValidateFail); }));
        this._onExternalAuthLoginValidateShowHint$ = this._externalAuthLoginValidateSuccess$.pipe(filter(function (d) { return d.payload.showHint; }), delay(150), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'authBase.emailLoginDisabledHint'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.info$(translations['authBase.emailLoginDisabledHint'], translations['common.close']); })); }));
        this._onExternalAuthLoginValidateFailRedirect$ = this._externalAuthLoginValidateFail$.pipe(map(function () { return new Go({ path: ['/login'] }); }));
        // -- connect
        this._onExternalAuthConnectRequest$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .externalAuthConnect$(payload)
                .pipe(map(function (res) { return new ExternalAuthConnectLocalSuccess(res.authUrl, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ExternalAuthConnectFail(data, payload); })); }));
        }));
        this._onExternalAuthConnectAuthRedirect$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectLocalSuccess), delay(100), tap(function (_a) {
            var payload = _a.payload;
            window.location.href = payload;
        }));
        this._onExternalAuthConnectFail$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectFail), switchMap(function (action) { return _this._snack.translatedFail$(action.payload.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ExternalAuthConnectFail); }));
        // -- connect validate
        this._onExternalAuthConnectValidateRequest$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthConnectValidateRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .externalAuthConnectValidate$(payload.type, payload.code, payload.state)
                .pipe(map(function (res) { return new ExternalAuthConnectValidateLocalSuccess(res); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ExternalAuthConnectValidateFail(data); })); }));
        }));
        this._onExternalAuthConnectValidateSuccessUpdateLocalStorage$ = this._externalAuthConnectValidateSuccess$.pipe(tap(function (_a) {
            var payload = _a.payload;
            return updateStoredEntities(payload.entities);
        }));
        this._onExternalAuthConnectValidateSuccessShowSnack$ = this._externalAuthConnectValidateSuccess$.pipe(switchMap(function () { return _this._snack.translatedSuccess$('authBase.externalAuthValidationSuccess', 'common.close'); }));
        this._onExternalAuthConnectValidateSuccessRedirect$ = this._externalAuthConnectValidateSuccess$.pipe(map(function () { return new Go({ path: ['/profile'] }); }));
        this._onExternalAuthConnectValidateFail$ = this._externalAuthConnectValidateFail$.pipe(switchMap(function (action) { return _this._snack.translatedFail$(action.payload.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ExternalAuthConnectValidateFail); }));
        this._onExternalAuthConnectValidateFailRedirect$ = this._externalAuthConnectValidateFail$.pipe(map(function () { return new Go({ path: ['/login'] }); }));
        // -- remove
        this._onExternalAuthRemoveRequest$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthDeleteRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .externalAuthDelete$(payload)
                .pipe(map(function (res) { return new ExternalAuthDeleteLocalSuccess(res, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ExternalAuthDeleteFail(data, payload); })); }));
        }));
        this._onExternalAuthDeleteFail$ = this._actions$.pipe(ofType(ActionTypes.ExternalAuthDeleteFail), switchMap(function (action) { return _this._snack.translatedFail$(action.payload.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ExternalAuthDeleteFail); }));
        this._onExternalAuthDeleteSuccessShowSnack$ = this._onExternalAuthDeleteSuccess$.pipe(switchMap(function () { return _this._snack.translatedSuccess$('authBase.externalAuthDeleteSuccess', 'common.close'); }));
        this._onExternalAuthDeleteSuccessUpdateLocalStorage$ = this._onExternalAuthDeleteSuccess$.pipe(tap(function (_a) {
            var payload = _a.payload;
            return updateStoredEntities(payload.entities);
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onCheckSavedToken$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onCheckSavedTokenFailSetReferer$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onAuthorizeUpdateLocalStorage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onAuthorizeSetLanguage$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onAuthorizeSetSentryMeta$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSetLanguage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onInvalidateAuthorizationRedirect$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onInvalidateAuthorizationApiLogout$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onInvalidateAuthorizationReset$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSetActiveClient$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSetActiveClientResetReferer$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSendPasswordChangeRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSendPasswordChangeFail$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onSendPasswordChangeSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginAuthRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateSuccessUpdateLocalStorage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateSuccessAuthorize$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateSuccessRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateFail$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateShowHint$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthLoginValidateFailRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectAuthRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateSuccessUpdateLocalStorage$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateSuccessShowSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateSuccessRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthConnectValidateFailRedirect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthRemoveRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthDeleteFail$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthDeleteSuccessShowSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthBaseEffect.prototype, "_onExternalAuthDeleteSuccessUpdateLocalStorage$", void 0);
    return AuthBaseEffect;
}());
export { AuthBaseEffect };
