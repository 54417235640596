import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { extractErrorData, RemoveError } from '@zerops/fe/ngrx';
import { DialogOpen, DialogClose } from '@zerops/fe/dialog';
import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  delay,
  filter,
  mergeMap,
  first,
  tap
} from 'rxjs/operators';
import { State } from '@app/models';
import {
  FilesUploadRequest,
  FilesAllUploadLocalSuccess,
  FilesUploadEmpty,
  FilesActionTypes,
  FilesUploadSingleFail
} from '@app/common/files';
import { ofWsType } from '@app/common/websockets';
import {
  AuthorizedRunEffect,
  authActiveUserClientId,
  authActiveUserNormalizedClient,
  authActiveClientUser
} from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { identity } from '@app/base/auth-base';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from './tickets-base.api';
import {
  ListRequest,
  ListLocalSuccess,
  ListFail,
  ActionTypes,
  EntityRequest,
  EntityFail,
  EntityLocalSuccess,
  MessageRequest,
  MessageLocalSuccess,
  MessageFail,
  MessageSuccess,
  MessageAddTemp,
  UpdateCopyListRequest,
  UpdateCopyListLocalSuccess,
  UpdateCopyListFail,
  TicketTopicListRequest,
  TicketTopicListLocalSuccess,
  TicketTopicListFail,
  UpdateCopyListCheckRequest,
  UpdateCopyListCheckLocalSuccess,
  UpdateCopyListDenied
} from './tickets-base.action';
import { getById } from './tickets-base.selector';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TicketsBaseEffect extends AuthorizedRunEffect {

  private _onMessageRequest$ = this._actions$.pipe(
    ofType<MessageRequest>(ActionTypes.MessageRequest),
  );

  private _onMessageRequestUploadPassed$ = this._onMessageRequest$.pipe(
    mergeMap(({ payload, meta }) => this._actions$.pipe(
      ofType<FilesAllUploadLocalSuccess | FilesUploadEmpty>(
        FilesActionTypes.UploadAllLocalSuccess,
        FilesActionTypes.UploadEmpty
      ),
      filter((action) => payload.instanceId === action.payload.instanceId),
      first(),
      map((action) => action.type === FilesActionTypes.UploadAllLocalSuccess
        ? { payload, meta, fileIds: action.payload.fileIds }
        : { payload, meta, fileIds: [] }
      )
    ))
  );

  // trigger list request on load
  @Effect()
  private _onInitList$ = this.onAuthorizedInit$.pipe(
    delay(1),
    withLatestFrom(this._store.pipe(
      select(authActiveClientUser)
    )),
    filter(([ _, user ]) => !!user.authorizedToSendTickets),
    map(() => new ListRequest())
  );

  // trigger ticket topics list on load
  @Effect()
  private _onInitTicketTopics$ = this.onAuthorizedInit$.pipe(
    delay(1),
    withLatestFrom(this._store.pipe(
      select(authActiveClientUser)
    )),
    filter(([ _, user ]) => !!user.authorizedToSendTickets),
    map(() => new TicketTopicListRequest())
  );

  // ticket topic list request
  @Effect()
  private _onTicketTopicRequest$ = this._actions$.pipe(
    ofType<TicketTopicListRequest>(ActionTypes.TicketTopicListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .ticketTopicList$(id)
      .pipe(
        map(({ ticketTopic }) => new TicketTopicListLocalSuccess(ticketTopic)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new TicketTopicListFail(data)))
        )
      )
    )
  );

  // list request
  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, clientId ]) => this._api
      .list$(clientId)
      .pipe(
        map((response) => new ListLocalSuccess(response.ticketList)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  );

  // entity request
  @Effect()
  private _onDetailRequest$ = this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap((({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    ))
  );

  // copy list
  @Effect()
  private _onCopyListCheckRequest$ = this._actions$.pipe(
    ofType<UpdateCopyListCheckRequest>(ActionTypes.UpdateCopyListCheckRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserNormalizedClient))),
    switchMap(([ { payload }, client ]) => {
      if (client.secureCommunication) {
        return this._api
          .secureCommunicationCheck$(
            payload.mail,
            client.id
          )
          .pipe(
            first(),
            map(() => ({
              pass: true,
              payload
            })),
            catchError((err) => {
              const errData = extractErrorData(err);

              if ([ 2101, 2145 ].includes(errData.code)) {
                return of({
                  pass: false,
                  payload
                });
              }

              return of({
                pass: true,
                payload
              });
            })
          );
      }

      return of({
        pass: true,
        payload
      });

    }),
    map(({ pass, payload }) => {
      if (pass) {
        return new UpdateCopyListRequest(payload.id, payload.data, payload.mail, 'add', payload.isWatch);
      } else {
        return new UpdateCopyListCheckLocalSuccess(pass, payload, payload.isWatch);
      }
    })
  );

  @Effect()
  private _onUpdateCopyListCheckLocalSuccess$ = this._actions$.pipe(
    ofType<UpdateCopyListCheckLocalSuccess>(ActionTypes.UpdateCopyListCheckLocalSuccess),
    filter(({ payload }) => !payload.pass),
    map(() => new DialogOpen(ActionTypes.UpdateCopyListCheckRequest))
  );

  @Effect()
  private _onCopyListRequest$ = this._actions$.pipe(
    ofType<UpdateCopyListRequest>(ActionTypes.UpdateCopyListRequest),
    // get ticket by id
    switchMap(({ payload, meta }) => this._store.pipe(
      select(getById(payload.id)),
      filter((t) => !!t),
      first(),
      map((t) => ({
        id: payload.id,
        payload: payload.data,
        mail: payload.mail,
        copyList: t.copyList,
        isWatch: payload.isWatch,
        isAdd: meta === 'add'
      }))
    )),
    switchMap(({
      id,
      payload,
      mail,
      isWatch,
      copyList,
      isAdd
    }): Observable<UpdateCopyListDenied | UpdateCopyListLocalSuccess | UpdateCopyListFail> => {
      // if mail is already part of copy list
      // and we are adding, not removing
      // show error snack
      if (copyList.includes(mail) && isAdd) {
        return of(new UpdateCopyListDenied(id, 'ticketBase.copyListDuplicaterErr'));

      } else {
        return this._api
          .updateCopyList$(id, payload)
          .pipe(
            map((response) => new UpdateCopyListLocalSuccess(response, id, isWatch)),
            catchError((err) => this._errorTranslation
              .get$(err)
              .pipe(map((data) => new UpdateCopyListFail(data, id, isWatch)))
            )
          );
        }
      }
    ),
    filter((action) => !!action)
  );

  @Effect({ dispatch: false })
  private _onUpdateCopyListDenied$ = this._actions$.pipe(
    ofType<UpdateCopyListDenied>(ActionTypes.UpdateCopyListDenied),
    switchMap(({ payload }) => this._snack.translatedWarning$(
      payload,
      'common.close'
    ))
  );

  @Effect()
  private _onUpdateCopyListFail$ = this._actions$.pipe(
    ofType<UpdateCopyListFail>(ActionTypes.UpdateCopyListFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.UpdateCopyListFail))
  );

  @Effect()
  private _onUpdateCopyListLocalSuccessCloseDialog$ = this._actions$.pipe(
    ofType<UpdateCopyListLocalSuccess>(ActionTypes.UpdateCopyListLocalSuccess),
    map(() => new DialogClose(ActionTypes.UpdateCopyListCheckRequest))
  );

  // starts upload
  @Effect()
  private _onMessageRequestUpload$ = this._onMessageRequest$.pipe(
    map(({ payload }) => new FilesUploadRequest(payload.instanceId))
  );

  // add optimistic entity
  @Effect()
  private _onMessageRequestAddTemp$ = this._onMessageRequest$.pipe(
    withLatestFrom(this._store.pipe(select(identity))),
    map(([ { payload, meta }, idnt ]) => new MessageAddTemp(
      payload.ticketId,
      payload.messageText,
      idnt,
      meta
    ))
  );

  @Effect()
  private _onMessageSocketSuccess$ = this._actions$.pipe(
    ofWsType('ticket'),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    filter(([ data, id ]) => data.clientId === id),
    map(([ data, _ ]) => new MessageSuccess(data))
  );

  @Effect()
  private _onTicketAddUploadFail$ = this._onMessageRequest$.pipe(
    mergeMap(({ payload, meta }) => this._actions$.pipe(
      ofType<FilesUploadSingleFail>(FilesActionTypes.UploadSingleFail),
      filter((action) => payload.instanceId === action.payload.instanceId),
      first(),
      map((action) => new MessageFail(meta, payload.ticketId, action.meta))
    ))
  );

  @Effect()
  private _onMessageRequestMain$ = this._onMessageRequestUploadPassed$.pipe(
    mergeMap(({ payload, meta, fileIds }) => this._api
      .message$(
        payload.ticketId,
        payload.messageText,
        meta,
        fileIds
      )
      .pipe(
        map((res) => new MessageLocalSuccess(res, meta)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new MessageFail(meta, payload.ticketId, data)))
        )
      )
    )
  );

  @Effect()
  private _onMessageFail$ = this._actions$.pipe(
    ofType<MessageFail>(ActionTypes.MessageFail),
    switchMap(({ meta }) => this._snack.fullyTranslatedSuccess$(
      'ticketsMessage.failSnack',
      'common.close',
      { err: meta.err.message }
    )),
    map(() => new RemoveError(ActionTypes.MessageFail))
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: TicketsBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) {
    super(_actions$, _store);
  }
}
