import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import {
  ServersEntityNormalizedData,
  ServerEntity,
  ServerEntityLight,
  ServersEntityEntities,
  ServerGraph,
  ServerGraphFormatted,
  IpRangeApiResponse
} from './servers-base.model';
import { normalizeList } from './servers-base.utils';

export enum ActionTypes {
  EntityRequest = '[Servers Base] Entity Request',
  EntityFail = '[Servers Base] Entity Fail',
  EntityLocalSuccess = '[Servers Base] Entity Local Success',

  GraphRequest = '[Servers Base] Graph Request',
  GraphFail = '[Servers Base] Graph Fail',
  GraphLocalSuccess = '[Servers Base] Graph Local Success',

  ListRequest = '[Servers Base] List Request',
  ListFail = '[Servers Base] List Fail',
  ListLocalSuccess = '[Servers Base] List Local Success',

  IpRangeRequest = '[Servers Base] IP Range Request',
  IpRangeFail = '[Servers Base] IP Range Fail',
  IpRangeLocalSuccess = '[Servers Base] IP Range Local Success'
}

export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ServersEntityEntities };

  constructor(data: ServerEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }
}

export class GraphRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.GraphRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.GraphFail
  };
  progress: ProgressActionMeta;

  constructor(
    public payload: {
      id: string,
      graphType: string[],
      graphInterval: string
    },
    public meta = false
  ) {
    this.progress = {
      add: !this.meta ? {
        key: `${ActionTypes.GraphRequest}:${this.payload.id}`,
        type: 'local',
        group: ActionTypes.GraphRequest
      } : undefined
    };
  }
}

export class GraphFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.GraphFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.GraphFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.GraphRequest}:${id}`
    };
  }

}

export class GraphLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.GraphLocalSuccess;
  progress: ProgressActionMeta;
  payload: {
    data: ServerGraphFormatted[][];
    id: string;
    graphType: string[];
  };

  constructor(
    data: ServerGraph[],
    id: string,
    graphType: string[]
  ) {
    const returnedGraphs = data.map((s) => s.name);

    const formattedData = data.map((d) => {
      return d.series.reduce((arr, ser) => {
        arr.push(ser.dateTime.map((label, index) => {

          const group = ser.name;
          const value = ser.median[index] !== undefined
            ? Math.round(ser.median[index])
            : Math.round(ser.value[index]);

          const min = ser.minValue[index] !== undefined
            ? Math.round(ser.minValue[index])
            : undefined;

          const max = ser.maxValue[index] !== undefined
            ? Math.round(ser.maxValue[index])
            : undefined;

          return { value, min, max, label, group };
        }));
        return arr;
      }, []);
    });

    this.payload = {
      id,
      graphType: graphType.filter((t) => returnedGraphs.includes(t)),
      data: formattedData
    };
    this.progress = {
      remove: `${ActionTypes.GraphRequest}:${id}`
    };
  }
}

export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };

  /**
   * @param payload category
   * @param meta group
   */
  constructor(public payload: string, public meta?: 'managed' | 'hosted') { }
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }

}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: ServersEntityNormalizedData;

  constructor(data: ServerEntity[] | ServerEntityLight[]) {
    this.payload = normalizeList(data);
  }
}

export class IpRangeRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.IpRangeRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.IpRangeFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.IpRangeRequest,
      type: 'local'
    }
  };

  /**
   * @param payload clientId
   */
  constructor(public payload: string) { }
}

export class IpRangeFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.IpRangeFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.IpRangeRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.IpRangeFail,
        type: 'global',
        data
      }
    };
  }

}

export class IpRangeLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.IpRangeLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.IpRangeRequest
  };

  constructor(public payload: IpRangeApiResponse) { }
}

export type Actions
  = EntityRequest
  | EntityFail
  | EntityLocalSuccess
  | GraphRequest
  | GraphFail
  | GraphLocalSuccess
  | ListRequest
  | ListFail
  | ListLocalSuccess
  | IpRangeRequest
  | IpRangeFail
  | IpRangeLocalSuccess;
