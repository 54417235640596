import { normalize, denormalize } from 'normalizr';
import { serverParkAccessListSchema, serverParkAccessSchema } from '@app/schemas';
import { ModuleTokens } from './server-park-access-base.constant';
export function normalizeList(data) {
    return normalize(data, serverParkAccessListSchema);
}
export function denormalizeList(ids, serverParkAccessBaseEntities) {
    var _a;
    return denormalize(ids, serverParkAccessListSchema, (_a = {},
        _a[ModuleTokens.Name] = serverParkAccessBaseEntities,
        _a));
}
export function denormalizeEntity(id, serverParkAccessBaseEntities) {
    var _a;
    return denormalize(id, serverParkAccessSchema, (_a = {},
        _a[ModuleTokens.Name] = serverParkAccessBaseEntities,
        _a));
}
