import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { extractErrorData } from '@zerops/fe/ngrx';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthActionTypes } from '@app/base/auth-base';
import { SettingsApi } from './settings.api';
import { LoadRequest, LoadLocalSuccess, LoadFail, ActionTypes } from './settings.action';
var SettingsEffect = /** @class */ (function () {
    function SettingsEffect(_actions$, _store, _api) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._onAuthorize$ = this._actions$.pipe(ofType(AuthActionTypes.Authorize));
        this._onAuthorizeLoadRequest$ = this._onAuthorize$.pipe(map(function () { return new LoadRequest(); }));
        this._onLoadRequest$ = this._actions$.pipe(ofType(ActionTypes.LoadRequest), switchMap(function () { return _this._api
            .settings$()
            .pipe(map(function (response) { return new LoadLocalSuccess(response); }), catchError(function (err) { return of(new LoadFail(extractErrorData(err))); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SettingsEffect.prototype, "_onAuthorizeLoadRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SettingsEffect.prototype, "_onLoadRequest$", void 0);
    return SettingsEffect;
}());
export { SettingsEffect };
