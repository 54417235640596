import * as tslib_1 from "tslib";
import { ModuleTokens } from './services-base.constant';
var ServicesBaseState = /** @class */ (function () {
    function ServicesBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
    }
    return ServicesBaseState;
}());
export { ServicesBaseState };
var ServicesEntityEntities = /** @class */ (function () {
    function ServicesEntityEntities() {
    }
    return ServicesEntityEntities;
}());
export { ServicesEntityEntities };
ModuleTokens.Name;
var ServicesEntityNormalizedData = /** @class */ (function () {
    function ServicesEntityNormalizedData() {
    }
    return ServicesEntityNormalizedData;
}());
export { ServicesEntityNormalizedData };
// TODO: interface
var ServiceEntityLight = /** @class */ (function () {
    function ServiceEntityLight() {
    }
    return ServiceEntityLight;
}());
export { ServiceEntityLight };
var ServiceEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ServiceEntityBase, _super);
    function ServiceEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServiceEntityBase;
}(ServiceEntityLight));
export { ServiceEntityBase };
var ServiceEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(ServiceEntityNormalized, _super);
    function ServiceEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServiceEntityNormalized;
}(ServiceEntityBase));
export { ServiceEntityNormalized };
// TODO: interface
var ServiceEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ServiceEntity, _super);
    function ServiceEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServiceEntity;
}(ServiceEntityBase));
export { ServiceEntity };
