import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { FormsModule } from '@app/common/forms';
import { EmailFormComponent } from './email-form.component';

@NgModule({
  declarations: [ EmailFormComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forChild(),
    NgrxFormsModule,
    NgrxFormsExtensionsModule,
    FormsModule
  ],
  exports: [ EmailFormComponent ]
})
export class EmailFormModule {

}
