import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'vshcz-content-title',
  templateUrl: './content-title.component.html',
  styleUrls: [ './content-title.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTitleComponent {
  @Input()
  position: 'left' | 'center' | 'right';

  @Input()
  size: 'big' | 'medium' | 'small';

  @HostBinding('style.text-align')
  get textAlignStyle() {
    return this.position;
  }

}
