/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recaptcha.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@zerops/fe/zerops-fe.ngfactory";
import * as i3 from "@zerops/fe";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../progress-item/progress-item.component.ngfactory";
import * as i8 from "../progress-item/progress-item.component";
import * as i9 from "../../../../../node_modules/ng-recaptcha/recaptcha/recaptcha.component.ngfactory";
import * as i10 from "ng-recaptcha/recaptcha/recaptcha.component";
import * as i11 from "ng-recaptcha/recaptcha/recaptcha-loader.service";
import * as i12 from "ng-recaptcha/recaptcha/recaptcha-settings";
import * as i13 from "./recaptcha.component";
import * as i14 from "@ngrx/store";
var styles_RecaptchaComponent = [i0.styles];
var RenderType_RecaptchaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecaptchaComponent, data: {} });
export { RenderType_RecaptchaComponent as RenderType_RecaptchaComponent };
export function View_RecaptchaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "zps-dialog", [], null, null, null, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.DialogComponent, [i4.MatDialog], { backdropClass: [0, "backdropClass"], open: [1, "open"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 11, "div", [["class", "__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "mat-h2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["translate", "recaptcha.title"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "__desc"], ["translate", "recaptcha.desc"]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "vshcz-progress-item", [["class", "__progress-item"]], [[2, "is-active", null]], null, null, i7.View_ProgressItemComponent_0, i7.RenderType_ProgressItemComponent)), i1.ɵdid(10, 49152, null, 0, i8.ProgressItemComponent, [], { active: [0, "active"], spinnerSize: [1, "spinnerSize"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "re-captcha", [["class", "__captcha"]], [[1, "id", 0]], [[null, "resolved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resolved" === en)) {
        var pd_0 = (_co.onResolve$.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_RecaptchaComponent_0, i9.RenderType_RecaptchaComponent)), i1.ɵdid(12, 4374528, null, 0, i10.RecaptchaComponent, [i1.ElementRef, i11.RecaptchaLoaderService, i1.NgZone, [2, i12.RECAPTCHA_SETTINGS]], { siteKey: [0, "siteKey"] }, { resolved: "resolved" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "__footer"], ["translate", "recaptcha.footer"]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "c-recaptcha__backdrop"; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.isActive$)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "recaptcha.title"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "recaptcha.desc"; _ck(_v, 8, 0, currVal_3); var currVal_5 = _co.isLoading; var currVal_6 = 20; _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_8 = _co.siteKey; _ck(_v, 12, 0, currVal_8); var currVal_9 = "recaptcha.footer"; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 10).isActiveClass; _ck(_v, 9, 0, currVal_4); var currVal_7 = i1.ɵnov(_v, 12).id; _ck(_v, 11, 0, currVal_7); }); }
export function View_RecaptchaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-recaptcha", [], null, null, null, View_RecaptchaComponent_0, RenderType_RecaptchaComponent)), i1.ɵdid(1, 245760, null, 0, i13.RecaptchaComponent, [i14.Store, i11.RecaptchaLoaderService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecaptchaComponentNgFactory = i1.ɵccf("vshcz-recaptcha", i13.RecaptchaComponent, View_RecaptchaComponent_Host_0, {}, {}, []);
export { RecaptchaComponentNgFactory as RecaptchaComponentNgFactory };
