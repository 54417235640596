<vshcz-popover
  [horizontalAlign]="horAlignment"
  [lockAlignment]="lockAlignment"
  [forceAlignment]="forceAlignment"
  verticalAlign="below">
  <mat-card class="__card  mat-elevation-z5">
    <div class="__heading" *ngIf="heading">
      <h3 class="mat-h3  __title">
        {{ heading }}
        <button
          class="__close"
          mat-icon-button
          (click)="!!popoverRef.close()">
          <mat-icon>close</mat-icon>
        </button>
      </h3>
    </div>
    <ng-content></ng-content>
  </mat-card>
</vshcz-popover>
