import { HttpClient } from '@angular/common/http';
import { ExternalAuthTypes } from './auth-base.constant';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthBaseApi = /** @class */ (function () {
    function AuthBaseApi(_http) {
        this._http = _http;
    }
    AuthBaseApi.prototype.logout$ = function (key) {
        return this._http.post("/api/public/auth/logout", { key: key });
    };
    AuthBaseApi.prototype.refresh$ = function (refreshTokenId) {
        return this._http.post("/api/public/auth/refresh", { refreshTokenId: refreshTokenId });
    };
    AuthBaseApi.prototype.recoverPassword$ = function (email) {
        return this._http.put("/api/public/user/recover-password", { email: email });
    };
    AuthBaseApi.prototype.externalAuthConnect$ = function (type) {
        var _a;
        var typeMap = (_a = {},
            _a[ExternalAuthTypes.MojeId] = {
                url: 'moje-id/start-account-connection',
                redirectUrl: environment.mojeIdConnectRedirectUrl
            },
            _a[ExternalAuthTypes.Google] = {
                url: 'google-account/start-account-connection',
                redirectUrl: environment.googleConnectRedirectUrl
            },
            _a[ExternalAuthTypes.AppleId] = {
                url: 'apple-id/start-account-connection',
                redirectUrl: environment.appleIdConnectRedirectUrl
            },
            _a);
        var _b = typeMap[type], url = _b.url, redirectUrl = _b.redirectUrl;
        return this._http.post("/api/public/" + url, { redirectUrl: redirectUrl });
    };
    AuthBaseApi.prototype.externalAuthLogin$ = function (type) {
        var _a;
        var typeMap = (_a = {},
            _a[ExternalAuthTypes.MojeId] = {
                url: 'moje-id/login-url',
                redirectUrl: environment.mojeIdLoginRedirectUrl
            },
            _a[ExternalAuthTypes.Google] = {
                url: 'google-account/login-url',
                redirectUrl: environment.googleLoginRedirectUrl
            },
            _a[ExternalAuthTypes.AppleId] = {
                url: 'apple-id/login-url',
                redirectUrl: environment.appleIdLoginRedirectUrl
            },
            _a);
        var _b = typeMap[type], url = _b.url, redirectUrl = _b.redirectUrl;
        return this._http.post("/api/public/" + url, { redirectUrl: redirectUrl });
    };
    AuthBaseApi.prototype.externalAuthLoginValidate$ = function (type, code, state) {
        var _a;
        var typeMap = (_a = {},
            _a[ExternalAuthTypes.MojeId] = {
                url: 'moje-id/login'
            },
            _a[ExternalAuthTypes.Google] = {
                url: 'google-account/login'
            },
            _a[ExternalAuthTypes.AppleId] = {
                url: 'apple-id/login'
            },
            _a);
        var url = typeMap[type].url;
        return this._http.post("/api/public/" + url, { code: code, state: state });
    };
    AuthBaseApi.prototype.externalAuthConnectValidate$ = function (type, code, state) {
        var _a;
        var typeMap = (_a = {},
            _a[ExternalAuthTypes.MojeId] = {
                url: 'moje-id/confirm-account-connection'
            },
            _a[ExternalAuthTypes.Google] = {
                url: 'google-account/confirm-account-connection'
            },
            _a[ExternalAuthTypes.AppleId] = {
                url: 'apple-id/confirm-account-connection'
            },
            _a);
        var url = typeMap[type].url;
        return this._http.post("/api/public/" + url, { code: code, state: state });
    };
    AuthBaseApi.prototype.externalAuthDelete$ = function (loginMethod) {
        return this._http.request('DELETE', "/api/public/user/login-method", { body: { loginMethod: loginMethod } });
    };
    AuthBaseApi.prototype.changePassword$ = function (email, password, token) {
        return this._http.put("/api/public/user/change-password", { email: email, password: password, token: token });
    };
    AuthBaseApi.prototype.getAuthInfo$ = function (accessToken) {
        return this._http.get("/api/public/auth/info", {
            headers: { Authorization: "Bearer " + accessToken }
        });
    };
    AuthBaseApi.prototype.getUserInfo$ = function (accessToken) {
        return this._http.get("/api/public/user/info", {
            headers: { Authorization: "Bearer " + accessToken }
        });
    };
    AuthBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function AuthBaseApi_Factory() { return new AuthBaseApi(i0.inject(i1.HttpClient)); }, token: AuthBaseApi, providedIn: "root" });
    return AuthBaseApi;
}());
export { AuthBaseApi };
