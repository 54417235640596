/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wrap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./wrap.component";
var styles_WrapComponent = [i0.styles];
var RenderType_WrapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WrapComponent, data: {} });
export { RenderType_WrapComponent as RenderType_WrapComponent };
export function View_WrapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_WrapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-wrap", [], [[2, "is-small", null], [2, "is-medium", null], [2, "is-big", null], [2, "is-full", null]], null, null, View_WrapComponent_0, RenderType_WrapComponent)), i1.ɵdid(1, 49152, null, 0, i2.WrapComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classIsSmall; var currVal_1 = i1.ɵnov(_v, 1).classIsMedium; var currVal_2 = i1.ɵnov(_v, 1).classIsBig; var currVal_3 = i1.ɵnov(_v, 1).classIsFull; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var WrapComponentNgFactory = i1.ɵccf("vshcz-wrap", i2.WrapComponent, View_WrapComponent_Host_0, { size: "size" }, {}, ["*"]);
export { WrapComponentNgFactory as WrapComponentNgFactory };
