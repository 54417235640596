import * as tslib_1 from "tslib";
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, merge } from 'rxjs';
import { takeUntil, map, withLatestFrom, switchMap, startWith } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { AddToQueue, RefusedToQueue, RemoveFromQueue } from './files.action';
import { queueFiles } from './files.selector';
var FilesContainer = /** @class */ (function (_super) {
    tslib_1.__extends(FilesContainer, _super);
    function FilesContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onSelect$ = new Subject();
        _this.onRemoveFromQueue$ = new Subject();
        // -- async
        _this.files$ = _this.instanceId$.pipe(switchMap(function (instanceId) { return _this._store.pipe(select(queueFiles(instanceId))); }));
        // # Action Streams
        _this._selectAction$ = _this.onSelect$.pipe(withLatestFrom(_this.files$, _this.instanceId$, _this.maxCount$.pipe(startWith(undefined)), _this.maxSize$.pipe(startWith(undefined))), map(function (_a) {
            var files = _a[0], currentFiles = _a[1], instanceId = _a[2], maxCount = _a[3], maxSize = _a[4];
            var totalFiles = files.length + currentFiles.length;
            var totalSize = _this._getTotalUploadedFilesSize(currentFiles, files);
            if (maxCount && totalFiles > maxCount) {
                return new RefusedToQueue('count');
            }
            if (maxSize && totalSize > maxSize) {
                return new RefusedToQueue('size');
            }
            return new AddToQueue({
                instanceId: instanceId,
                files: files
            });
        }));
        _this._removeFromQueueAction$ = _this.onRemoveFromQueue$.pipe(withLatestFrom(_this.instanceId$), map(function (_a) {
            var uuid = _a[0], instanceId = _a[1];
            return new RemoveFromQueue({
                instanceId: instanceId,
                uuid: uuid
            });
        }));
        merge(_this._removeFromQueueAction$, _this._selectAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    FilesContainer.prototype._getTotalUploadedFilesSize = function (current, added) {
        var currentSize = current.reduce(function (total, item) {
            return total + item.meta.size;
        }, 0);
        var addedSize = added.reduce(function (total, item) {
            total = total + item.meta.size;
            return total;
        }, 0);
        return currentSize + addedSize;
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], FilesContainer.prototype, "instanceId$", void 0);
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], FilesContainer.prototype, "maxCount$", void 0);
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], FilesContainer.prototype, "maxSize$", void 0);
    return FilesContainer;
}(BaseClass));
export { FilesContainer };
