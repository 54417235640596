import {
  Component,
  Input,
  ContentChild,
  AfterViewInit,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { errorsByKeys, progressesByKeys, RemoveError } from '@zerops/fe/ngrx';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { Observable, Subject, merge } from 'rxjs';
import {
  switchMap,
  map,
  distinctUntilChanged,
  delay,
  withLatestFrom,
  startWith,
  takeUntil,
  share
} from 'rxjs/operators';
import isArray from 'lodash-es/isArray';

@Component({
  selector: 'vshcz-progress-error',
  templateUrl: './progress-error.container.html',
  styleUrls: [ './progress-error.container.scss' ]
})
export class ProgressErrorContainer
  extends BaseClass
  implements AfterViewInit, OnInit {

  // # Event Streams
  onResetError$ = new Subject<void>();

  // # Data
  // -- angular
  @Input('errorKey')
  @ObservableInput()
  errorKeys$: Observable<string[] | string>;

  @Input('progressDisabled')
  @ObservableInput()
  progressDisabled$: Observable<boolean>;

  @Input('progressKey')
  @ObservableInput()
  progressKeys$: Observable<string[] | string>;

  @Input()
  set hideProgressSpinner(v) {
    this._hideProgressSpinner = coerceBooleanProperty(v);
  }
  get hideProgressSpinner() {
    return this._hideProgressSpinner;
  }

  @Input()
  errorCloseLabel: string;

  @Input()
  disabled: boolean;

  @Input()
  errorRetryLabel: string;

  @Input()
  errorShowDetailsLabel: string;

  @Input()
  errorHideDetailsLabel: string;

  @Input()
  set denseButton(v) {
    this._denseButton = coerceBooleanProperty(v);
  }
  get denseButton() {
    return this._denseButton;
  }

  @Output()
  retry = new EventEmitter<void>();

  @ContentChild(MatButton)
  buttonRef: MatButton;

  // -- async
  errors$ = this.errorKeys$.pipe(
    switchMap((keys) => {
      const errs = isArray(keys) ? keys : [ keys ];

      return this._store.pipe(select(errorsByKeys(errs)));
    }),
    map((res) => res && res.length ? res[0] : undefined),
    distinctUntilChanged(),
    share()
  );

  // -- sync
  progressState: boolean;
  showAdditionalErrorInfo = false;

  private _hideProgressSpinner = false;
  private  _denseButton = false;

  // # Actions
  private _resetAction$ = this.onResetError$.pipe(
    withLatestFrom(this.errorKeys$),
    map(([ _, key ]) => new RemoveError(key))
  );

  constructor(private _store: Store<any>) {
    super();
  }

  ngOnInit() {
    // # Action Dispatcher
    merge(
      this._resetAction$
    )
    .pipe(takeUntil(this._ngOnDestroy$))
    .subscribe(this._store);
  }

  ngAfterViewInit() {

    this.progressKeys$
      .pipe(
        switchMap((keys) => {
          const _keys = isArray(keys) ? keys : [ keys ];

          return this._store.pipe(select(progressesByKeys(_keys)));
        }),
        delay(1),
        withLatestFrom(this.progressDisabled$.pipe(startWith(false))),
        takeUntil(this._ngOnDestroy$)
      )
      .subscribe(([ state, disabled ]) => {
        if (!disabled) {
          if (this.disabled === undefined) {
            this.buttonRef.disabled = !!state.length;
          }
          this.progressState = !!state.length;
        } else {
          this.buttonRef.disabled = true;
          this.progressState = false;
        }
      });

  }

  toggleAdditionalErrorInfo() {
    this.showAdditionalErrorInfo = !this.showAdditionalErrorInfo;
  }
}
