import * as tslib_1 from "tslib";
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyTicketAddFormReducer } from '@app/common/ticket-add-form';
import { TicketAddState } from './ticket-add.model';
import { ActionTypes } from './ticket-add.action';
import { ModuleTokens } from './ticket-add.constant';
var initialState = new TicketAddState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyTicketAddFormReducer(state, action, ModuleTokens.Name, {
        title: validate(required),
        ticketMessage: validate(required)
    });
    if (action.type === ActionTypes.Reset) {
        return tslib_1.__assign({}, state, { forms: initialState.forms });
    }
    return state;
}
