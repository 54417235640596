<vshcz-wrap size="big" class="__wrap">

  <div
    fxLayout="row"
    fxLayoutAlign="start stretch">

    <div fxFlex="none" class="__item">
      <div class="__padder  __padder--left">
        <a
          class="__dashboard-link  __logo-link"
          [class.is-compact]="!media.isActive('gt-sm')"
          mat-button
          (click)="!media.isActive('gt-sm')
            ? toTop()
            : undefined"
          [routerLink]="[ '/dashboard' ]">

          <mat-icon
            *ngIf="media.isActive('gt-sm')"
            class="__dashboard-link_item  __dashboard-link_item--hidden">
            dashboard
          </mat-icon>

          <vshcz-logo
            class="__logo  __dashboard-link_item  __dashboard-link_item--active">
          </vshcz-logo>

          <span
            *ngIf="!media.isActive('gt-sm')"
            class="__terminal">
            cd <span class="__blink">/</span>
          </span>

        </a>
      </div>
    </div>

    <!-- hide completely in gt-sm -->
    <div
      *ngIf="media.isActive('gt-sm')"
      fxFlex="none"
      class="__item">
      <div
        class="__padder  __padder--menu"
        [class.has-no-billing]="(showFinancialAndStatus$ | async) === false"
        [class.is-visible]="_menuHidden$ | async"
        [class.has-transition]="_menuTransitionEnabled">
        <vshcz-menu-items
          class="__menu-items"
          [overdueInvoicesCount]="overdueInvoiceCount$ | async"
          [servicesCount]="undefined">
        </vshcz-menu-items>
      </div>
    </div>

    <div fxFlex="10px" class="__item">
      <span class="__separator"></span>
    </div>

    <div
      fxFlex="auto"
      fxFlex.gt-md="auto"
      class="__item">
      <div class="__padder  __padder--search">
        <div
          class="__search">
          <button
            class="__search_button"
            mat-button
            type="button"
            (click)="onSearchOpen$.next()"
            color="accent">
            <span class="__search_text">

              <!-- complex -->
              <ng-container *ngIf="!media.isActive('md') && !media.isActive('lt-sm')">
                <span translate="searchTrigger.start"></span>

                <mat-icon class="__search_text-icon">search</mat-icon>

                <span
                  *ngIf="media.isActive('gt-md')"
                  translate="searchTrigger.or">
                </span>

                <vshcz-key-chip *ngIf="media.isActive('gt-md')">
                  alt + f
                </vshcz-key-chip>

                <span
                  *ngIf="media.isActive('gt-md')"
                  translate="searchTrigger.end">
                </span>

                <span
                  *ngIf="!media.isActive('gt-md')"
                  translate="searchTrigger.endMob">
                </span>
              </ng-container>

              <!-- simple -->
              <ng-container *ngIf="media.isActive('md') || media.isActive('lt-sm')">

                <mat-icon class="__search_text-icon">search</mat-icon>

                <span translate="searchTrigger.search">
                </span>

              </ng-container>

            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      fxFlex="none"
      class="__item  __item--right">
      <vshcz-logged-user [compact]="!media.isActive('gt-sm')"></vshcz-logged-user>
    </div>

  </div>

</vshcz-wrap>
