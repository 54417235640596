import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthEntity, ExternalAuthLoginValidateResponse } from './auth-base.model';
import { UserEntity, UserEntityBase } from '@app/base/users-base';
import { ExternalAuthApiTypes, ExternalAuthTypes } from './auth-base.constant';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthBaseApi {

  constructor(private _http: HttpClient) { }

  logout$(key: string) {
    return this._http.post<{ success: boolean }>(
      `/api/public/auth/logout`,
      { key }
    );
  }

  refresh$(refreshTokenId: string) {
    return this._http.post<AuthEntity>(
      `/api/public/auth/refresh`,
      { refreshTokenId }
    );
  }

  recoverPassword$(email: string) {
    return this._http.put<{ success: true }>(
      `/api/public/user/recover-password`,
      { email }
    );
  }

  externalAuthConnect$(type: ExternalAuthTypes) {
    const typeMap = {
      [ExternalAuthTypes.MojeId]: {
        url: 'moje-id/start-account-connection',
        redirectUrl: environment.mojeIdConnectRedirectUrl
      },
      [ExternalAuthTypes.Google]: {
        url: 'google-account/start-account-connection',
        redirectUrl: environment.googleConnectRedirectUrl
      },
      [ExternalAuthTypes.AppleId]: {
        url: 'apple-id/start-account-connection',
        redirectUrl: environment.appleIdConnectRedirectUrl
      }
    };

    const { url, redirectUrl } = typeMap[type];

    return this._http.post<{ authUrl: string; }>(
      `/api/public/${url}`,
      { redirectUrl }
    );
  }

  externalAuthLogin$(type: ExternalAuthTypes) {
    const typeMap = {
      [ExternalAuthTypes.MojeId]: {
        url: 'moje-id/login-url',
        redirectUrl: environment.mojeIdLoginRedirectUrl
      },
      [ExternalAuthTypes.Google]: {
        url: 'google-account/login-url',
        redirectUrl: environment.googleLoginRedirectUrl
      },
      [ExternalAuthTypes.AppleId]: {
        url: 'apple-id/login-url',
        redirectUrl: environment.appleIdLoginRedirectUrl
      }
    };

    const { url, redirectUrl } = typeMap[type];

    return this._http.post<{ authUrl: string; }>(
      `/api/public/${url}`,
      { redirectUrl }
    );
  }

  externalAuthLoginValidate$(type: ExternalAuthTypes, code: string, state: string) {
    const typeMap = {
      [ExternalAuthTypes.MojeId]: {
        url: 'moje-id/login'
      },
      [ExternalAuthTypes.Google]: {
        url: 'google-account/login'
      },
      [ExternalAuthTypes.AppleId]: {
        url: 'apple-id/login'
      }
    };

    const { url } = typeMap[type];

    return this._http.post<ExternalAuthLoginValidateResponse>(
      `/api/public/${url}`,
      { code, state }
    );
  }

  externalAuthConnectValidate$(type: ExternalAuthTypes, code: string, state: string) {
    const typeMap = {
      [ExternalAuthTypes.MojeId]: {
        url: 'moje-id/confirm-account-connection'
      },
      [ExternalAuthTypes.Google]: {
        url: 'google-account/confirm-account-connection'
      },
      [ExternalAuthTypes.AppleId]: {
        url: 'apple-id/confirm-account-connection'
      }
    };

    const { url } = typeMap[type];

    return this._http.post<UserEntityBase>(
      `/api/public/${url}`,
      { code, state }
    );
  }

  externalAuthDelete$(loginMethod: ExternalAuthApiTypes) {
    return this._http.request<UserEntityBase>(
      'DELETE',
      `/api/public/user/login-method`,
      { body: { loginMethod } }
    );
  }

  changePassword$(email: string, password: string, token: string) {
    return this._http.put<AuthEntity>(
      `/api/public/user/change-password`,
      { email, password, token }
    );
  }

  getAuthInfo$(accessToken: string) {
    return this._http.get<AuthEntity>(
      `/api/public/auth/info`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
  }

  getUserInfo$(accessToken: string) {
    return this._http.get<UserEntity>(
      `/api/public/user/info`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
  }

}
