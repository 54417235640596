import { DomainRecordBaseState } from './domain-record-base.model';
import { Actions, ActionTypes } from './domain-record-base.action';

const initialState = new DomainRecordBaseState();

export function reducer(
  state = initialState,
  action: Actions
): DomainRecordBaseState {

  if (action.type === ActionTypes.EntityLocalSuccess) {
    return {
      ...state,
      recordsMap: {
        ...state.recordsMap,
        [action.payload.id]: action.payload
      }
    };
  }

  if (action.type === ActionTypes.UpdateLocalSuccess) {
    return {
      ...state,
      recordsMap: {
        ...state.recordsMap,
        [action.payload]: undefined
      }
    };
  }

  return state;
}
