import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { TicketsEntity } from '@app/base/tickets-base';

@Component({
  selector: 'vshcz-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketItemComponent {
  @Input()
  data: TicketsEntity;

  @Input()
  more: string;

  @Input()
  set truncateMessage(v) {
    if (v) {
      this._truncateMessage = coerceNumberProperty(v);
    }
  }

  get truncateMessage() {
    return this._truncateMessage;
  }

  private _truncateMessage = 100;

}
