import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollableComponent } from './scrollable.component';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [ ScrollableComponent ],
  imports: [
    CommonModule,

    ScrollDispatchModule
  ],
  exports: [ ScrollableComponent ]
})
export class ScrollableModule {

}
