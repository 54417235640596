export enum ModuleTokens {
  Name = 'servicesBase'
}

export enum ServiceCategory {
  ServerManaged,
  ServerHosted,
  External
}

interface ServiceType {
  key: string;
  type: ServiceCategory;
  param?: string;
}

export enum ServiceCodes {
  allManaged = 'ALL_MANAGED',
  cdn = 'CDN',
  cloudDns = 'CLOUD_DNS',
  cloudMail = 'CLOUD_MAIL',
  cloudSpace = 'CLOUD_SPACE',
  cloudManagedServer = 'CLOUD_MANAGED_SERVER',
  dedicatedServer = 'DEDICATED_SERVER',
  gpuDedicatedServer = 'GPU_DEDICATED_SERVER',
  vds = 'VDS',
  domain = 'DOMAIN',
  internal = 'INTERNAL',
  managedCluster = 'MANAGED_CLUSTER',
  managedServer = 'MANAGED_SERVER',
  m1ManagedServer = 'M1_MANAGED_SERVER',
  m2ManagedServer = 'M2_MANAGED_SERVER',
  windowsManagedServer = 'MANAGED_WINDOWS_SERVER',
  windowsManagedCluster = 'MANAGED_WINDOWS_CLUSTER',
  windowsManagedCloud = 'MANAGED_WINDOWS_CLOUD',
  awsManagedServer = 'AWS_MANAGED_SERVER',
  windowsManagedBasic = 'MANAGED_WINDOWS_BASIC',
  unknown = 'UNKNOWN',
  rackHosting = 'RACK_HOSTING',
  serverHosting = 'SERVER_HOSTING',
  f1Cdn = 'F1_CDN'
}

export const ServicesTypes: { [key: string]: ServiceType; } = {
  CloudMail: {
    key: ServiceCodes.cloudMail,
    type: ServiceCategory.External
  },
  CloudSpace: {
    key: ServiceCodes.cloudSpace,
    type: ServiceCategory.External
  },
  CloudDns: {
    key: ServiceCodes.cloudDns,
    type: ServiceCategory.External
  },
  F1Cdn: {
    key: ServiceCodes.f1Cdn,
    type: ServiceCategory.External
  },
  Domain: {
    key: ServiceCodes.domain,
    type: ServiceCategory.External
  },
  ManagedCluster: {
    key: ServiceCodes.managedCluster,
    param: 'managed-cluster',
    type: ServiceCategory.ServerManaged
  },
  ManagedServer: {
    key: ServiceCodes.managedServer,
    param: 'managed-server',
    type: ServiceCategory.ServerManaged
  },
  M1ManagedServer: {
    key: ServiceCodes.m1ManagedServer,
    param: 'm1_managed-server',
    type: ServiceCategory.ServerManaged
  },
  M2ManagedServer: {
    key: ServiceCodes.m2ManagedServer,
    param: 'm2_managed-server',
    type: ServiceCategory.ServerManaged
  },
  WindowsManagedServer: {
    key: ServiceCodes.windowsManagedServer,
    param: 'windows_managed-server',
    type: ServiceCategory.ServerManaged
  },
  WindowsManagedCluster: {
    key: ServiceCodes.windowsManagedCluster,
    param: 'windows_managed-cluster',
    type: ServiceCategory.ServerManaged
  },
  WindowsManagedCloud: {
    key: ServiceCodes.windowsManagedCloud,
    param: 'windows_managed-cloud',
    type: ServiceCategory.ServerManaged
  },
  WindowsManagedBasic: {
    key: ServiceCodes.windowsManagedBasic,
    param: 'windows_managed-basic',
    type: ServiceCategory.ServerManaged
  },
  CloudManagedServer: {
    key: ServiceCodes.cloudManagedServer,
    param: 'cloud-managed-server',
    type: ServiceCategory.ServerManaged
  },
  AwsManagedServer: {
    key: ServiceCodes.awsManagedServer,
    param: 'aws-managed-server',
    type: ServiceCategory.ServerManaged
  },
  ServerHosting: {
    key: ServiceCodes.serverHosting,
    param: 'server-hosting',
    type: ServiceCategory.ServerHosted
  },
  RackHosting: {
    key: ServiceCodes.rackHosting,
    param: 'rack-hosting',
    type: ServiceCategory.ServerHosted
  },
  DedicatedServer: {
    key: ServiceCodes.dedicatedServer,
    param: 'dedicated-server',
    type: ServiceCategory.ServerHosted
  },
  Vds: {
    key: ServiceCodes.vds,
    param: 'vds',
    type: ServiceCategory.ServerHosted
  },
  GpuDedicatedServer: {
    key: ServiceCodes.gpuDedicatedServer,
    param: 'gpu-dedicated-server',
    type: ServiceCategory.ServerHosted
  }
};
