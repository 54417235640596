import { Component, Input } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Store } from '@ngrx/store';
import { Currency } from '../settings';
import { HashMap } from 'utils';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseClass } from '@zerops/fe/core';
import { State } from 'app/models';
import { DialogOpen } from '@zerops/fe';
import { BulkPaymentFeesDialogKey} from '../bulk-payment-fees-dialog';
import { BulkPaymentInvoicesDialogKey} from '../bulk-payment-invoices-dialog';
import { PaymentIntentTypes } from '@app/base/invoices-base/invoices-base.constant';
import { PaymentKinds } from '@app/base/payments-base/payments-base.constant';

@Component({
  selector: 'vshcz-bulk-payment-overview',
  templateUrl: './bulk-payment-overview.component.html',
  styleUrls: [ './bulk-payment-overview.component.scss' ],
})
export class BulkPaymentOverviewComponent extends BaseClass {

  onPaymentDialogOpen$ = new Subject<{
    instanceKind: 'dashboard' | 'menu' | 'route';
    key: string;
    paymentKind: PaymentKinds;
    paymentIntentType: PaymentIntentTypes;
  }>();
  onPaymentStripeIntent$ = new Subject<void>();
  invoicesDialogKey = BulkPaymentInvoicesDialogKey;
  feesDialogKey = BulkPaymentFeesDialogKey;
  paymentKinds = PaymentKinds;
  paymentIntentTypes = PaymentIntentTypes;

  /**
   * A flag that identifies the appropriate instance of the component is used
   * to compare it to the state of a processed payment in the store.
   */
  @Input()
  instanceKind: 'dashboard' | 'menu' | 'route';

  @Input()
  notApplyCredit = false;

  @Input()
  isFeePayment = false;

  @Input()
  unpaidCount: number;

  @Input()
  total: number;

  @Input()
  currencyId: string;

  @Input()
  currencyMap: HashMap<Currency>;

  @Input()
  qrData: string;

  @Input()
  credit: number;

  @Input()
  cardPaymentEnabled: boolean;

  private _paymentDialogOpenAction$ = this.onPaymentDialogOpen$.pipe(
    /**
     * Open a dialog window where a user enters necessary card data and starts a payment.
     */
    map(({ key, instanceKind, paymentKind, paymentIntentType }) => new DialogOpen(key, { instanceKind, paymentKind, paymentIntentType }))
  );

  constructor(
    private _store: Store<State>,
    public media: ObservableMedia
  ) {
    super();

    // # Store Dispatcher
    merge(
      this._paymentDialogOpenAction$
    ).pipe(
      takeUntil(this._ngOnDestroy$)
    ).subscribe(this._store);

  }
}
