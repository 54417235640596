import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyTicketTextFormReducer, getTicketTextFormKey } from '@app/common/ticket-text-form';
import { TicketsTriggerState } from './tickets-trigger.model';
import { Actions, ActionTypes } from './tickets-trigger.action';
import { ModuleTokens, TicketsTriggerStates } from './tickets-trigger.constant';

const initialState = new TicketsTriggerState();

export function reducer(
  state = initialState,
  action: Actions
): TicketsTriggerState {

  state = applyTicketTextFormReducer(
    { ...state },
    action,
    ModuleTokens.Name,
    {
      ticketMessage: validate(required)
    }
  );

  switch (action.type) {
    case ActionTypes.Open:
      return {
        ...state,
        open: true
      };

    case ActionTypes.Close:
      return { ...initialState };

    case ActionTypes.SetState:
      return {
        ...state,
        state: action.payload.state,
        forms: initialState.forms,
        open: action.payload.state === TicketsTriggerStates.Add
          ? true
          : state.open
      };

    case ActionTypes.SetDetailId:
      return {
        ...state,
        detailId: action.payload,
        state: TicketsTriggerStates.Detail
      };

    case ActionTypes.ResetDetailId:
      return {
        ...state,
        detailId: undefined,
        forms: {
          ...initialState.forms
        }
      };

    case ActionTypes.ResetMessageForm:

      const ticketTextFormKey = getTicketTextFormKey(ModuleTokens.Name);

      return {
        ...state,
        forms: {
          ...state.forms,
          [ticketTextFormKey]: initialState.forms[ticketTextFormKey]
        }
      };

    case ActionTypes.ShowTrigger:
     return {
       ...state,
       showTrigger: true
     };

    case ActionTypes.HideTrigger:
     return {
       ...state,
       showTrigger: false
     };

    case ActionTypes.Reset:
      return { ...initialState };

  }

  return state;
}
