import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { SnackService } from '@app/common/snack';
import { listEntities, filters } from './tickets-list.selector';
import { ResetFilter } from './tickets-list.action';
var TicketsListContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsListContainer, _super);
    function TicketsListContainer(_store, _clipboardService, _snack) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._clipboardService = _clipboardService;
        _this._snack = _snack;
        // # Event Streams
        _this.onResetFilter$ = new Subject();
        _this.showActions = true;
        _this.selected = new EventEmitter();
        // -- async
        // TODO: move to base
        _this.list$ = _this._store.pipe(select(listEntities));
        _this.activeFilters$ = _this._store.pipe(select(filters));
        // # Action Streams
        _this._resetFilterAction$ = _this.onResetFilter$.pipe(map(function () { return new ResetFilter(); }));
        // # Store Dispatcher
        _this._resetFilterAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    TicketsListContainer.prototype.copyLinkToClipboard = function (data) {
        var url = window.location.href + ";ticket=" + data.id;
        this._clipboardService.copyFromContent(url);
        this._snack
            .translatedSuccess$('common.copied', 'common.close')
            .subscribe();
    };
    TicketsListContainer.prototype.trackBy = function (_, item) {
        return item.id;
    };
    return TicketsListContainer;
}(BaseClass));
export { TicketsListContainer };
