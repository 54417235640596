import * as tslib_1 from "tslib";
import * as uuidv4 from 'uuid/v4';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["AddToQueue"] = "[Files] Add File to Queue";
    ActionTypes["RefusedToQueue"] = "[Files] Refused to Add File to Queue";
    ActionTypes["MarkQueueItemAsDone"] = "[Files] Mark Queue Item as Done";
    ActionTypes["RemoveFromQueue"] = "[Files] Remove File to Queue";
    ActionTypes["ClearInstanceQueue"] = "[Files] Clear Instance Queue";
    ActionTypes["EmptyQueue"] = "[Files] Empty Queue";
    ActionTypes["UploadRequest"] = "[Files] Upload Request";
    ActionTypes["UploadEmpty"] = "[Files] Upload Empty";
    ActionTypes["UploadProgress"] = "[Files] Upload Progress";
    ActionTypes["UploadSingleFail"] = "[Files] Upload Single Fail";
    ActionTypes["UploadSingleLocalSuccess"] = "[Files] Upload Single Local Success";
    ActionTypes["UploadAllLocalSuccess"] = "[Files] Upload All Local Success";
    ActionTypes["ClearUploadedFiles"] = "[Files] Clear Uploaded Files";
})(ActionTypes || (ActionTypes = {}));
var AddToQueue = /** @class */ (function () {
    function AddToQueue(data) {
        this.type = ActionTypes.AddToQueue;
        this.payload = tslib_1.__assign({}, data, { files: data.files.map(function (file) { return (tslib_1.__assign({}, file, { uuid: uuidv4() })); }) });
    }
    return AddToQueue;
}());
export { AddToQueue };
var RefusedToQueue = /** @class */ (function () {
    function RefusedToQueue(payload) {
        this.payload = payload;
        this.type = ActionTypes.RefusedToQueue;
    }
    return RefusedToQueue;
}());
export { RefusedToQueue };
var RemoveFromQueue = /** @class */ (function () {
    function RemoveFromQueue(payload) {
        this.payload = payload;
        this.type = ActionTypes.RemoveFromQueue;
    }
    return RemoveFromQueue;
}());
export { RemoveFromQueue };
var ClearInstanceQueue = /** @class */ (function () {
    /**
     * @param payload instance id
     */
    function ClearInstanceQueue(payload) {
        this.payload = payload;
        this.type = ActionTypes.ClearInstanceQueue;
    }
    return ClearInstanceQueue;
}());
export { ClearInstanceQueue };
var MarkQueueItemAsDone = /** @class */ (function () {
    function MarkQueueItemAsDone(payload) {
        this.payload = payload;
        this.type = ActionTypes.MarkQueueItemAsDone;
    }
    return MarkQueueItemAsDone;
}());
export { MarkQueueItemAsDone };
var EmptyQueue = /** @class */ (function () {
    /**
     * @param payload instance id
     */
    function EmptyQueue(payload) {
        this.payload = payload;
        this.type = ActionTypes.EmptyQueue;
    }
    return EmptyQueue;
}());
export { EmptyQueue };
var UploadRequest = /** @class */ (function () {
    /**
     * @param payload instance id
     */
    function UploadRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.UploadRequest;
    }
    return UploadRequest;
}());
export { UploadRequest };
var UploadSingleFail = /** @class */ (function () {
    function UploadSingleFail(payload, meta) {
        this.payload = payload;
        this.meta = meta;
        this.type = ActionTypes.UploadSingleFail;
    }
    return UploadSingleFail;
}());
export { UploadSingleFail };
var UploadProgress = /** @class */ (function () {
    function UploadProgress(payload) {
        this.payload = payload;
        this.type = ActionTypes.UploadProgress;
    }
    return UploadProgress;
}());
export { UploadProgress };
var UploadSingleLocalSuccess = /** @class */ (function () {
    function UploadSingleLocalSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.UploadSingleLocalSuccess;
    }
    return UploadSingleLocalSuccess;
}());
export { UploadSingleLocalSuccess };
var UploadAllLocalSuccess = /** @class */ (function () {
    function UploadAllLocalSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.UploadAllLocalSuccess;
    }
    return UploadAllLocalSuccess;
}());
export { UploadAllLocalSuccess };
var UploadEmpty = /** @class */ (function () {
    function UploadEmpty(payload) {
        this.payload = payload;
        this.type = ActionTypes.UploadEmpty;
    }
    return UploadEmpty;
}());
export { UploadEmpty };
var ClearUploadedFiles = /** @class */ (function () {
    /**
     * @param payload instance id
     */
    function ClearUploadedFiles(payload) {
        this.payload = payload;
        this.type = ActionTypes.ClearUploadedFiles;
    }
    return ClearUploadedFiles;
}());
export { ClearUploadedFiles };
