import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import differenceInDays from 'date-fns/esm/differenceInDays';
import startOfDay from 'date-fns/esm/startOfDay';
import { InvoiceStatuses } from '@app/base/invoices-base';

@Component({
  selector: 'vshcz-invoice-dates-info',
  templateUrl: './invoice-dates-info.component.html',
  styleUrls: [ './invoice-dates-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDatesInfoComponent {

  invoiceStatuses = InvoiceStatuses;

  diffDays: number;

  @Input()
  created: string;

  @Input()
  set dueDate(v) {
    this._dueDate = v;

    this.diffDays = differenceInDays(startOfDay(new Date(v)), startOfDay(new Date()));
  }

  get dueDate() {
    return this._dueDate;
  }

  @Input()
  paidDate: string;

  @Input()
  daysOverdue: number;

  @Input()
  status: InvoiceStatuses;

  private _dueDate: string;
}
