import * as tslib_1 from "tslib";
import { getTicketEntityById, TicketsBaseActionTypes, TicketsBaseConfirmRealizationDateRequest, TicketsBaseRejectRealizationDateRequest } from '@app/base/tickets-base';
import { select, Store } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { progressByKey } from '@zerops/fe/ngrx';
import { BaseClass } from '@zerops/fe/core';
import { activeUserClientId } from '@app/base/auth-base';
import { ticketRealizationDateFormState, ticketRealizationDateFormValue } from './ticket-realization-date.selector';
var TicketRealizationDateContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketRealizationDateContainer, _super);
    function TicketRealizationDateContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onConfirmRealizationDate$ = new Subject();
        _this.onRejectRealizationDate$ = new Subject();
        // # Form States
        _this.ticketRealizationDateFormState$ = _this._store.pipe(select(ticketRealizationDateFormState));
        // -- async
        _this.rejectRealizationDateRequestKey$ = _this.id$.pipe(map(function (id) { return TicketsBaseActionTypes.RejectRealizationDateRequest + ":" + id; }));
        _this.rejectRealizationDateFailKey$ = _this.id$.pipe(map(function (id) { return TicketsBaseActionTypes.RejectRealizationDateFail + ":" + id; }));
        _this.realizationDateConfirmLoading$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(progressByKey(TicketsBaseActionTypes.ConfirmRealizationDateRequest + ":" + id))); }), map(function (loading) { return !!loading; }));
        _this.realizationDateRejectLoading$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(progressByKey(TicketsBaseActionTypes.RejectRealizationDateRequest + ":" + id))); }), map(function (loading) { return !!loading; }));
        _this.activeClientId$ = _this._store.pipe(select(activeUserClientId));
        _this.ticket$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id)), filter(function (t) { return !!t; })); }));
        // # Action Streams
        _this._confirmRealizationDateAction$ = _this.onConfirmRealizationDate$.pipe(withLatestFrom(_this.ticket$, _this.activeClientId$), map(function (_a) {
            var _ = _a[0], id = _a[1].id, activeClientId = _a[2];
            return new TicketsBaseConfirmRealizationDateRequest(id, {
                clientId: activeClientId
            });
        }));
        _this._rejectRealizationDateAction$ = _this.onRejectRealizationDate$.pipe(withLatestFrom(_this.ticket$, _this.activeClientId$, _this._store.pipe(select(ticketRealizationDateFormValue))), map(function (_a) {
            var _ = _a[0], id = _a[1].id, activeClientId = _a[2], messageText = _a[3].messageText;
            return new TicketsBaseRejectRealizationDateRequest(id, {
                clientId: activeClientId,
                messageText: messageText
            });
        }));
        // # Action Dispatcher
        merge(_this._confirmRealizationDateAction$, _this._rejectRealizationDateAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketRealizationDateContainer.prototype, "id$", void 0);
    return TicketRealizationDateContainer;
}(BaseClass));
export { TicketRealizationDateContainer };
