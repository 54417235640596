import { Action } from '@zerops/fe/core';

export enum ActionTypes {
  Reset = '[Ticket Rating] Reset'
}

export class Reset implements Action {
  readonly type = ActionTypes.Reset;
}

export type Actions = Reset;
