import * as tslib_1 from "tslib";
import { ModuleTokens } from './client-user-base.constant';
var ClientUserBaseState = /** @class */ (function () {
    function ClientUserBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
        this.list = [];
    }
    return ClientUserBaseState;
}());
export { ClientUserBaseState };
var ClientUserEntityEntities = /** @class */ (function () {
    function ClientUserEntityEntities() {
    }
    return ClientUserEntityEntities;
}());
export { ClientUserEntityEntities };
ModuleTokens.Name;
var ClientUserEntityNormalizedData = /** @class */ (function () {
    function ClientUserEntityNormalizedData() {
    }
    return ClientUserEntityNormalizedData;
}());
export { ClientUserEntityNormalizedData };
var ClientUserEntityLight = /** @class */ (function () {
    function ClientUserEntityLight() {
    }
    return ClientUserEntityLight;
}());
export { ClientUserEntityLight };
var ClientUserEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserEntityBase, _super);
    function ClientUserEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientUserEntityBase;
}(ClientUserEntityLight));
export { ClientUserEntityBase };
var ClientUserEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserEntityNormalized, _super);
    function ClientUserEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientUserEntityNormalized;
}(ClientUserEntityBase));
export { ClientUserEntityNormalized };
var ClientUserEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserEntity, _super);
    function ClientUserEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientUserEntity;
}(ClientUserEntityBase));
export { ClientUserEntity };
