import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DonutChartComponent,
  DonutChartSegmentComponent
} from './components';

@NgModule({
  declarations: [
    DonutChartComponent,
    DonutChartSegmentComponent
  ],
  imports: [ CommonModule ],
  exports: [ DonutChartComponent ]
})
export class DonutChartModule { }
