import { AuthBaseState } from './auth-base.model';
import { Actions, ActionTypes } from './auth-base.action';

const initialState = new AuthBaseState();

export function reducer(
  state = initialState,
  action: Actions
): AuthBaseState {

  switch (action.type) {

    case ActionTypes.Authorize:
      return {
        ...state,
        userId: action.payload.userId,
        accessToken: action.payload.auth.accessToken,
        refreshToken: action.payload.auth.refreshToken,
        authorType: action.payload.auth.author
          ? action.payload.auth.author.authorType
          : 'CLIENT',
        cloudDnsRoleCode: action.payload.auth.author
          ? action.payload.auth.author.cloudDnsRoleCode
          : undefined,
        clientUserId: action.payload.clientId,
        authorized: true
      };

    case ActionTypes.SetActiveClient:
      return {
        ...state,
        clientUserId: action.payload
      };

    case ActionTypes.SetReferer:
      return {
        ...state,
        referer: action.payload
      };

    case ActionTypes.ResetReferer:
      return {
        ...state,
        referer: undefined
      };

    // reset
    case ActionTypes.InvalidateAuthorization:
      return { ...initialState };

  }

  return state;
}
