<zps-dialog
  [open]="open$ | async">
  <div class="__content">

    <vshcz-content-title
      size="big"
      position="center"
      class="__title">
      <span translate="domainRoutingInfoDialog.dialogTitle"></span>
    </vshcz-content-title>

    <div class="__text-wrap">

      <p
        *ngIf="isCzDomain; else nonCzDomainRef"
        [innerHTML]="'domainRoutingInfoDialog.isCzDomainText' | translate: { domainName: domainName$ | async }">
      </p>

      <ng-template #nonCzDomainRef>
        <p [innerHTML]="'domainRoutingInfoDialog.nonCzDomainText' | translate: { domainName: domainName$ | async }"></p>
      </ng-template>

      <p class="is-last">

        <span [innerHTML]="'domainRoutingInfoDialog.additionalText' | translate: { domainName: domainName$ | async }"></span>

        <button
          class="__support-button"
          mat-button
          type="button"
          (click)="onOpenTicketTrigger$.next()"
          [zpsNgrxDialogClose]="dialogKey">
          <span translate="domainRoutingInfoDialog.support"></span>
        </button>

      </p>

    </div>

    <button
      class="__close"
      mat-icon-button
      [zpsNgrxDialogClose]="dialogKey">
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zps-dialog>
