import * as tslib_1 from "tslib";
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { TicketRatingState } from './ticket-rating.model';
import { applyFormReducer } from './ticket-rating.forms';
import { ModuleTokens } from './ticket-rating.constant';
import { ActionTypes } from './ticket-rating.action';
var initialState = new TicketRatingState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyFormReducer(state, action, ModuleTokens.Name, {
        speed: validate(required),
        expertise: validate(required),
        communication: validate(required)
    });
    if (action.type === ActionTypes.Reset) {
        return tslib_1.__assign({}, initialState);
    }
    return state;
}
