import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentTitleComponent } from './content-title.component';

@NgModule({
  declarations: [ ContentTitleComponent ],
  imports: [ CommonModule ],
  exports: [ ContentTitleComponent ]
})
export class ContentTitleModule { }
