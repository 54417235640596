<zps-dialog [open]="dialogOpen$ | async">
  <div class="__content">

    <h4 
      class="__title"
      translate="loginCompanySelect.title">
    </h4>

    <span 
      class="__desc"
      translate="loginCompanySelect.desc">
    </span>

    <vshcz-company-select
      [clientUserList]="userCompanies$ | async"
      [activeId]="activeClientId$ | async"
      (companySelect)=onSetClientId$.next($event)>
    </vshcz-company-select> 

    <span class="c-or">
      <span
        class="c-or__text"
        translate="common.or">
      </span>
    </span>

    <button mat-button type="button" class="__button" (click)="onLogout$.next()">
      <mat-icon>exit_to_app</mat-icon> <span translate="loginCompanySelect.logout"></span>
    </button>

  </div>
</zps-dialog>
