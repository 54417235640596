import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './client-services-base.constant';
import { reducer } from './client-services-base.reducer';
import { ClientServicesBaseEffect } from './client-services-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ ClientServicesBaseEffect ])
  ]
})
export class ClientServicesBaseModule {

}
