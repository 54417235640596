import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { Subject, of, interval } from 'rxjs';
import { map, takeUntil, delayWhen, distinctUntilChanged } from 'rxjs/operators';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { environment } from 'environments/environment';
import { state } from './recaptcha.selector';
import { SetInactive } from './recaptcha.action';
var RecaptchaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RecaptchaComponent, _super);
    function RecaptchaComponent(_store, _recaptcha, _cdRef) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._recaptcha = _recaptcha;
        _this._cdRef = _cdRef;
        // # Event Streams
        _this.onResolve$ = new Subject();
        // # Data
        // -- sync
        _this.isLoading = true;
        // -- async
        _this.isActive$ = _this._store.pipe(select(state));
        _this.siteKey = environment.googleSiteKey;
        _this.isLoading$ = _this._recaptcha.ready.pipe(map(function (v) { return !v; }), delayWhen(function (v) { return !v ? interval(600) : of(undefined); }), distinctUntilChanged());
        // # Action Streams
        _this._resolveAction$ = _this.onResolve$.pipe(map(function (d) { return new SetInactive(d); }));
        _this.isLoading$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (s) {
            setTimeout(function () {
                _this.isLoading = s;
                _this._cdRef.markForCheck();
                _this._cdRef.detectChanges();
            });
        });
        _this._resolveAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    return RecaptchaComponent;
}(BaseClass));
export { RecaptchaComponent };
