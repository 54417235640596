import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@ngx-translate/core";
var SnackService = /** @class */ (function () {
    function SnackService(_snack, _translate) {
        this._snack = _snack;
        this._translate = _translate;
    }
    SnackService.prototype.fail$ = function (message, close, duration, position) {
        return this._snack.open(message, close, {
            duration: duration || 5000,
            horizontalPosition: position || 'left',
            panelClass: 'c-vshcz-fail-snack'
        })
            .onAction();
    };
    SnackService.prototype.success$ = function (message, close, duration, position) {
        return this._snack.open(message, close, {
            duration: duration || 5000,
            horizontalPosition: position || 'left',
            panelClass: 'c-vshcz-success-snack'
        })
            .onAction();
    };
    SnackService.prototype.warning$ = function (message, close) {
        return this._snack.open(message, close, {
            duration: 5000,
            horizontalPosition: 'left',
            panelClass: 'c-vshcz-warning-snack'
        })
            .onAction();
    };
    SnackService.prototype.info$ = function (message, close, duration, position) {
        return this._snack.open(message, close, {
            duration: duration || 5000,
            horizontalPosition: position || 'center',
            panelClass: 'c-vshcz-info-snack'
        })
            .onAction();
    };
    SnackService.prototype.translatedSuccess$ = function (messageKey, closeKey, data) {
        var _this = this;
        var keys = [messageKey, closeKey];
        return this._translate
            .get(keys, data)
            .pipe(switchMap(function (translations) { return _this.success$(translations[messageKey], translations[closeKey]); }));
    };
    SnackService.prototype.translatedFail$ = function (message, closeKey) {
        var _this = this;
        return this._translate
            .get(closeKey)
            .pipe(switchMap(function (keyTranslation) { return _this.fail$(message, keyTranslation); }));
    };
    SnackService.prototype.fullyTranslatedSuccess$ = function (messageKey, closeKey, data) {
        var _this = this;
        var keys = [messageKey, closeKey];
        return this._translate
            .get(keys, data)
            .pipe(switchMap(function (translations) { return _this.fail$(translations[messageKey], translations[closeKey]); }));
    };
    SnackService.prototype.translatedWarning$ = function (messageKey, closeKey) {
        var _this = this;
        var keys = [messageKey, closeKey];
        return this._translate
            .get(keys)
            .pipe(switchMap(function (translations) { return _this.warning$(translations[messageKey], translations[closeKey]); }));
    };
    SnackService.ngInjectableDef = i0.defineInjectable({ factory: function SnackService_Factory() { return new SnackService(i0.inject(i1.MatSnackBar), i0.inject(i2.TranslateService)); }, token: SnackService, providedIn: "root" });
    return SnackService;
}());
export { SnackService };
