<div
  class="__layout"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="12px grid">

  <!-- compact -->
  <ng-container *ngIf="compact">
    <button
      mat-button
      type="button"
      [vshczPopoverAnchorFor]="popRef"
      (click)="openPop()"
      class="__compact-trigger">
      <vshcz-avatar
        [size]="40"
        [source]="'gravatar'"
        [mail]="identity.email">
      </vshcz-avatar>
    </button>
  </ng-container>

  <!-- full -->
  <ng-container *ngIf="!compact">

    <div
      fxFlex
      class="__item  __user-wrap">
      <vshcz-user
        class="__user"
        [fullName]="identity._activeUserClient.fullName"
        [company]="identity._activeUserClient?.client.companyName"
        [position]="identity._activeUserClient?._translatedRoleName"
        [mail]="identity.email">
      </vshcz-user>
    </div>

    <div fxFlex="45px" class="__item">
      <button
        mat-icon-button
        type="button"
        [vshczPopoverAnchorFor]="popRef"
        (click)="openPop()"
        class="__trigger">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

  </ng-container>

</div>

<vshcz-popover
  #popRef
  (closed)="onPopClose()"
  horizontalAlign="end"
  hasBackdrop>
  <mat-card class="__pop-card  mat-elevation-z7">

    <div
      class="__pop-card_content"
      @transformContent
      *ngIf="popOpen">

      <div class="__pop-card_user">
        <vshcz-user
          class="__user"
          [fullName]="identity.fullName"
          [company]="identity._activeUserClient.client.companyName"
          [position]="identity._activeUserClient._translatedRoleName"
          [mail]="identity.email">
        </vshcz-user>
      </div>

      <!-- options -->
      <mat-list class="__options-list">

        <mat-list-item
          [routerLink]="[ '/profile' ]"
          class="__list-item"
          (click)="popRef.close()">
          <mat-icon matListIcon>account_circle</mat-icon>
          <h3 matLine>
            <span translate="loggedUser.myAccountButton"></span>
          </h3>
        </mat-list-item>

        <mat-list-item
          [vshczPopoverAnchorFor]="confirmRef.popRef"
          (click)="confirmRef.popRef.open()"
          class="__list-item">
          <mat-icon matListIcon>lock</mat-icon>
          <h3 matLine>
            <span translate="loggedUser.passChangeButton"></span>
          </h3>
        </mat-list-item>

        <vshcz-confirm
          #confirmRef
          [heading]="'loggedUser.changePasswordConfirm' | translate"
          [confirmButton]="'loggedUser.changePassword' | translate"
          confirmColor="primary"
          confirmIcon="lock"
          [closeButton]="'common.close' | translate"
          (confirm)="!!onChangePassword$.next()">
        </vshcz-confirm>

        <mat-list-item
          class="__list-item"
          (click)="!!onLogout$.next(); popRef.close()">
          <mat-icon matListIcon>exit_to_app</mat-icon>
          <h3 matLine>
            <span translate="loggedUser.logoutButton"></span>
          </h3>
        </mat-list-item>

      </mat-list>

      <ng-container *ngIf="identity.clientUserList.length > 1">

        <mat-divider></mat-divider>

        <!-- companies -->
        <div class="__companies">

          <h3 class="__pop-card_title  __companies_title">
            <span translate="loggedUser.companiesHeading"></span>:
          </h3>

          <vshcz-company-select
            class="__company-select"
            [clientUserList]="identity.clientUserList"
            [activeId]="identity._activeUserClient.clientId"
            (companySelect)="onChangeClient$.next($event); popRef.close()">
          </vshcz-company-select>

        </div>

      </ng-container>

    </div>
  </mat-card>
</vshcz-popover>

