/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kvm-badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./kvm-badge.component";
var styles_KvmBadgeComponent = [i0.styles];
var RenderType_KvmBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KvmBadgeComponent, data: {} });
export { RenderType_KvmBadgeComponent as RenderType_KvmBadgeComponent };
export function View_KvmBadgeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "__badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["KVM"]))], null, null); }
export function View_KvmBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-kvm-badge", [], null, null, null, View_KvmBadgeComponent_0, RenderType_KvmBadgeComponent)), i1.ɵdid(1, 49152, null, 0, i2.KvmBadgeComponent, [], null, null)], null, null); }
var KvmBadgeComponentNgFactory = i1.ɵccf("vshcz-kvm-badge", i2.KvmBadgeComponent, View_KvmBadgeComponent_Host_0, {}, {}, []);
export { KvmBadgeComponentNgFactory as KvmBadgeComponentNgFactory };
