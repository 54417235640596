var _a;
export var ModuleTokens;
(function (ModuleTokens) {
    ModuleTokens["Name"] = "domainsBase";
    ModuleTokens["ActiveRecords"] = "domainActiveRecordsBase";
    ModuleTokens["LastRecords"] = "domainLastRecordsBase";
})(ModuleTokens || (ModuleTokens = {}));
export var DomainStatuses;
(function (DomainStatuses) {
    DomainStatuses["New"] = "NEW";
    DomainStatuses["Active"] = "ACTIVE";
    DomainStatuses["WaitingToTtl"] = "WAITING_TO_TTL";
    DomainStatuses["PublishingToInternet"] = "PUBLISHING_TO_INTERNET";
    DomainStatuses["Warning"] = "WARNING";
    DomainStatuses["NotAvailable"] = "DOMAIN_NOT_AVAILABLE";
    DomainStatuses["NotHeadingToVshosting"] = "DOMAIN_NOT_HEADING_TO_VSHOSTING";
    DomainStatuses["Deleted"] = "DELETED";
    DomainStatuses["Deactivated"] = "DEACTIVATED";
})(DomainStatuses || (DomainStatuses = {}));
export var DomainStatusesPriorities = (_a = {},
    _a[DomainStatuses.NotHeadingToVshosting] = 9,
    _a[DomainStatuses.Warning] = 8,
    _a[DomainStatuses.NotAvailable] = 7,
    _a[DomainStatuses.WaitingToTtl] = 6,
    _a[DomainStatuses.New] = 5,
    _a[DomainStatuses.Deactivated] = 4,
    _a[DomainStatuses.PublishingToInternet] = 3,
    _a[DomainStatuses.Active] = 2,
    _a[DomainStatuses.Deleted] = 1,
    _a);
