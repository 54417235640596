import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { Action } from '@ngrx/store';
import { applyRealizationDateRejectFormReducer } from './modules';
import { TicketRealizationDateState } from './ticket-realization-date.model';
import { ModuleTokens } from './ticket-realization-date.constant';

const initialState = new TicketRealizationDateState();

export function reducer(
  state = initialState,
  action: Action
): TicketRealizationDateState {

  state = applyRealizationDateRejectFormReducer(
    { ...state },
    action,
    ModuleTokens.Name,
    {
      messageText: validate(required)
    }
  );

  return state;
}
