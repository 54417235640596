import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { ticketTextFormState } from '@app/common/ticket-text-form';
import {
  ModuleTokens,
  TicketsTriggerStates
} from './tickets-trigger.constant';
import { priceOfferRejectFormState } from '@app/common/ticket-price-offer';
import { realizationDateRejectFormState } from '@app/common/ticket-realization-date';

export class TicketsTriggerState implements HasNgrxForm {
  forms = {
    ...ticketTextFormState(ModuleTokens.Name)(
      undefined,
      {
        ticketMessage: validate(required)
      }
    ),
    ...priceOfferRejectFormState(ModuleTokens.Name)(
      undefined,
      {
        messageText: validate(required)
      }
    ),
    ...realizationDateRejectFormState(ModuleTokens.Name)(
      undefined,
      {
        messageText: validate(required)
      }
    )
  };

  state: TicketsTriggerStates;
  detailId: string;
  open: boolean;
  showTrigger: boolean;

  constructor() {
    this.open = false;
    this.state = TicketsTriggerStates.Dashboard;
    this.showTrigger = true;
  }
}
