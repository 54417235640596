<mat-icon class="__item-type-icon  __invoice-icon">call_made</mat-icon>

<span class="__item_main">
  <vshcz-price
    class="__amount"
    [priceSize]="priceSize"
    [symbolSize]="symbolSize"
    [amount]="amount"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap">
  </vshcz-price>
</span>

<span class="__item_secondary">

  <span [innerHTML]="'paymentItem.on' | translate"></span>

  <strong [innerHTML]="created | date: 'mediumDate'"></strong>

</span>

<vshcz-payment-status
  class="__status"
  [status]="status"
  [tooltip]="'paymentStatuses.tooltips.' + status | translate">
</vshcz-payment-status>
