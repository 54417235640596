import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchEntity } from './search.model';

@Injectable({
  providedIn: 'root'
})
export class SearchApi {
  constructor(private _http: HttpClient) { }

  suggest$(keyword: string, clientId: string) {
    return this._http.get<{ fulltextSuggestList: SearchEntity[]; }>(
      `/api/public/client/${clientId}/fulltext/suggest?content=${keyword}`
    );
  }

  search$(keyword: string, clientId: string) {
    return this._http.get<{ fulltextSearchList: SearchEntity[]; }>(
      `/api/public/client/${clientId}/fulltext/search?content=${keyword}`
    );
  }

}
