import {
  Actions,
  EffectNotification,
  ofType,
  OnRunEffects
} from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, defer, of, merge } from 'rxjs';
import {
  exhaustMap,
  takeUntil,
  filter,
  withLatestFrom,
  switchMap,
  first
} from 'rxjs/operators';
import { State } from '@app/models';
import { ActionTypes } from '../auth-base.action';
import {
  isAuthenticated,
  activeUserClientId
} from '../auth-base.selector';

// TODO: check if works with multiple clients
export class AuthorizedRunEffect implements OnRunEffects {
  private _a$: Actions;
  private _s: Store<State>;
  onAuthorizedInit$ = defer(() => of(null));

  constructor(a: Actions, s: Store<State>) {
    this._a$ = a;
    this._s = s;
   }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return merge(
      defer(() => of(null)).pipe(
        withLatestFrom(
          this._s.pipe(select(isAuthenticated)),
          this._s.pipe(select(activeUserClientId))
        ),
        filter(([ _, state, clientId ]) => !!state && clientId !== undefined)
      ),
      this._a$.pipe(
        ofType(ActionTypes.Authorize),
        switchMap(() => this._s.pipe(
          select(activeUserClientId),
          filter((i) => !!i),
          first()
        )),
      )
    )
    .pipe(
      exhaustMap(() => resolvedEffects$.pipe(
        takeUntil(this._a$.pipe(ofType(ActionTypes.InvalidateAuthorization)))
      )
    ));
  }
}
