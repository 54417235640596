import { Action } from '@zerops/fe/core';
import { ParsedFrame } from '@app/common/websockets';

export enum ActionTypes {
  Connected = '[Clouddns Websockets] Connected',
  Reconnect = '[Clouddns Websockets] Reconnect',
  StatusChange = '[Clouddns Websockets] Status Change',
  Message = '[Clouddns Websockets] Message',
  Send = '[Clouddns Websockets] Send'
}

export class Connected implements Action {
  readonly type = ActionTypes.Connected;
}

export class Reconnect implements Action {
  readonly type = ActionTypes.Reconnect;

  /**
  * @param payload token
  */
  constructor(public payload: string) {}
}

export class StatusChange implements Action {
  readonly type = ActionTypes.StatusChange;

  constructor(public payload: number) { }
}

export class Message implements Action {
  readonly type = ActionTypes.Message;

  constructor(public payload: ParsedFrame) { }
}

export class Send implements Action {
  readonly type = ActionTypes.Send;

  constructor(public payload: string) { }
}

export type Actions
  = Connected
  | Reconnect
  | StatusChange
  | Message
  | Send;
