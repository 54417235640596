import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Currency } from '../settings';

@Component({
  selector: 'vshcz-price',
  templateUrl: './price.component.html',
  styleUrls: [ './price.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceComponent {

  @Input()
  currencyMap: { [index: string]: Currency };

  @Input()
  mode: 'bold-price' | 'bold-symbol' | 'bold' = 'bold-price';

  @Input()
  set amount(v) {
    this._amount = coerceNumberProperty(v);
  }

  get amount() {
    return this._amount;
  }

  @Input()
  currencyId: string;

  @Input()
  set priceSize(v) {
    this._priceSize = coerceNumberProperty(v);
  }

  get priceSize() {
    return this._priceSize;
  }

  @Input()
  set symbolSize(v) {
    this._symbolSize = coerceNumberProperty(v);
  }

  get symbolSize() {
    return this._symbolSize;
  }

  private _priceSize = 20;
  private _symbolSize = 15;
  private _amount: number;
}
