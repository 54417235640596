import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search.model';
import { ModuleTokens } from './search.constant';
import { formSelector, formValueSelector } from './search.forms';

export const slice = createFeatureSelector<SearchState>(ModuleTokens.Name);

export const searchFormState = createSelector(
  slice,
  formSelector(ModuleTokens.Name)
);

export const searchFormValue = createSelector(
  slice,
  formValueSelector(ModuleTokens.Name)
);

export const suggestResults = createSelector(
  slice,
  (s) => s.suggest
);

export const searchResults = createSelector(
  slice,
  (s) => s.search
);

export const mode = createSelector(
  slice,
  (s) => s.mode
);

export const open = createSelector(
  slice,
  (s) => s.open
);
