import { ElementRef, EventEmitter, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { PopoverComponent } from './popover.component';
import { getInvalidPopoverError } from './popover.errors';
import { PopoverAnchoringService } from './popover-anchoring.service';
var PopoverAnchorDirective = /** @class */ (function () {
    function PopoverAnchorDirective(_elementRef, _viewContainerRef, _anchoring) {
        this._elementRef = _elementRef;
        this._viewContainerRef = _viewContainerRef;
        this._anchoring = _anchoring;
        /** Emits when the popover is opened. */
        this.popoverOpened = new EventEmitter();
        /** Emits when the popover is closed. */
        this.popoverClosed = new EventEmitter();
        /** Emits when the directive is destroyed. */
        this._onDestroy = new Subject();
    }
    Object.defineProperty(PopoverAnchorDirective.prototype, "attachedPopover", {
        /** Reference to the popover instance. */
        get: function () { return this._attachedPopover; },
        set: function (value) {
            this._validateAttachedPopover(value);
            this._attachedPopover = value;
            // Anchor the popover to the element ref
            this._anchoring.anchor(this.attachedPopover, this._viewContainerRef, this._elementRef);
        },
        enumerable: true,
        configurable: true
    });
    PopoverAnchorDirective.prototype.ngOnInit = function () {
        var _this = this;
        // Re-emit open and close events
        var opened$ = this._anchoring.popoverOpened
            .pipe(tap(function () { return _this.popoverOpened.emit(); }));
        var closed$ = this._anchoring.popoverClosed
            .pipe(tap(function (value) { return _this.popoverClosed.emit(value); }));
        merge(opened$, closed$).pipe(takeUntil(this._onDestroy)).subscribe();
    };
    PopoverAnchorDirective.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    /** Gets whether the popover is presently open. */
    PopoverAnchorDirective.prototype.isPopoverOpen = function () {
        return this._anchoring.isPopoverOpen();
    };
    /** Toggles the popover between the open and closed states. */
    PopoverAnchorDirective.prototype.togglePopover = function () {
        this._anchoring.togglePopover();
    };
    /** Opens the popover. */
    PopoverAnchorDirective.prototype.openPopover = function () {
        this._anchoring.openPopover();
    };
    /** Closes the popover. */
    PopoverAnchorDirective.prototype.closePopover = function (value) {
        this._anchoring.closePopover(value);
    };
    /** Throws an error if the popover instance is not provided. */
    PopoverAnchorDirective.prototype._validateAttachedPopover = function (popover) {
        if (!popover || !(popover instanceof PopoverComponent)) {
            throw getInvalidPopoverError();
        }
    };
    return PopoverAnchorDirective;
}());
export { PopoverAnchorDirective };
