<div
  class="__trigger"
  [class.is-dragover]="isDragover"
  (click)="onClick($event)"
  (drop)="drop($event)"
  (dragenter)="dragenter($event)"
  (dragover)="dragover($event)"
  (dragleave)="dragleave($event)">
  <ng-content></ng-content>
</div>

<input
  type="file"
  class="__input"
  [attr.multiple]="multiple ? true : undefined"
  [accept]="accept"
  #fileInput
  (change)="onFileChange($event)"
/>
