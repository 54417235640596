import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { WsReconnectComponent } from './ws-reconnect.component';

@NgModule({
  declarations: [ WsReconnectComponent ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild()
  ],
  exports: [ WsReconnectComponent ]
})
export class WsReconnectModule {
}
