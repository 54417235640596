/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-pop-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../../popover/popover.component.ngfactory";
import * as i10 from "../../../popover/popover.component";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i13 from "@angular/material/card";
import * as i14 from "./filter-pop-popover.component";
var styles_FilterPopPopoverComponent = [i0.styles];
var RenderType_FilterPopPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterPopPopoverComponent, data: {} });
export { RenderType_FilterPopPopoverComponent as RenderType_FilterPopPopoverComponent };
function View_FilterPopPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "h3", [["class", "mat-h3  __title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "__close"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.popoverRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); var currVal_2 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 6).inline; var currVal_4 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_FilterPopPopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popoverRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "vshcz-popover", [["verticalAlign", "below"]], null, null, null, i9.View_PopoverComponent_0, i9.RenderType_PopoverComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i10.PopoverComponent, [i5.FocusTrapFactory, [2, i11.DOCUMENT]], { horizontalAlign: [0, "horizontalAlign"], verticalAlign: [1, "verticalAlign"], forceAlignment: [2, "forceAlignment"], lockAlignment: [3, "lockAlignment"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "mat-card", [["class", "__card  mat-elevation-z5 mat-card"]], null, null, null, i12.View_MatCard_0, i12.RenderType_MatCard)), i1.ɵdid(4, 49152, null, 0, i13.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FilterPopPopoverComponent_1)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.horAlignment; var currVal_1 = "below"; var currVal_2 = _co.forceAlignment; var currVal_3 = _co.lockAlignment; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.heading; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_FilterPopPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-filter-pop-popover", [], null, null, null, View_FilterPopPopoverComponent_0, RenderType_FilterPopPopoverComponent)), i1.ɵdid(1, 49152, null, 0, i14.FilterPopPopoverComponent, [], null, null)], null, null); }
var FilterPopPopoverComponentNgFactory = i1.ɵccf("vshcz-filter-pop-popover", i14.FilterPopPopoverComponent, View_FilterPopPopoverComponent_Host_0, { heading: "heading", horAlignment: "horAlignment", lockAlignment: "lockAlignment", forceAlignment: "forceAlignment" }, { closed: "closed" }, ["*"]);
export { FilterPopPopoverComponentNgFactory as FilterPopPopoverComponentNgFactory };
