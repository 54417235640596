import { Subject, fromEvent, merge, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
        this.globalNamespace = 'vsh:';
        this.onSet$ = new Subject();
        this.storage$ = merge(timer().pipe(take(1)), fromEvent(window, 'storage'), this.onSet$);
    }
    LocalStorageService.prototype.set = function (namespace, key, value) {
        localStorage.setItem(this.globalNamespace + namespace + ":" + key, JSON.stringify(value));
        this.onSet$.next();
    };
    LocalStorageService.prototype.update = function (namespace, key, update, defaultValue) {
        var newValue = update(this.get(namespace, key, defaultValue));
        this.set(namespace, key, newValue);
        return newValue;
    };
    LocalStorageService.prototype.getNamespace = function (namespace) {
        var result = {};
        for (var index = 0; index < localStorage.length; index++) {
            var prefix = this.globalNamespace + namespace + ':';
            var key = localStorage.key(index);
            var valueKey = key.substr(prefix.length, key.length);
            if (key.startsWith(prefix)) {
                try {
                    result[valueKey] = JSON.parse(localStorage.getItem(key));
                }
                catch (e) {
                }
            }
        }
        return result;
    };
    LocalStorageService.prototype.get = function (namespace, key, defaultValue) {
        var value = localStorage.getItem(this.globalNamespace + namespace + ":" + key);
        if (!value) {
            return defaultValue;
        }
        try {
            return JSON.parse(value);
        }
        catch (e) {
            return defaultValue;
        }
    };
    LocalStorageService.prototype.get$ = function (namespace, key, defaultValue) {
        var _this = this;
        return this.storage$.pipe(map(function () { return localStorage.getItem(_this.globalNamespace + namespace + ":" + key); }), map(function (v) {
            if (!v) {
                return defaultValue;
            }
            try {
                return JSON.parse(v);
            }
            catch (e) {
                return defaultValue;
            }
        }));
    };
    LocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
