import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

try {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.warn('Application bootstrap failed: ', err));
} catch (e) {
  console.warn('Application bootstrap failed: ', e);
}
