import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'vshcz-progress-item',
  templateUrl: './progress-item.component.html',
  styleUrls: [ './progress-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressItemComponent {
  @Input()
  set active(v) {
    this._active = v;
  }
  get active() {
    return this._active;
  }

  @HostBinding('class.is-active')
  get isActiveClass() {
    return this.active;
  }

  @Input()
  spinnerSize = 40;

  @Input()
  strokeWidth = 3;

  private _active: boolean;
}
