import * as tslib_1 from "tslib";
import { ActionTypes } from './ticket-authorization.action';
import { TicketAuthorizationState } from './ticket-authorization.model';
import { applyFormReducer } from './ticket-authorization.forms';
import { ModuleTokens } from './ticket-authorization.constant';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
var initialState = new TicketAuthorizationState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyFormReducer(state, action, ModuleTokens.Name, {
        clientId: validate(required)
    });
    // reset
    if (action.type === ActionTypes.AuthorizeLocalSuccess) {
        return tslib_1.__assign({}, initialState);
    }
    return state;
}
