import * as tslib_1 from "tslib";
import { ModuleTokens } from './payments-base.constant';
var PaymentsState = /** @class */ (function () {
    function PaymentsState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
        this.list = [];
    }
    return PaymentsState;
}());
export { PaymentsState };
var PaymentsEntityEntities = /** @class */ (function () {
    function PaymentsEntityEntities() {
    }
    return PaymentsEntityEntities;
}());
export { PaymentsEntityEntities };
ModuleTokens.Name;
var PaymentsEntityNormalizedData = /** @class */ (function () {
    function PaymentsEntityNormalizedData() {
    }
    return PaymentsEntityNormalizedData;
}());
export { PaymentsEntityNormalizedData };
var PaymentEntityLight = /** @class */ (function () {
    function PaymentEntityLight() {
    }
    return PaymentEntityLight;
}());
export { PaymentEntityLight };
var PaymentEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentEntityBase, _super);
    function PaymentEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PaymentEntityBase;
}(PaymentEntityLight));
export { PaymentEntityBase };
var PaymentEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentEntityNormalized, _super);
    function PaymentEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PaymentEntityNormalized;
}(PaymentEntityBase));
export { PaymentEntityNormalized };
var PaymentEntity = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentEntity, _super);
    function PaymentEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PaymentEntity;
}(PaymentEntityBase));
export { PaymentEntity };
