import { normalize, denormalize } from 'normalizr';
import { clientListSchema, clientSchema } from '@app/schemas';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import { ClientEntity, ClientEntityLight } from './clients-base.model';
import { ModuleTokens } from './clients-base.constant';

export function normalizeList(data: ClientEntity[] | ClientEntityLight[]) {
  return normalize(data, clientListSchema);
}

export function denormalizeEntity(
  id: string,
  clientEnts: any,
  servicesEnts: any
) {
  return denormalize(
    id,
    clientSchema,
    {
      [ModuleTokens.Name]: clientEnts,
      [ServicesBaseModuleTokens.Name]: servicesEnts
    }
  );
}
