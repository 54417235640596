import { EventEmitter, ElementRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
var UploadComponent = /** @class */ (function () {
    function UploadComponent() {
        this.files = [];
        this.isDragover = false;
        this.imageAcceptExtensions = '.jpg, .jpeg, .png';
        this.select = new EventEmitter(false);
    }
    Object.defineProperty(UploadComponent.prototype, "multiple", {
        get: function () {
            return this._multiple;
        },
        set: function (v) {
            this._multiple = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (v) {
            this._disable = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadComponent.prototype, "accept", {
        get: function () {
            return this._accept;
        },
        set: function (val) {
            switch (val) {
                case 'image':
                    this._accept = this.imageAcceptExtensions;
                    break;
                case 'video':
                    this._accept = 'video/*';
                    break;
                case 'gif':
                    this._accept = '.gif';
                    break;
                case 'imagevideo':
                    this._accept = this.imageAcceptExtensions + ', video/*';
                    break;
                default:
                    this._accept = '*';
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    UploadComponent.prototype.onClick = function (e) {
        e.preventDefault();
        if (!this.disable) {
            this.fileInput.nativeElement.click();
        }
    };
    UploadComponent.prototype.drop = function (e) {
        e.preventDefault();
        if (!this.disable) {
            if (!e.dataTransfer || !e.dataTransfer.files.length) {
                return;
            }
            this._proccessFiles(e.dataTransfer.files);
        }
    };
    UploadComponent.prototype.dragenter = function (e) {
        e.preventDefault();
        this.isDragover = true;
    };
    UploadComponent.prototype.dragover = function (e) {
        e.preventDefault();
        this.isDragover = true;
    };
    UploadComponent.prototype.dragleave = function (e) {
        e.preventDefault();
        this.isDragover = false;
    };
    UploadComponent.prototype.onFileChange = function (el) {
        this._proccessFiles(el.target.files);
    };
    UploadComponent.prototype._proccessFiles = function (files) {
        var _this = this;
        var that = this;
        var promises = [];
        var _loop_1 = function (index) {
            var file = files[index];
            var promise = new Promise(function (resolve) {
                var data = {
                    dataUrl: URL.createObjectURL(file),
                    type: _this._getType(file),
                    meta: file,
                    height: undefined,
                    width: undefined,
                    thumbnails: []
                };
                if (data.type === 'image') {
                    var i_1 = new Image();
                    i_1.onload = function () {
                        data.height = i_1.height;
                        data.width = i_1.width;
                        that.files.push(data);
                        resolve();
                    };
                    i_1.src = data.dataUrl;
                }
                else if (data.type === 'video') {
                    var v = document.createElement('video');
                    v.addEventListener('loadedmetadata', function () {
                        data.width = this.videoWidth;
                        data.height = this.videoHeight;
                        that.files.push(data);
                        resolve();
                    });
                    v.src = data.dataUrl;
                }
                else {
                    that.files.push(data);
                    resolve();
                }
            });
            promises.push(promise);
        };
        for (var index = 0; index < files.length; index++) {
            _loop_1(index);
        }
        Promise.all(promises).then(function () {
            _this.select.emit(_this.files);
            _this.files = [];
            _this.fileInput.nativeElement.value = '';
        });
    };
    UploadComponent.prototype._getType = function (file) {
        if (file.type === 'image/gif') {
            return 'gif';
        }
        var typeBeforeSlash = file.type.substr(0, file.type.indexOf('/'));
        if (typeBeforeSlash === 'video') {
            return 'video';
        }
        if (typeBeforeSlash === 'image') {
            return 'image';
        }
        return 'file';
    };
    return UploadComponent;
}());
export { UploadComponent };
