/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-field.component";
var styles_FormFieldComponent = [i0.styles];
var RenderType_FormFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFieldComponent, data: {} });
export { RenderType_FormFieldComponent as RenderType_FormFieldComponent };
function View_FormFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "__label"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_FormFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "__hints"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
export function View_FormFieldComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "__content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasLabel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.hasHints; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FormFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "vshcz-form-field", [], [[2, "is-last", null], [2, "has-appearance-radio", null], [2, "has-appearance-button-toggle", null], [2, "has-appearance-slide-toggle", null]], null, null, View_FormFieldComponent_0, RenderType_FormFieldComponent)), i1.ɵdid(1, 49152, null, 2, i3.FormFieldComponent, [], null, null), i1.ɵqud(335544320, 1, { label: 0 }), i1.ɵqud(335544320, 2, { hints: 0 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classIsLast; var currVal_1 = i1.ɵnov(_v, 1).classHasAppearanceRadio; var currVal_2 = i1.ɵnov(_v, 1).classHasAppearanceButtonToggle; var currVal_3 = i1.ɵnov(_v, 1).classHasAppearanceSliceToggle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var FormFieldComponentNgFactory = i1.ɵccf("vshcz-form-field", i3.FormFieldComponent, View_FormFieldComponent_Host_0, { appearance: "appearance", last: "last" }, {}, ["vshcz-form-label", "*", "vshcz-form-hints"]);
export { FormFieldComponentNgFactory as FormFieldComponentNgFactory };
