<vshcz-scrollable maxHeight="60vh">
  <mat-list dense class="__list">
    <mat-list-item
      [class.is-active]="activeId === item.clientId"
      class="__company-item"
      (click)="!!companySelect.emit(item.clientId)"
      *ngFor="let item of clientUserList">

      <vshcz-avatar
        class="__company-icon"
        [size]="28"
        source="clearbit"
        [web]="item.client.webUrl">
      </vshcz-avatar>

      <h3 class="__text" matLine>
        <strong>{{ item.client.companyName }}</strong>
      </h3>

      <p class="__text" matLine>
        <span [translate]="'common.roles.' + item.roleCode "></span>
      </p>

    </mat-list-item>
  </mat-list>
</vshcz-scrollable>
