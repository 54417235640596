import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './servers-base.constant';
import { reducer } from './servers-base.reducer';
import { ServersBaseEffect } from './servers-base.effect';
import { ServersIpBaseEffect } from './servers-base-ip.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([
      ServersBaseEffect,
      ServersIpBaseEffect
    ])
  ]
})
export class ServersBaseModule { }
