export class WebsocketsState {
  reconnecting: boolean;

  constructor() {
    this.reconnecting = false;
  }
}

export interface ParsedFrame {
  type: string;
  data: any;
  meta?: any;
}

export enum WebsocketStatuses {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}
