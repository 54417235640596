import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { PermissionService } from '@zerops/fe/permission';
import { map, filter, takeUntil, delay } from 'rxjs/operators';
import { merge, Subject, combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { State } from '@app/models';
import { Go } from '@app/common/ngrx-router';
import { AvatarGroupItem } from '@app/common/avatar-group';
import {
  authActiveUserNormalizedClient,
  SetActiveClient,
  authActiveUserClientUsers,
  authActiveUserClientId,
  Roles
} from '@app/base/auth-base';
import { clientUserListEntities } from '@app/base/client-user-base';

@Component({
  selector: 'vshcz-company-card',
  templateUrl: './company-card.container.html',
  styleUrls: ['./company-card.container.scss']
})
export class CompanyCardContainer extends BaseClass {
  // # Event Streams
  onRoute$ = new Subject<any[]>();
  onChangeClient$ = new Subject<string>();

  // # Data
  // -- async
  company$ = this._store.pipe(
    select(authActiveUserNormalizedClient),
    filter((c) => !!c)
  );
  avatarGroupData$ = this._store.pipe(
    select(clientUserListEntities),
    filter((data) => !!(data && data.length)),
    map((data): AvatarGroupItem[] => data.map((itm) => ({
      title: itm.fullName,
      id: itm.id,
      mail: itm.email
    })))
  );
  activeCompanyId$ = this._store.pipe(
    select(authActiveUserClientId)
  );
  userCompanies$ = this._store.pipe(
    select(authActiveUserClientUsers),
    map((d) => orderBy(d, [ (itm) => itm.client.companyName ], [ 'asc' ]))
  );
  isManager$ = this._permission.authorize({
    only: [ Roles.Manager ]
  });
  // -- angular
  @Input()
  mode: 'horizontal' | 'vertical' = 'horizontal';

  @Output()
  companySelectToggle = new EventEmitter<boolean>();

  @Output()
  contentUpdated = new EventEmitter<void>();

  @Output()
  contentClicked = new EventEmitter<void>();

  private _routeAction$ = this.onRoute$.pipe(
    map((path) => new Go({ path }))
  );

  private _changeClientAction = this.onChangeClient$.pipe(
    map((id) => new SetActiveClient(id, true))
  );

  constructor(
    private _store: Store<State>,
    private _permission: PermissionService
  ) {
    super();

    // emit that content changed so menu can resize the pop
    combineLatest(
      this.company$
    )
      // delay to account for component's 'mode'
      .pipe(takeUntil(this._ngOnDestroy$), delay(0))
      .subscribe(() => this.contentUpdated.emit());

    merge(
      this._routeAction$,
      this._changeClientAction
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }
}
