import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import { PaymentIntent } from 'ngx-stripe';
import {
  InvoiceEntityNormalizedData,
  InvoiceEntityLight,
  InvoiceEntity,
  InvoiceEntityEntities,
  ClientInvoiceLiabilities,
  ClientFeeLiabilities
} from './invoices-base.model';
import { normalizeList } from './invoices-base.utils';
import { PaymentIntentTypes } from '../invoices-base';
import { StripePaymentStatuses } from './invoices-base.constant';

export enum ActionTypes {
  // -- list
  ListRequest = '[Invoices Base] List Request',
  ListFail = '[Invoices Base] List Fail',
  ListLocalSuccess = '[Invoices Base] List Local Success',

  // -- liability invoice
  LiabilityInvoiceRequest = '[Invoices Base] Liability Invoice Request',
  LiabilityInvoiceFail = '[Invoices Base] Liability Invoice Fail',
  LiabilityInvoiceLocalSuccess = '[Invoices Base] Liability Invoice Local Success',

  // -- liability fee
  LiabilityFeeRequest = '[Invoices Base] Liability Fee Request',
  LiabilityFeeFail = '[Invoices Base] Liability Fee Fail',
  LiabilityFeeLocalSuccess = '[Invoices Base] Liability Fee Local Success',

  // -- payment intent
  PaymentIntentRequest = '[Invoices Base] Payment Intent Request',
  PaymentIntentFail = '[Invoices Base] Payment Intent Fail',
  PaymentIntentLocalSuccess = '[Invoices Base] Payment Intent Local Success',

  // -- confirm payment
  ConfirmPaymentRequest = '[Invoices Base] Confirm Payment Request',
  ConfirmPaymentFail = '[Invoices Base] Confirm Payment Fail',
  ConfirmPaymentLocalSuccess = '[Invoices Base] Confirm Payment Local Success',

  // -- entity
  EntityRequest = '[Invoices Base] Entity Request',
  EntityFail = '[Invoices Base] Entity Fail',
  EntityLocalSuccess = '[Invoices Base] Entity Local Success'
}

// -- list
export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }

}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: InvoiceEntityNormalizedData;

  constructor(data: InvoiceEntityLight[]) {
    this.payload = normalizeList(data);
  }
}

// -- liability invoice
export class LiabilityInvoiceRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.LiabilityInvoiceRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.LiabilityInvoiceFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.LiabilityInvoiceRequest,
      type: 'local'
    }
  };
}

export class LiabilityInvoiceFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.LiabilityInvoiceFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LiabilityInvoiceRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.LiabilityInvoiceFail,
        type: 'global',
        data
      }
    };
  }

}

export class LiabilityInvoiceLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.LiabilityInvoiceLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LiabilityInvoiceRequest
  };
  payload: ClientInvoiceLiabilities;

  constructor(data: ClientInvoiceLiabilities) {
    this.payload = data;
  }
}

// -- liability fee
export class LiabilityFeeRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.LiabilityFeeRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.LiabilityFeeFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.LiabilityFeeRequest,
      type: 'local'
    }
  };
}

export class LiabilityFeeFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.LiabilityFeeFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LiabilityFeeRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.LiabilityFeeFail,
        type: 'global',
        data
      }
    };
  }

}

export class LiabilityFeeLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.LiabilityFeeLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LiabilityFeeRequest
  };
  payload: ClientFeeLiabilities;

  constructor(data: ClientFeeLiabilities) {
    this.payload = data;
  }
}

// -- payment intent
export class PaymentIntentRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.PaymentIntentRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.PaymentIntentFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.PaymentIntentRequest,
      type: 'local'
    }
  };
  payload: {
    instanceKind: 'dashboard' | 'menu' | 'route';
    status: StripePaymentStatuses;
    clientId: string;
    type: PaymentIntentTypes; amount: number;
  };

  constructor(data: {
    instanceKind: 'dashboard' | 'menu' | 'route';
    status: StripePaymentStatuses;
    clientId: string;
    type: PaymentIntentTypes; amount: number;
  }) {
    this.payload = data;
  }
}

export class PaymentIntentFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.PaymentIntentFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.PaymentIntentRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.PaymentIntentFail,
        type: 'global',
        data
      }
    };
  }

}

export class PaymentIntentLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.PaymentIntentLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.PaymentIntentRequest
  };
  payload: string;

  constructor(secret: string) {
    this.payload = secret;
  }
}

// -- confirm payment
export class ConfirmPaymentRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ConfirmPaymentRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ConfirmPaymentFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ConfirmPaymentRequest,
      type: 'local'
    }
  };
}

export class ConfirmPaymentFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ConfirmPaymentFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ConfirmPaymentRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ConfirmPaymentFail,
        type: 'global',
        data
      }
    };
  }

}

export class ConfirmPaymentLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ConfirmPaymentLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ConfirmPaymentRequest
  };
  payload: { paymentIntentType: PaymentIntentTypes; paymentIntent: PaymentIntent; };

  constructor(paymentIntentType: PaymentIntentTypes, paymentIntent: PaymentIntent) {
    this.payload = { paymentIntentType, paymentIntent };
  }
}

// -- entity
export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: InvoiceEntityEntities };

  constructor(data: InvoiceEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }
}

export type Actions
  // -- list
  = ListRequest
  | ListFail
  | ListLocalSuccess

  // -- liability invoice
  | LiabilityInvoiceRequest
  | LiabilityInvoiceFail
  | LiabilityInvoiceLocalSuccess

  // -- liability fee
  | LiabilityFeeRequest
  | LiabilityFeeFail
  | LiabilityFeeLocalSuccess

  // -- payment intent
  | PaymentIntentRequest
  | PaymentIntentFail
  | PaymentIntentLocalSuccess

  // -- confirm payment
  | ConfirmPaymentRequest
  | ConfirmPaymentFail
  | ConfirmPaymentLocalSuccess

  // -- entity
  | EntityRequest
  | EntityFail
  | EntityLocalSuccess;
