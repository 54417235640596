import { UserEntity } from '@app/base/users-base';
import { ClientEntity } from '@app/base/clients-base';
import { Language } from '@app/common/settings';
import { ModuleTokens, ClientUserRoles } from './client-user-base.constant';

export class ClientUserBaseState {
  entities: ClientUserEntityEntities;
  list: string[];

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
    this.list = [];
  }
}

export class ClientUserEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ClientUserEntityNormalized; };
}

export class ClientUserEntityNormalizedData {
  entities: ClientUserEntityEntities;
  result: string[];
}

export class ClientUserEntityLight {
  id: string;
  clientId: string;
  userId: string;
  status: string;
  roleCode: ClientUserRoles;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCallingCode: number;
  phoneNumber: number;
  smsNotifications: boolean;
  language: Language;
  accessToClientZone: boolean;
  authorizedToSendTickets: boolean;
  cloudDnsEnabled: boolean;
  cdnEnabled: boolean;
  cloudMailEnabled: boolean;
  accessToAllServers: boolean;
  serverIdAccessList: string[];
}

export class ClientUserEntityBase extends ClientUserEntityLight { }

export class ClientUserEntityNormalized extends ClientUserEntityBase {
  client: string;
  user?: string;
}

export class ClientUserEntity extends ClientUserEntityBase {
  client: ClientEntity;
  user?: UserEntity;
}
