import { NgModule } from '@angular/core';
import {
  DisplacerComponent,
  DisplacerPortalDirective
} from './displacer.component';

@NgModule({
  declarations: [
    DisplacerComponent,
    DisplacerPortalDirective
  ],
  exports: [ DisplacerComponent ]
})
export class DisplacerModule { }
