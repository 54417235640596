import format from 'date-fns/esm/format';
import { BytesPipe } from 'angular-pipes';
import { ServicesTypes } from '@app/base/services-base';
import { abbreviateNumber } from 'utils';

export enum ModuleTokens {
  Name = 'serversBase'
}

export enum ServerIntervalTypes {
  Hours = 'HOURS',
  Day = 'DAY',
  Days = 'DAYS',
  Week = 'WEEK',
  Month = 'MONTH',
  Months = 'MONTHS',
  Year = 'YEAR'
}

export const SERVERS_INTERVAL_TYPES = [
  {
    key: ServerIntervalTypes.Hours
  },
  {
    key: ServerIntervalTypes.Day
  },
  {
    key: ServerIntervalTypes.Days
  },
  {
    key: ServerIntervalTypes.Week
  },
  {
    key: ServerIntervalTypes.Month
  },
  {
    key: ServerIntervalTypes.Months
  },
  {
    key: ServerIntervalTypes.Year
  }
];

const _xAxisTickFnc = (s, d, ts: ServerIntervalTypes) => {
  let f;
  switch (ts) {
    case ServerIntervalTypes.Hours:
    case ServerIntervalTypes.Day:
      f = 'HH:mm';
      break;

    default:
      f = 'dd.MM.';
      break;
  }
  return format(d[0][s].label, f);
};

const _yAxisTickPercent = (s) => `${s}%`;
const _yAxisTickNumber = (s) => abbreviateNumber(s);

export const SERVER_GRAPH_TYPES: { [key: string]: {
  key: string;
  group: string;
  priority: number;
  theme?: any;
  yMin?: number;
  yMax?: number;
  warningThreshold?: {
    start: number;
    end: number;
  };
  dangerThreshold?: {
    start: number;
  };
  yAxisTickFormat?: (s: any) => any,
  xAxisTickFormat?: (s: any, d: any, ts: any) => any
}; } = {
  cpuUsage: {
    key: 'cpuUsage',
    group: 'main',
    priority: 100,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 30,
      end: 70
    },
    dangerThreshold: {
      start: 70
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  maxDiskSpaceQuotaUsage: {
    key: 'maxDiskSpaceQuotaUsage',
    group: 'main',
    priority: 90,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 90,
      end: 95
    },
    dangerThreshold: {
      start: 95
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  memoryUsage: {
    key: 'memoryUsage',
    group: 'main',
    priority: 80,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 70,
      end: 85
    },
    dangerThreshold: {
      start: 85
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  ioWait: {
    key: 'ioWait',
    group: 'others',
    priority: 90,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 5,
      end: 10
    },
    dangerThreshold: {
      start: 10
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  diskSpaceQuotaUsage: {
    key: 'diskSpaceQuotaUsage',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#26a69a',
        circle: '#00695c',
      },
      {
        line: '#0d47a1',
        circle: '#002171',
      }
    ],
    yMin: 0,
    yMax: 100,
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  diskFilesFoldersQuotaUsage: {
    key: 'diskFilesFoldersQuotaUsage',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#26a69a',
        circle: '#00695c',
      },
      {
        line: '#0d47a1',
        circle: '#002171',
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  networkTraffic: {
    key: 'networkTraffic',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#1976d2',
        circle: '#1a237e',
      },
      {
        line: '#c2185b',
        circle: '#880e4f',
      }
    ],
    yAxisTickFormat: (s) => {
      const b = new BytesPipe();

      const x = s > 0
        ? `${b.transform(s, 1)}`
        : `${b.transform(-s, 1)}`;

      return x;
    },
    xAxisTickFormat: _xAxisTickFnc
  },
  networkTrafficRelative: {
    key: 'networkTrafficRelative',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 50,
      end: 75
    },
    dangerThreshold: {
      start: 75
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  apacheRequests: {
    key: 'apacheRequests',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#ff9800',
        circle: '#c66900'
      },
      {
        line: '#29b6f6',
        circle: '#0277bd'
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  nginxRequests: {
    key: 'nginxRequests',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd'
      },
      {
        line: '#ff9800',
        circle: '#c66900'
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  mySqlQueries: {
    key: 'mySqlQueries',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#f44336',
        circle: '#d32f2f',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#26a69a',
        circle: '#00695c',
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  mySqlRunningThreads: {
    key: 'mySqlRunningThreads',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    warningThreshold: {
      start: 75,
      end: 90
    },
    dangerThreshold: {
      start: 90
    },
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  mySqlSlowQueries: {
    key: 'mySqlSlowQueries',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd'
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  postgreSqlQueries: {
    key: 'postgreSqlQueries',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#f44336',
        circle: '#d32f2f',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#26a69a',
        circle: '#00695c',
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  postgreSqlActiveBackends: {
    key: 'postgreSqlActiveBackends',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7'
    }],
    yMin: 0,
    yMax: 100,
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  redisCommands: {
    key: 'redisCommands',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd'
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  redisMemoryUsage: {
    key: 'redisMemoryUsage',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 80,
      end: 90
    },
    dangerThreshold: {
      start: 90
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  mongoOperations: {
    key: 'mongoOperations',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#f44336',
        circle: '#d32f2f',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#ff9800',
        circle: '#c66900',
      },
      {
        line: '#1976d2',
        circle: '#1a237e',
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  memcachedRequests: {
    key: 'memcachedRequests',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#aed581',
        circle: '#689f38'
      },
      {
        line: '#29b6f6',
        circle: '#0277bd'
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  memcachedMemoryUsage: {
    key: 'memcachedMemoryUsage',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 80,
      end: 90
    },
    dangerThreshold: {
      start: 90
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  },
  elasticsearchOperations: {
    key: 'elasticsearchOperations',
    group: 'others',
    priority: 1,
    theme: [
      {
        line: '#f44336',
        circle: '#d32f2f',
      },
      {
        line: '#aed581',
        circle: '#689f38',
      },
      {
        line: '#9c27b0',
        circle: '#7b1fa2',
      },
      {
        line: '#29b6f6',
        circle: '#0277bd',
      },
      {
        line: '#607d8b',
        circle: '#37474f',
      },
      {
        line: '#26a69a',
        circle: '#00695c',
      }
    ],
    yMin: 0,
    yAxisTickFormat: _yAxisTickNumber,
    xAxisTickFormat: _xAxisTickFnc
  },
  elasticSearchMemoryUsage: {
    key: 'elasticSearchMemoryUsage',
    group: 'others',
    priority: 1,
    theme: [{
      line: '#4caf50',
      circle: '#1b5e20',
      area: '#a5d6a7',
      lineWarning: '#ffa000',
      areaWarning: '#ffe082',
      lineDanger: '#d32f2f',
      areaDanger: '#e57373'
    }],
    yMin: 0,
    yMax: 100,
    warningThreshold: {
      start: 80,
      end: 90
    },
    dangerThreshold: {
      start: 90
    },
    yAxisTickFormat: _yAxisTickPercent,
    xAxisTickFormat: _xAxisTickFnc
  }
};

export const SERVER_SERVICE_KEYS_BY_CATEGORY = {
  [ServicesTypes.ManagedServer.param]: ServicesTypes.ManagedServer.key,
  [ServicesTypes.ManagedCluster.param]: ServicesTypes.ManagedCluster.key,
  [ServicesTypes.WindowsManagedServer.param]: ServicesTypes.WindowsManagedServer.key,
  [ServicesTypes.WindowsManagedCluster.param]: ServicesTypes.WindowsManagedCluster.key,
  [ServicesTypes.CloudManagedServer.param]: ServicesTypes.CloudManagedServer.key,
  [ServicesTypes.ServerHosting.param]: ServicesTypes.ServerHosting.key,
  [ServicesTypes.DedicatedServer.param]: ServicesTypes.DedicatedServer.key,
  [ServicesTypes.Vds.param]: ServicesTypes.Vds.key,
  [ServicesTypes.GpuDedicatedServer.param]: ServicesTypes.GpuDedicatedServer.key,
};

export const SERVER_SERVICE_TYPES_BY_CATEGORY = {
  [ServicesTypes.ManagedServer.param]: ServicesTypes.ManagedServer.type,
  [ServicesTypes.ManagedCluster.param]: ServicesTypes.ManagedCluster.type,
  [ServicesTypes.WindowsManagedServer.param]: ServicesTypes.WindowsManagedServer.type,
  [ServicesTypes.WindowsManagedCluster.param]: ServicesTypes.WindowsManagedCluster.type,
  [ServicesTypes.CloudManagedServer.param]: ServicesTypes.CloudManagedServer.type,
  [ServicesTypes.ServerHosting.param]: ServicesTypes.ServerHosting.type,
  [ServicesTypes.DedicatedServer.param]: ServicesTypes.DedicatedServer.type,
  [ServicesTypes.GpuDedicatedServer.param]: ServicesTypes.GpuDedicatedServer.type,
  [ServicesTypes.Vds.param]: ServicesTypes.Vds.type
};
