import * as tslib_1 from "tslib";
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyTicketTextFormReducer, getTicketTextFormKey } from '@app/common/ticket-text-form';
import { TicketsTriggerState } from './tickets-trigger.model';
import { ActionTypes } from './tickets-trigger.action';
import { ModuleTokens, TicketsTriggerStates } from './tickets-trigger.constant';
var initialState = new TicketsTriggerState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    state = applyTicketTextFormReducer(tslib_1.__assign({}, state), action, ModuleTokens.Name, {
        ticketMessage: validate(required)
    });
    switch (action.type) {
        case ActionTypes.Open:
            return tslib_1.__assign({}, state, { open: true });
        case ActionTypes.Close:
            return tslib_1.__assign({}, initialState);
        case ActionTypes.SetState:
            return tslib_1.__assign({}, state, { state: action.payload.state, forms: initialState.forms, open: action.payload.state === TicketsTriggerStates.Add
                    ? true
                    : state.open });
        case ActionTypes.SetDetailId:
            return tslib_1.__assign({}, state, { detailId: action.payload, state: TicketsTriggerStates.Detail });
        case ActionTypes.ResetDetailId:
            return tslib_1.__assign({}, state, { detailId: undefined, forms: tslib_1.__assign({}, initialState.forms) });
        case ActionTypes.ResetMessageForm:
            var ticketTextFormKey = getTicketTextFormKey(ModuleTokens.Name);
            return tslib_1.__assign({}, state, { forms: tslib_1.__assign({}, state.forms, (_a = {}, _a[ticketTextFormKey] = initialState.forms[ticketTextFormKey], _a)) });
        case ActionTypes.ShowTrigger:
            return tslib_1.__assign({}, state, { showTrigger: true });
        case ActionTypes.HideTrigger:
            return tslib_1.__assign({}, state, { showTrigger: false });
        case ActionTypes.Reset:
            return tslib_1.__assign({}, initialState);
    }
    return state;
}
