import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarModule } from '@app/common/avatar';
import { ContactComponent } from './contact.component';

@NgModule({
  declarations: [ ContactComponent ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    AvatarModule
  ],
  exports: [ ContactComponent ]
})
export class ContactModule { }
