import * as tslib_1 from "tslib";
import { select, Store } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { Observable, Subject, merge } from 'rxjs';
import { ObservableInput } from 'observable-input';
import { switchMap, map, filter, withLatestFrom, takeUntil } from 'rxjs/operators';
import { getTicketEntityById, TicketsBaseUpdateCopyListRequest, TicketsBaseActionTypes, TicketsBaseUpdateCopyListCheckRequest } from '@app/base/tickets-base';
import { authActiveUserNormalizedClient, identity } from '@app/base/auth-base';
import { emailFormGenerateTranslationKeys } from '@app/common/email-form';
import { ticketCopyListFormState } from './ticket-copy-list.selector';
var TicketCopyListContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketCopyListContainer, _super);
    function TicketCopyListContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Form States
        _this.formState$ = _this._store.pipe(select(ticketCopyListFormState));
        // # Event Streams
        _this.onRemove$ = new Subject();
        _this.onCheck$ = new Subject();
        _this.onAdd$ = new Subject();
        // # Data
        // -- sync
        _this.transKeys = emailFormGenerateTranslationKeys('ticketCopyList');
        _this.addRequestKey = TicketsBaseActionTypes.UpdateCopyListRequest;
        _this.checkRequestKey = TicketsBaseActionTypes.UpdateCopyListCheckRequest;
        _this.addErrorKey = TicketsBaseActionTypes.UpdateCopyListFail;
        // -- async
        _this.addRequestKey$ = _this.id$.pipe(filter(function (id) { return !!id; }), map(function (id) { return _this.addRequestKey + ":" + id; }));
        _this.addCheckRequestKey$ = _this.id$.pipe(filter(function (id) { return !!id; }), map(function (id) { return [
            _this.addRequestKey + ":" + id,
            _this.checkRequestKey + ":" + id
        ]; }));
        _this.addErrorKey$ = _this.id$.pipe(filter(function (id) { return !!id; }), map(function (id) { return _this.addErrorKey + ":" + id; }));
        _this.ticket$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id)), filter(function (t) { return !!t; })); }));
        _this.data$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id)), filter(function (t) { return !!t; }), map(function (ticket) { return ticket.copyList; })); }));
        _this.clientUserClient$ = _this._store.pipe(select(authActiveUserNormalizedClient), filter(function (c) { return !!c; }));
        _this.secureCommunicationDialogOpen$ = _this._store.pipe(select(getDialogState(_this.checkRequestKey)), map(function (s) { return s.state; }));
        _this.companyName$ = _this._store.pipe(select(authActiveUserNormalizedClient), filter(function (c) { return !!c; }), map(function (c) { return c.companyName; }));
        _this.identity$ = _this._store.pipe(select(identity));
        // # Action Streams
        _this._checkAction$ = _this.onCheck$.pipe(withLatestFrom(_this.formState$, _this.data$, _this.id$, _this.formState$.pipe(map(function (s) { return s.value.email; }))), filter(function (_a) {
            var _ = _a[0], state = _a[1];
            return state.isValid;
        }), map(function (_a) {
            var _ = _a[0], __ = _a[1], data = _a[2], id = _a[3], mail = _a[4];
            return new TicketsBaseUpdateCopyListCheckRequest(id, data.concat([mail]), mail);
        }));
        _this._addAction$ = _this.onAdd$.pipe(withLatestFrom(_this.formState$, _this.data$, _this.id$, _this.formState$.pipe(map(function (s) { return s.value.email; }))), map(function (_a) {
            var _ = _a[0], __ = _a[1], data = _a[2], id = _a[3], mail = _a[4];
            return new TicketsBaseUpdateCopyListRequest(id, data.concat([mail]), mail, 'add');
        }));
        _this._removeAction$ = _this.onRemove$.pipe(withLatestFrom(_this.data$, _this.id$), map(function (_a) {
            var removedMail = _a[0], data = _a[1], id = _a[2];
            return new TicketsBaseUpdateCopyListRequest(id, data.filter(function (m) { return m !== removedMail; }), removedMail, 'remove');
        }));
        // # Action Dispatcher
        merge(_this._checkAction$, _this._addAction$, _this._removeAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketCopyListContainer.prototype, "id$", void 0);
    return TicketCopyListContainer;
}(BaseClass));
export { TicketCopyListContainer };
