import { UserCardTranslations } from './user-card.model';

const _prefix = 'user-card_';

export const DEFAULT_TRANSLATION_KEYS: UserCardTranslations = {
  tags: {
    accessToClientZone: _prefix + 'tags_accessToClientZone',
    authorizedToSendTickets: _prefix + 'tags_authorizedToSendTickets',
    cloudDnsEnabled: _prefix + 'tags_cloudDnsEnabled',
    cdnEnabled: _prefix + 'tags_cdnEnabled',
    cloudMailEnabled: _prefix + 'tags_cloudMailEnabled'
  }
};
