import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClientServiceStatusComponent } from './client-service-status.component';

@NgModule({
  declarations: [ ClientServiceStatusComponent ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [ ClientServiceStatusComponent ]
})
export class ClientServiceStatusModule {
}
