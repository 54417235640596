<mat-card class="__ticket-topic-pop-card  mat-elevation-z5">
  <mat-action-list dense>
    <ng-container *ngFor="let item of groupedTopics; let last = last;">

      <h3
        class="__ticket-topic-pop-subheader"
        mat-subheader>
        {{ 'ticketTopics.' + item.key | translate }}
      </h3>

      <button
        mat-list-item
        matRipple
        type="button"
        class="__ticket-topic-pop-button"
        (click)="!!selected.emit(topic.id)"
        *ngFor="let topic of item.items">
        {{ topic._name }}
      </button>

      <mat-divider *ngIf="!last"></mat-divider>

    </ng-container>
  </mat-action-list>

</mat-card>
