import {
  Component,
  Directive,
  TemplateRef,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayConfig
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[vshczDisplacerPortal]'
})
export class DisplacerPortalDirective extends TemplatePortal {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Component({
  selector: 'vshcz-displacer',
  templateUrl: './displacer.component.html'
})
export class DisplacerComponent implements OnDestroy, AfterViewInit {
  @ViewChild(DisplacerPortalDirective)
  private _portal: DisplacerPortalDirective;

  @Output()
  initialized = new EventEmitter<void>();

  private _config = new OverlayConfig();
  private _overlayRef: OverlayRef;

  constructor(private _overlay: Overlay) { }

  ngOnDestroy() {
    this._overlayRef.detach();
  }

  ngAfterViewInit() {
    this._overlayRef = this._overlay.create(this._config);
    this._overlayRef.attach(this._portal);

    this.initialized.emit();
  }

}
