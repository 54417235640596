import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';

@Injectable({
  providedIn: 'root'
})
export class CloudDNSGuard implements CanActivate {

  constructor(private _router: Router) {}

  canActivate() {

    if (toBoolean(environment.enableCDNS)) {
      return true;
    } else {
      this._router.navigate([ '/', 'dashboard' ]);
      return false;
    }
  }
}
