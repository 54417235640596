import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  TranslateModule,
  TranslateCompiler,
  TranslateService
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TicketAddFormModule } from '@app/common/ticket-add-form';
import { FormModule } from '@app/common/forms';
import { ProgressErrorModule } from '@app/common/progress-error';
import { FilesModule } from '@app/common/files';
import { TicketAddContainer } from './ticket-add.container';
import { TicketAddEffect } from './ticket-add.effect';
import { ModuleTokens } from './ticket-add.constant';
import { reducer } from './ticket-add.reducer';

// TODO: root module
@NgModule({
  declarations: [ TicketAddContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ TicketAddEffect ]),
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    TicketAddFormModule,
    FormModule,
    ProgressErrorModule,
    FilesModule
  ],
  exports: [ TicketAddContainer ]
})
export class TicketAddModule { }
