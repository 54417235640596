import {
  Component,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  HostListener,
  HostBinding
} from '@angular/core';
import { BASE_UNIT } from '@app/constants';
import { ItemCoordinates } from '../../menu.model';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'vshcz-menu-pop',
  templateUrl: './menu-pop.component.html',
  styleUrls: [ './menu-pop.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuPopComponent implements AfterViewInit, OnChanges {
  _sizeMap: {
    [index: number]: {
      width: number;
      height: number;
    };
  } = {};

  baseSizes = {
    width: 380,
    height: 400
  };

  bgScaleX = 1;
  bgScaleY = 1;

  contentOffset: number;

  appBarHeight = 63;

  @Input()
  coordinates: ItemCoordinates;

  @Input()
  activeIndex: number;

  @Input()
  open: boolean;

  @Input()
  showFinancial: boolean;

  @Input()
  showSettings: boolean;

  @Output()
  leave = new EventEmitter<HTMLElement>();

  @Output()
  companySelectToggle = new EventEmitter<boolean>();

  @Output()
  close = new EventEmitter<boolean>();

  @ViewChild('data')
  dataRef: ElementRef<HTMLElement>;

  @ViewChild('arrow')
  arrowRef: ElementRef<HTMLElement>;

  @ViewChild('bg')
  bgRef: ElementRef<HTMLElement>;

  @ViewChildren('item')
  itemRefs: QueryList<ElementRef<HTMLElement>>;

  private _isPristine: boolean;

  @HostBinding('class.is-active')
  get classIsActive() {
    return this.open;
  }

  @HostBinding('class.is-pristine')
  get classIsPristine() {
    return this._isPristine;
  }

  viewportHeight$ = this._ruler.change(100).pipe(
    map(() => this._ruler.getViewportSize().height),
    startWith(this._ruler.getViewportSize().height)
  );

  contentHeight$ = this.viewportHeight$.pipe(
    map((height) => height - this.appBarHeight - 50)
  );

  @HostListener('mouseleave', ['$event'])
  onMouseleave(e: any) {
    this.leave.emit(e.target);
  }

  ngOnChanges(changes: SimpleChanges) {
    const curIndex = changes && changes.activeIndex
      ? changes.activeIndex.currentValue
      : undefined;

    if (curIndex  !== undefined && this._sizeMap[curIndex]) {
      const { width, height } = this._sizeMap[curIndex];
      const coordinates: ItemCoordinates = changes.coordinates.currentValue;
      const newOffset = coordinates.xCenter - (this._sizeMap[curIndex].width / 2);
      this.contentOffset = newOffset > BASE_UNIT ? newOffset : BASE_UNIT;

      this.bgScaleX = width / this.baseSizes.width;
      this.bgScaleY = height / this.baseSizes.height;
    }

    if (!this.open) {
      this._isPristine = true;
    } else {
      // hack to delay inner animations
      setTimeout(() => {
        this._isPristine = false;
      });
    }
  }

  ngAfterViewInit() {
    this._setSizeMap();
  }

  refresh() {
    this._setSizeMap();
  }

  constructor(
    private _ruler: ViewportRuler
  ) {}

  private _setSizeMap() {
    this.itemRefs.forEach((element, i) => {
      this._sizeMap[i] = {
        width: element.nativeElement.clientWidth,
        height: element.nativeElement.clientHeight
      };
    });
  }

}
