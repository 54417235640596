import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { map, takeUntil, delayWhen, tap, filter } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import animateScrollTo from 'animated-scroll-to';
import { PermissionService } from '@zerops/fe/permission';
import { getRouterSlice } from '@app/common/ngrx-router';
import { SearchOpen } from '@app/common/search';
import { MenuItemsComponent } from '@app/common/menu';
import { overdueInvoices } from '@app/base/invoices-base';
import { Roles } from '@app/base/auth-base';
var AppBarContainer = /** @class */ (function (_super) {
    tslib_1.__extends(AppBarContainer, _super);
    function AppBarContainer(media, _permissions, _store) {
        var _this = _super.call(this) || this;
        _this.media = media;
        _this._permissions = _permissions;
        _this._store = _store;
        // # Event Streams
        _this.onSearchOpen$ = new Subject();
        // # Data
        // -- sync
        _this._menuTransitionEnabled = false;
        // -- async
        _this._menuHidden$ = _this._store.pipe(select(getRouterSlice), filter(function (s) { return !!s; }), map(function (slice) { return slice && slice.state
            ? slice.state.url.includes('/dashboard')
            : false; }), map(function (f) { return !f; }), delayWhen(function (a) { return a ? timer(250) : timer(0); }), tap(function () {
            if (_this.menuItemsRef) {
                _this.menuItemsRef.refresh();
            }
        }));
        _this.overdueInvoiceCount$ = _this._store.pipe(select(overdueInvoices), map(function (invoices) { return !!invoices ? invoices.length : 0; }));
        // https://redmine.vshosting.cz/issues/14397
        // servicesCount$ = this._store.pipe(
        //   select(authActiveUserServicesActiveGrouped),
        //   map((groups) => !!groups
        //     ? groups.length
        //     : 0
        //   )
        // );
        _this.showGraphsSettingsAndExternal$ = _this._permissions.authorize({
            only: [Roles.Manager, Roles.Technical]
        });
        _this.showFinancialAndStatus$ = _this._permissions.authorize({
            only: [Roles.Manager, Roles.Financial]
        });
        // # Action Streams
        _this._searchOpenAction$ = _this.onSearchOpen$.pipe(map(function () { return new SearchOpen(); }));
        // # Store Dispatcher
        _this._searchOpenAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    AppBarContainer.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this._menuTransitionEnabled = true;
        });
    };
    AppBarContainer.prototype.toTop = function () {
        return animateScrollTo(0, {
            maxDuration: 800
        });
    };
    return AppBarContainer;
}(BaseClass));
export { AppBarContainer };
