import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  map,
  filter,
  delay,
  mergeMap,
  withLatestFrom,
  switchMap,
  first
} from 'rxjs/operators';
import { FocusAction } from 'ngrx-forms';
import { ROUTER_NAVIGATION_TYPE, RouteNavigation } from 'ngrx-router';
import {
  TicketsBaseEntityRequest,
  TicketsBaseActionTypes,
  TicketsBaseMessageAddTemp,
  ticketTopicById
} from '@app/base/tickets-base';
import { getTicketTextFormKey } from '@app/common/ticket-text-form';
import {
  getTicketAddFormKey,
  ticketAddFormUpdateGroupAction
} from '@app/common/ticket-add-form';
import {
  TicketAddModuleTokens,
  TicketAddReset,
  TicketAddActionTypes,
  TicketAddLocalSuccess
} from '@app/common/ticket-add';
import { TicketsListResetFilter } from '@app/common/tickets-list';
import { Go } from '@app/common/ngrx-router';
import { identity } from '@app/base/auth-base';
import { State } from '@app/models';
import {
  SetState,
  SetDetailId,
  ActionTypes,
  ResetMessageForm,
  Reset,
  Open,
  HideTrigger,
  ShowTrigger,
  Close
} from './tickets-trigger.action';
import { TicketsTriggerStates, ModuleTokens } from './tickets-trigger.constant';
import { showTrigger } from './tickets-trigger.selector';

@Injectable()
export class TicketsTriggerEffect {

  private _onSetState$ = this._actions$.pipe(ofType<SetState>(ActionTypes.SetState));

  @Effect()
  private _onRouteWithTicketQueryParam$ = this._actions$.pipe(
    ofType<RouteNavigation>(ROUTER_NAVIGATION_TYPE),
    filter(({ payload }) => !!payload.params.ticket),
    map(({ payload }) => new Open(payload.params.ticket))
  );

  // show or hide trigger based on route data
  @Effect()
  private _onRouteWithHideTrigger$ = this._actions$.pipe(
    ofType<RouteNavigation>(ROUTER_NAVIGATION_TYPE),
    map(({ payload }) => !!payload.data['hideSupport']),
    withLatestFrom(this._store.pipe(
      select(showTrigger)
    )),
    // only toggle trigger when the
    // value is not the same as current state
    filter(([ toHide, shown ]) => {
      const isHidden = !shown;

      return toHide !== isHidden;
    }),
    map(([ hide ]) => {
      if (hide) {
        return new HideTrigger();
      }

      return new ShowTrigger();
    })
  );

  @Effect()
  private _onOpenWithTicketId$ = this._actions$.pipe(
    ofType<Open>(ActionTypes.Open),
    filter(({ payload }) => !!payload),
    delay(100),
    mergeMap(({ payload }) => {
      let route = this._router.routerState.root;

      const routeCommand = [ '/' ];

      while (route.firstChild) {
        route = route.firstChild;
        routeCommand.push(...route.snapshot.url.map((u) => u.path));
      }

      return [
        new SetDetailId(payload),
        new Go({ path: routeCommand })
      ];
    })
  );

  @Effect()
  private _onSetStateDetail$ = this._onSetState$.pipe(
    filter(({ payload }) => payload.state === TicketsTriggerStates.Detail),
    map(({ payload }) => new SetDetailId(payload.meta))
  );

  @Effect()
  private _onSetStateAddSetTopic$ = this._onSetState$.pipe(
    filter(({ payload }) => payload.state === TicketsTriggerStates.Add),
    switchMap(({ payload }) => this._store.pipe(
      select(ticketTopicById(payload.meta)),
      first()
    )),
    withLatestFrom(this._store.pipe(
      select(identity)
    )),
    map(([ topic, user ]) => ticketAddFormUpdateGroupAction(
      TicketAddModuleTokens.Name,
      {
        ticketTopicId: topic.id,
        title: topic.name[user.language.id],
        ticketMessage: topic.template[user.language.id]
      }
    ))
  );

  @Effect()
  private _onSetStateAddFocus$ = this._onSetState$.pipe(
    filter(({ payload }) => payload.state === TicketsTriggerStates.Add),
    delay(10),
    map(({ payload }) => new FocusAction(
      `${getTicketAddFormKey(TicketAddModuleTokens.Name)}.title`
    ))
  );

  // reset add form state when switching away from add state
  @Effect()
  private _onSetStateResetAddForm$ = this._onSetState$.pipe(
    filter(({ payload }) => payload.state !== TicketsTriggerStates.Add),
    map(() => new TicketAddReset())
  );

  // reset add form state when closing tickets trigger
  @Effect()
  private _onCloseResetAddForm$ = this._actions$.pipe(
    ofType<Close>(ActionTypes.Close),
    map(() => new TicketAddReset())
  );

  @Effect()
  private _onSetDetailId$ = this._actions$.pipe(
    ofType<SetDetailId>(ActionTypes.SetDetailId),
    map(({ payload }) => new TicketsBaseEntityRequest(payload))
  );

  @Effect()
  private _onMessageTempAddResetForm$ = this._actions$.pipe(
    ofType<TicketsBaseMessageAddTemp>(TicketsBaseActionTypes.MessageAddTemp),
    withLatestFrom(this._store.pipe(
      select(showTrigger)
    )),
    filter(([ _, isTrigger ]) => !!isTrigger),
    map(() => new ResetMessageForm())
  );

  @Effect()
  private _onResetFormFocus$ = this._actions$.pipe(
    ofType<ResetMessageForm>(ActionTypes.ResetMessageForm),
    map(() => new FocusAction(`${getTicketTextFormKey(ModuleTokens.Name)}.ticketMessage`))
  );

  @Effect()
  private _onTicketAddSwitchState$ = this._actions$.pipe(
    ofType<TicketAddLocalSuccess>(TicketAddActionTypes.AddLocalSuccess),
    withLatestFrom(this._store.pipe(
      select(showTrigger)
    )),
    filter(([ _, isTrigger ]) => !!isTrigger),
    map(([ { meta } ]) => new SetState({
      state: TicketsTriggerStates.Detail,
      meta: meta.id
    }))
  );

  // reset list filters when closing / reseting whole trigger
  @Effect()
  private _onReset$ = this._actions$.pipe(
    ofType<Reset>(ActionTypes.Reset),
    map(() => new TicketsListResetFilter())
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _router: Router
  ) { }
}
