import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, concat } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  map,
  catchError,
  mergeMap,
  concatMap,
  first,
  bufferTime,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { SnackService } from '@app/common/snack';
import { authActiveUserClientId } from '@app/base/auth-base';
import {
  RefusedToQueue,
  ActionTypes,
  UploadRequest,
  UploadSingleLocalSuccess,
  UploadSingleFail,
  UploadEmpty,
  UploadProgress,
  UploadAllLocalSuccess,
  MarkQueueItemAsDone,
  ClearInstanceQueue
} from './files.action';
import { queueFiles, uploadedFiles } from './files.selector';
import { FilesApi } from './files.api';

@Injectable()
export class FilesEffect {

  private _onUploadRequest$ = this._actions$.pipe(
    ofType<UploadRequest>(ActionTypes.UploadRequest)
  );

  @Effect({ dispatch: false })
  private _onRefusedToQueue$ = this._actions$.pipe(
    ofType<RefusedToQueue>(ActionTypes.RefusedToQueue),
    switchMap(({ payload }) => this._snack.translatedWarning$(
      payload === 'size'
        ? 'common.files.maxSize'
        : 'common.files.maxCount',
      'common.close'
    ))
  );

  // no files in query, send upload empty action
  @Effect()
  private _onUploadRequestEmpty$ = this._onUploadRequest$.pipe(
    switchMap(({ payload }) => this._store.pipe(
      select(queueFiles(payload)),
      first(),
      filter((files) => files.length === 0),
      map(() => new UploadEmpty({ instanceId: payload }))
    ))
  );

  // there are files in queury, proceed with upload
  @Effect()
  private _onUploadRequestProceed$ = this._onUploadRequest$.pipe(
    switchMap(({ payload }) => this._store.pipe(
      select(queueFiles(payload)),
      first(),
      filter((files) => files.length > 0),
      mergeMap((queue) => queue),
      withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
      concatMap(([ queueItem, clientId ]) => concat(
        of(new MarkQueueItemAsDone({
          instanceId: payload,
          uuid: queueItem.uuid
        })),
        this._api
          .upload$(queueItem.meta, clientId, true)
          .pipe(
            filter((event) => event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response),
            bufferTime(50),
            filter((events) => events.length > 0),
            map((events) => {
              const event: any = events[events.length - 1];
              if (event.type === HttpEventType.UploadProgress) {
                return new UploadProgress(event.loaded / event.total);
              } else {
                return new UploadSingleLocalSuccess({
                  instanceId: payload,
                  fileId: event.body.fileId
                });
              }
            }),
            catchError((err) => this._errorTranslation
              .get$(err)
              .pipe(map((data) => new UploadSingleFail(
                {
                  fileUuid: queueItem.uuid,
                  instanceId: payload,
                },
                data
              )))
            )
          )
      )),
      takeUntil(this._actions$.pipe(
        ofType<UploadSingleFail>(ActionTypes.UploadSingleFail),
        filter((action) => action.payload.instanceId === payload)
      ))
    ))
  );

  @Effect()
  private _onUploadAllDone$ = this._actions$.pipe(
    ofType<UploadSingleLocalSuccess>(ActionTypes.UploadSingleLocalSuccess),
    // switch to queue everytime single uploade succeeds
    switchMap(({ payload }) => this._store.pipe(
      select(queueFiles(payload.instanceId)),
      // only take the first result
      first(),
      // check if all items in queue are done
      filter((queue) => queue.filter((item) => !item.done).length === 0),
      // select uploaded files
      withLatestFrom(this._store.pipe(select(uploadedFiles(payload.instanceId)))),
      // dispatch result with them
      map(([ _, fileIds ]) => new UploadAllLocalSuccess({
        fileIds,
        instanceId: payload.instanceId
      }))
    ))
  );

  // clear queue after all files were uploaded
  @Effect()
  private _onUploadAllSuccessClearQueue$ = this._actions$.pipe(
    ofType<UploadAllLocalSuccess>(ActionTypes.UploadAllLocalSuccess),
    map(({ payload }) => new ClearInstanceQueue(payload.instanceId))
  );

  constructor(
    private _api: FilesApi,
    private _store: Store<State>,
    private _actions$: Actions,
    private _snack: SnackService,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
