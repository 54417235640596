import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { Action } from '@ngrx/store';
import { applyPriceOfferRejectFormReducer } from './modules';
import { TicketPriceOfferState } from './ticket-price-offer.model';
import { ModuleTokens } from './ticket-price-offer.constant';

const initialState = new TicketPriceOfferState();

export function reducer(
  state = initialState,
  action: Action
): TicketPriceOfferState {

  state = applyPriceOfferRejectFormReducer(
    { ...state },
    action,
    ModuleTokens.Name,
    {
      messageText: validate(required)
    }
  );

  return state;
}
