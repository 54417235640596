<mat-form-field class="__textarea-field">
  <textarea
    rows="1"
    (keypress)="onKeypress($event)"
    cdkAutosizeMaxRows="5"
    (vshczSizeChange)="sizeChange.emit($event)"
    vshczTextareaAutosize
    [placeholder]="formTranslations.placeholders.ticketMessage | translate"
    class="__textarea"
    [ngrxEnableFocusTracking]="true"
    [ngrxFormControlState]="state.controls.ticketMessage"
    matInput></textarea>
</mat-form-field>
