import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './servers-base.constant';
import { ServersBaseState } from './servers-base.model';
import {
  Actions as BaseActions,
  ActionTypes as BaseActionTypes
} from './servers-base.action';
import {
  Actions as IpActions,
  ActionTypes as IpActionTypes
} from './servers-base-ip.action';

const initialState = new ServersBaseState();

type Actions = BaseActions | IpActions;

export function reducer(
  state = initialState,
  action: Actions
): ServersBaseState {
  state = applyEntities<ServersBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === BaseActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  if (action.type === BaseActionTypes.GraphRequest) {
    if (!action.meta) {
      return {
        ...state,
        graphs: {}
      };
    }
  }

  if (action.type === BaseActionTypes.GraphLocalSuccess) {

    return {
      ...state,
      graphs: {
        ...state.graphs,
        [action.payload.id]: {
          ...state.graphs[action.payload.id],
          ...action.payload.graphType.reduce((obj, _, i) => {
            obj[action.payload.graphType[i]] = action.payload.data[i];
            return obj;
          }, {})
        }
      }
    };
  }

  if (action.type === BaseActionTypes.IpRangeLocalSuccess) {
    return {
      ...state,
      ipV4Range: action.payload.ipV4RangeList,
      ipV6Range: action.payload.ipV6RangeList,
      freeIpV4List: action.payload.clientFreeIpV4AddressList,
      usedIpV6List: action.payload.clientUsedIpV6AddressList
    };
  }

  if (action.type === IpActionTypes.AddIpV4LocalSuccess) {
    return {
      ...state,
      freeIpV4List: state.freeIpV4List.filter(
        (d) => d.serverIpV4Id !== action.meta.id
      )
    };
  }

  if (action.type === IpActionTypes.AddIpV6LocalSuccess) {
    return {
      ...state,
      usedIpV6List: [
        ...state.usedIpV6List,
        {
          ipV6: action.meta.ip,
          serverIpV6Id: action.meta.id
        }
      ]
    };
  }

  if (action.type === IpActionTypes.DeleteIpLocalSuccess) {
    if (action.meta.type === 'ipv4') {
      return {
        ...state,
        freeIpV4List: [
          ...state.freeIpV4List,
          { serverIpV4Id: action.meta.id, ipV4: action.meta.ip }
        ]
      };
    }

    if (action.meta.type === 'ipv6') {
      return {
        ...state,
        usedIpV6List: state.usedIpV6List.filter(
          (d) => d.serverIpV6Id !== action.meta.id
        )
      };
    }
  }

  return state;
}
