import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersEntities, UsersBaseModuleTokens } from '@app/base/users-base';
import { clientsBaseEntities, ClientsBaseModuleTokens } from '@app/base/clients-base';
import { servicesEntities, ServicesBaseModuleTokens } from '@app/base/services-base';
import { ClientUserBaseState, ClientUserEntity } from './client-user-base.model';
import { ModuleTokens } from './client-user-base.constant';
import { denormalizeEntity, denormalizeList } from './client-user-base.utils';

export const slice = createFeatureSelector<ClientUserBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const getById = (id: string) => createSelector(
  entities,
  usersEntities,
  clientsBaseEntities,
  servicesEntities,
  (ents, usersEnt, clientsEnt, servicesEnt) => {
    if (!ents) { return undefined; }

    return denormalizeEntity(
      id,
      ents[ModuleTokens.Name],
      usersEnt[UsersBaseModuleTokens.Name],
      clientsEnt[ClientsBaseModuleTokens.Name],
      servicesEnt[ServicesBaseModuleTokens.Name]
    ) as ClientUserEntity;
  }
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  usersEntities,
  entities,
  clientsBaseEntities,
  servicesEntities,
  (ids, usersEnt, clientUserEnt, clientsEnt, servicesEnt) => {
    return denormalizeList(
      ids,
      clientUserEnt[ModuleTokens.Name],
      usersEnt[UsersBaseModuleTokens.Name],
      servicesEnt[ServicesBaseModuleTokens.Name],
      clientsEnt[ClientsBaseModuleTokens.Name]
    ) as ClientUserEntity[];
  }
);
