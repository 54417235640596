import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

enum States {
  reconnecting = '1',
  reconnected = '0'
}

@Component({
  selector: 'vshcz-ws-reconnect',
  templateUrl: './ws-reconnect.component.html',
  styleUrls: [ './ws-reconnect.component.scss' ],
  animations: [
    trigger('reconnecting', [

      transition(':leave', [
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }),

        animate(120, style({
          opacity: 0,
          transform: 'translate3d(0, 10px, 0)'
        }))
      ])

    ]),

    trigger('reconnected', [

      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translate3d(0, -10px, 0)'
        }),

        animate(170, style({
          opacity: 1,
          transform: 'translate3d(0, 0px, 0)'
        }))
      ])

    ]),

    trigger('wrap', [

      state('0', style({
        backgroundColor: '#4cb75f'
      })),

      state('1', style({
        backgroundColor: '#ea6c6c'
      })),

      transition('1 => 0', [
        animate('150ms 100ms')
      ])

    ])
  ]
})
export class WsReconnectComponent {
  states = States;

  @Input()
  state: States = States.reconnecting;
}
