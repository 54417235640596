import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { A11yModule } from '@angular/cdk/a11y';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { FormsModule } from '@app/common/forms';
import { WrapModule } from '@app/common/wrap';
import { EmptyStateModule } from '@app/common/empty-state';
import { KeyChipModule } from '@app/common/key-chip';
import { SearchItemModule, SearchTableModule } from './modules';
import { SearchContainer } from './search.container';
import { ModuleTokens } from './search.constant';
import { reducer } from './search.reducer';
import { SearchEffect } from './search.effect';

@NgModule({
  declarations: [ SearchContainer ],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    A11yModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ SearchEffect ]),
    TranslateModule.forChild(),
    NgrxFormsModule,
    NgrxFormsExtensionsModule,
    FormsModule,
    WrapModule,
    KeyChipModule,
    SearchItemModule,
    SearchTableModule,
    EmptyStateModule
  ],
  exports: [ SearchContainer ]
})
export class SearchModule { }
