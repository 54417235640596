import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import { DialogClose, DialogActionTypes } from '@zerops/fe/dialog';
import {
  switchMap,
  withLatestFrom,
  map,
  catchError,
  filter
} from 'rxjs/operators';
import {
  TicketsBaseActionTypes,
  TicketsBaseRateRequest,
  TicketsBaseRateFail,
  TicketsBaseRateLocalSuccess,
  TicketsBaseApi
} from '@app/base/tickets-base';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { Reset } from './ticket-rating.action';
import { formValue } from './ticket-rating.selector';

@Injectable({ providedIn: 'root' })
export class TicketRatingEffect {

  private _onLocalSuccess$ = this._actions$.pipe(
    ofType<TicketsBaseRateLocalSuccess>(TicketsBaseActionTypes.RateLocalSuccess)
  );

  @Effect()
  private _onRateRequest$ = this._actions$.pipe(
    ofType<TicketsBaseRateRequest>(TicketsBaseActionTypes.RateRequest),
    withLatestFrom(
      this._store.pipe(select(authActiveUserClientId)),
      this._store.pipe(select(formValue))
    ),
    switchMap(([ { payload }, clientId, ratingData ]) => this._api
      .rate$(
        payload.id,
        payload.clientId ? payload.clientId : clientId,
        payload.rating === 4
          ? {
              speed: 5,
              expertise: 5,
              communication: 5
            }
          : ratingData
      )
      .pipe(
        map(() => new TicketsBaseRateLocalSuccess(payload.id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new TicketsBaseRateFail(
            data,
            payload.id,
            {
              errorMessage: data.message,
              errorCode: data.code
            }
          )))
        )
      )
    )
  );

  @Effect()
  private _onRateGoodFailSnack$ = this._actions$.pipe(
    ofType<TicketsBaseRateFail>(TicketsBaseActionTypes.RateFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta.errorMessage,
      'common.close'
    )),
    map(() => new RemoveError(TicketsBaseActionTypes.RateFail))
  );

  // close dialog on success
  @Effect()
  private _onRateSuccessCloseDialog$ = this._onLocalSuccess$.pipe(
    map(() => new DialogClose(TicketsBaseActionTypes.RateRequest))
  );

  // reset ticket rating state on success / dialog close
  @Effect()
  private _onRateSuccessReset$ = this._onLocalSuccess$.pipe(
    map(() => new Reset())
  );

  @Effect()
  private _onDialogCloseReset$ = this._actions$.pipe(
    ofType<DialogClose>(DialogActionTypes.Close),
    filter(({ payload }) => payload.key === TicketsBaseActionTypes.RateRequest),
    map(() => new Reset())
  );

  constructor(
    private _actions$: Actions,
    private _api: TicketsBaseApi,
    private _store: Store<State>,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) { }
}
