import * as tslib_1 from "tslib";
import { ObservableMedia } from '@angular/flex-layout';
import { Store } from '@ngrx/store';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseClass } from '@zerops/fe/core';
import { DialogOpen } from '@zerops/fe';
import { BulkPaymentFeesDialogKey } from '../bulk-payment-fees-dialog';
import { BulkPaymentInvoicesDialogKey } from '../bulk-payment-invoices-dialog';
import { PaymentIntentTypes } from '@app/base/invoices-base/invoices-base.constant';
import { PaymentKinds } from '@app/base/payments-base/payments-base.constant';
var BulkPaymentOverviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BulkPaymentOverviewComponent, _super);
    function BulkPaymentOverviewComponent(_store, media) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this.media = media;
        _this.onPaymentDialogOpen$ = new Subject();
        _this.onPaymentStripeIntent$ = new Subject();
        _this.invoicesDialogKey = BulkPaymentInvoicesDialogKey;
        _this.feesDialogKey = BulkPaymentFeesDialogKey;
        _this.paymentKinds = PaymentKinds;
        _this.paymentIntentTypes = PaymentIntentTypes;
        _this.notApplyCredit = false;
        _this.isFeePayment = false;
        _this._paymentDialogOpenAction$ = _this.onPaymentDialogOpen$.pipe(
        /**
         * Open a dialog window where a user enters necessary card data and starts a payment.
         */
        map(function (_a) {
            var key = _a.key, instanceKind = _a.instanceKind, paymentKind = _a.paymentKind, paymentIntentType = _a.paymentIntentType;
            return new DialogOpen(key, { instanceKind: instanceKind, paymentKind: paymentKind, paymentIntentType: paymentIntentType });
        }));
        // # Store Dispatcher
        merge(_this._paymentDialogOpenAction$).pipe(takeUntil(_this._ngOnDestroy$)).subscribe(_this._store);
        return _this;
    }
    return BulkPaymentOverviewComponent;
}(BaseClass));
export { BulkPaymentOverviewComponent };
