import { DomainRecordType } from './domain-record-base.model';
import {
  required,
  pattern
} from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';
import { inRange } from './domains-base.utils';

export enum DomainRecordStatuses {
  new = 'NEW',
  active = 'ACTIVE',
  deleted = 'DELETED'
}

export const DOMAIN_RECORD_TYPES: DomainRecordType[] = [
  {
    record: 'a',
    focus: 'value',
    defaultValues: {
      value: ''
    },
    validationRules: {
      value: validate(required)
    }
  },
  {
    record: 'aaaa',
    defaultValues: {
      value: ''
    },
    validationRules: {
      value: validate(required)
    }
  },
  {
    record: 'cname',
    defaultValues: {
      value: ''
    },
    validationRules: {
      value: validate(required)
    }
  },
  {
    record: 'mx',
    defaultValues: {
      value: '',
      priority: null
    },
    validationRules: {
      value: validate(required),
      priority: validate(
        // non-negative integer 0-65535
        inRange(0, 65535),
        required
      )
    }
  },
  {
    record: 'txt',
    defaultValues: {
      value: ''
    },
    validationRules: {
      value: validate(required)
    }
  },
  {
    record: 'srv',
    defaultValues: {
      value: '',
      priority: null,
      weight: null,
      port: null
    },
    validationRules: {
      value: validate(required),
      priority: validate(
        // non-negative integer 0-65535
        inRange(0, 65535),
        required
      ),
      weight: validate(
        // non-negative integer 0-65535
        inRange(0, 65535),
        required
      ),
      port: validate(
        // non-negative integer 0-65535
        inRange(0, 65535),
        required
      ),
    }
  },
  {
    record: 'caa',
    defaultValues: {
      value: '',
      flag: null,
      tag: null
    },
    validationRules: {
      value: validate(required),
      flag: validate(
        // non-negative integer 0-255
        inRange(0, 255),
        required
      ),
      tag: validate(required)
    }
  },
  {
    record: 'sshfp',
    defaultValues: {
      value: '',
      algorithm: null,
      algorithmType: null
    },
    validationRules: {
      value: validate(required),
      algorithm: validate(required),
      algorithmType: validate(required)
    }
  },
  {
    record: 'tlsa',
    defaultValues: {
      value: '',
      certificateUsage: null,
      selector: null,
      matchingType: null
    },
    validationRules: {
      value: validate(required),
      certificateUsage: validate(required),
      selector: validate(required),
      matchingType: validate(required)
    }
  }
];
