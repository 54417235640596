import { Component, OnInit, ViewChild } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { map, takeUntil, delayWhen, tap, filter } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import animateScrollTo from 'animated-scroll-to';
import { State } from '@app/models';
import { PermissionService } from '@zerops/fe/permission';
import { getRouterSlice } from '@app/common/ngrx-router';
import { SearchOpen } from '@app/common/search';
import { MenuItemsComponent } from '@app/common/menu';
import { overdueInvoices } from '@app/base/invoices-base';
import {
  authActiveUserServicesActiveGrouped,
  Roles
} from '@app/base/auth-base';

@Component({
  selector: 'vshcz-app-bar',
  templateUrl: './app-bar.container.html',
  styleUrls: [ './app-bar.container.scss' ]
})
export class AppBarContainer extends BaseClass implements OnInit {
  // # Event Streams
  onSearchOpen$ = new Subject<void>();

  // # Data
  // -- sync
  _menuTransitionEnabled = false;

  // -- angular
  @ViewChild(MenuItemsComponent)
  menuItemsRef: MenuItemsComponent;

  // -- async
  _menuHidden$ = this._store.pipe(
    select(getRouterSlice),
    filter((s) => !!s),
    map((slice) => slice && slice.state
      ? slice.state.url.includes('/dashboard')
      : false
    ),
    map((f) => !f),
    delayWhen((a) => a ? timer(250) : timer(0)),
    tap(() => {
      if (this.menuItemsRef) {
        this.menuItemsRef.refresh();
      }
    })
  );

  overdueInvoiceCount$ = this._store.pipe(
    select(overdueInvoices),
    map((invoices) => !!invoices ? invoices.length : 0)
  );

  // https://redmine.vshosting.cz/issues/14397
  // servicesCount$ = this._store.pipe(
  //   select(authActiveUserServicesActiveGrouped),
  //   map((groups) => !!groups
  //     ? groups.length
  //     : 0
  //   )
  // );

  showGraphsSettingsAndExternal$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Technical ]
  });

  showFinancialAndStatus$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Financial ]
  });

  // # Action Streams
  private _searchOpenAction$ = this.onSearchOpen$.pipe(
    map(() => new SearchOpen())
  );

  constructor(
    public media: ObservableMedia,
    private _permissions: PermissionService,
    private _store: Store<State>
  ) {
    super();

    // # Store Dispatcher
    this._searchOpenAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }

  ngOnInit() {
    setTimeout(() => {
      this._menuTransitionEnabled = true;
    });
  }

  toTop() {
    return animateScrollTo(
      0,
      {
        maxDuration: 800
      }
    );
  }

}
