import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '@app/common/logo';
import { DisplacerModule } from '@app/common/displacer';
import { BillingCardModule } from '@app/common/billing-card';
import { CompanyCardModule } from '@app/common/company-card';
import { ServicesCardModule } from '@app/common/services-card';
import {
  MenuPopComponent,
  MenuItemsComponent,
  MenuItemDirective
} from './components';

@NgModule({
  declarations: [
    MenuItemsComponent,
    MenuPopComponent,
    MenuItemDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatListModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    LogoModule,
    DisplacerModule,
    BillingCardModule,
    CompanyCardModule,
    ServicesCardModule
  ],
  exports: [ MenuItemsComponent ]
})
export class MenuModule { }
