import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Output,
  EventEmitter
} from '@angular/core';
import { FocusableOption } from '@angular/cdk/a11y';
import { Currency } from '@app/common/settings';
import { HashMap } from 'utils';
import { SearchEntityWithLink, SearchEntityMapped } from '../../search.model';
import { SearchEntities } from '../../search.constant';
import { searchLinkGenerator } from '../../search.utils';

@Component({
  selector: 'vshcz-search-item',
  templateUrl: 'search-item.component.html',
  styleUrls: [ 'search-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchItemComponent implements FocusableOption {
  // # Data
  // -- sync
  disabled: boolean;
  focused: boolean;
  dataWithLink: SearchEntityWithLink;
  entities = SearchEntities;

  // -- angular
  @Input()
  set data(v: SearchEntityMapped) {
    this._data = v;

    if (this._data) {
      this.dataWithLink = {
        ...v,
        _link: searchLinkGenerator(v.entity, v.entityId)
      };
    }
  }

  @Input()
  currencyMap: HashMap<Currency>;

  @Input()
  isActive: boolean;

  @Input()
  entityTranslations: { [key: string]: string; };

  @Output()
  clicked = new EventEmitter<void>();

  @HostBinding('class.is-active')
  get classIsActive() {
    return this.isActive;
  }

  private _data: SearchEntityMapped;

  getLabel() {
    return '';
    // return this.data.title;
  }

  focus() { }

  onClick() {
    this.clicked.emit();
  }
}
