import { ElementRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';
import animateScrollTo from 'animated-scroll-to';
var ScrollableComponent = /** @class */ (function () {
    function ScrollableComponent(_el, _cdRef) {
        this._el = _el;
        this._cdRef = _cdRef;
        this.onTopEdge = false;
        this.onBottomEdge = true;
        this._enableEdges = false;
        this._maxScrollDuration = 1500;
    }
    Object.defineProperty(ScrollableComponent.prototype, "height", {
        set: function (v) {
            if (typeof v === 'number') {
                this.heightFormatted = v + 'px';
            }
            else {
                this.heightFormatted = v;
            }
            this._height = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollableComponent.prototype, "maxHeight", {
        set: function (v) {
            if (typeof v === 'number') {
                this.maxHeightFormatted = v + 'px';
            }
            else {
                this.maxHeightFormatted = v;
            }
            this._maxHeight = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollableComponent.prototype, "minHeight", {
        set: function (v) {
            if (typeof v === 'number') {
                this.minHeightFormatted = v + 'px';
            }
            else {
                this.minHeightFormatted = v;
            }
            this._minHeight = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollableComponent.prototype, "maxScrollDuration", {
        get: function () {
            return this._maxScrollDuration;
        },
        set: function (v) {
            this._maxScrollDuration = coerceNumberProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollableComponent.prototype, "enableEdges", {
        get: function () {
            return this._enableEdges;
        },
        set: function (v) {
            this._enableEdges = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    ScrollableComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO: optimize
        if (this.enableEdges) {
            this._scrollTopSubscription$ = this.areaRefScrollable
                .elementScrolled()
                .pipe(map(function () { return _this.areaRef.nativeElement.scrollTop; }), startWith(0), distinctUntilChanged())
                .subscribe(function (scrollTop) {
                _this.scrollTop = scrollTop;
                _this.areaHeight = _this.areaRef.nativeElement.scrollHeight;
                _this.clientHeight = _this.areaRef.nativeElement.clientHeight;
                _this.refreshEdges();
            });
        }
    };
    ScrollableComponent.prototype.ngOnDestroy = function () {
        if (this._scrollTopSubscription$) {
            this._scrollTopSubscription$.unsubscribe();
        }
    };
    ScrollableComponent.prototype.scrollToBottom = function (duration) {
        if (duration === undefined) {
            duration = this._maxScrollDuration;
        }
        this.scrollTo(this.areaRef.nativeElement.scrollHeight + 1000, undefined, duration);
    };
    ScrollableComponent.prototype.scrollToTop = function () {
        this.scrollTo(0);
    };
    ScrollableComponent.prototype.scrollTo = function (offset, onComplete, duration) {
        if (onComplete === void 0) { onComplete = function () { return undefined; }; }
        animateScrollTo(offset, {
            element: this.areaRef.nativeElement,
            maxDuration: duration !== undefined
                ? duration
                : this._maxScrollDuration,
            minDuration: duration !== undefined
                ? 0
                : 250,
            onComplete: onComplete
        });
    };
    ScrollableComponent.prototype.refreshEdges = function (hard) {
        if (hard === void 0) { hard = false; }
        if (hard) {
            this.onTopEdge = false;
            this.onBottomEdge = true;
            this.scrollTop = this.areaRef.nativeElement.scrollTop;
            this._cdRef.detectChanges();
        }
        if (this.scrollTop > 2) {
            if (!this.onTopEdge) {
                this.onTopEdge = true;
                this._cdRef.detectChanges();
            }
        }
        else {
            if (this.onTopEdge) {
                this.onTopEdge = false;
                this._cdRef.detectChanges();
            }
        }
        if ((this.scrollTop + this.clientHeight) < (this.areaHeight - 2)) {
            if (!this.onBottomEdge) {
                this.onBottomEdge = true;
                this._cdRef.detectChanges();
            }
        }
        else {
            if (this.onBottomEdge && this.areaHeight !== this.clientHeight) {
                this.onBottomEdge = false;
                this._cdRef.detectChanges();
            }
        }
    };
    return ScrollableComponent;
}());
export { ScrollableComponent };
