import * as tslib_1 from "tslib";
import { LocalStorageService } from '@app/services';
export var STORAGE_NAMESPACE = 'kvm';
export var STORAGE_KEY = 'consoles';
var KvmConsoleConnectionService = /** @class */ (function () {
    function KvmConsoleConnectionService(_localStorage) {
        this._localStorage = _localStorage;
    }
    KvmConsoleConnectionService.prototype.start = function (consoleType, serverId, serverName, data) {
        if (!!consoleType && !!serverId && !!data) {
            if (!this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY)) {
                // set new localStorage space and console
                this._setConsole(consoleType, serverId, serverName, data);
            }
            else {
                var consoleData = this._getConsoleData(consoleType + ":" + serverId);
                // console exists
                if (!!consoleData) {
                    this._useConsole(tslib_1.__assign({}, consoleData, { kvmAccessLink: data.kvmAccessLink, kvmFileLink: data.kvmFileLink }));
                }
                else {
                    // console does not exist, create new
                    this._setConsole(consoleType, serverId, serverName, data);
                }
            }
        }
    };
    KvmConsoleConnectionService.prototype.removeConsole = function (consoleType, serverId) {
        var consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY);
        if (!!consolesMap && consolesMap[consoleType + ":" + serverId]) {
            delete consolesMap[consoleType + ":" + serverId];
            this._localStorage.set(STORAGE_NAMESPACE, STORAGE_KEY, tslib_1.__assign({}, consolesMap));
        }
    };
    KvmConsoleConnectionService.prototype._setConsole = function (consoleType, serverId, serverName, data) {
        var _a;
        var consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY);
        this._localStorage.set(STORAGE_NAMESPACE, STORAGE_KEY, tslib_1.__assign({}, consolesMap, (_a = {}, _a[consoleType + ":" + serverId] = tslib_1.__assign({}, data, { consoleType: consoleType, created: new Date(), serverId: serverId,
            serverName: serverName }), _a)));
        var consoleData = this._getConsoleData(consoleType + ":" + serverId);
        this._useConsole(consoleData);
    };
    KvmConsoleConnectionService.prototype._getConsoleData = function (key) {
        var consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY);
        if (!!consolesMap && consolesMap[key]) {
            return consolesMap[key];
        }
        else {
            return undefined;
        }
    };
    KvmConsoleConnectionService.prototype._useConsole = function (consoleData) {
        if (!!consoleData) {
            if (!!consoleData.kvmFileLink) {
                location.replace(consoleData.kvmFileLink);
            }
            else {
                if (!!consoleData.kvmAccessLink) {
                    window.open(consoleData.kvmAccessLink);
                }
            }
        }
    };
    return KvmConsoleConnectionService;
}());
export { KvmConsoleConnectionService };
