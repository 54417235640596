import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffect } from './settings.effect';
import { reducer } from './settings.reducer';
import { ModuleTokens } from './settings.constant';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ SettingsEffect ])
  ]
})
export class SettingsModule {

}
