import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ActionsSubject } from '@ngrx/store';
import { FormGroupState, MarkAsSubmittedAction } from 'ngrx-forms';

@Component({
  selector: 'vshcz-form',
  templateUrl: './form.component.html',
  styleUrls: [ './form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent<FS extends { [key: string]: any; }> {

  @Input()
  state: FormGroupState<FS>;

  @Input()
  set preventEnterSubmit(v) {
    this._preventEnterSubmit = coerceBooleanProperty(v);
  }

  get preventEnterSubmit() {
    return this._preventEnterSubmit;
  }

  @Output()
  submit = new EventEmitter<Event>();

  @Output()
  submitError = new EventEmitter<{
    offset: number;
    element: HTMLElement;
  }>();

  @ViewChild('formElRef', { read: ElementRef })
  formElRef: ElementRef;

  private _preventEnterSubmit = false;

  constructor(
    private _actions: ActionsSubject,
    public elementRef: ElementRef
  ) { }

  triggerSubmit() {
    const e = new Event('submit', {
      bubbles: true,
      cancelable: true
    });

    this.formElRef.nativeElement.dispatchEvent(e);
  }

  _onSubmit(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.submit.emit(e);

    if (this.state && this.state.isUnsubmitted) {
      this._actions.next(new MarkAsSubmittedAction(this.state.id));
    }

    setTimeout(() => {
      const errs = this._getFirstErrorInput();

      if (errs) {
        this.submitError.emit(errs);
      }

    });

  }

  _onEnter(e: Event) {
    if (this.preventEnterSubmit) {
      e.preventDefault();
    }
  }

  private _getFirstErrorInput() {
    const errFields: NodeList = this.elementRef.nativeElement.getElementsByClassName('mat-form-field');

    let errField: HTMLElement;
    for (let i = 0; i < errFields.length; ++i) {
      const field = errFields[i] as HTMLElement;

      if (field.classList.contains('mat-form-field-invalid')) {
        errField = field;
        break;
      }
    }

    if (errField) {
      const _inputFields = errField.getElementsByTagName('input');
      let _input;
      if (_inputFields) {
        _input = _inputFields[0];
      }

      return {
        offset: errField.offsetTop,
        element: _input
      };

    }
  }
}
