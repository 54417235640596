import * as tslib_1 from "tslib";
import { createFormGroupState, SetValueAction, updateGroup, createFormStateReducerWithUpdate, markAsSubmitted, markAsUnsubmitted, formStateReducer } from 'ngrx-forms';
export var ngrxFormsExtensionFormState = function (key, values, updateFns) {
    if (values === void 0) { values = {}; }
    if (updateFns === void 0) { updateFns = {}; }
    return updateGroup(createFormGroupState(key, values), updateFns);
};
export var applyNgrxFormsExtensionsReducer = function (formKey, state, action, defaultSettings) {
    if (defaultSettings === void 0) { defaultSettings = {}; }
    var _a;
    var formReducer = createFormStateReducerWithUpdate(updateGroup(defaultSettings));
    var form = formReducer(state.forms[formKey], action);
    if (form !== state.forms[formKey]) {
        return tslib_1.__assign({}, state, { forms: tslib_1.__assign({}, state.forms, (_a = {}, _a[formKey] = form, _a)) });
    }
    return state;
};
export var applyNgrxFormsExtensionsUnsafeReducer = function (formKey, state, action, defaultSettings) {
    if (defaultSettings === void 0) { defaultSettings = {}; }
    var _a;
    var updateFn = updateGroup(defaultSettings);
    var form = updateFn(formStateReducer(state.forms[formKey], action));
    if (form !== state.forms[formKey]) {
        return tslib_1.__assign({}, state, { forms: tslib_1.__assign({}, state.forms, (_a = {}, _a[formKey] = form, _a)) });
    }
    return state;
};
var ngrxFormExtensionsSelector = function (key) {
    return function (s) { return s.forms[key]; };
};
var ɵ0 = ngrxFormExtensionsSelector;
var ngrxFormExtensionsValueSelector = function (key) {
    return function (s) { return s.forms[key].value; };
};
var ɵ1 = ngrxFormExtensionsValueSelector;
var NgrxFormExtensionModel = /** @class */ (function () {
    function NgrxFormExtensionModel(formKey) {
        this.formKey = formKey;
    }
    NgrxFormExtensionModel.prototype.createFormState = function (id, defaultValues, userValues, updateFns) {
        if (defaultValues === void 0) { defaultValues = {}; }
        if (userValues === void 0) { userValues = {}; }
        if (updateFns === void 0) { updateFns = {}; }
        return ngrxFormsExtensionFormState(this.getKey(id), tslib_1.__assign({}, defaultValues, userValues), updateFns);
    };
    NgrxFormExtensionModel.prototype.reduceAction = function (state, action, id, defaultSettings) {
        return applyNgrxFormsExtensionsReducer(this.getKey(id), state, action, defaultSettings);
    };
    NgrxFormExtensionModel.prototype.reduceUnsafeAction = function (state, action, id, defaultSettings) {
        return applyNgrxFormsExtensionsUnsafeReducer(this.getKey(id), state, action, defaultSettings);
    };
    NgrxFormExtensionModel.prototype.selectState = function (id) {
        return ngrxFormExtensionsSelector(this.getKey(id));
    };
    NgrxFormExtensionModel.prototype.selectValue = function (id) {
        return ngrxFormExtensionsValueSelector(this.getKey(id));
    };
    NgrxFormExtensionModel.prototype.markAsSubmitted = function (id, state) {
        var _a;
        var key = this.getKey(id);
        return _a = {},
            _a[key] = markAsSubmitted(state.forms[key]),
            _a;
    };
    NgrxFormExtensionModel.prototype.markAsUnsubmitted = function (id, state) {
        var _a;
        var key = this.getKey(id);
        return _a = {},
            _a[key] = markAsUnsubmitted(state.forms[key]),
            _a;
    };
    NgrxFormExtensionModel.prototype.updateGroupValue = function (id, value) {
        return new SetValueAction(this.formKey + "__" + id, value);
    };
    NgrxFormExtensionModel.prototype.updateGroupState = function (state, id, updateFns) {
        var _a;
        var key = this.getKey(id);
        return _a = {},
            _a[key] = updateGroup(state.forms[key], updateFns),
            _a;
    };
    NgrxFormExtensionModel.prototype.getKey = function (id) {
        return this.formKey + "__" + id;
    };
    return NgrxFormExtensionModel;
}());
export { NgrxFormExtensionModel };
export { ɵ0, ɵ1 };
