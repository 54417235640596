import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invoicesEntities, InvoicesBaseModuleTokens } from '@app/base/invoices-base';
import { PaymentsState, PaymentEntity } from './payments-base.model';
import { ModuleTokens } from './payments-base.constant';
import { denormalizeList } from './payments-base.utils';

export const slice = createFeatureSelector<PaymentsState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  entities,
  invoicesEntities,
  (ids, ents, invEnts) => {

    if (!ents || !invEnts) { return undefined; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name],
      invEnts[InvoicesBaseModuleTokens.Name]
    ) as PaymentEntity[];
  }
);
