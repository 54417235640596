import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ticketsBaseEntities,
  ticketsBaseDenormalizeEntity,
  TicketsBaseModuleTokens,
  TicketsEntity
} from '@app/base/tickets-base';
import {
  ticketTextFormValueSelector,
  ticketTextFormStateSelector
} from '@app/common/ticket-text-form';
import {
  priceOfferRejectFormStateSelector,
  priceOfferRejectFormValueSelector
} from '@app/common/ticket-price-offer';
import {
  realizationDateRejectFormStateSelector,
  realizationDateRejectFormValueSelector
} from '@app/common/ticket-realization-date';
import { TicketsTriggerState } from './tickets-trigger.model';
import { ModuleTokens } from './tickets-trigger.constant';

export const slice = createFeatureSelector<TicketsTriggerState>(ModuleTokens.Name);

export const state = createSelector(slice, (s) => s.state);

export const detailId = createSelector(slice, (s) => s.detailId);

export const detailData = createSelector(
  detailId,
  ticketsBaseEntities,
  (id, ents) => {
    return ticketsBaseDenormalizeEntity(
      id,
      ents[TicketsBaseModuleTokens.Name],
      ents[TicketsBaseModuleTokens.Messages]
    ) as TicketsEntity;
  }
);

export const ticketDetailTicketTextFormState = createSelector(
  slice,
  ticketTextFormStateSelector(ModuleTokens.Name)
);

export const ticketDetailTicketTextFormValue = createSelector(
  slice,
  ticketTextFormValueSelector(ModuleTokens.Name)
);

export const open = createSelector(
  slice,
  (s) => s.open
);

export const showTrigger = createSelector(
  slice,
  (s) => s.showTrigger
);
