import { Action } from '@zerops/fe/core';
import { ErrorsAction, ProgressAction, ErrorsActionMeta, ProgressActionMeta, ApiError } from '@zerops/fe/ngrx/zerops-fe-ngrx';
import { KvmStatus, KvmCommands } from '@app/base/servers-base';
import { KvmConsoleTypes, KvmConsoleData } from './kvm-settings.model';

export enum ActionTypes {
  // detail id
  SetDetailId = '[Kvm Settings] Set Detail Id',
  // KVM status
  KvmStatusRequest = '[Kvm Settings] KVM Status Request',
  KvmStatusLocalSuccess = '[Kvm Settings] KVM Status Local Success',
  KvmStatusFail = '[Kvm Settings] KVM Status Fail',
  // KVM run command
  KvmRunCommandRequest = '[Kvm Settings] KVM Run Command Request',
  KvmRunCommandLocalSuccess = '[Kvm Settings] KVM Run Command Local Success',
  KvmRunCommandFail = '[Kvm Settings] KVM Run Command Fail',
  // KVM console start
  KvmConsoleStartRequest = '[Kvm Settings] KVM Console Sart Request',
  KvmConsoleStartLocalSuccess = '[Kvm Settings] KVM Console Sart Local Success',
  KvmConsoleStartFail = '[Kvm Settings] KVM Console Sart Fail',
  // reset
  Reset = '[Kvm Settings] reset'
}

// detail
export class SetDetailId implements Action {
  readonly type = ActionTypes.SetDetailId;

  constructor(public payload: string) {}
}

// KVM status
export class KvmStatusRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmStatusRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.KvmStatusFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.KvmStatusRequest,
      type: 'local'
    }
  };
}

export class KvmStatusLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.KvmStatusLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmStatusRequest
  };

  constructor(public payload: KvmStatus) {}
}

export class KvmStatusFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmStatusFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmStatusRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.KvmStatusFail,
        type: 'local',
        data
      }
    };
  }
}

// KVM run command
export class KvmRunCommandRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmRunCommandRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.KvmRunCommandFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.KvmRunCommandRequest,
      type: 'local'
    }
  };

  constructor(public payload: KvmCommands) {}
}

export class KvmRunCommandLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.KvmRunCommandLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmRunCommandRequest
  };
}

export class KvmRunCommandFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmRunCommandFail;
  errors: ErrorsActionMeta;
  meta: string;
  progress: ProgressActionMeta = {
    remove: ActionTypes.KvmRunCommandRequest
  };

  constructor(data: ApiError) {
    this.meta = data.message;
    this.errors = {
      add: {
        key: ActionTypes.KvmRunCommandFail,
        type: 'local',
        data
      }
    };
  }
}

// KVM console start
export class KvmConsoleStartRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmConsoleStartRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.KvmConsoleStartFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: `${ActionTypes.KvmConsoleStartRequest}`,
      type: 'local'
    }
  };

  constructor(public payload: KvmConsoleTypes, public meta: { serverId: string; serverName?: string; }) {}
}

export class KvmConsoleStartLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.KvmConsoleStartLocalSuccess;
  progress: ProgressActionMeta = {
    remove: `${ActionTypes.KvmConsoleStartRequest}`
  };

  constructor(
    public payload: KvmConsoleData,
    public meta: { consoleType: KvmConsoleTypes; serverId: string; serverName?: string; }
  ) {}
}

export class KvmConsoleStartFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.KvmConsoleStartFail;
  errors: ErrorsActionMeta;
  meta: string;
  progress: ProgressActionMeta = {
    remove: `${ActionTypes.KvmConsoleStartRequest}`
  };

  constructor(data: ApiError) {
    this.meta = data.message;
    this.errors = {
      add: {
        key: ActionTypes.KvmRunCommandFail,
        type: 'local',
        data
      }
    };
  }
}

export class Reset implements Action {
  readonly type = ActionTypes.Reset;
}

export type Actions
  = SetDetailId
  | KvmStatusRequest
  | KvmStatusFail
  | KvmStatusLocalSuccess
  | KvmRunCommandRequest
  | KvmRunCommandFail
  | KvmRunCommandLocalSuccess
  | KvmConsoleStartRequest
  | KvmConsoleStartLocalSuccess
  | KvmConsoleStartFail
  | Reset;
