import { FormGroupState } from 'ngrx-forms';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { formState } from './ticket-rating.forms';
import { ModuleTokens } from './ticket-rating.constant';

export class TicketRatingState implements HasNgrxForm {
  forms = {
    ...formState(ModuleTokens.Name)()
  };
}

export class TicketRatingFormValue {
  speed: number;
  expertise: number;
  communication: number;
  message?: string;
}

export type TicketRatingFormState = FormGroupState<TicketRatingFormValue>;
