import { AuthActionTypes, AuthActions } from '@app/base/auth-base';
import { Actions as SettingsActions } from './settings.action';
import { SettingsState } from './settings.model';
import { ActionTypes } from './settings.action';

const initialState = new SettingsState();

type Actions = SettingsActions | AuthActions;

export function reducer(
  state = initialState,
  action: Actions
) {
  if (action.type === ActionTypes.LoadLocalSuccess) {
    return {
      ...state,
      ...action.payload
    };
  }

  // reset state
  if (action.type === AuthActionTypes.InvalidateAuthorization) {
    return { ...initialState };
  }

  return state;

}
