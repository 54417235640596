import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Reset } from '@zerops/fe/core';
import { Error, RemoveError } from '@zerops/fe/ngrx';
import { TranslateService } from '@ngx-translate/core';
import {
  map,
  tap,
  filter,
  switchMap,
  withLatestFrom,
  catchError,
  delay
} from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { Go } from '@app/common/ngrx-router';
import { SnackService } from '@app/common/snack';
import { ErrorTranslationService } from '@app/services';
import { State } from '@app/models';
import {
  ActionTypes,
  SetActiveClient,
  CheckSavedToken,
  Authorize,
  InvalidateAuthorization,
  SetLanguage,
  SendPasswordChangeRequest,
  SendPasswordChangeLocalSuccess,
  SendPasswordChangeFail,
  ResetReferer,
  SetReferer,
  ExternalAuthConnectRequest,
  ExternalAuthConnectFail,
  ExternalAuthConnectLocalSuccess,
  ExternalAuthConnectValidateRequest,
  ExternalAuthConnectValidateLocalSuccess,
  ExternalAuthConnectValidateFail,
  ExternalAuthDeleteRequest,
  ExternalAuthDeleteLocalSuccess,
  ExternalAuthDeleteFail,
  ExternalAuthLoginRequest,
  ExternalAuthLoginLocalSuccess,
  ExternalAuthLoginFail,
  ExternalAuthLoginValidateRequest,
  ExternalAuthLoginValidateLocalSuccess,
  ExternalAuthLoginValidateFail
} from './auth-base.action';
import {
  saveAuth,
  getStoredAuth,
  saveSelectedClient,
  getSelectedClient,
  removeStoredAuthAndClient,
  updateStoredEntities
} from './auth-base.utils';
import { AuthBaseApi } from './auth-base.api';
import { identity, referer } from './auth-base.selector';

@Injectable()
export class AuthBaseEffect {

  private _onAuthorize$ = this._actions$.pipe(
    ofType<Authorize>(ActionTypes.Authorize)
  );
  private _externalAuthConnectValidateSuccess$ = this._actions$.pipe(
    ofType<ExternalAuthConnectValidateLocalSuccess>(ActionTypes.ExternalAuthConnectValidateLocalSuccess)
  );
  private _externalAuthConnectValidateFail$ = this._actions$.pipe(
    ofType<ExternalAuthConnectValidateFail>(ActionTypes.ExternalAuthConnectValidateFail)
  );
  private _externalAuthLoginValidateSuccess$ = this._actions$.pipe(
    ofType<ExternalAuthLoginValidateLocalSuccess>(ActionTypes.ExternalAuthLoginValidateLocalSuccess)
  );
  private _externalAuthLoginValidateFail$ = this._actions$.pipe(
    ofType<ExternalAuthLoginValidateFail>(ActionTypes.ExternalAuthLoginValidateFail)
  );
  private _onExternalAuthDeleteSuccess$ = this._actions$.pipe(
    ofType<ExternalAuthDeleteLocalSuccess>(ActionTypes.ExternalAuthDeleteLocalSuccess)
  );

  @Effect()
  private _onCheckSavedToken$ = this._actions$.pipe(
    ofType<CheckSavedToken>(ActionTypes.CheckSavedToken),
    map(() => {
      const data = getStoredAuth();
      const clientId = getSelectedClient();

      // user was not logged in
      // or didn't select the clientId
      if (!data || !data.auth.accessToken || !clientId) {
        return new InvalidateAuthorization();
      }

      // authorize is handled inside "login-as-client"
      if (window.location.href.includes('qmsxauweag')) { return; }

      return new Authorize(
        data.entities,
        data.userId,
        data.auth,
        clientId,
        false
      );
    }),
    filter((action) => !!action)
  );

  // set referer when accessing page unauthorized
  // window.location.pathname is the only way to get the referer path at this point
  @Effect()
  private _onCheckSavedTokenFailSetReferer$ = this._actions$.pipe(
    ofType<CheckSavedToken>(ActionTypes.CheckSavedToken),
    map(() => {
      const data = getStoredAuth();
      const clientId = getSelectedClient();

      // TODO: refactor .includes to some more system solution
      if ((!data || !data.auth.accessToken || !clientId)
        && !window.location.pathname.includes('login')
        && !window.location.pathname.includes('password-recovery')
        && !window.location.pathname.includes('forgotten-password')
        && !window.location.pathname.includes('invitation')) {
        return new SetReferer(window.location.pathname);
      }

    }),
    filter((action) => !!action)
  );

  @Effect()
  private _onAuthorizeUpdateLocalStorage$ = this._onAuthorize$.pipe(
    tap(({ payload }) => {
      saveAuth(payload.auth, payload.entities, payload.userId);
      if (payload.clientId) {
        saveSelectedClient(payload.clientId);
      }
    }),
    withLatestFrom(this._store.pipe(select(referer))),
    map(([ { payload }, refererPath ]) => payload.redirect
      ? new Go({ path: refererPath ? [ refererPath ] : [ '/dashboard' ] })
      : undefined
    ),
    filter((action) => !!action)
  );

  @Effect()
  private _onAuthorizeSetLanguage$ = this._onAuthorize$.pipe(
    map(({ payload }) => new SetLanguage(payload.user.language.id))
  );

  @Effect({ dispatch: false })
  private _onAuthorizeSetSentryMeta$ = this._onAuthorize$.pipe(
    tap(({ payload }) => {
      Sentry.configureScope((scope) => {
        scope.setUser({ 'email': payload.user.email });
      });
    })
  );

  @Effect({ dispatch: false })
  private _onSetLanguage$ = this._actions$.pipe(
    ofType<SetLanguage>(ActionTypes.SetLanguage),
    tap(({ payload }) => {
      this._translate.use(payload);
    })
  );

  @Effect()
  private _onInvalidateAuthorizationRedirect$ = this._actions$.pipe(
    ofType<InvalidateAuthorization>(ActionTypes.InvalidateAuthorization),
    map(({ payload }) => payload
      ? new Go({ path: ['/login'] })
      : undefined
    ),
    filter((action) => !!action)
  );

  // sent post to delete access token from REDIS then delete auth
  @Effect({ dispatch: false })
  private _onInvalidateAuthorizationApiLogout$ = this._actions$.pipe(
    ofType<InvalidateAuthorization>(ActionTypes.InvalidateAuthorization),
    filter(() => !!getStoredAuth()),
    map(() => getStoredAuth().auth.accessToken),
    tap(() => removeStoredAuthAndClient()),
    switchMap((token) => this._api
      .logout$(token)
      .pipe(catchError(() => EMPTY))
    )
  );

  @Effect()
  private _onInvalidateAuthorizationReset$ = this._actions$.pipe(
    ofType<InvalidateAuthorization>(ActionTypes.InvalidateAuthorization),
    map(() => new Reset())
  );

  @Effect()
  private _onSetActiveClient$ = this._actions$.pipe(
    ofType<SetActiveClient>(ActionTypes.SetActiveClient),
    tap(({ payload }) => saveSelectedClient(payload)),
    withLatestFrom(this._store.pipe(select(referer))),
    map(([ { meta }, refererPath ]) => {
      const path = meta
        ? [ refererPath ? refererPath : '/dashboard', { reload: true } ]
        : [ refererPath ? refererPath : '/dashboard' ];
      return new Go({ path });
    }),
    filter((action) => !!action)
  );

  @Effect()
  private _onSetActiveClientResetReferer$ = this._actions$.pipe(
    ofType<SetActiveClient>(ActionTypes.SetActiveClient),
    map(() => new ResetReferer()),
  );

  @Effect()
  private _onSendPasswordChangeRequest$ = this._actions$.pipe(
    ofType<SendPasswordChangeRequest>(ActionTypes.SendPasswordChangeRequest),
    withLatestFrom(this._store.pipe(select(identity))),
    switchMap(([ _, { email } ]) => this._api
      .recoverPassword$(email)
      .pipe(
        map(() => new SendPasswordChangeLocalSuccess()),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new SendPasswordChangeFail(data)))
        )
      )
    )
  );

  @Effect()
  private _onSendPasswordChangeFail$ = this._actions$.pipe(
    ofType<SendPasswordChangeFail>(ActionTypes.SendPasswordChangeFail),
    switchMap(({ errors }) => this._translate
      .get('common.close')
      .pipe((map((translation) => {
        const er = errors.add as Error;
        return {
          close: translation,
          message: er.data.message
        };
      })))
    ),
    switchMap(({ close, message }) => this._snack
      .fail$(message, close)
      .pipe(map(() => new RemoveError(ActionTypes.SendPasswordChangeFail)))
    )
  );

  @Effect({ dispatch: false })
  private _onSendPasswordChangeSuccess$ = this._actions$.pipe(
    ofType<SendPasswordChangeLocalSuccess>(ActionTypes.SendPasswordChangeLocalSuccess),
    switchMap(() => this._translate
      .get([
        'common.close',
        'authBase.passwordChangeSuccessSnack'
      ])
      .pipe(
        switchMap((translations) => this._snack.success$(
          translations['authBase.passwordChangeSuccessSnack'],
          translations['common.close']
        ))
      )
    )
  );

  // -- external auth
  // -- login
  @Effect()
  private _onExternalAuthLoginRequest$ = this._actions$.pipe(
    ofType<ExternalAuthLoginRequest>(ActionTypes.ExternalAuthLoginRequest),
    switchMap(({ payload }) => this._api
      .externalAuthLogin$(payload)
      .pipe(
        map(({ authUrl }) => new ExternalAuthLoginLocalSuccess(authUrl)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ExternalAuthLoginFail(data)))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  private _onExternalAuthLoginAuthRedirect$ = this._actions$.pipe(
    ofType<ExternalAuthLoginLocalSuccess>(ActionTypes.ExternalAuthLoginLocalSuccess),
    delay(100),
    tap(({ payload }) => {
      window.location.href = payload;
    })
  );

  @Effect()
  private _onExternalAuthLoginFail$ = this._actions$.pipe(
    ofType<ExternalAuthLoginFail>(ActionTypes.ExternalAuthLoginFail),
    switchMap((action) => this._snack.translatedFail$(
      action.payload.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ExternalAuthLoginFail))
  );

  // -- login validate
  @Effect()
  private _onExternalAuthLoginValidateRequest$ = this._actions$.pipe(
    ofType<ExternalAuthLoginValidateRequest>(ActionTypes.ExternalAuthLoginValidateRequest),
    switchMap(({ payload }) => this._api
      .externalAuthLoginValidate$(payload.type, payload.code, payload.state)
      .pipe(
        map((res) => new ExternalAuthLoginValidateLocalSuccess(res)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ExternalAuthLoginValidateFail(data)))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  private _onExternalAuthLoginValidateSuccessUpdateLocalStorage$ = this._externalAuthLoginValidateSuccess$.pipe(
    tap(({ payload }) => updateStoredEntities(payload.entities))
  );

  @Effect()
  private _onExternalAuthLoginValidateSuccessAuthorize$ = this._externalAuthLoginValidateSuccess$.pipe(
    map(({ payload }) => new Authorize(
      payload.entities,
      payload.userId,
      payload.auth
    ))
  );

  @Effect()
  private _onExternalAuthLoginValidateSuccessRedirect$ = this._externalAuthLoginValidateSuccess$.pipe(
    map(() => new Go({ path: [ '/dashboard' ]}))
  );

  @Effect()
  private _onExternalAuthLoginValidateFail$ = this._externalAuthLoginValidateFail$.pipe(
    switchMap((action) => this._snack.translatedFail$(
      action.payload.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ExternalAuthLoginValidateFail))
  );

  @Effect({ dispatch: false })
  private _onExternalAuthLoginValidateShowHint$ = this._externalAuthLoginValidateSuccess$.pipe(
    filter((d) => d.payload.showHint),
    delay(150),
    switchMap(() => this._translate
      .get([
        'common.close',
        'authBase.emailLoginDisabledHint'
      ])
      .pipe(
        switchMap((translations) => this._snack.info$(
          translations['authBase.emailLoginDisabledHint'],
          translations['common.close']
        ))
      )
    )
  );

  @Effect()
  private _onExternalAuthLoginValidateFailRedirect$ = this._externalAuthLoginValidateFail$.pipe(
    map(() => new Go({ path: [ '/login' ]}))
  );

  // -- connect
  @Effect()
  private _onExternalAuthConnectRequest$ = this._actions$.pipe(
    ofType<ExternalAuthConnectRequest>(ActionTypes.ExternalAuthConnectRequest),
    switchMap(({ payload }) => this._api
      .externalAuthConnect$(payload)
      .pipe(
        map((res) => new ExternalAuthConnectLocalSuccess(res.authUrl, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ExternalAuthConnectFail(data, payload)))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  private _onExternalAuthConnectAuthRedirect$ = this._actions$.pipe(
    ofType<ExternalAuthConnectLocalSuccess>(ActionTypes.ExternalAuthConnectLocalSuccess),
    delay(100),
    tap(({ payload }) => {
      window.location.href = payload;
    })
  );

  @Effect()
  private _onExternalAuthConnectFail$ = this._actions$.pipe(
    ofType<ExternalAuthConnectFail>(ActionTypes.ExternalAuthConnectFail),
    switchMap((action) => this._snack.translatedFail$(
      action.payload.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ExternalAuthConnectFail))
  );

  // -- connect validate
  @Effect()
  private _onExternalAuthConnectValidateRequest$ = this._actions$.pipe(
    ofType<ExternalAuthConnectValidateRequest>(ActionTypes.ExternalAuthConnectValidateRequest),
    switchMap(({ payload }) => this._api
      .externalAuthConnectValidate$(payload.type, payload.code, payload.state)
      .pipe(
        map((res) => new ExternalAuthConnectValidateLocalSuccess(res)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ExternalAuthConnectValidateFail(data)))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  private _onExternalAuthConnectValidateSuccessUpdateLocalStorage$ = this._externalAuthConnectValidateSuccess$.pipe(
    tap(({ payload }) => updateStoredEntities(payload.entities))
  );

  @Effect({ dispatch: false })
  private _onExternalAuthConnectValidateSuccessShowSnack$ = this._externalAuthConnectValidateSuccess$.pipe(
    switchMap(() => this._snack.translatedSuccess$(
      'authBase.externalAuthValidationSuccess',
      'common.close'
    ))
  );

  @Effect()
  private _onExternalAuthConnectValidateSuccessRedirect$ = this._externalAuthConnectValidateSuccess$.pipe(
    map(() => new Go({ path: [ '/profile' ]}))
  );

  @Effect()
  private _onExternalAuthConnectValidateFail$ = this._externalAuthConnectValidateFail$.pipe(
    switchMap((action) => this._snack.translatedFail$(
      action.payload.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ExternalAuthConnectValidateFail))
  );

  @Effect()
  private _onExternalAuthConnectValidateFailRedirect$ = this._externalAuthConnectValidateFail$.pipe(
    map(() => new Go({ path: [ '/login' ]}))
  );

  // -- remove
  @Effect()
  private _onExternalAuthRemoveRequest$ = this._actions$.pipe(
    ofType<ExternalAuthDeleteRequest>(ActionTypes.ExternalAuthDeleteRequest),
    switchMap(({ payload }) => this._api
      .externalAuthDelete$(payload)
      .pipe(
        map((res) => new ExternalAuthDeleteLocalSuccess(res, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ExternalAuthDeleteFail(data, payload)))
        )
      )
    )
  );

  @Effect()
  private _onExternalAuthDeleteFail$ = this._actions$.pipe(
    ofType<ExternalAuthDeleteFail>(ActionTypes.ExternalAuthDeleteFail),
    switchMap((action) => this._snack.translatedFail$(
      action.payload.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ExternalAuthDeleteFail))
  );

  @Effect({ dispatch: false })
  private _onExternalAuthDeleteSuccessShowSnack$ = this._onExternalAuthDeleteSuccess$.pipe(
    switchMap(() => this._snack.translatedSuccess$(
      'authBase.externalAuthDeleteSuccess',
      'common.close'
    ))
  );

  @Effect({ dispatch: false })
  private _onExternalAuthDeleteSuccessUpdateLocalStorage$ = this._onExternalAuthDeleteSuccess$.pipe(
    tap(({ payload }) => updateStoredEntities(payload.entities))
  );

  constructor(
    private _actions$: Actions,
    private _api: AuthBaseApi,
    private _store: Store<State>,
    private _snack: SnackService,
    private _errorTranslation: ErrorTranslationService,
    private _translate: TranslateService
  ) { }
}
