import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StarFieldComponent } from './star-field.component';

@NgModule({
  declarations: [ StarFieldComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ StarFieldComponent ]
})
export class StarFieldModule {

}
