import { normalize, denormalize } from 'normalizr';
import { clientServicesListSchema } from '@app/schemas';
import {
  ClientServiceEntityLight,
  ClientServiceEntityBase
} from './client-services-base.model';
import { ModuleTokens } from './client-services-base.constant';

export function normalizeList(data: ClientServiceEntityBase[] | ClientServiceEntityLight[]) {
  return normalize(data, clientServicesListSchema);
}

export function denormalizeList(
  ids: string[],
  invoicesEntities: any
) {
  return denormalize(
    ids,
    clientServicesListSchema,
    {
      [ModuleTokens.Name]: invoicesEntities
    }
  );
}
