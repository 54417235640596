import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { EntityBadgeModule } from '@app/common/entity-badge';
import { SearchTableComponent } from './search-table.component';

@NgModule({
  declarations: [ SearchTableComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatSortModule,
    TranslateModule.forChild(),
    EntityBadgeModule
  ],
  exports: [ SearchTableComponent ]
})
export class SearchTableModule {

}
