import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressItemModule } from '@app/common/progress-item';
import { WatchTicketComponent } from './watch-ticket.component';

@NgModule({
  declarations: [ WatchTicketComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    ProgressItemModule,
    TranslateModule.forChild()
  ],
  exports: [ WatchTicketComponent ]
})
export class WatchTicketModule { }
