import { EmailFormTranslations } from './email-form.model';

const _prefix = 'email-form_';

export function generateTranslationKeys(prefix = _prefix) {
  return {
    labels: {
      email: prefix + 'labels_email'
    },
    placeholders: {
      email: prefix + 'placeholder_email'
    },
    hints: {
      email: prefix + 'hints_email'
    },
    errors: {
      email: {
        required: prefix + 'errors_email_required',
        email: prefix + 'errors_email_email'
      }
    }
  };
}

export const DEFAULT_TRANSLATION_KEYS: EmailFormTranslations = generateTranslationKeys();
