import { NgrxFormExtensionModel } from '@app/common/ngrx-forms-extensions';
import { formKey } from './email-form.constant';
export var formModel = new NgrxFormExtensionModel(formKey);
export var getFormKey = function (id) { return formModel.getKey(id); };
export var formState = function (id) { return function (defaultValues, updateFns) {
    if (defaultValues === void 0) { defaultValues = {}; }
    if (updateFns === void 0) { updateFns = {}; }
    var _a;
    return (_a = {},
        _a[getFormKey(id)] = formModel.createFormState(id, {
            email: undefined
        }, defaultValues, updateFns),
        _a);
}; };
