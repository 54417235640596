import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '@app/common/price';
import { PaymentStatusModule } from '@app/common/payment-status';
import { PaymentItemComponent } from './payment-item.component';

@NgModule({
  declarations: [ PaymentItemComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule.forChild(),
    PriceModule,
    PaymentStatusModule
  ],
  exports: [ PaymentItemComponent ]
})
export class PaymentItemModule { }
