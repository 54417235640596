<zps-dialog
  [open]="isActive$ | async"
  [backdropClass]="'c-recaptcha__backdrop'">
  <div class="__content">

    <h2 class="mat-h2">
      <span translate="recaptcha.title"></span>
    </h2>

    <p class="__desc" translate="recaptcha.desc"></p>

    <vshcz-progress-item
      [spinnerSize]="20"
      class="__progress-item"
      [active]="isLoading">
      <re-captcha
        class="__captcha"
        (resolved)="onResolve$.next($event)"
        [siteKey]="siteKey">
      </re-captcha>
    </vshcz-progress-item>

    <p class="__footer" translate="recaptcha.footer"></p>

  </div>
</zps-dialog>
