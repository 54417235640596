<!-- TODO: detail as sync value -->
<mat-card
  class="__list  mat-elevation-z5"
  [class.is-mobile]="media.isActive('lt-md')"
  (mouseenter)="onCardHover$.next(true)"
  (mouseleave)="onCardHover$.next(false)"
  *ngIf="open$ | async"
  @ticketsTrigger>

  <!-- top right floating close button  -->
  <button
    mat-mini-fab
    class="__close-button  mat-elevation-z1"
    @ticketsTriggerCloseButton
    type="button"
    (click)="onClose$.next()"
    *ngIf="closeShown$ | async">
    <mat-icon>close</mat-icon>
  </button>

  <div
    class="__content-wrap"
    [ngSwitch]="state$ | async"
    [style.height.px]="height">

    <!-- DASHBOARD STATE -->
    <div
      class="__dashboard"
      *ngSwitchCase="states.Dashboard"
      fxLayout="row wrap"
      fxLayoutAlign="center center">
      <div fxFlex>

        <vshcz-content-title
          size="big"
          class="__support-title">
          <span translate="ticketsTrigger.title"></span>
        </vshcz-content-title>

        <!-- contacts -->
        <div
          class="__contacts"
          fxLayout="row wrap"
          fxLayoutAlign="center start">

          <!-- manager / technical user -->
          <ng-container *ngIf="(activeClientUser$ | async)?.roleCode === clientUserRoles.Manager
            || (activeClientUser$ | async)?.roleCode === clientUserRoles.TechnicalUser">
            <div [fxFlex]="(activeClientUser$ | async)?.roleCode === clientUserRoles.TechnicalUser ? 100 : 50">
              <vshcz-contact
                [title]="'ticketsTrigger.technicalSupport' | translate"
                [phonePrefix]="'ticketsTrigger.phonePrefix' | translate"
                [phone]="'ticketsTrigger.phoneSupport' | translate"
                [text]="'ticketsTrigger.technicalSupportDesc' | translate"
                [mail]="'ticketsTrigger.mailSupport' | translate">
              </vshcz-contact>
            </div>
          </ng-container>

          <!-- manager / financial user -->
          <ng-container *ngIf="(activeClientUser$ | async)?.roleCode === clientUserRoles.Manager
            || (activeClientUser$ | async)?.roleCode === clientUserRoles.FinancialUser ">
            <div [fxFlex]="50">

              <!-- no salesman -->
              <vshcz-contact
                *ngIf="!(activeClientSalesman$ | async)"
                [title]="'ticketsTrigger.salesDepartment' | translate"
                [phonePrefix]="'ticketsTrigger.phonePrefix' | translate"
                [phone]="'ticketsTrigger.phoneSupport' | translate"
                [mail]="'ticketsTrigger.mailSalesDepartment' | translate">
              </vshcz-contact>

              <!-- with salesman -->
              <vshcz-contact
                *ngIf="(activeClientSalesman$ | async) as salesman"
                [title]="'ticketsTrigger.salesDepartment' | translate"
                [name]="salesman.fullName"
                [phonePrefix]="salesman.countryCallingCode"
                [phone]="salesman.phoneNumber"
                [mail]="salesman.email">
              </vshcz-contact>

            </div>
          </ng-container>

        </div>

        <!-- topics -->
        <button
          mat-raised-button
          class="__topics"
          color="primary"
          class="__add-ticket-button  __topics"
          [vshczPopoverAnchorFor]="ticketTopicPopInnerRef"
          (click)="!!ticketTopicPopInnerRef.open()">
          <mat-icon>add_circle_outline</mat-icon>&nbsp;
          <span translate="ticketsTrigger.topicSelect"></span>
          &nbsp;<mat-icon>arrow_drop_down</mat-icon>
        </button>

        <span class="c-or">
          <span
            class="c-or__text"
            translate="ticketsTrigger.or">
          </span>
        </span>

        <!-- go to list -->
        <div>
          <button
            class="__ticket-history-button"
            mat-button
            color="accent"
            (click)="!!onSetState$.next({ state: states.List })">
            <mat-icon>all_inbox</mat-icon>
             <span translate="ticketsTrigger.showHistory"></span>
          </button>
        </div>

      </div>
    </div>

    <!-- LIST STATE -->
    <div *ngSwitchCase="states.List">

      <div
        class="__header"
        [style.height.px]="headerHeight">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center center">

          <!-- back button -->
          <div fxFlex="50px">
            <button
              class="__back-button"
              mat-icon-button
              (click)="!!onSetState$.next({ state: states.Dashboard })">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
          </div>

          <!-- list filters -->
          <div fxFlex>
            <vshcz-tickets-filter></vshcz-tickets-filter>
          </div>

          <div
            fxFlex="40px"
            class="u-text--right">
            <a
              class="__open-in-new-link"
              target="_blank"
              [routerLink]="[ '/tickets' ]"
              [matTooltip]="'common.openInNew' | translate">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>

        </div>
      </div>

      <!-- list of tickets -->
      <vshcz-tickets-list
        (selected)="onSetState$.next({
          state: states.Detail,
          meta: $event.id
        })"
        [height]="height - headerHeight">
      </vshcz-tickets-list>

    </div>

    <!-- ADD STATE -->
    <div *ngSwitchCase="states.Add">

      <div
        class="__header"
        [style.height.px]="headerHeight">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center center">

          <!-- back button -->
          <div fxFlex="50px">
            <button
              class="__back-button"
              mat-icon-button
              (click)="!!onSetState$.next({ state: states.Dashboard })">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
          </div>

          <!-- title + topic -->
          <div
            fxFlex
            [innerHTML]="'ticketsTrigger.newTicket'
              | translate: { topic: selectedAddTopic$ | async }">
          </div>
        </div>
      </div>

      <!-- ticket add form -->
      <div class="__add">
        <vshcz-ticket-add></vshcz-ticket-add>
      </div>

    </div>

    <!-- detail -->
    <div *ngSwitchCase="states.Detail">

      <div
        class="__header"
        [style.height.px]="headerHeight">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center center">

          <!-- back button -->
          <div fxFlex="50px">
            <button
              class="__back-button"
              mat-icon-button
              (click)="!!onSetState$.next({ state: states.List })">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
          </div>

          <!-- ticket title -->
          <div
            fxFlex
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            class="__ticket_title_wrap">
            <ng-container *ngIf="detail">

              <strong class="__code">{{ detail?.code }}:</strong>

              <vshcz-ticket-state
                class="__state"
                stickerOnly
                [state]="detail?.clientZoneStatus">
              </vshcz-ticket-state>

              <a
                class="__ticket-link"
                target="_blank"
                [routerLink]="[ '/tickets', 'detail', detail?.id ]">
                {{ detail?.title }} <mat-icon class="__ticket-link__icon">open_in_new</mat-icon>
              </a>

            </ng-container>
          </div>

          <!-- ticket actions -->
          <div
            fxFlex="255px"
            class="u-text--right"
            *ngIf="detail">

            <button
              *ngIf="detail?.adminUserAssignLock
                && detail?.clientZoneStatus !== 'CLOSED'"
              mat-icon-button
              [matTooltip]="'ticketUnlock.tooltip' | translate"
              matTooltipClass="c-search-tooltip"
              [vshczPopoverAnchorFor]="unlockPopRef"
              (click)="!!unlockPopRef.toggle()">
              <mat-icon>lock</mat-icon>
            </button>

            <button
              mat-icon-button
              *ngIf="detail?.clientZoneStatus !== 'CLOSED'"
              [matTooltip]="'ticketsTrigger.subscriptionTooltip' | translate"
              [vshczPopoverAnchorFor]="subscriptionPopRef"
              (click)="!!subscriptionPopRef.toggle()">
              <mat-icon>add_alert</mat-icon>
            </button>

            <vshcz-watch-ticket
              *ngIf="(detail$ | async)?.clientZoneStatus !== ticketStatuses.Closed"
              [loading]="watchLoading$ | async"
              [authUserEmail]="(identity$ | async)?.email"
              [ticketAssignedUserEmail]="(detail$ | async)?.assignedUserEmail"
              [ticketCopyList]="(detail$ | async)?.copyList"
              (clicked)="onToggleWatchTicket$.next()">
            </vshcz-watch-ticket>

          </div>

        </div>
      </div>

      <!-- ticket detail messages -->
      <vshcz-ticket-detail
        *ngIf="detail"
        [height]="(height - headerHeight - detailMessageHeight)
          - (detail?.attachedPriceOffer === attachedPriceOfferStates.Unconfirmed ? ticketPriceOfferHeight : 0)
          - (detail?.clientZoneStatus === ticketStatuses.Resolved ? ticketRaitingHeight : 0)
          + (detail?.clientZoneStatus === ticketStatuses.Closed ? detailMessageHeight : 0)
          - (detail?.realizationDateWaitingConfirmation && detail?.clientZoneStatus === ticketStatuses.WaitingForClient ? ticketRealizationDateHeight : 0)"
        [id]="detail?.id">
      </vshcz-ticket-detail>

      <!-- authorization required -->
      <vshcz-ticket-authorization
        class="__authorization"
        [id]="detail?.id"
        *ngIf="detail?.clientZoneStatus === ticketStatuses.WaitingForAuthorisation">
      </vshcz-ticket-authorization>

      <!--
        add ticket message,
        only visible if status is not
        waiting authorization and closed
      -->
      <div
        class="__submit-form"
        [class.is-hidden]="detail?.clientZoneStatus === ticketStatuses.Closed
          || detail?.clientZoneStatus === ticketStatuses.WaitingForAuthorisation"
        [class.is-resolved]="detail?.clientZoneStatus === ticketStatuses.Resolved"
        #submitFormRef>

        <!-- rating -->
        <vshcz-ticket-rating
          class="__rating"
          [id]="detail?.id"
          [active]="detail?.clientZoneStatus === ticketStatuses.Resolved">
        </vshcz-ticket-rating>

        <!-- price offer -->
        <vshcz-ticket-price-offer
          class="__price-offer"
          [id]="detail?.id"
          [active]="detail?.attachedPriceOffer === attachedPriceOfferStates.Unconfirmed">
        </vshcz-ticket-price-offer>

        <!-- ticket realization date -->
        <vshcz-ticket-realization-date
          class="__realization-date"
          [id]="detail?.id"
          [active]="detail?.realizationDateWaitingConfirmation && detail?.clientZoneStatus === ticketStatuses.WaitingForClient">
        </vshcz-ticket-realization-date>

        <vshcz-form
          *ngIf="detail
            && detail?.clientZoneStatus !== ticketStatuses.Closed
            && detail?.clientZoneStatus !== ticketStatuses.WaitingForAuthorisation"
          #formElRef
          (submit)="onSendMessage$.next(detail?.id)"
          class="__submit-form_form">

          <vshcz-ticket-text-form
            [state]="ticketUpdateTextFormState$ | async"
            (sizeChange)="setSizes($event)">
          </vshcz-ticket-text-form>

          <div class="__submit-form_actions">

            <vshcz-files
              [maxCount]="6"
              [maxSize]="4000000"
              [instanceId]="uploadIntanceId$ | async">
            </vshcz-files>

            <button
              mat-icon-button
              color="accent"
              type="submit">
              <mat-icon>send</mat-icon>
            </button>

          </div>

        </vshcz-form>

      </div>

    </div>

  </div>

</mat-card>

<!-- main floating fixed ticket button -->
<div
  class="__trigger-button  mat-elevation-z8"
  [class.is-open]="open$ | async">

  <button
    matRipple
    [vshczPopoverAnchorFor]="ticketTopicPopOuterRef"
    matTooltipClass="c-search-tooltip"
    (click)="!!ticketTopicPopOuterRef.open()"
    [matTooltip]="'ticketsTrigger.supportAddButtonTooltip' | translate"
    class="__trigger-alt">
    <mat-icon class="__trigger-alt_icon">add_circle_outline</mat-icon>
  </button>

  <button
    class="__trigger-main"
    [class.is-mobile]="media.isActive('lt-md')"
    type="button"
    matRipple
    (click)="onToggle$.next()">

    <span
      class="__trigger-main_text"
      translate="ticketsTrigger.supportButton">
    </span>

    <div class="__trigger-main_icon">
      <mat-icon class="__icon  __icon--support">contact_support</mat-icon>
      <mat-icon class="__icon  __icon--close">close</mat-icon>
    </div>

  </button>
</div>

<!-- unlock pop -->
<vshcz-popover
  #unlockPopRef
  hasBackdrop
  verticalAlign="below">
  <mat-card class="__unlock-pop-card  mat-elevation-z5">

    <h2 class="mat-h3">
      <span translate="ticketUnlock.heading"></span>
    </h2>

    <div>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="onUnlock$.next()">
        <mat-icon>lock_open</mat-icon>&nbsp;
        <span translate="ticketUnlock.confirmButton"></span>
      </button>

      &nbsp;

      <button mat-button (click)="!!unlockPopRef.close()">
        <span translate="common.close"></span>
      </button>

    </div>

    <div class="__unlock-pop-card_divider">
      <mat-divider></mat-divider>
    </div>

    <div
      class="__unlock-pop-card_desc"
      [innerHTML]="'ticketUnlock.desc' | translate">
    </div>

  </mat-card>
</vshcz-popover>

<!-- subscriptions pop -->
<vshcz-popover
  #subscriptionPopRef
  hasBackdrop
  [autoFocus]="false"
  horizontalAlign="start"
  verticalAlign="below">
  <mat-card class="__card  __ticket-copy-list-pop-card mat-elevation-z5">
    <h3
      class="mat-h3  __ticket-copy-list_title"
      translate="ticketsTrigger.copyList">
    </h3>
    <p
      class="mat-body  __ticket-copy-list_desc"
      translate="ticketsTrigger.copyListDesc">
    </p>
    <vshcz-ticket-copy-list [id]="detail?.id">
    </vshcz-ticket-copy-list>
  </mat-card>
</vshcz-popover>

<!-- ticket topic pop inner -->
<vshcz-popover
  #ticketTopicPopInnerRef
  hasBackdrop
  horizontalAlign="start"
  verticalAlign="below">
  <vshcz-ticket-topic-list
    [groupedTopics]="ticketTopicsGrouped$ | async"
    (selected)="onSetState$.next({ state: states.Add, meta: $event })">
  </vshcz-ticket-topic-list>
</vshcz-popover>

<!-- ticket topic pop outer -->
<vshcz-popover
  #ticketTopicPopOuterRef
  hasBackdrop
  [autoFocus]="false"
  (afterOpen)="preventFocusRestore(ticketTopicPopOuterRef)"
  horizontalAlign="start"
  verticalAlign="below">
  <vshcz-ticket-topic-list
    [groupedTopics]="ticketTopicsGrouped$ | async"
    (selected)="ticketTopicPopOuterRef.close(); onSetState$.next({ state: states.Add, meta: $event })">
  </vshcz-ticket-topic-list>
</vshcz-popover>
