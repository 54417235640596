export enum TicketStatuses {
  Accepted = 'ACCEPTED',
  Closed = 'CLOSED',
  // Planned = 'PLANNED',
  Resolved = 'RESOLVED',
  InRealisation = 'IN_REALISATION',
  WaitingForClient = 'WAITING_FOR_CLIENT',
  WaitingForAuthorisation = 'WAITING_AUTHORIZATION'
}

export const TicketStatusesPriorityMap = {
  [TicketStatuses.WaitingForAuthorisation]: 4,
  [TicketStatuses.WaitingForClient]: 3,
  [TicketStatuses.InRealisation]: 2,
  [TicketStatuses.Accepted]: 1,
  // [TicketStatuses.Planned]: 1,
  [TicketStatuses.Resolved]: 1,
  [TicketStatuses.Closed]: 1
};
