import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SearchApi = /** @class */ (function () {
    function SearchApi(_http) {
        this._http = _http;
    }
    SearchApi.prototype.suggest$ = function (keyword, clientId) {
        return this._http.get("/api/public/client/" + clientId + "/fulltext/suggest?content=" + keyword);
    };
    SearchApi.prototype.search$ = function (keyword, clientId) {
        return this._http.get("/api/public/client/" + clientId + "/fulltext/search?content=" + keyword);
    };
    SearchApi.ngInjectableDef = i0.defineInjectable({ factory: function SearchApi_Factory() { return new SearchApi(i0.inject(i1.HttpClient)); }, token: SearchApi, providedIn: "root" });
    return SearchApi;
}());
export { SearchApi };
