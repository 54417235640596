import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import { mergeMap, map, catchError, filter, switchMap } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { SnackService } from '@app/common/snack';
import { ServersBaseApi } from './servers-base.api';
import { ActionTypes, AddIpV4LocalSuccess, AddIpV4Fail, DeleteIpFail, DeleteIpLocalSuccess, AddIpV6LocalSuccess, AddIpV6Fail, SavePtrRequest, SavePtrLocalSuccess, SavePtrFail } from './servers-base-ip.action';
var ServersIpBaseEffect = /** @class */ (function () {
    function ServersIpBaseEffect(_actions$, _api, _store, _snack, _errorTranslation) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._store = _store;
        this._snack = _snack;
        this._errorTranslation = _errorTranslation;
        // add ipv4
        this._onAddIpV4Request$ = this._actions$.pipe(ofType(ActionTypes.AddIpV4Request), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .addIpV4$(payload.serverId, payload.ipId)
                .pipe(map(function (response) { return new AddIpV4LocalSuccess(response, payload.serverId, payload.ptr, payload.ipId, payload.ip); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new AddIpV4Fail(data, payload.serverId); })); }));
        }));
        // add ipv6
        this._onAddIpV6Request$ = this._actions$.pipe(ofType(ActionTypes.AddIpV6Request), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .addIpV6$(payload.serverId, payload.ip)
                .pipe(map(function (response) { return new AddIpV6LocalSuccess(response, payload.serverId, payload.ptr, payload.ip); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new AddIpV6Fail(data, payload.serverId); })); }));
        }));
        this._onAddIpV6V4LocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.AddIpV6LocalSuccess, ActionTypes.AddIpV4LocalSuccess), filter(function (_a) {
            var meta = _a.meta;
            return !!(meta && meta.ptr && meta.id);
        }), map(function (_a) {
            var meta = _a.meta;
            return new SavePtrRequest({
                ptr: meta.ptr,
                serverId: meta.serverId,
                id: meta.id,
                ip: meta.ip
            });
        }));
        // remove ip
        this._onDeleteIpRequest$ = this._actions$.pipe(ofType(ActionTypes.DeleteIpRequest), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .removeIp$(payload.serverId, payload.id)
                .pipe(map(function (response) { return new DeleteIpLocalSuccess(response, payload.id, payload.ip, payload.type); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new DeleteIpFail(data, payload.id); })); }));
        }));
        this._onSavePtrRequest$ = this._actions$.pipe(ofType(ActionTypes.SavePtrRequest), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .savePtr$(payload.serverId, payload.id, payload.ptr)
                .pipe(map(function (response) { return new SavePtrLocalSuccess(response, payload.id, payload.ip); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new SavePtrFail(data, payload.id); })); }));
        }));
        // snacks
        this._onAddIpV4LocalSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.AddIpV4LocalSuccess), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedSuccess$('serversBase.ip4AddSuccessSnack', 'common.close', {
                ip: meta.ip
            });
        }));
        this._onAddIpV6LocalSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.AddIpV6LocalSuccess), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedSuccess$('serversBase.ip6AddSuccessSnack', 'common.close', {
                ip: meta.ip
            });
        }));
        this._onDeleteIpLocalSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.DeleteIpLocalSuccess), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedSuccess$('serversBase.ipDeleteSuccessSnack', 'common.close', {
                ip: meta.ip
            });
        }));
        this._onUpdatePtrLocalSuccessSnack$ = this._actions$.pipe(ofType(ActionTypes.SavePtrLocalSuccess), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedSuccess$('serversBase.ptrSaveSuccessSnack', 'common.close', {
                ip: meta
            });
        }));
        this._onDeleteIpFailSnack$ = this._actions$.pipe(ofType(ActionTypes.DeleteIpFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta.message, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.DeleteIpFail); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onAddIpV4Request$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onAddIpV6Request$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onAddIpV6V4LocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onDeleteIpRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onSavePtrRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onAddIpV4LocalSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onAddIpV6LocalSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onDeleteIpLocalSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onUpdatePtrLocalSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServersIpBaseEffect.prototype, "_onDeleteIpFailSnack$", void 0);
    return ServersIpBaseEffect;
}());
export { ServersIpBaseEffect };
