<h3 class="__name">

  <strong class="__code">{{ data.code }}:</strong> {{ data.title }}

  <vshcz-ticket-state
    class="__state"
    [state]="data.clientZoneStatus">
  </vshcz-ticket-state>

</h3>

<div
  class="__serration"
  *ngIf="data.publicMessageCount > 1">
  <div class="__serration_bg"></div>
  <div class="__serration_content">
    <span class="__serration_text">
      {{ more }}
    </span>
  </div>
</div>

<vshcz-ticket-message
  [created]="data.lastUpdate"
  [avatarTooltip]="data.lastTicketMessageAuthor ? data.lastTicketMessageAuthor.fullName : 'VSHosting'"
  [avatarSource]="data.lastTicketMessageAuthor ? 'gravatar' : 'image'"
  [avatarMail]="data.lastTicketMessageAuthor ? data.lastTicketMessageAuthor.email : undefined"
  [avatarImage]="'/assets/images/logo.png'"
  [align]="'left'"
  [isPrimary]="data.lastTicketMessageAuthor"
  [signature]="!!data.ticketMessageList
    && !!data.ticketMessageList.length
    && data.ticketMessageList[data.ticketMessageList.length - 1].signature"
  [text]="data.lastTicketMessageText | truncate: truncateMessage">
</vshcz-ticket-message>
