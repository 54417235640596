import { ElementRef, QueryList, AfterViewInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { BASE_UNIT } from '@app/constants';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { map, startWith } from 'rxjs/operators';
var MenuPopComponent = /** @class */ (function () {
    function MenuPopComponent(_ruler) {
        var _this = this;
        this._ruler = _ruler;
        this._sizeMap = {};
        this.baseSizes = {
            width: 380,
            height: 400
        };
        this.bgScaleX = 1;
        this.bgScaleY = 1;
        this.appBarHeight = 63;
        this.leave = new EventEmitter();
        this.companySelectToggle = new EventEmitter();
        this.close = new EventEmitter();
        this.viewportHeight$ = this._ruler.change(100).pipe(map(function () { return _this._ruler.getViewportSize().height; }), startWith(this._ruler.getViewportSize().height));
        this.contentHeight$ = this.viewportHeight$.pipe(map(function (height) { return height - _this.appBarHeight - 50; }));
    }
    Object.defineProperty(MenuPopComponent.prototype, "classIsActive", {
        get: function () {
            return this.open;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuPopComponent.prototype, "classIsPristine", {
        get: function () {
            return this._isPristine;
        },
        enumerable: true,
        configurable: true
    });
    MenuPopComponent.prototype.onMouseleave = function (e) {
        this.leave.emit(e.target);
    };
    MenuPopComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var curIndex = changes && changes.activeIndex
            ? changes.activeIndex.currentValue
            : undefined;
        if (curIndex !== undefined && this._sizeMap[curIndex]) {
            var _a = this._sizeMap[curIndex], width = _a.width, height = _a.height;
            var coordinates = changes.coordinates.currentValue;
            var newOffset = coordinates.xCenter - (this._sizeMap[curIndex].width / 2);
            this.contentOffset = newOffset > BASE_UNIT ? newOffset : BASE_UNIT;
            this.bgScaleX = width / this.baseSizes.width;
            this.bgScaleY = height / this.baseSizes.height;
        }
        if (!this.open) {
            this._isPristine = true;
        }
        else {
            // hack to delay inner animations
            setTimeout(function () {
                _this._isPristine = false;
            });
        }
    };
    MenuPopComponent.prototype.ngAfterViewInit = function () {
        this._setSizeMap();
    };
    MenuPopComponent.prototype.refresh = function () {
        this._setSizeMap();
    };
    MenuPopComponent.prototype._setSizeMap = function () {
        var _this = this;
        this.itemRefs.forEach(function (element, i) {
            _this._sizeMap[i] = {
                width: element.nativeElement.clientWidth,
                height: element.nativeElement.clientHeight
            };
        });
    };
    return MenuPopComponent;
}());
export { MenuPopComponent };
