/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./payment-status.component";
var styles_PaymentStatusComponent = [i0.styles];
var RenderType_PaymentStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentStatusComponent, data: {} });
export { RenderType_PaymentStatusComponent as RenderType_PaymentStatusComponent };
export function View_PaymentStatusComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "span", [["class", "__status-icon"], ["matTooltipClass", "c-search-tooltip"], ["matTooltipPosition", "after"], ["matTooltipShowDelay", "400"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "__status-icon--green": 0, "__status-icon--orange": 1 }), i1.ɵdid(3, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { position: [0, "position"], disabled: [1, "disabled"], showDelay: [2, "showDelay"], message: [3, "message"], tooltipClass: [4, "tooltipClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "__status-icon"; var currVal_1 = _ck(_v, 2, 0, (_co.status === "OK"), (_co.status === "AMOUNT_NOT_MATCHED")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "after"; var currVal_3 = !_co.tooltip; var currVal_4 = "400"; var currVal_5 = _co.tooltip; var currVal_6 = "c-search-tooltip"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_PaymentStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-payment-status", [], null, null, null, View_PaymentStatusComponent_0, RenderType_PaymentStatusComponent)), i1.ɵdid(1, 49152, null, 0, i10.PaymentStatusComponent, [], null, null)], null, null); }
var PaymentStatusComponentNgFactory = i1.ɵccf("vshcz-payment-status", i10.PaymentStatusComponent, View_PaymentStatusComponent_Host_0, { tooltip: "tooltip", status: "status" }, {}, []);
export { PaymentStatusComponentNgFactory as PaymentStatusComponentNgFactory };
