import * as tslib_1 from "tslib";
import { applyEntities } from '@zerops/fe/core';
import { DomainsBaseState } from './domains-base.model';
import { ModuleTokens } from './domains-base.constant';
import { ActionTypes } from './domains-base.action';
var initialState = new DomainsBaseState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c;
    state = applyEntities(state, [
        ModuleTokens.Name,
        ModuleTokens.ActiveRecords,
        ModuleTokens.LastRecords
    ], action);
    switch (action.type) {
        case ActionTypes.ListLocalSuccess:
            return tslib_1.__assign({}, state, { list: action.payload.result });
        case ActionTypes.SoaRequestLocalSuccess:
            return tslib_1.__assign({}, state, { soaMap: tslib_1.__assign({}, state.soaMap, (_a = {}, _a[action.payload.domainId] = action.payload, _a)) });
        case ActionTypes.SoaUpdateLocalSuccess:
            return tslib_1.__assign({}, state, { soaMap: tslib_1.__assign({}, state.soaMap, (_b = {}, _b[action.payload.domainId] = action.payload, _b)) });
        case ActionTypes.DnssecKeyLocalSuccess:
            return tslib_1.__assign({}, state, { dnssecKeys: tslib_1.__assign({}, state.dnssecKeys, (_c = {}, _c[action.meta] = action.payload, _c)) });
    }
    return state;
}
