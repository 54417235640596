import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ContentTitleModule } from '../content-title';
import { ScrollableModule } from '../scrollable';
import { DomainRoutingInfoDialogContainer } from './domain-routing-info-dialog.container';

@NgModule({
  declarations: [ DomainRoutingInfoDialogContainer ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    DialogModule,
    TranslateModule.forChild(),
    ContentTitleModule,
    ScrollableModule
  ],
  exports: [ DomainRoutingInfoDialogContainer ]
})
export class DomainRoutingInfoDialogModule {
}
