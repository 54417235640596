<vshcz-form-field last>
  <mat-form-field [floatLabel]="floatLabel">

    <mat-label>
      <span [translate]="translations.labels.email"></span>
    </mat-label>

    <input
      type="email"
      matInput
      [placeholder]="showPlaceholder
        ? (translations.placeholders.email | translate)
        : undefined"
      [ngrxEnableFocusTracking]="true"
      [ngrxFormControlState]="state.controls.email"
    />

    <mat-hint *ngIf="translations?.hints?.email && showHint">
      <span [translate]="translations.hints.email"></span>
    </mat-hint>

    <mat-error *ngIf="state.errors._email?.required">
      <span [translate]="translations.errors.email.required"></span>
    </mat-error>

    <mat-error *ngIf="state.errors._email?.email">
      <span [translate]="translations.errors.email.email"></span>
    </mat-error>

  </mat-form-field>
</vshcz-form-field>
