import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState, Currency } from './settings.model';
import { ModuleTokens } from './settings.constant';
import { HashMap } from 'utils';

export const slice = createFeatureSelector<SettingsState>(ModuleTokens.Name);

export const languages = createSelector(
  slice,
  (s) => s.languageList
);

export const countries = createSelector(
  slice,
  (s) => s.countryList
);

export const currencies = createSelector(
  slice,
  (s) => s.currencyList
);

export const currencyMap = createSelector(
  currencies,
  (c) => c.reduce((obj, curr) => {
    obj[curr.id] = curr;
    return obj;
  }, {}) as HashMap<Currency>
);
