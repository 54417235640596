import {
  ProgressAction,
  ErrorsAction,
  ProgressActionMeta,
  ErrorsActionMeta,
  ApiError
} from '@zerops/fe/ngrx';

export enum ActionTypes {
  LoadRequest = '[Settings] Load Request',
  LoadFail = '[Settings] Load Fail',
  LoadLocalSuccess = '[Settings] Load Local Success'
}

export class LoadRequest implements ProgressAction, ErrorsAction {
  readonly type = ActionTypes.LoadRequest;
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.LoadRequest,
      type: 'local'
    }
  };
  errors: ErrorsActionMeta = {
    remove: ActionTypes.LoadFail
  };
}

export class LoadFail implements ProgressAction, ErrorsAction {
  readonly type = ActionTypes.LoadFail;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LoadRequest
  };
  errors: ErrorsActionMeta;

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.LoadFail,
        type: 'global',
        data
      }
    };
  }
}

export class LoadLocalSuccess implements ProgressAction, ErrorsAction {
  readonly type = ActionTypes.LoadLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.LoadRequest
  };
  errors: ErrorsActionMeta = {
    remove: ActionTypes.LoadFail
  };

  constructor(public payload) { }
}

export type Actions
  = LoadRequest
  | LoadFail
  | LoadLocalSuccess;
