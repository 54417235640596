import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModuleTokens } from './domains-base.constant';
import { DomainRecordBaseState } from './domain-record-base.model';

export const slice = createFeatureSelector<DomainRecordBaseState>(ModuleTokens.ActiveRecords);

export const recordsMaps = createSelector(
  slice,
  (s) => s.recordsMap
);

export const recordById = (id: string) => createSelector(
  recordsMaps,
  (records) => records[id]
);
