import { normalize, denormalize } from 'normalizr';
import { ticketsListSchema, ticketsSchema } from '@app/schemas';
import { UserEntity } from '@app/base/users-base';
import {
  TicketsEntity,
  TicketsEntityLight,
  TicketsEntityNormalizedData
} from './tickets-base.model';
import { ModuleTokens } from './tickets-base.constant';

export function normalizeList(data: TicketsEntity[] | TicketsEntityLight[]): TicketsEntityNormalizedData {
  return normalize(data, ticketsListSchema);
}

export function denormalizeEntity(
  id: string,
  ticketsEntities: any,
  ticketMessagesEntities: any
) {
  return denormalize(
    id,
    ticketsSchema,
    {
      [ModuleTokens.Name]: ticketsEntities,
      [ModuleTokens.Messages]: ticketMessagesEntities
    }
  );
}

export function denormalizeList(
  ids: string[],
  ticketsEntities: any,
  ticketMessagesEntities: any
) {
  return denormalize(
    ids,
    ticketsListSchema,
    {
      [ModuleTokens.Name]: ticketsEntities,
      [ModuleTokens.Messages]: ticketMessagesEntities
    }
  );
}

export function createTempTicketEntities(
  ticketId: string,
  messageId: string,
  message: string,
  user: UserEntity
) {
  return {
    ticketsBase: {
      [ticketId]: {
        id: ticketId,
        ticketMessageList: [ messageId ]
      }
    },
    ticketsMessagesBase: {
      [messageId]: {
        id: messageId,
        messageText: message,
        display: 'USER',
        created: new Date(),
        ticketId,
        author: {
          email: user.email,
          fullName: user.fullName
        }
      }
    }
  };
}
