import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from '@app/common/avatar';
import { AvatarGroupModule } from '@app/common/avatar-group';
import { PopoverModule } from '../popover';
import { CompanySelectModule } from '../company-select';
import { CompanyCardContainer } from './company-card.container';

@NgModule({
  declarations: [ CompanyCardContainer ],
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    AvatarModule,
    AvatarGroupModule,
    PopoverModule,
    CompanySelectModule
  ],
  exports: [ CompanyCardContainer ]
})
export class CompanyCardModule { }
