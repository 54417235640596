import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RemoveError } from '@zerops/fe/ngrx';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom, switchMap, catchError, tap } from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { ServerParkAccessApi } from './server-park-access-base.api';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail,
  DeleteRequest,
  DeleteLocalSuccess,
  DeleteFail
} from './server-park-access-base.action';

@Injectable()
export class ServerParkAccessListEffect {
  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .list$(id)
      .pipe(
        map(({ clientPersonServerParkAccess }) => new ListLocalSuccess(
          clientPersonServerParkAccess
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  );

  @Effect()
  private _onDeleteRequest$ = this._actions$.pipe(
    ofType<DeleteRequest>(ActionTypes.DeleteRequest),
    switchMap(({ payload, meta }) => this._api
      .delete$(payload)
      .pipe(
        map(() => new DeleteLocalSuccess(payload, meta)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new DeleteFail(data, payload)))
        )
      )
    )
  );

  @Effect()
  private _onDeleteFail$ = this._actions$.pipe(
    ofType<DeleteFail>(ActionTypes.DeleteFail),
    switchMap((action) => this._snack.translatedFail$(
      action.meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DeleteFail))
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: ServerParkAccessApi,
    private _snack: SnackService,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
