<div class="__content" [class.is-active]="active">
  <ng-content></ng-content>
</div>

<mat-spinner
  *ngIf="active"
  [color]="'accent'"
  [diameter]="spinnerSize"
  [strokeWidth]="strokeWidth"
  class="__spinner  c-progress-item-spinner">
</mat-spinner>
