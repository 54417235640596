import { Component, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BaseClass } from '@zerops/fe/core';
import { of, Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import groupBy from 'lodash-es/groupBy';
import loMap from 'lodash-es/map';
import sortBy from 'lodash-es/sortBy';
import { Store, select } from '@ngrx/store';
import { State } from '@app/models';
import {
  TicketsListRemoveFromFilter,
  TicketsListResetFilter,
  TicketsListAddToFilter,
  ticketsListFilters
} from '@app/common/tickets-list';
import {
  FilterPopSelection,
  FilterPopItem
} from '@app/common/filter-pop';

@Component({
  selector: 'vshcz-tickets-filter',
  templateUrl: './tickets-filter.container.html',
  styleUrls: [ './tickets-filter.container.scss' ]
})
export class TicketsFilterContainer extends BaseClass {
  // # Event Streams
  onToggleFilter$ = new Subject<FilterPopSelection>();
  onSelectAll$ = new Subject<string[]>();
  onResetFilter$ = new Subject<void>();

  // # Data
  // -- sync
  separatorKeysCodes = [ ENTER, COMMA ];

  // -- async
  // TODO: move to base
  filters$ = of<FilterPopItem[]>([
    { id: 'CLOSED', group: 'closed' },
    // { id: 'PLANNED', group: 'open' },
    { id: 'RESOLVED', group: 'open' },
    { id: 'ACCEPTED', group: 'open' },
    { id: 'IN_REALISATION', group: 'open' },
    { id: 'WAITING_FOR_CLIENT', group: 'open' },
    { id: 'WAITING_AUTHORIZATION', group: 'open' }
  ]);
  groupedFiltersMap$ = this.filters$.pipe(
    map((filters) => groupBy(filters, 'group'))
  );
  groupedFilters$ = this.groupedFiltersMap$.pipe(
    map((data) => loMap(
      data,
      (items, key) => ({
        key,
        items: items.map((itm) => itm.id)
      })
    ))
  );
  activeFilters$ = this._store.pipe(select(ticketsListFilters));
  activeGroup$ = this.activeFilters$.pipe(
    withLatestFrom(this.groupedFilters$),
    map(([ activeFilters, filters ]) => {
      const matchingActiveFilter = filters.find((group) => {
        return sortBy(group.items).toString() === sortBy(activeFilters).toString();
      });

      return matchingActiveFilter ? matchingActiveFilter.key : undefined;
    })
  );

  // -- angular
  @Input()
  maxSize = 2;

  @Input()
  stickerOnly: boolean;

  @Input()
  popHorizontalAlignment = 'start';

  // # Action Streams
  private _toggleFilterAction$ = this.onToggleFilter$.pipe(
    map((selection) => {
      if (selection.state !== true) {
        return new TicketsListRemoveFromFilter(selection.value);
      }

      return new TicketsListAddToFilter(selection.value);
    })
  );
  private _selectAllAction$ = this.onSelectAll$.pipe(
    map((items) => new TicketsListAddToFilter(items, true))
  );
  private _resetFilterAction$ = this.onResetFilter$.pipe(
    map(() => new TicketsListResetFilter())
  );

  constructor(private _store: Store<State>) {
    super();

    // # Store Dispatcher
    merge(
      this._toggleFilterAction$,
      this._resetFilterAction$,
      this._selectAllAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }

  selectAll(items: FilterPopItem[]) {
    this.onSelectAll$.next(items.map((item) => item.id));
  }

}
