import { AfterViewInit, EventEmitter, TemplateRef, OnChanges } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
var FilterPopMultiselectItemDirective = /** @class */ (function () {
    function FilterPopMultiselectItemDirective() {
    }
    return FilterPopMultiselectItemDirective;
}());
export { FilterPopMultiselectItemDirective };
var FilterPopMultiselectGroupDirective = /** @class */ (function () {
    function FilterPopMultiselectGroupDirective() {
    }
    return FilterPopMultiselectGroupDirective;
}());
export { FilterPopMultiselectGroupDirective };
var FilterPopMultiselectComponent = /** @class */ (function () {
    function FilterPopMultiselectComponent() {
        this.checkboxPosition = 'after';
        this.selection = new EventEmitter();
        this._selected = [];
    }
    Object.defineProperty(FilterPopMultiselectComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (v) {
            this._items = v;
            if (v) {
                var map_1 = {};
                this.groupedItems = v.reduce(function (arr, item) {
                    var g = item.group ? item.group : '_';
                    if (map_1[g] === undefined) {
                        map_1[g] = arr.length;
                        arr.push({
                            key: g,
                            items: []
                        });
                    }
                    arr[map_1[g]].items.push(item);
                    return arr;
                }, []);
            }
            else {
                this.groupedItems = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopMultiselectComponent.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (v) {
            this._selected = v;
            this._selectOptions(this._selected);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopMultiselectComponent.prototype, "itemTemplate", {
        get: function () { return this._itemTemplate; },
        set: function (v) {
            this._itemTemplate = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterPopMultiselectComponent.prototype, "groupTemplate", {
        get: function () { return this._groupTemplate; },
        set: function (v) {
            this._groupTemplate = v;
        },
        enumerable: true,
        configurable: true
    });
    FilterPopMultiselectComponent.prototype.ngAfterViewInit = function () {
        this._selectOptions(this._selected);
    };
    FilterPopMultiselectComponent.prototype.ngOnChanges = function () {
        this._selectOptions(this._selected);
    };
    FilterPopMultiselectComponent.prototype.onSelect = function (e) {
        this.selection.emit({
            value: e.option.value,
            state: e.option.selected
        });
    };
    FilterPopMultiselectComponent.prototype.trackBy = function (index) {
        return index;
    };
    FilterPopMultiselectComponent.prototype._selectOptions = function (values) {
        var _this = this;
        if (this.selectionRef && this.selectionRef.options) {
            setTimeout(function () {
                _this.selectionRef.options.forEach(function (itemRef) {
                    if (values.indexOf(itemRef.value) >= 0) {
                        if (!itemRef.selected) {
                            itemRef.toggle();
                        }
                    }
                    else {
                        if (itemRef.selected) {
                            itemRef.toggle();
                        }
                    }
                });
            });
        }
    };
    return FilterPopMultiselectComponent;
}());
export { FilterPopMultiselectComponent };
