export enum ModuleTokens {
  Name = 'domainsBase',
  ActiveRecords = 'domainActiveRecordsBase',
  LastRecords = 'domainLastRecordsBase'
}

export enum DomainStatuses {
  New = 'NEW',
  Active = 'ACTIVE',
  WaitingToTtl =  'WAITING_TO_TTL',
  PublishingToInternet =  'PUBLISHING_TO_INTERNET',
  Warning = 'WARNING',
  NotAvailable = 'DOMAIN_NOT_AVAILABLE',
  NotHeadingToVshosting = 'DOMAIN_NOT_HEADING_TO_VSHOSTING',
  Deleted = 'DELETED',
  Deactivated = 'DEACTIVATED'
}

export const DomainStatusesPriorities = {
  [DomainStatuses.NotHeadingToVshosting]: 9,
  [DomainStatuses.Warning]: 8,
  [DomainStatuses.NotAvailable]: 7,
  [DomainStatuses.WaitingToTtl]: 6,
  [DomainStatuses.New]: 5,
  [DomainStatuses.Deactivated]: 4,
  [DomainStatuses.PublishingToInternet]: 3,
  [DomainStatuses.Active]: 2,
  [DomainStatuses.Deleted]: 1
};
