import { StateUpdateFns } from 'ngrx-forms';
import { NgrxFormExtensionModel } from '@app/common/ngrx-forms-extensions';
import { formKey } from './password-form.constant';
import { PasswordFormValue } from './password-form.model';

export const formModel = new NgrxFormExtensionModel<PasswordFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<PasswordFormValue> = {},
  updateFns: StateUpdateFns<PasswordFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      password: undefined
    },
    defaultValues,
    updateFns
  )
});
