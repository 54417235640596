import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { PopoverComponent } from '@app/common/popover';

@Component({
  selector: 'vshcz-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [ './confirm.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmComponent {

  @Input()
  confirmButton: string;

  @Input()
  confirmIcon: string;

  @Input()
  confirmColor = 'primary';

  @Input()
  closeButton: string;

  @Input()
  heading: string;

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  close = new EventEmitter<void>();

  @ViewChild(PopoverComponent)
  popRef: PopoverComponent;
}
