import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './payments-base.constant';
import { reducer } from './payments-base.reducer';
import { PaymentsBaseEffect } from './payments-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ PaymentsBaseEffect ])
  ]
})
export class PaymentsBaseModule {

}
