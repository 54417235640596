import { Actions, ActionTypes } from './clouddns-websockets.action';
import { ClouddnsWebsocketsState } from './clouddns-websockets.model';

const initialState = new ClouddnsWebsocketsState();

export function reducer(
  state = initialState,
  action: Actions
): ClouddnsWebsocketsState {

  switch (action.type) {

    case ActionTypes.Reconnect:
      return {
        ...state,
        reconnecting: true
      };

    case ActionTypes.Connected:
      return {
        ...state,
        reconnecting: false
      };
  }

  return state;
}
