import { ModuleTokens, DomainStatuses } from './domains-base.constant';
import { DomainRecordStatuses } from './domain-record-base.constant';

export class DomainsBaseState {
  entities: DomainsBaseEntityEntities;
  list: string[];
  soaMap: { [domainId: string]: DomainRecordSoa; };
  dnssecKeys: { [index: string]: DnsSecKeyEntityBase; };

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { },
      [ModuleTokens.ActiveRecords]: { },
      [ModuleTokens.LastRecords]: { }
    };
    this.list = [];
    this.soaMap = {};
    this.dnssecKeys = {};
  }
}

export class DomainsBaseEntityEntities {
  [ModuleTokens.Name]: { [id: string]: DomainEntityNormalized; };
  [ModuleTokens.ActiveRecords]: { [id: string]: DomainRecordSimpleBase; };
  [ModuleTokens.LastRecords]: { [id: string]: DomainRecordSimpleBase; };
}

export class DomainEntityNormalizedData {
  entities: DomainsBaseEntityEntities;
  result: string[];
}

export class DomainEntityLight {
  id: string;
  clientId: string;
  domainName: string;
  status: DomainStatuses;
  dnsSecEnabled: boolean;
  dnsSec: DnsSecEntityBase;
  dnsClientAppId: string;
  activeDomainVersionId: string;
  lastDomainVersionId: string;
  lastDnsCheck: string;
  soaTtl: number;
  soaChanged: boolean;
}

export class DomainEntityBase extends DomainEntityLight {
  connectedDnsClientApp: DnsClientAppEntityBase;
  activeDomainVersion: DomainVersionEntityBase;
  lastDomainVersion: DomainVersionEntityBase;
  lastDomainVersionRecordsChanged: boolean;
}

export class DomainEntityNormalized extends DomainEntityBase {
  activeDomainRecordList: string[];
  lastDomainRecordList: string[];
}

export class DomainEntity extends DomainEntityBase {
  activeDomainRecordList: DomainRecordSimpleBase[];
  lastDomainRecordList: DomainRecordSimpleBase[];
}

export class DomainRecordSimpleBase {
  checksum: string;
  class: string;
  created: string;
  domainId: string;
  domainVersionId: string;
  id: string;
  lastUpdate: string;
  name: string;
  originApplicationType: string;
  originUserFullName: string;
  originalDomainRecordId: string;
  status: DomainRecordStatuses;
  ttl: number;
  type: string;
  value: string;
}

export class DnsClientAppEntityBase {
  id: string;
  clientId: string;
  applicationType: string;
  applicationVersion: string;
  applicationHostname: string;
  created: string;
  lastUpdate: string;
}

export class DnsSecEntityBase {
  enabledDate: string;
  published: boolean;
  publishedDate: string;
  regStatus: string;
  regStatusDate: string;
}

export class DomainRecordSoa {
  domainId: string;
  soaTtl: number;
  soaMname: string;
  soaRname: string;
  soaSerial: number;
  soaRefresh: number;
  soaRetry: number;
  soaExpire: number;
  created: string;
  lastUpdate: string;
}

export class DnsSecKeyEntityBase {
  keytype: string;
  active: boolean;
  dnsKey: string;
  ds: string[];
  algorithm: string;
  bits: number;
}

export class DomainVersionEntityBase {
  id: string;
  domainId: string;
  version: number;
  soaTtl: number;
  soaMname: string;
  soaRname: string;
  soaRefresh: number;
  soaRetry: number;
  soaExpire: number;
  soaMinimum: number;
  recordsChecksum: string;
  checksum: string;
  created: string;
  lastUpdate: string;
}
