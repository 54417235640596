import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientServiceEntityBase } from './client-services-base.model';

@Injectable({
  providedIn: 'root'
})
export class ClientServicesBaseApi {
  constructor(private _http: HttpClient) { }

  list$(clientId: string) {
    return this._http.get<{ clientServiceList: ClientServiceEntityBase[] }>(
      `/api/public/client/${clientId}/client-service`
    );
  }
}
