import { StateUpdateFns } from 'ngrx-forms';
import { Action } from '@ngrx/store';
import { NgrxFormExtensionModel, HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { formKey } from './realization-date-reject-form.constant';
import { RealizationDateRejectFormValue, RealizationDateRejectFormState } from './realization-date-reject-form.model';

export const formModel = new NgrxFormExtensionModel<RealizationDateRejectFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<RealizationDateRejectFormValue> = {},
  updateFns: StateUpdateFns<RealizationDateRejectFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      messageText: undefined
    },
    defaultValues,
    updateFns
  )
});

export const applyFormReducer = <S extends HasNgrxForm>(
  state: S,
  action: Action,
  id: string,
  defaultSettings?: StateUpdateFns<RealizationDateRejectFormValue>
) => formModel.reduceAction(state, action, id, defaultSettings);

export const markAsSubmitted = (id: string, state: any) => formModel.markAsSubmitted(id, state);
export const markAsUnsubmitted = (id: string, state: any) => formModel.markAsUnsubmitted(id, state);
export const formSelector = (id: string) => formModel.selectState<RealizationDateRejectFormState>(id);
export const formValueSelector = (id: string) => formModel.selectValue(id);
export const updateGroupAction = (
  id: string,
  value: Partial<RealizationDateRejectFormValue>
) => formModel.updateGroupValue(id, value);
