import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterAction from './ngrx-router.action';
var NgrxRouterEffect = /** @class */ (function () {
    function NgrxRouterEffect(_router, _actions$, _location) {
        var _this = this;
        this._router = _router;
        this._actions$ = _actions$;
        this._location = _location;
        this._onNavigate$ = this._actions$.pipe(ofType(RouterAction.ActionTypes.Go), map(function (action) { return action.payload; }), tap(function (_a) {
            var path = _a.path, queryParams = _a.query, extras = _a.extras;
            return _this._router.navigate(path, tslib_1.__assign({ queryParams: queryParams }, extras));
        }));
        this._onNavigateBack$ = this._actions$.pipe(ofType(RouterAction.ActionTypes.Back), tap(function () { return _this._location.back(); }));
        this._onNavigateForward$ = this._actions$.pipe(ofType(RouterAction.ActionTypes.Forward), tap(function () { return _this._location.forward(); }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], NgrxRouterEffect.prototype, "_onNavigate$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], NgrxRouterEffect.prototype, "_onNavigateBack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], NgrxRouterEffect.prototype, "_onNavigateForward$", void 0);
    return NgrxRouterEffect;
}());
export { NgrxRouterEffect };
