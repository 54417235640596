import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingsApi = /** @class */ (function () {
    function SettingsApi(_http) {
        this._http = _http;
    }
    SettingsApi.prototype.settings$ = function () {
        return this._http.get("/api/public/settings");
    };
    SettingsApi.ngInjectableDef = i0.defineInjectable({ factory: function SettingsApi_Factory() { return new SettingsApi(i0.inject(i1.HttpClient)); }, token: SettingsApi, providedIn: "root" });
    return SettingsApi;
}());
export { SettingsApi };
