import { Component, Input } from '@angular/core';
import { DEFAULT_TRANSLATION_KEYS } from './price-offer-reject-form.translations';
import { PriceOfferRejectFormState, PriceOfferRejectFormTranslations } from './price-offer-reject-form.model';

@Component({
  selector: 'vshcz-price-offer-reject-form',
  templateUrl: './price-offer-reject-form.component.html',
  styleUrls: [ './price-offer-reject-form.component.scss' ]
})
export class PriceOfferRejectFormComponent {
  @Input()
  state: PriceOfferRejectFormState;

  @Input()
  translations: PriceOfferRejectFormTranslations = DEFAULT_TRANSLATION_KEYS;
}
