import * as tslib_1 from "tslib";
import { TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
var DisplacerPortalDirective = /** @class */ (function (_super) {
    tslib_1.__extends(DisplacerPortalDirective, _super);
    function DisplacerPortalDirective(templateRef, viewContainerRef) {
        return _super.call(this, templateRef, viewContainerRef) || this;
    }
    return DisplacerPortalDirective;
}(TemplatePortal));
export { DisplacerPortalDirective };
var DisplacerComponent = /** @class */ (function () {
    function DisplacerComponent(_overlay) {
        this._overlay = _overlay;
        this.initialized = new EventEmitter();
        this._config = new OverlayConfig();
    }
    DisplacerComponent.prototype.ngOnDestroy = function () {
        this._overlayRef.detach();
    };
    DisplacerComponent.prototype.ngAfterViewInit = function () {
        this._overlayRef = this._overlay.create(this._config);
        this._overlayRef.attach(this._portal);
        this.initialized.emit();
    };
    return DisplacerComponent;
}());
export { DisplacerComponent };
