import { HttpClient } from '@angular/common/http';
import { QueueingSubject } from 'queueing-subject';
import { environment } from 'environments/environment';
import { connect } from '@app/common/websockets';
import { CLOUDDNS_RECEIVER_ID } from './clouddns-websockets.constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClouddnsWebsocketsApi = /** @class */ (function () {
    function ClouddnsWebsocketsApi(_http) {
        this._http = _http;
        this._input = new QueueingSubject();
    }
    ClouddnsWebsocketsApi.prototype.connect = function (token) {
        var endpoint = environment.production
            ? window.location.hostname
            : environment.cloudDnsWsUrl;
        var wsProtocol = !environment.production && environment.forceDevWsProtocol ?
            environment.forceDevWsProtocol
            : window.location.protocol === 'https:'
                ? 'wss'
                : 'ws';
        var _a = connect(wsProtocol + "://" + endpoint + "/clouddns/public-web-socket/" + CLOUDDNS_RECEIVER_ID + "/" + token, this._input), messages = _a.messages, connectionStatus = _a.connectionStatus;
        this.messages$ = messages;
        this.status$ = connectionStatus;
    };
    ClouddnsWebsocketsApi.prototype.send = function (value) {
        this._input.next(value);
    };
    ClouddnsWebsocketsApi.prototype.auth$ = function (accessToken) {
        return this._http.post('/clouddns/public-web-socket/login', { accessToken: accessToken });
    };
    ClouddnsWebsocketsApi.ngInjectableDef = i0.defineInjectable({ factory: function ClouddnsWebsocketsApi_Factory() { return new ClouddnsWebsocketsApi(i0.inject(i1.HttpClient)); }, token: ClouddnsWebsocketsApi, providedIn: "root" });
    return ClouddnsWebsocketsApi;
}());
export { ClouddnsWebsocketsApi };
