import * as tslib_1 from "tslib";
import { DomainRecordBaseState } from './domain-record-base.model';
import { ActionTypes } from './domain-record-base.action';
var initialState = new DomainRecordBaseState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b;
    if (action.type === ActionTypes.EntityLocalSuccess) {
        return tslib_1.__assign({}, state, { recordsMap: tslib_1.__assign({}, state.recordsMap, (_a = {}, _a[action.payload.id] = action.payload, _a)) });
    }
    if (action.type === ActionTypes.UpdateLocalSuccess) {
        return tslib_1.__assign({}, state, { recordsMap: tslib_1.__assign({}, state.recordsMap, (_b = {}, _b[action.payload] = undefined, _b)) });
    }
    return state;
}
