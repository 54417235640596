import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FormHintsDirective } from '@app/common/forms/components/form-hints/form-hints.directive';
import { FormLabelDirective } from '../form-label/form-label.directive';
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent() {
        this.appearance = 'regular';
    }
    Object.defineProperty(FormFieldComponent.prototype, "last", {
        get: function () {
            return this._last;
        },
        set: function (v) {
            this._last = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "classIsLast", {
        get: function () {
            return this.last;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "classHasAppearanceRadio", {
        get: function () {
            return this.appearance === 'radio';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "classHasAppearanceButtonToggle", {
        get: function () {
            return this.appearance === 'button-toggle';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "classHasAppearanceSliceToggle", {
        get: function () {
            return this.appearance === 'slide-toggle';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "hasLabel", {
        get: function () {
            return !!this.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "hasHints", {
        get: function () {
            return !!this.hints;
        },
        enumerable: true,
        configurable: true
    });
    return FormFieldComponent;
}());
export { FormFieldComponent };
