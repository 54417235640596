import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './server-park-access-base.constant';
import { reducer } from './server-park-access-base.reducer';
import { ServerParkAccessListEffect } from './server-park-access-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ ServerParkAccessListEffect ])
  ]
})
export class ServerParkAccessBaseModule {

}
