import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var CloudDNSGuard = /** @class */ (function () {
    function CloudDNSGuard(_router) {
        this._router = _router;
    }
    CloudDNSGuard.prototype.canActivate = function () {
        if (toBoolean(environment.enableCDNS)) {
            return true;
        }
        else {
            this._router.navigate(['/', 'dashboard']);
            return false;
        }
    };
    CloudDNSGuard.ngInjectableDef = i0.defineInjectable({ factory: function CloudDNSGuard_Factory() { return new CloudDNSGuard(i0.inject(i1.Router)); }, token: CloudDNSGuard, providedIn: "root" });
    return CloudDNSGuard;
}());
export { CloudDNSGuard };
