import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  priceOfferRejectFormStateSelector,
  priceOfferRejectFormValueSelector
} from './modules';
import { TicketPriceOfferState } from './ticket-price-offer.model';
import { ModuleTokens } from './ticket-price-offer.constant';

export const slice = createFeatureSelector<TicketPriceOfferState>(ModuleTokens.Name);

export const ticketPriceOferRejectFormState = createSelector(
  slice,
  priceOfferRejectFormStateSelector(ModuleTokens.Name)
);

export const ticketPriceOferRejectFormValue = createSelector(
  slice,
  priceOfferRejectFormValueSelector(ModuleTokens.Name)
);
