export class TicketsListState {
  list: string[];
  filter: string[];

  constructor() {
    this.list = [];
    this.filter = [
      // 'PLANNED',
      'RESOLVED',
      'ACCEPTED',
      'IN_REALISATION',
      'WAITING_FOR_CLIENT',
      'WAITING_AUTHORIZATION'
    ];
  }
}
