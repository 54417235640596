import * as tslib_1 from "tslib";
import { ModuleTokens } from './tickets-base.constant';
var TicketsBaseState = /** @class */ (function () {
    function TicketsBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a[ModuleTokens.Messages] = {},
            _a);
        this.ticketTopics = [];
        this.list = [];
    }
    return TicketsBaseState;
}());
export { TicketsBaseState };
var TicketsBaseEntityEntities = /** @class */ (function () {
    function TicketsBaseEntityEntities() {
    }
    return TicketsBaseEntityEntities;
}());
export { TicketsBaseEntityEntities };
ModuleTokens.Name, ModuleTokens.Messages;
var TicketsEntityNormalizedData = /** @class */ (function () {
    function TicketsEntityNormalizedData() {
    }
    return TicketsEntityNormalizedData;
}());
export { TicketsEntityNormalizedData };
var TicketMessageEntity = /** @class */ (function () {
    function TicketMessageEntity() {
    }
    return TicketMessageEntity;
}());
export { TicketMessageEntity };
var TicketsEntityLight = /** @class */ (function () {
    function TicketsEntityLight() {
    }
    return TicketsEntityLight;
}());
export { TicketsEntityLight };
export var AttachedPriceOfferStates;
(function (AttachedPriceOfferStates) {
    AttachedPriceOfferStates["None"] = "NONE";
    AttachedPriceOfferStates["Unconfirmed"] = "UNCONFIRMED";
    AttachedPriceOfferStates["Confirmed"] = "CONFIRMED";
})(AttachedPriceOfferStates || (AttachedPriceOfferStates = {}));
var TicketsEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsEntityBase, _super);
    function TicketsEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TicketsEntityBase;
}(TicketsEntityLight));
export { TicketsEntityBase };
var TicketsEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsEntityNormalized, _super);
    function TicketsEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TicketsEntityNormalized;
}(TicketsEntityBase));
export { TicketsEntityNormalized };
var TicketsEntity = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsEntity, _super);
    function TicketsEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TicketsEntity;
}(TicketsEntityBase));
export { TicketsEntity };
var TicketFile = /** @class */ (function () {
    function TicketFile() {
    }
    return TicketFile;
}());
export { TicketFile };
var TicketTopicEntity = /** @class */ (function () {
    function TicketTopicEntity() {
    }
    return TicketTopicEntity;
}());
export { TicketTopicEntity };
var TicketTopicGroup = /** @class */ (function () {
    function TicketTopicGroup() {
    }
    return TicketTopicGroup;
}());
export { TicketTopicGroup };
