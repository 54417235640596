import {
  Component,
  Input,
  EventEmitter,
  Output,
  Directive,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { PopoverComponent } from '@app/common/popover';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[vshczFilterPopButtonGroup]'
})
export class FilterPopButtonGroupDirective { }

@Directive({
  selector: '[vshczFilterPopButtonItem]'
})
export class FilterPopButtonItemDirective { }

@Component({
  selector: 'vshcz-filter-pop-button',
  templateUrl: './filter-pop-button.component.html',
  styleUrls: [ './filter-pop-button.component.scss' ]
})
export class FilterPopButtonComponent {
  extras = 0;

  @Input()
  maxSize = 2;

  @Input()
  prefix: string;

  @Input()
  anchorForRef: PopoverComponent;

  @Input()
  set disableReset(v) {
    this._disableReset = coerceBooleanProperty(v);
  }
  get disableReset() { return this._disableReset; }

  @Input()
  placeholder: string;

  @Input()
  activeGroup: string;

  @Input()
  set items(v) {
    const sliced = [ ...v ]
      .slice()
      .splice(0, this.maxSize);

    this.extras = v.length - sliced.length;
    this._items = sliced;
  }

  get items() {
    return this._items;
  }

  @Output()
  reset = new EventEmitter<void>();

  @ContentChild(FilterPopButtonGroupDirective, { read: TemplateRef })
  set groupTemplate(v) {
    this._groupTemplate = v;
  }
  get groupTemplate() { return this._groupTemplate; }

  @ContentChild(FilterPopButtonItemDirective, { read: TemplateRef })
  set itemTemplate(v) {
    this._itemTemplate = v;
  }
  get itemTemplate() { return this._itemTemplate; }

  private _groupTemplate: TemplateRef<any>;
  private _itemTemplate: TemplateRef<any>;
  private _disableReset: boolean;

  private _items: string[];
}
