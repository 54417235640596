import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { extractErrorData, RemoveError } from '@zerops/fe/ngrx';
import { DialogOpen, DialogClose } from '@zerops/fe/dialog';
import { map, switchMap, withLatestFrom, catchError, delay, filter, mergeMap, first } from 'rxjs/operators';
import { FilesUploadRequest, FilesActionTypes } from '@app/common/files';
import { ofWsType } from '@app/common/websockets';
import { AuthorizedRunEffect, authActiveUserClientId, authActiveUserNormalizedClient, authActiveClientUser } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { identity } from '@app/base/auth-base';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from './tickets-base.api';
import { ListRequest, ListLocalSuccess, ListFail, ActionTypes, EntityFail, EntityLocalSuccess, MessageLocalSuccess, MessageFail, MessageSuccess, MessageAddTemp, UpdateCopyListRequest, UpdateCopyListLocalSuccess, UpdateCopyListFail, TicketTopicListRequest, TicketTopicListLocalSuccess, TicketTopicListFail, UpdateCopyListCheckLocalSuccess, UpdateCopyListDenied } from './tickets-base.action';
import { getById } from './tickets-base.selector';
var TicketsBaseEffect = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsBaseEffect, _super);
    function TicketsBaseEffect(_actions$, _store, _api, _errorTranslation, _snack) {
        var _this = _super.call(this, _actions$, _store) || this;
        _this._actions$ = _actions$;
        _this._store = _store;
        _this._api = _api;
        _this._errorTranslation = _errorTranslation;
        _this._snack = _snack;
        _this._onMessageRequest$ = _this._actions$.pipe(ofType(ActionTypes.MessageRequest));
        _this._onMessageRequestUploadPassed$ = _this._onMessageRequest$.pipe(mergeMap(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return _this._actions$.pipe(ofType(FilesActionTypes.UploadAllLocalSuccess, FilesActionTypes.UploadEmpty), filter(function (action) { return payload.instanceId === action.payload.instanceId; }), first(), map(function (action) { return action.type === FilesActionTypes.UploadAllLocalSuccess
                ? { payload: payload, meta: meta, fileIds: action.payload.fileIds }
                : { payload: payload, meta: meta, fileIds: [] }; }));
        }));
        // trigger list request on load
        _this._onInitList$ = _this.onAuthorizedInit$.pipe(delay(1), withLatestFrom(_this._store.pipe(select(authActiveClientUser))), filter(function (_a) {
            var _ = _a[0], user = _a[1];
            return !!user.authorizedToSendTickets;
        }), map(function () { return new ListRequest(); }));
        // trigger ticket topics list on load
        _this._onInitTicketTopics$ = _this.onAuthorizedInit$.pipe(delay(1), withLatestFrom(_this._store.pipe(select(authActiveClientUser))), filter(function (_a) {
            var _ = _a[0], user = _a[1];
            return !!user.authorizedToSendTickets;
        }), map(function () { return new TicketTopicListRequest(); }));
        // ticket topic list request
        _this._onTicketTopicRequest$ = _this._actions$.pipe(ofType(ActionTypes.TicketTopicListRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .ticketTopicList$(id)
                .pipe(map(function (_a) {
                var ticketTopic = _a.ticketTopic;
                return new TicketTopicListLocalSuccess(ticketTopic);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new TicketTopicListFail(data); })); }));
        }));
        // list request
        _this._onListRequest$ = _this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], clientId = _a[1];
            return _this._api
                .list$(clientId)
                .pipe(map(function (response) { return new ListLocalSuccess(response.ticketList); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        // entity request
        _this._onDetailRequest$ = _this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap((function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        })));
        // copy list
        _this._onCopyListCheckRequest$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListCheckRequest), withLatestFrom(_this._store.pipe(select(authActiveUserNormalizedClient))), switchMap(function (_a) {
            var payload = _a[0].payload, client = _a[1];
            if (client.secureCommunication) {
                return _this._api
                    .secureCommunicationCheck$(payload.mail, client.id)
                    .pipe(first(), map(function () { return ({
                    pass: true,
                    payload: payload
                }); }), catchError(function (err) {
                    var errData = extractErrorData(err);
                    if ([2101, 2145].includes(errData.code)) {
                        return of({
                            pass: false,
                            payload: payload
                        });
                    }
                    return of({
                        pass: true,
                        payload: payload
                    });
                }));
            }
            return of({
                pass: true,
                payload: payload
            });
        }), map(function (_a) {
            var pass = _a.pass, payload = _a.payload;
            if (pass) {
                return new UpdateCopyListRequest(payload.id, payload.data, payload.mail, 'add', payload.isWatch);
            }
            else {
                return new UpdateCopyListCheckLocalSuccess(pass, payload, payload.isWatch);
            }
        }));
        _this._onUpdateCopyListCheckLocalSuccess$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListCheckLocalSuccess), filter(function (_a) {
            var payload = _a.payload;
            return !payload.pass;
        }), map(function () { return new DialogOpen(ActionTypes.UpdateCopyListCheckRequest); }));
        _this._onCopyListRequest$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListRequest), 
        // get ticket by id
        switchMap(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return _this._store.pipe(select(getById(payload.id)), filter(function (t) { return !!t; }), first(), map(function (t) { return ({
                id: payload.id,
                payload: payload.data,
                mail: payload.mail,
                copyList: t.copyList,
                isWatch: payload.isWatch,
                isAdd: meta === 'add'
            }); }));
        }), switchMap(function (_a) {
            var id = _a.id, payload = _a.payload, mail = _a.mail, isWatch = _a.isWatch, copyList = _a.copyList, isAdd = _a.isAdd;
            // if mail is already part of copy list
            // and we are adding, not removing
            // show error snack
            if (copyList.includes(mail) && isAdd) {
                return of(new UpdateCopyListDenied(id, 'ticketBase.copyListDuplicaterErr'));
            }
            else {
                return _this._api
                    .updateCopyList$(id, payload)
                    .pipe(map(function (response) { return new UpdateCopyListLocalSuccess(response, id, isWatch); }), catchError(function (err) { return _this._errorTranslation
                    .get$(err)
                    .pipe(map(function (data) { return new UpdateCopyListFail(data, id, isWatch); })); }));
            }
        }), filter(function (action) { return !!action; }));
        _this._onUpdateCopyListDenied$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListDenied), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._snack.translatedWarning$(payload, 'common.close');
        }));
        _this._onUpdateCopyListFail$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta.message, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.UpdateCopyListFail); }));
        _this._onUpdateCopyListLocalSuccessCloseDialog$ = _this._actions$.pipe(ofType(ActionTypes.UpdateCopyListLocalSuccess), map(function () { return new DialogClose(ActionTypes.UpdateCopyListCheckRequest); }));
        // starts upload
        _this._onMessageRequestUpload$ = _this._onMessageRequest$.pipe(map(function (_a) {
            var payload = _a.payload;
            return new FilesUploadRequest(payload.instanceId);
        }));
        // add optimistic entity
        _this._onMessageRequestAddTemp$ = _this._onMessageRequest$.pipe(withLatestFrom(_this._store.pipe(select(identity))), map(function (_a) {
            var _b = _a[0], payload = _b.payload, meta = _b.meta, idnt = _a[1];
            return new MessageAddTemp(payload.ticketId, payload.messageText, idnt, meta);
        }));
        _this._onMessageSocketSuccess$ = _this._actions$.pipe(ofWsType('ticket'), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), filter(function (_a) {
            var data = _a[0], id = _a[1];
            return data.clientId === id;
        }), map(function (_a) {
            var data = _a[0], _ = _a[1];
            return new MessageSuccess(data);
        }));
        _this._onTicketAddUploadFail$ = _this._onMessageRequest$.pipe(mergeMap(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return _this._actions$.pipe(ofType(FilesActionTypes.UploadSingleFail), filter(function (action) { return payload.instanceId === action.payload.instanceId; }), first(), map(function (action) { return new MessageFail(meta, payload.ticketId, action.meta); }));
        }));
        _this._onMessageRequestMain$ = _this._onMessageRequestUploadPassed$.pipe(mergeMap(function (_a) {
            var payload = _a.payload, meta = _a.meta, fileIds = _a.fileIds;
            return _this._api
                .message$(payload.ticketId, payload.messageText, meta, fileIds)
                .pipe(map(function (res) { return new MessageLocalSuccess(res, meta); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new MessageFail(meta, payload.ticketId, data); })); }));
        }));
        _this._onMessageFail$ = _this._actions$.pipe(ofType(ActionTypes.MessageFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.fullyTranslatedSuccess$('ticketsMessage.failSnack', 'common.close', { err: meta.err.message });
        }), map(function () { return new RemoveError(ActionTypes.MessageFail); }));
        return _this;
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onInitList$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onInitTicketTopics$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onTicketTopicRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onDetailRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onCopyListCheckRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onUpdateCopyListCheckLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onCopyListRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onUpdateCopyListDenied$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onUpdateCopyListFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onUpdateCopyListLocalSuccessCloseDialog$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onMessageRequestUpload$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onMessageRequestAddTemp$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onMessageSocketSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onTicketAddUploadFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onMessageRequestMain$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseEffect.prototype, "_onMessageFail$", void 0);
    return TicketsBaseEffect;
}(AuthorizedRunEffect));
export { TicketsBaseEffect };
