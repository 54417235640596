import { ElementRef, NgZone, OnDestroy, ViewContainerRef } from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { ESCAPE } from '@angular/cdk/keycodes';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil, take, filter, tap } from 'rxjs/operators';
import { PopoverNotificationService, NotificationAction } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "@angular/cdk/bidi";
var PopoverAnchoringService = /** @class */ (function () {
    function PopoverAnchoringService(_overlay, _ngZone, _dir) {
        this._overlay = _overlay;
        this._ngZone = _ngZone;
        this._dir = _dir;
        /** Emits when the popover is opened. */
        this.popoverOpened = new Subject();
        /** Emits when the popover is closed. */
        this.popoverClosed = new Subject();
        /** Whether the popover is presently open. */
        this._popoverOpen = false;
        /** Emits when the directive is destroyed. */
        this._onDestroy = new Subject();
    }
    PopoverAnchoringService.prototype.ngOnDestroy = function () {
        // Destroy popover before terminating subscriptions so that any resulting
        // detachments update 'closed state'
        this._destroyPopover();
        // Terminate subscriptions
        if (this._notificationsSubscription) {
            this._notificationsSubscription.unsubscribe();
        }
        if (this._positionChangeSubscription) {
            this._positionChangeSubscription.unsubscribe();
        }
        this._onDestroy.next();
        this._onDestroy.complete();
        this.popoverOpened.complete();
        this.popoverClosed.complete();
    };
    /** Anchor a popover instance to a view and connection element. */
    PopoverAnchoringService.prototype.anchor = function (popover, viewContainerRef, anchor) {
        // Destroy any previous popovers
        this._destroyPopover();
        // Assign local refs
        this._popover = popover;
        this._viewContainerRef = viewContainerRef;
        this._anchor = anchor;
        // Provide notification service as a communication channel between popover and anchor.
        // Then subscribe to notifications to take appropriate actions.
        this._popover._notifications = this._notifications = new PopoverNotificationService();
        this._subscribeToNotifications();
    };
    /** Gets whether the popover is presently open. */
    PopoverAnchoringService.prototype.isPopoverOpen = function () {
        return this._popoverOpen;
    };
    /** Toggles the popover between the open and closed states. */
    PopoverAnchoringService.prototype.togglePopover = function () {
        return this._popoverOpen ? this.closePopover() : this.openPopover();
    };
    /** Opens the popover. */
    PopoverAnchoringService.prototype.openPopover = function () {
        if (!this._popoverOpen) {
            this._createOverlay();
            this._subscribeToBackdrop();
            this._subscribeToEscape();
            this._subscribeToDetachments();
            this._saveOpenedState();
        }
    };
    /** Closes the popover. */
    PopoverAnchoringService.prototype.closePopover = function (value) {
        if (this._overlayRef) {
            this._saveClosedState(value);
            this._overlayRef.detach();
        }
    };
    /** Create an overlay to be attached to the portal. */
    PopoverAnchoringService.prototype._createOverlay = function () {
        // Create overlay if it doesn't yet exist
        if (!this._overlayRef) {
            this._portal = new TemplatePortal(this._popover._templateRef, this._viewContainerRef);
            var popoverConfig = {
                horizontalAlign: this._popover.horizontalAlign,
                verticalAlign: this._popover.verticalAlign,
                hasBackdrop: this._popover.hasBackdrop,
                backdropClass: this._popover.backdropClass,
                scrollStrategy: this._popover.scrollStrategy,
                forceAlignment: this._popover.forceAlignment,
                lockAlignment: this._popover.lockAlignment,
            };
            var overlayConfig = this._getOverlayConfig(popoverConfig, this._anchor);
            this._subscribeToPositionChanges(overlayConfig.positionStrategy);
            this._overlayRef = this._overlay.create(overlayConfig);
        }
        // Actually open the popover
        this._overlayRef.attach(this._portal);
        return this._overlayRef;
    };
    /** Removes the popover from the DOM. Does NOT update open state. */
    PopoverAnchoringService.prototype._destroyPopover = function () {
        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._overlayRef = null;
        }
    };
    /**
     * Destroys the popover immediately if it is closed, or waits until it
     * has been closed to destroy it.
     */
    PopoverAnchoringService.prototype._destroyPopoverOnceClosed = function () {
        var _this = this;
        if (this.isPopoverOpen() && this._overlayRef) {
            this._overlayRef.detachments().pipe(take(1), takeUntil(this._onDestroy)).subscribe(function () { return _this._destroyPopover(); });
        }
        else {
            this._destroyPopover();
        }
    };
    /**
     * Call appropriate anchor method when an event is dispatched through
     * the notification service.
     */
    PopoverAnchoringService.prototype._subscribeToNotifications = function () {
        var _this = this;
        if (this._notificationsSubscription) {
            this._notificationsSubscription.unsubscribe();
        }
        this._notificationsSubscription = this._notifications.events()
            .subscribe(function (event) {
            switch (event.action) {
                case NotificationAction.OPEN:
                    _this.openPopover();
                    break;
                case NotificationAction.CLOSE:
                    _this.closePopover(event.value);
                    break;
                case NotificationAction.TOGGLE:
                    _this.togglePopover();
                    break;
                case NotificationAction.REPOSITION:
                // TODO: When the overlay's position can be dynamically changed, do not destroy
                case NotificationAction.UPDATE_CONFIG:
                    _this._destroyPopoverOnceClosed();
                    break;
            }
        });
    };
    /** Close popover when backdrop is clicked. */
    PopoverAnchoringService.prototype._subscribeToBackdrop = function () {
        var _this = this;
        this._overlayRef
            .backdropClick()
            .pipe(tap(function () { return _this._popover.backdropClicked.emit(); }), filter(function () { return _this._popover.interactiveClose; }), takeUntil(this.popoverClosed), takeUntil(this._onDestroy))
            .subscribe(function () { return _this.closePopover(); });
    };
    /** Close popover when escape keydown event occurs. */
    PopoverAnchoringService.prototype._subscribeToEscape = function () {
        var _this = this;
        this._overlayRef
            .keydownEvents()
            .pipe(tap(function (event) { return _this._popover.overlayKeydown.emit(event); }), filter(function (event) { return event.keyCode === ESCAPE; }), filter(function () { return _this._popover.interactiveClose; }), takeUntil(this.popoverClosed), takeUntil(this._onDestroy))
            .subscribe(function () { return _this.closePopover(); });
    };
    /** Set state back to closed when detached. */
    PopoverAnchoringService.prototype._subscribeToDetachments = function () {
        var _this = this;
        this._overlayRef
            .detachments()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(function () { return _this._saveClosedState(); });
    };
    /** Save the opened state of the popover and emit. */
    PopoverAnchoringService.prototype._saveOpenedState = function () {
        if (!this._popoverOpen) {
            this._popover._open = this._popoverOpen = true;
            this.popoverOpened.next();
            this._popover.opened.emit();
        }
    };
    /** Save the closed state of the popover and emit. */
    PopoverAnchoringService.prototype._saveClosedState = function (value) {
        if (this._popoverOpen) {
            this._popover._open = this._popoverOpen = false;
            this.popoverClosed.next(value);
            this._popover.closed.emit(value);
        }
    };
    /** Gets the text direction of the containing app. */
    PopoverAnchoringService.prototype._getDirection = function () {
        return this._dir && this._dir.value === 'rtl' ? 'rtl' : 'ltr';
    };
    /** Create and return a config for creating the overlay. */
    PopoverAnchoringService.prototype._getOverlayConfig = function (config, anchor) {
        return new OverlayConfig({
            positionStrategy: this._getPositionStrategy(config.horizontalAlign, config.verticalAlign, config.forceAlignment, config.lockAlignment, anchor),
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass || 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this._getScrollStrategyInstance(config.scrollStrategy),
            direction: this._getDirection(),
        });
    };
    /**
     * Listen to changes in the position of the overlay and set the correct alignment classes,
     * ensuring that the animation origin is correct, even with a fallback position.
     */
    PopoverAnchoringService.prototype._subscribeToPositionChanges = function (position) {
        var _this = this;
        if (this._positionChangeSubscription) {
            this._positionChangeSubscription.unsubscribe();
        }
        this._positionChangeSubscription = position.positionChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(function (change) {
            // Position changes may occur outside the Angular zone
            _this._ngZone.run(function () {
                _this._popover._setAlignmentClasses(getHorizontalPopoverAlignment(change.connectionPair.overlayX), getVerticalPopoverAlignment(change.connectionPair.overlayY));
            });
        });
    };
    /** Map a scroll strategy string type to an instance of a scroll strategy. */
    PopoverAnchoringService.prototype._getScrollStrategyInstance = function (strategy) {
        switch (strategy) {
            case 'block':
                return this._overlay.scrollStrategies.block();
            case 'reposition':
                return this._overlay.scrollStrategies.reposition();
            case 'close':
                return this._overlay.scrollStrategies.close();
            case 'noop':
            default:
                return this._overlay.scrollStrategies.noop();
        }
    };
    /** Create and return a position strategy based on config provided to the component instance. */
    PopoverAnchoringService.prototype._getPositionStrategy = function (horizontalTarget, verticalTarget, forceAlignment, lockAlignment, anchor) {
        // Attach the overlay at the preferred position
        var targetPosition = getPosition(horizontalTarget, verticalTarget);
        var positions = [targetPosition];
        var strategy = this._overlay.position()
            .flexibleConnectedTo(anchor)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withLockedPosition(lockAlignment);
        // Unless the alignment is forced, add fallbacks based on the preferred positions
        if (!forceAlignment) {
            var fallbacks = this._getFallbacks(horizontalTarget, verticalTarget);
            positions.push.apply(positions, fallbacks);
        }
        return strategy.withPositions(positions);
    };
    /** Get fallback positions based around target alignments. */
    PopoverAnchoringService.prototype._getFallbacks = function (hTarget, vTarget) {
        // Determine if the target alignments overlap the anchor
        var horizontalOverlapAllowed = hTarget !== 'before' && hTarget !== 'after';
        var verticalOverlapAllowed = vTarget !== 'above' && vTarget !== 'below';
        // If a target alignment doesn't cover the anchor, don't let any of the fallback alignments
        // cover the anchor
        var possibleHorizontalAlignments = horizontalOverlapAllowed ?
            ['before', 'start', 'center', 'end', 'after'] :
            ['before', 'after'];
        var possibleVerticalAlignments = verticalOverlapAllowed ?
            ['above', 'start', 'center', 'end', 'below'] :
            ['above', 'below'];
        // Create fallbacks for each allowed prioritized fallback alignment combo
        var fallbacks = [];
        prioritizeAroundTarget(hTarget, possibleHorizontalAlignments).forEach(function (h) {
            prioritizeAroundTarget(vTarget, possibleVerticalAlignments).forEach(function (v) {
                fallbacks.push(getPosition(h, v));
            });
        });
        // Remove the first item since it will be the target alignment and isn't considered a fallback
        return fallbacks.slice(1, fallbacks.length);
    };
    PopoverAnchoringService.ngInjectableDef = i0.defineInjectable({ factory: function PopoverAnchoringService_Factory() { return new PopoverAnchoringService(i0.inject(i1.Overlay), i0.inject(i0.NgZone), i0.inject(i2.Directionality, 8)); }, token: PopoverAnchoringService, providedIn: "root" });
    return PopoverAnchoringService;
}());
export { PopoverAnchoringService };
/** Helper function to get a cdk position pair from Popover alignments. */
function getPosition(h, v) {
    var _a = getHorizontalConnectionPosPair(h), originX = _a.originX, overlayX = _a.overlayX;
    var _b = getVerticalConnectionPosPair(v), originY = _b.originY, overlayY = _b.overlayY;
    return new ConnectionPositionPair({ originX: originX, originY: originY }, { overlayX: overlayX, overlayY: overlayY });
}
/** Helper function to convert an overlay connection position to equivalent popover alignment. */
function getHorizontalPopoverAlignment(h) {
    if (h === 'start') {
        return 'after';
    }
    if (h === 'end') {
        return 'before';
    }
    return 'center';
}
/** Helper function to convert an overlay connection position to equivalent popover alignment. */
function getVerticalPopoverAlignment(v) {
    if (v === 'top') {
        return 'below';
    }
    if (v === 'bottom') {
        return 'above';
    }
    return 'center';
}
/** Helper function to convert alignment to origin/overlay position pair. */
function getHorizontalConnectionPosPair(h) {
    switch (h) {
        case 'before':
            return { originX: 'start', overlayX: 'end' };
        case 'start':
            return { originX: 'start', overlayX: 'start' };
        case 'end':
            return { originX: 'end', overlayX: 'end' };
        case 'after':
            return { originX: 'end', overlayX: 'start' };
        default:
            return { originX: 'center', overlayX: 'center' };
    }
}
/** Helper function to convert alignment to origin/overlay position pair. */
function getVerticalConnectionPosPair(v) {
    switch (v) {
        case 'above':
            return { originY: 'top', overlayY: 'bottom' };
        case 'start':
            return { originY: 'top', overlayY: 'top' };
        case 'end':
            return { originY: 'bottom', overlayY: 'bottom' };
        case 'below':
            return { originY: 'bottom', overlayY: 'top' };
        default:
            return { originY: 'center', overlayY: 'center' };
    }
}
/**
 * Helper function that takes an ordered array options and returns a reorderded
 * array around the target item. e.g.:
 *
 * target: 3; options: [1, 2, 3, 4, 5, 6, 7];
 *
 * return: [3, 4, 2, 5, 1, 6, 7]
 */
function prioritizeAroundTarget(target, options) {
    var targetIndex = options.indexOf(target);
    // Set the first item to be the target
    var reordered = [target];
    // Make left and right stacks where the highest priority item is last
    var left = options.slice(0, targetIndex);
    var right = options.slice(targetIndex + 1, options.length).reverse();
    // Alternate between stacks until one is empty
    while (left.length && right.length) {
        reordered.push(right.pop());
        reordered.push(left.pop());
    }
    // Flush out right side
    while (right.length) {
        reordered.push(right.pop());
    }
    // Flush out left side
    while (left.length) {
        reordered.push(left.pop());
    }
    return reordered;
}
