import * as tslib_1 from "tslib";
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { Store, select } from '@ngrx/store';
import { map, tap, filter, takeUntil, mergeMap, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogKey } from './domain-routing-info-dialog.constant';
import { SetTicketTriggerState, TicketTriggerOpen } from '../tickets-trigger';
var DomainRoutingInfoDialogContainer = /** @class */ (function (_super) {
    tslib_1.__extends(DomainRoutingInfoDialogContainer, _super);
    function DomainRoutingInfoDialogContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Stream
        _this.onOpenTicketTrigger$ = new Subject();
        // # Data
        // sync
        _this.dialogKey = DialogKey;
        _this.isCzDomain = false;
        // async
        _this.open$ = _this._store.pipe(select(getDialogState(_this.dialogKey)), map(function (_a) {
            var state = _a.state;
            return !!state;
        }));
        _this.domainName$ = _this._store.pipe(select(getDialogState(_this.dialogKey)), filter(function (_a) {
            var meta = _a.meta;
            return !!meta;
        }), delay(0), tap(function (_a) {
            var meta = _a.meta;
            return meta.endsWith('.cz.') ? _this.isCzDomain = true : _this.isCzDomain = false;
        }), map(function (_a) {
            var meta = _a.meta;
            return meta;
        }));
        // # Action Streams
        _this._openTicketTriggerAction$ = _this.onOpenTicketTrigger$.pipe(mergeMap(function () { return [
            new SetTicketTriggerState({
                state: 1,
                meta: 'CLOUDDNS_ISSUE'
            }),
            new TicketTriggerOpen()
        ]; }));
        _this._openTicketTriggerAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    return DomainRoutingInfoDialogContainer;
}(BaseClass));
export { DomainRoutingInfoDialogContainer };
