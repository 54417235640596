import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ServerParkAccessApi = /** @class */ (function () {
    function ServerParkAccessApi(_http) {
        this._http = _http;
    }
    ServerParkAccessApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/serverpark-access");
    };
    ServerParkAccessApi.prototype.delete$ = function (id) {
        return this._http.delete("/api/public/serverpark-access/" + id);
    };
    ServerParkAccessApi.ngInjectableDef = i0.defineInjectable({ factory: function ServerParkAccessApi_Factory() { return new ServerParkAccessApi(i0.inject(i1.HttpClient)); }, token: ServerParkAccessApi, providedIn: "root" });
    return ServerParkAccessApi;
}());
export { ServerParkAccessApi };
