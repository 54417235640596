import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { DialogModule } from '@zerops/fe/dialog';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { EmailFormModule } from '@app/common/email-form';
import { ContentTitleModule } from '@app/common/content-title';
import { FormModule } from '@app/common/forms';
import { ProgressErrorModule } from '@app/common/progress-error';
import { TicketCopyListContainer } from './ticket-copy-list.container';
import { ModuleTokens } from './ticket-copy-list.constant';
import { reducer } from './ticket-copy-list.reducer';
import { TicketCopyListEffect } from './ticket-copy-list.effect';

@NgModule({
  declarations: [ TicketCopyListContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ TicketCopyListEffect ]),
    DialogModule,
    FormModule,
    EmailFormModule,
    ProgressErrorModule,
    ContentTitleModule
  ],
  exports: [ TicketCopyListContainer ]
})
export class TicketCopyListModule {

}
