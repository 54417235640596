<mat-form-field>

  <mat-label>
    <span [translate]="translations.labels.messageText"></span>
  </mat-label>

  <textarea
    rows="5"
    cdkAutosizeMaxRows="5"
    vshczTextareaAutosize
    class="__textarea"
    [ngrxEnableFocusTracking]="true"
    [ngrxFormControlState]="state.controls.messageText"
    matInput></textarea>

</mat-form-field>
