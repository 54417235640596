import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerSerializerProvider } from './ngrx-router.serializer';
import { NgrxRouterEffect } from './ngrx-router.effect';
import { StoreModule } from '@ngrx/store';

@NgModule({
  providers: [
    routerSerializerProvider
  ],
  imports: [
    EffectsModule.forFeature([ NgrxRouterEffect ]),
  ]
})
export class NgrxRouterModule {

}
