import { normalize, denormalize } from 'normalizr';
import { ModuleTokens as ClientUserBaseModuleTokens } from '@app/base/client-user-base/client-user-base.constant';
import { ClientsBaseModuleTokens } from '@app/base/clients-base';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import { userListSchema, userSchema } from '@app/schemas';
import { ModuleTokens } from './users-base.constant';
export function normalizeList(data) {
    return normalize(data, userListSchema);
}
export function denormalizeEntity(id, usersEntities, clientUserEntities, clientsEntities, servicesEntities) {
    var _a;
    return denormalize(id, userSchema, (_a = {},
        _a[ModuleTokens.Name] = usersEntities,
        _a[ClientUserBaseModuleTokens.Name] = clientUserEntities,
        _a[ClientsBaseModuleTokens.Name] = clientsEntities,
        _a[ServicesBaseModuleTokens.Name] = servicesEntities,
        _a));
}
export function denormalizeList(ids, usersEntities, clientUserEntities, clientsEntities, servicesEntities) {
    var _a;
    return denormalize(ids, userListSchema, (_a = {},
        _a[ModuleTokens.Name] = usersEntities,
        _a[ClientUserBaseModuleTokens.Name] = clientUserEntities,
        _a[ClientsBaseModuleTokens.Name] = clientsEntities,
        _a[ServicesBaseModuleTokens.Name] = servicesEntities,
        _a));
}
