import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PopoverComponent } from '@app/common/popover';
import { InvalidateAuthorization, identity, SendPasswordChangeRequest, authActiveUserClientId, SetActiveClient } from '@app/base/auth-base';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
var DEFAULT_TRANSITION = '300ms cubic-bezier(0.25, 0.8, 0.25, 1)';
export var transformContent = trigger('transformContent', [
    transition(':enter', [
        style({ transform: 'translateY(-6px)' }),
        animate(DEFAULT_TRANSITION, style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
        animate(DEFAULT_TRANSITION, style({ opacity: 0, transform: 'translateY(3px)' }))
    ])
]);
var LoggedUserContainer = /** @class */ (function (_super) {
    tslib_1.__extends(LoggedUserContainer, _super);
    function LoggedUserContainer(_store, _translate) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._translate = _translate;
        // # Event Streams
        _this.onLogout$ = new Subject();
        _this.onChangePassword$ = new Subject();
        _this.onChangeClient$ = new Subject();
        // # Data
        // -- sync
        _this.popOpen = false;
        // -- async
        _this.identity$ = _this._store.pipe(select(identity), filter(function (i) { return !!i; }), withLatestFrom(_this._store.pipe(select(authActiveUserClientId)), _this._translate.get([
            'common.roles.MANAGER',
            'common.roles.TECHNICAL_USER',
            'common.roles.FINANCIAL_USER'
        ])), map(function (_a) {
            var idnt = _a[0], activeClientId = _a[1], translations = _a[2];
            var projectedClientUserList = idnt.clientUserList.map(function (c) { return (tslib_1.__assign({}, c, { _translatedRoleName: translations["common.roles." + c.roleCode] })); });
            return tslib_1.__assign({}, idnt, { _clientUserList: projectedClientUserList, _activeUserClient: projectedClientUserList.find(function (c) { return c.clientId === activeClientId; }) });
        }));
        // # Action Streams
        _this._logoutAction$ = _this.onLogout$.pipe(map(function () { return new InvalidateAuthorization(true); }));
        _this._changeClientAction$ = _this.onChangeClient$.pipe(map(function (id) { return new SetActiveClient(id, true); }));
        _this._changePasswordAction$ = _this.onChangePassword$.pipe(map(function () { return new SendPasswordChangeRequest(); }));
        // # Data Resolvers
        _this.identity$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (d) { return _this.identity = d; });
        // # Action Dispatcher
        merge(_this._logoutAction$, _this._changeClientAction$, _this._changePasswordAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        _super.prototype.ngOnInit.call(_this);
        return _this;
    }
    Object.defineProperty(LoggedUserContainer.prototype, "compact", {
        get: function () {
            return this._compact;
        },
        // -- angular
        set: function (v) {
            this._compact = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoggedUserContainer.prototype, "classIsOpen", {
        get: function () {
            return this.popOpen;
        },
        enumerable: true,
        configurable: true
    });
    LoggedUserContainer.prototype.openPop = function () {
        this.popOpen = true;
        this.popRef.open();
    };
    LoggedUserContainer.prototype.onPopClose = function () {
        this.popOpen = false;
    };
    return LoggedUserContainer;
}(BaseClass));
export { LoggedUserContainer };
