import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RemoveError } from '@zerops/fe/ngrx';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SnackService } from '@app/common/snack';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from './tickets-base.api';
import { ActionTypes, UnlockLocalSuccess, UnlockFail, ApprovePriceOfferLocalSuccess, ApprovePriceOfferFail, RejectPriceOfferLocalSuccess, RejectPriceOfferFail, ConfirmRealizationDateLocalSuccess, ConfirmRealizationDateFail, RejectRealizationDateLocalSuccess, RejectRealizationDateFail } from './tickets-base.action';
import { TranslateService } from '@ngx-translate/core';
// separated effect file containing effects listening to actions fired even if user not authorized (ticket public pages)
var TicketsBaseNotAuthorizedEffect = /** @class */ (function () {
    function TicketsBaseNotAuthorizedEffect(_actions$, _api, _translate, _errorTranslation, _snack) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._translate = _translate;
        this._errorTranslation = _errorTranslation;
        this._snack = _snack;
        // unlock
        this._onUnlockRequest$ = this._actions$.pipe(ofType(ActionTypes.UnlockRequest), switchMap((function (_a) {
            var payload = _a.payload;
            return _this._api
                .unlock$(payload)
                .pipe(map(function (response) { return new UnlockLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new UnlockFail(data, payload); })); }));
        })));
        // price offer
        this._onApprovePriceOfferRequest$ = this._actions$.pipe(ofType(ActionTypes.ApprovePriceOfferRequest), switchMap((function (_a) {
            var payload = _a.payload, _b = _a.meta, lastAdminTicketMessageId = _b.lastAdminTicketMessageId, clientId = _b.clientId;
            return _this._api
                .approvePriceOffer$(payload, lastAdminTicketMessageId, clientId)
                .pipe(map(function (response) { return new ApprovePriceOfferLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ApprovePriceOfferFail(data, payload); })); }));
        })));
        this._onApprovePriceOfferLocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.ApprovePriceOfferLocalSuccess), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'ticketBase.approvePriceOfferSuccess'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.success$(translations['ticketBase.approvePriceOfferSuccess'], translations['common.close']); })); }));
        this._onApprovePriceOfferFail$ = this._actions$.pipe(ofType(ActionTypes.ApprovePriceOfferFail), switchMap(function (action) { return _this._snack.translatedFail$(action.meta.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ApprovePriceOfferFail); }));
        this._onRejectPriceOfferRequest$ = this._actions$.pipe(ofType(ActionTypes.RejectPriceOfferRequest), switchMap((function (_a) {
            var payload = _a.payload, _b = _a.meta, clientId = _b.clientId, messageText = _b.messageText;
            return _this._api
                .rejectPriceOffer$(payload, clientId, messageText)
                .pipe(map(function (response) { return new RejectPriceOfferLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new RejectPriceOfferFail(data, payload); })); }));
        })));
        this._onRejectPriceOfferLocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.RejectPriceOfferLocalSuccess), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'ticketBase.rejectPriceOfferSuccess'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.success$(translations['ticketBase.rejectPriceOfferSuccess'], translations['common.close']); })); }));
        this._onRejectPriceOfferFail$ = this._actions$.pipe(ofType(ActionTypes.RejectPriceOfferFail), switchMap(function (action) { return _this._snack.translatedFail$(action.meta.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.RejectPriceOfferFail); }));
        // realization date
        this._onConfirmRealizationDateRequest$ = this._actions$.pipe(ofType(ActionTypes.ConfirmRealizationDateRequest), switchMap((function (_a) {
            var payload = _a.payload, clientId = _a.meta.clientId;
            return _this._api
                .confirmRealizationDate$(payload, clientId)
                .pipe(map(function (response) { return new ConfirmRealizationDateLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ConfirmRealizationDateFail(data, payload); })); }));
        })));
        this._onConfirmRealizationDateLocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.ConfirmRealizationDateLocalSuccess), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'ticketBase.confirmRealizationDateSuccess'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.success$(translations['ticketBase.confirmRealizationDateSuccess'], translations['common.close']); })); }));
        this._onConfirmRealizationDateFail$ = this._actions$.pipe(ofType(ActionTypes.ConfirmRealizationDateFail), switchMap(function (action) { return _this._snack.translatedFail$(action.meta.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.ConfirmRealizationDateFail); }));
        this._onRejectRealizationDateRequest$ = this._actions$.pipe(ofType(ActionTypes.RejectRealizationDateRequest), switchMap((function (_a) {
            var payload = _a.payload, _b = _a.meta, clientId = _b.clientId, messageText = _b.messageText;
            return _this._api
                .rejectRealizationDate$(payload, clientId, messageText)
                .pipe(map(function (response) { return new RejectRealizationDateLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new RejectRealizationDateFail(data, payload); })); }));
        })));
        this._onRejectRealizationDateLocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.RejectRealizationDateLocalSuccess), switchMap(function () { return _this._translate
            .get([
            'common.close',
            'ticketBase.rejectRealizationDateSuccess'
        ])
            .pipe(switchMap(function (translations) { return _this._snack.success$(translations['ticketBase.rejectRealizationDateSuccess'], translations['common.close']); })); }));
        this._onRejectRealizationDateFail$ = this._actions$.pipe(ofType(ActionTypes.RejectRealizationDateFail), switchMap(function (action) { return _this._snack.translatedFail$(action.meta.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.RejectRealizationDateFail); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onUnlockRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onApprovePriceOfferRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onApprovePriceOfferLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onApprovePriceOfferFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectPriceOfferRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectPriceOfferLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectPriceOfferFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onConfirmRealizationDateRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onConfirmRealizationDateLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onConfirmRealizationDateFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectRealizationDateRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectRealizationDateLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsBaseNotAuthorizedEffect.prototype, "_onRejectRealizationDateFail$", void 0);
    return TicketsBaseNotAuthorizedEffect;
}());
export { TicketsBaseNotAuthorizedEffect };
