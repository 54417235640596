import isArray from 'lodash-es/isArray';
import uniq from 'lodash-es/uniq';
import {
  TicketsBaseActions,
  TicketsBaseActionTypes
} from '@app/base/tickets-base';
import {
  TicketAddActions,
  TicketAddActionTypes
} from '@app/common/ticket-add';
import { TicketsListState } from './tickets-list.model';
import {
  Actions as TicketsListActions,
  ActionTypes
} from './tickets-list.action';

const initialState = new TicketsListState();

type Actions = TicketsBaseActions | TicketsListActions | TicketAddActions;

export function reducer(
  state = initialState,
  action: Actions
): TicketsListState {

  switch (action.type) {
    case TicketsBaseActionTypes.ListLocalSuccess:
      return {
        ...state,
        list: action.payload.result
      };

    case ActionTypes.AddToFilter:
      const bag = isArray(action.payload)
        ? action.payload
        : [ action.payload ];

      const existing = action.meta ? [] : state.filter;

      return {
        ...state,
        filter: [
          ...existing,
          ...bag
        ]
      };

    case ActionTypes.RemoveFromFilter:
      return {
        ...state,
        filter: isArray(action.payload)
          ? state.filter.filter((key) => action.payload.indexOf(key) >= 0)
          : state.filter.filter((key) => key !== action.payload)
      };

    case ActionTypes.ResetFilter:
      return {
        ...state,
        filter: []
      };

    case TicketAddActionTypes.AddLocalSuccess:
      return {
        ...state,
        list: uniq([
          ...action.payload.result,
          ...state.list
        ])
      };

    case TicketsBaseActionTypes.MessageSuccess:
      return {
        ...state,
        list: uniq([
          ...action.payload.result,
          ...state.list
        ])
      };
  }

  return state;
}
