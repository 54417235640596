import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleTokens } from './domains-base.constant';
import { reducer } from './domains-base.reducer';
import { DomainsBaseEffect } from './domains-base.effect';
import { reducer as domainRecordReducer } from './domain-record-base.reducer';
import { DomainRecordBaseEffect } from './domain-record-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    StoreModule.forFeature(
      ModuleTokens.ActiveRecords,
      domainRecordReducer
    ),
    EffectsModule.forFeature([
      DomainsBaseEffect,
      DomainRecordBaseEffect
    ])
  ]
})
export class DomainsBaseModule {

}
