import { normalize, denormalize } from 'normalizr';
import { ticketsListSchema, ticketsSchema } from '@app/schemas';
import { ModuleTokens } from './tickets-base.constant';
export function normalizeList(data) {
    return normalize(data, ticketsListSchema);
}
export function denormalizeEntity(id, ticketsEntities, ticketMessagesEntities) {
    var _a;
    return denormalize(id, ticketsSchema, (_a = {},
        _a[ModuleTokens.Name] = ticketsEntities,
        _a[ModuleTokens.Messages] = ticketMessagesEntities,
        _a));
}
export function denormalizeList(ids, ticketsEntities, ticketMessagesEntities) {
    var _a;
    return denormalize(ids, ticketsListSchema, (_a = {},
        _a[ModuleTokens.Name] = ticketsEntities,
        _a[ModuleTokens.Messages] = ticketMessagesEntities,
        _a));
}
export function createTempTicketEntities(ticketId, messageId, message, user) {
    var _a, _b;
    return {
        ticketsBase: (_a = {},
            _a[ticketId] = {
                id: ticketId,
                ticketMessageList: [messageId]
            },
            _a),
        ticketsMessagesBase: (_b = {},
            _b[messageId] = {
                id: messageId,
                messageText: message,
                display: 'USER',
                created: new Date(),
                ticketId: ticketId,
                author: {
                    email: user.email,
                    fullName: user.fullName
                }
            },
            _b)
    };
}
