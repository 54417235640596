import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServicesBaseState } from './services-base.model';
import { ModuleTokens } from './services-base.constant';

export const slice = createFeatureSelector<ServicesBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);
