import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyTicketAddFormReducer } from '@app/common/ticket-add-form';
import { TicketAddState } from './ticket-add.model';
import { Actions, ActionTypes } from './ticket-add.action';
import { ModuleTokens } from './ticket-add.constant';

const initialState = new TicketAddState();

export function reducer(
  state = initialState,
  action: Actions
): TicketAddState {
  state = applyTicketAddFormReducer(
    state,
    action,
    ModuleTokens.Name,
    {
      title: validate(required),
      ticketMessage: validate(required)
    }
  );

  if (action.type === ActionTypes.Reset) {
    return {
      ...state,
      forms: initialState.forms
    };
  }

  return state;
}
