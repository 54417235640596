/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./progress-item.component";
var styles_ProgressItemComponent = [i0.styles];
var RenderType_ProgressItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressItemComponent, data: {} });
export { RenderType_ProgressItemComponent as RenderType_ProgressItemComponent };
function View_ProgressItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "__spinner  c-progress-item-spinner mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "accent"; var currVal_4 = _co.spinnerSize; var currVal_5 = _co.strokeWidth; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProgressItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "__content"]], [[2, "is-active", null]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressItemComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.active; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_ProgressItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-progress-item", [], [[2, "is-active", null]], null, null, View_ProgressItemComponent_0, RenderType_ProgressItemComponent)), i1.ɵdid(1, 49152, null, 0, i7.ProgressItemComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isActiveClass; _ck(_v, 0, 0, currVal_0); }); }
var ProgressItemComponentNgFactory = i1.ɵccf("vshcz-progress-item", i7.ProgressItemComponent, View_ProgressItemComponent_Host_0, { active: "active", spinnerSize: "spinnerSize", strokeWidth: "strokeWidth" }, {}, ["*"]);
export { ProgressItemComponentNgFactory as ProgressItemComponentNgFactory };
