import { EventEmitter } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
var CompanySelectComponent = /** @class */ (function () {
    function CompanySelectComponent() {
        this.companySelect = new EventEmitter();
    }
    Object.defineProperty(CompanySelectComponent.prototype, "clientUserList", {
        get: function () {
            return this._clientUserList;
        },
        set: function (v) {
            if (v) {
                this._clientUserList = orderBy(v, [function (itm) { return itm.client.companyName; }], ['asc']);
            }
            else {
                this._clientUserList = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    return CompanySelectComponent;
}());
export { CompanySelectComponent };
