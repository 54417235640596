<span
  [ngClass]="{
    'is-new': status === statuses.New,
    'is-active': status === statuses.Active,
    'is-waiting-to-ttl': status === statuses.WaitingToTtl,
    'is-publishing-to-internet': status === statuses.PublishingToInternet,
    'is-not-available': status === statuses.NotAvailable,
    'is-not-heading-to-vshosting': status === statuses.NotHeadingToVshosting,
    'is-deleted': status === statuses.Deleted,
    'is-deactivated': status === statuses.Deactivated,
    'is-warning': status === statuses.Warning,
    'is-small': size === 'small'
  }"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  matTooltipPosition="after"
  matTooltipClass="c-search-tooltip"
  matTooltipShowDelay="400"
  class="__status-icon">
</span>

<span
  *ngIf="!!label"
  class="__label">
  {{ label }}
</span>
