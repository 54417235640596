import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noDecimals'
})
export class NoDecimalsPipe implements PipeTransform {
  transform(number: number): string {
    return number.toFixed(2).toString().split('.')[0];
  }
}
