import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, EMPTY, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, first, mergeMap, map } from 'rxjs/operators';
import { SetActive, ActionTypes } from './recaptcha.action';
var RecaptchaInterceptor = /** @class */ (function () {
    function RecaptchaInterceptor(_store, _actions$, _http) {
        this._store = _store;
        this._actions$ = _actions$;
        this._http = _http;
        this._recaptchaToken$ = new BehaviorSubject(null);
        this._blocking = false;
    }
    RecaptchaInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (err) {
            if (err.error.error.code === 405 || err.error.error.code === 406) {
                if (!_this._blocking) {
                    _this._blocking = true;
                    _this._recaptchaToken$.next(null);
                    _this._store.dispatch(new SetActive());
                    return _this._actions$.pipe(ofType(ActionTypes.SetInactive), mergeMap(function (_a) {
                        var payload = _a.payload;
                        request = request.clone({
                            setHeaders: {
                                'g-recaptcha-response': payload
                            }
                        });
                        return next
                            .handle(request)
                            .pipe(catchError(function (errInner) {
                            if (errInner.error.error.code === 406) {
                                _this._blocking = true;
                                _this._store.dispatch(new SetActive());
                                return EMPTY;
                            }
                            else {
                                _this._blocking = false;
                                return throwError(errInner);
                            }
                        }), map(function (res) {
                            if (res.status && res.status === 200) {
                                _this._recaptchaToken$.next(payload);
                                _this._blocking = false;
                            }
                            return res;
                        }));
                    }));
                }
                else {
                    return _this._recaptchaToken$.pipe(filter(function (token) { return !!token; }), first(), mergeMap(function () { return next.handle(request); }));
                }
            }
            else {
                return throwError(err);
            }
        }));
    };
    return RecaptchaInterceptor;
}());
export { RecaptchaInterceptor };
export var recaptchaInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: RecaptchaInterceptor,
    multi: true
};
