<label class="__label" *ngIf="hasLabel">
  <ng-content select="vshcz-form-label"></ng-content>
</label>

<div class="__content">
  <ng-content></ng-content>
</div>

<div class="__hints" *ngIf="hasHints">
  <ng-content select="vshcz-form-hints"></ng-content>
</div>
