import * as tslib_1 from "tslib";
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { applyRealizationDateRejectFormReducer } from './modules';
import { TicketRealizationDateState } from './ticket-realization-date.model';
import { ModuleTokens } from './ticket-realization-date.constant';
var initialState = new TicketRealizationDateState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyRealizationDateRejectFormReducer(tslib_1.__assign({}, state), action, ModuleTokens.Name, {
        messageText: validate(required)
    });
    return state;
}
