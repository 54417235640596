export enum ModuleTokens {
  Name = 'ticketsTrigger',
  MessageUploadEntity = 'ticketMessage'
}

export enum TicketsTriggerStates {
  List,
  Add,
  Detail,
  Dashboard
}
