import { Component, Input } from '@angular/core';
import { DEFAULT_TRANSLATION_KEYS } from './realization-date-reject-form.translations';
import { RealizationDateRejectFormState, RealizationDateRejectFormTranslations } from './realization-date-reject-form.model';

@Component({
  selector: 'vshcz-realization-date-reject-form',
  templateUrl: './realization-date-reject-form.component.html',
  styleUrls: [ './realization-date-reject-form.component.scss' ]
})
export class RealizationDateRejectFormComponent {
  @Input()
  state: RealizationDateRejectFormState;

  @Input()
  translations: RealizationDateRejectFormTranslations = DEFAULT_TRANSLATION_KEYS;
}
