<div>
  <div>
    <h3
      class="__service_name"
      fxLayout="row"
      fxLayoutAlign="start start">

      <span class="__service-index" *ngIf="index !== undefined">{{ index + 1 }}</span>

      <strong *ngIf="itemsCount" class="__count">{{ itemsCount }}x </strong>

      <span
        [translate]="'services.codes.' + itemKey"
        class="__item-key"
        [class.is-light]="itemsCount">
      </span>

      <mat-icon *ngIf="isExternal" class="__icon">open_in_new</mat-icon>

    </h3>
  </div>
</div>
