/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./avatar.component";
import * as i8 from "@angular/platform-browser";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
function View_AvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "__spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "__spinner mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.size; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AvatarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "__image"]], [[2, "is-loading", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { backgroundImage: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (!!_co.imageUrl ? (("url(" + _co.imageUrl) + ")") : undefined)); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.isLoading; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 0, 0, currVal_0); }); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-avatar", [], [[4, "height", "px"], [4, "width", "px"]], null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 114688, null, 0, i7.AvatarComponent, [i8.DomSanitizer, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).styleHeight; var currVal_1 = i1.ɵnov(_v, 1).styleWidth; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AvatarComponentNgFactory = i1.ɵccf("vshcz-avatar", i7.AvatarComponent, View_AvatarComponent_Host_0, { mail: "mail", web: "web", extension: "extension", image: "image", source: "source", size: "size" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
