import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PaymentStatuses } from '@app/base/payments-base';
import { Currency } from '@app/common/settings';
import { HashMap } from 'utils';

@Component({
  selector: 'vshcz-payment-item',
  templateUrl: './payment-item.component.html',
  styleUrls: [ './payment-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentItemComponent {

  @Input()
  id: string;

  @Input()
  amount: number;

  @Input()
  currencyId: string;

  @Input()
  created: string;

  @Input()
  status: PaymentStatuses;

  @Input()
  priceSize = 20;

  @Input()
  symbolSize = 15;

  @Input()
  currencyMap: HashMap<Currency>;

}
