import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentEntityLight, PaymentEntity } from './payments-base.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentsBaseApi {
  constructor(private _http: HttpClient) { }

  list$(clientId: string) {
    return this._http.get<{ paymentList: PaymentEntityLight[] }>(
      `/api/public/client/${clientId}/payment`
    );
  }

  entity$(id: string) {
    return this._http.get<PaymentEntity>(
      `/api/public/payment/${id}`
    );
  }
}
