import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'vshcz-contact',
  templateUrl: './contact.component.html',
  styleUrls: [ './contact.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent {

  @Input()
  title: string;

  @Input()
  name: string;

  @Input()
  mail: string;

  @Input()
  phone: string;

  @Input()
  text: string;

  @Input()
  phonePrefix: string;

}
