import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, filter, delay, mergeMap, withLatestFrom, switchMap, first } from 'rxjs/operators';
import { FocusAction } from 'ngrx-forms';
import { ROUTER_NAVIGATION_TYPE } from 'ngrx-router';
import { TicketsBaseEntityRequest, TicketsBaseActionTypes, ticketTopicById } from '@app/base/tickets-base';
import { getTicketTextFormKey } from '@app/common/ticket-text-form';
import { getTicketAddFormKey, ticketAddFormUpdateGroupAction } from '@app/common/ticket-add-form';
import { TicketAddModuleTokens, TicketAddReset, TicketAddActionTypes } from '@app/common/ticket-add';
import { TicketsListResetFilter } from '@app/common/tickets-list';
import { Go } from '@app/common/ngrx-router';
import { identity } from '@app/base/auth-base';
import { SetState, SetDetailId, ActionTypes, ResetMessageForm, Open, HideTrigger, ShowTrigger } from './tickets-trigger.action';
import { TicketsTriggerStates, ModuleTokens } from './tickets-trigger.constant';
import { showTrigger } from './tickets-trigger.selector';
var TicketsTriggerEffect = /** @class */ (function () {
    function TicketsTriggerEffect(_actions$, _store, _router) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._router = _router;
        this._onSetState$ = this._actions$.pipe(ofType(ActionTypes.SetState));
        this._onRouteWithTicketQueryParam$ = this._actions$.pipe(ofType(ROUTER_NAVIGATION_TYPE), filter(function (_a) {
            var payload = _a.payload;
            return !!payload.params.ticket;
        }), map(function (_a) {
            var payload = _a.payload;
            return new Open(payload.params.ticket);
        }));
        // show or hide trigger based on route data
        this._onRouteWithHideTrigger$ = this._actions$.pipe(ofType(ROUTER_NAVIGATION_TYPE), map(function (_a) {
            var payload = _a.payload;
            return !!payload.data['hideSupport'];
        }), withLatestFrom(this._store.pipe(select(showTrigger))), 
        // only toggle trigger when the
        // value is not the same as current state
        filter(function (_a) {
            var toHide = _a[0], shown = _a[1];
            var isHidden = !shown;
            return toHide !== isHidden;
        }), map(function (_a) {
            var hide = _a[0];
            if (hide) {
                return new HideTrigger();
            }
            return new ShowTrigger();
        }));
        this._onOpenWithTicketId$ = this._actions$.pipe(ofType(ActionTypes.Open), filter(function (_a) {
            var payload = _a.payload;
            return !!payload;
        }), delay(100), mergeMap(function (_a) {
            var payload = _a.payload;
            var route = _this._router.routerState.root;
            var routeCommand = ['/'];
            while (route.firstChild) {
                route = route.firstChild;
                routeCommand.push.apply(routeCommand, route.snapshot.url.map(function (u) { return u.path; }));
            }
            return [
                new SetDetailId(payload),
                new Go({ path: routeCommand })
            ];
        }));
        this._onSetStateDetail$ = this._onSetState$.pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.state === TicketsTriggerStates.Detail;
        }), map(function (_a) {
            var payload = _a.payload;
            return new SetDetailId(payload.meta);
        }));
        this._onSetStateAddSetTopic$ = this._onSetState$.pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.state === TicketsTriggerStates.Add;
        }), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._store.pipe(select(ticketTopicById(payload.meta)), first());
        }), withLatestFrom(this._store.pipe(select(identity))), map(function (_a) {
            var topic = _a[0], user = _a[1];
            return ticketAddFormUpdateGroupAction(TicketAddModuleTokens.Name, {
                ticketTopicId: topic.id,
                title: topic.name[user.language.id],
                ticketMessage: topic.template[user.language.id]
            });
        }));
        this._onSetStateAddFocus$ = this._onSetState$.pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.state === TicketsTriggerStates.Add;
        }), delay(10), map(function (_a) {
            var payload = _a.payload;
            return new FocusAction(getTicketAddFormKey(TicketAddModuleTokens.Name) + ".title");
        }));
        // reset add form state when switching away from add state
        this._onSetStateResetAddForm$ = this._onSetState$.pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.state !== TicketsTriggerStates.Add;
        }), map(function () { return new TicketAddReset(); }));
        // reset add form state when closing tickets trigger
        this._onCloseResetAddForm$ = this._actions$.pipe(ofType(ActionTypes.Close), map(function () { return new TicketAddReset(); }));
        this._onSetDetailId$ = this._actions$.pipe(ofType(ActionTypes.SetDetailId), map(function (_a) {
            var payload = _a.payload;
            return new TicketsBaseEntityRequest(payload);
        }));
        this._onMessageTempAddResetForm$ = this._actions$.pipe(ofType(TicketsBaseActionTypes.MessageAddTemp), withLatestFrom(this._store.pipe(select(showTrigger))), filter(function (_a) {
            var _ = _a[0], isTrigger = _a[1];
            return !!isTrigger;
        }), map(function () { return new ResetMessageForm(); }));
        this._onResetFormFocus$ = this._actions$.pipe(ofType(ActionTypes.ResetMessageForm), map(function () { return new FocusAction(getTicketTextFormKey(ModuleTokens.Name) + ".ticketMessage"); }));
        this._onTicketAddSwitchState$ = this._actions$.pipe(ofType(TicketAddActionTypes.AddLocalSuccess), withLatestFrom(this._store.pipe(select(showTrigger))), filter(function (_a) {
            var _ = _a[0], isTrigger = _a[1];
            return !!isTrigger;
        }), map(function (_a) {
            var meta = _a[0].meta;
            return new SetState({
                state: TicketsTriggerStates.Detail,
                meta: meta.id
            });
        }));
        // reset list filters when closing / reseting whole trigger
        this._onReset$ = this._actions$.pipe(ofType(ActionTypes.Reset), map(function () { return new TicketsListResetFilter(); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onRouteWithTicketQueryParam$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onRouteWithHideTrigger$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onOpenWithTicketId$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onSetStateDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onSetStateAddSetTopic$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onSetStateAddFocus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onSetStateResetAddForm$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onCloseResetAddForm$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onSetDetailId$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onMessageTempAddResetForm$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onResetFormFocus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onTicketAddSwitchState$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketsTriggerEffect.prototype, "_onReset$", void 0);
    return TicketsTriggerEffect;
}());
export { TicketsTriggerEffect };
