import * as tslib_1 from "tslib";
import { emailFormModel } from '@app/common/email-form';
import { TicketsBaseActionTypes } from '@app/base/tickets-base';
import { TicketCopyListState } from './ticket-copy-list.model';
import { ModuleTokens } from './ticket-copy-list.constant';
import { validate } from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
var initialState = new TicketCopyListState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = emailFormModel.reduceAction(state, action, ModuleTokens.Name, {
        email: validate(required, email)
    });
    if (action.type === TicketsBaseActionTypes.UpdateCopyListLocalSuccess) {
        return tslib_1.__assign({}, initialState);
    }
    return state;
}
