import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientServicesBaseState, ClientServiceEntityBase } from './client-services-base.model';
import { ModuleTokens } from './client-services-base.constant';
import { denormalizeList } from './client-services-base.utils';

export const slice = createFeatureSelector<ClientServicesBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  entities,
  (ids, ents) => {

    if (!ents) { return undefined; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name]
    ) as ClientServiceEntityBase[];
  }
);
