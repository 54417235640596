import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { FilesUploadRequest, FilesActionTypes, FilesEmptyQueue } from '@app/common/files';
import { ClearUploadedFiles } from '@app/common/files/files.action';
import { TicketAddApi } from './ticket-add.api';
import { ActionTypes, AddLocalSuccess, AddFail } from './ticket-add.action';
import { ticketAddFormValue } from './ticket-add.selector';
import { ModuleTokens } from './ticket-add.constant';
var TicketAddEffect = /** @class */ (function () {
    function TicketAddEffect(_actions$, _store, _api, _errorTranslation) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._errorTranslation = _errorTranslation;
        this._onAddRequest$ = this._actions$.pipe(ofType(ActionTypes.AddRequest));
        this._onTicketAddUpload$ = this._onAddRequest$.pipe(map(function () { return new FilesUploadRequest(ModuleTokens.Name); }));
        this._onTicketAddUploadFail$ = this._onTicketAddUpload$.pipe(switchMap(function (_a) {
            var payload = _a.payload;
            return _this._actions$.pipe(ofType(FilesActionTypes.UploadSingleFail), filter(function (action) { return action.payload.instanceId === payload; }), map(function (_a) {
                var meta = _a.meta;
                return meta;
            }));
        }), map(function (meta) { return new AddFail(meta); }));
        this._onTicketAddRequest$ = this._onAddRequest$.pipe(switchMap(function () { return _this._actions$.pipe(ofType(FilesActionTypes.UploadAllLocalSuccess, FilesActionTypes.UploadEmpty), filter(function (_a) {
            var payload = _a.payload;
            return payload.instanceId === ModuleTokens.Name;
        }), map(function (action) { return action.type === FilesActionTypes.UploadAllLocalSuccess
            ? action.payload.fileIds
            : []; })); }), withLatestFrom(this._store.pipe(select(ticketAddFormValue)), this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var attachments = _a[0], formVals = _a[1], clientId = _a[2];
            return _this._api
                .add$(tslib_1.__assign({}, formVals, { attachments: attachments }), clientId)
                .pipe(map(function (response) { return new AddLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new AddFail(data); })); }));
        }));
        // reset uploaded files on add success
        this._onAddLocalSuccess$ = this._actions$.pipe(ofType(ActionTypes.AddLocalSuccess), map(function () { return new ClearUploadedFiles(ModuleTokens.Name); }));
        // empty upload queue when resetting state
        this._onReset$ = this._actions$.pipe(ofType(ActionTypes.Reset), map(function () { return new FilesEmptyQueue(ModuleTokens.Name); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAddEffect.prototype, "_onTicketAddUpload$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAddEffect.prototype, "_onTicketAddUploadFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAddEffect.prototype, "_onTicketAddRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAddEffect.prototype, "_onAddLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAddEffect.prototype, "_onReset$", void 0);
    return TicketAddEffect;
}());
export { TicketAddEffect };
