<div>
  <span class="__title">{{ title }}</span>
  <mat-icon *ngIf="required" class="__required">grade</mat-icon>
</div>

<div class="__buttons">
  <button
    mat-icon-button
    type="button"
    class="__button"
    *ngFor="let star of stars; let index = index;"
    (click)="value = index + 1">
    <mat-icon>{{ !value || value <= index ? 'star_border' : 'star' }}</mat-icon>
  </button>
</div>
