import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ServersBaseApi = /** @class */ (function () {
    function ServersBaseApi(_http) {
        this._http = _http;
    }
    ServersBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/api/public/server/" + id);
    };
    ServersBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/server");
    };
    ServersBaseApi.prototype.graph$ = function (serverId, graphType, graphInterval) {
        return this._http.post("/api/public/server/" + serverId + "/graph", { graphType: graphType, graphInterval: graphInterval, factor: 5 });
    };
    ServersBaseApi.prototype.ipRange$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/ip-range");
    };
    ServersBaseApi.prototype.addIpV4$ = function (serverId, serverIpId) {
        return this._http.post("/api/public/server/" + serverId + "/ipv4", { serverIpId: serverIpId });
    };
    ServersBaseApi.prototype.addIpV6$ = function (serverId, ipV6) {
        return this._http.post("/api/public/server/" + serverId + "/ipv6", { ipV6: ipV6 });
    };
    ServersBaseApi.prototype.removeIp$ = function (serverId, serverIpId) {
        return this._http.request('delete', "/api/public/server/" + serverId + "/ip", { body: { serverIpId: serverIpId } });
    };
    ServersBaseApi.prototype.savePtr$ = function (serverId, serverIpId, ptr) {
        var _ptr = ptr === '' ? null : ptr;
        return this._http.put("/api/public/server/" + serverId + "/ptr", { serverIpId: serverIpId, ptr: _ptr });
    };
    ServersBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function ServersBaseApi_Factory() { return new ServersBaseApi(i0.inject(i1.HttpClient)); }, token: ServersBaseApi, providedIn: "root" });
    return ServersBaseApi;
}());
export { ServersBaseApi };
