import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TicketAuthorizationState } from './ticket-authorization.model';
import { ModuleTokens } from './ticket-authorization.constant';
import { formSelector, formValueSelector } from './ticket-authorization.forms';

export const slice = createFeatureSelector<TicketAuthorizationState>(ModuleTokens.Name);

export const ticketAuthorizationFormState = createSelector(
  slice,
  formSelector(ModuleTokens.Name)
);

export const ticketAuthorizationFormValue = createSelector(
  slice,
  formValueSelector(ModuleTokens.Name)
);
