<table
  mat-table
  matSort
  [dataSource]="data"
  (matSortChange)="sorted.emit($event)"
  class="__table  mat-elevation-z1">

  <!-- title -->
  <ng-container matColumnDef="title">

    <th
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef>
      <span
        class="__cell"
        translate="searchTable.title">
      </span>
    </th>

    <td
      mat-cell
      *matCellDef="let element">
      <a
        class="__link"
        (click)="clicked.emit()"
        [routerLink]="element._link">
        <span class="__cell">
          {{ element.title }}
        </span>
      </a>
    </td>

  </ng-container>

  <!-- description -->
  <ng-container matColumnDef="subtitle">

    <th
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef>
      <span
        class="__cell"
        translate="searchTable.description">
      </span>
    </th>

    <td
      mat-cell
      *matCellDef="let element">
      <span class="__cell">
        {{ element.subtitle }}
      </span>
    </td>

  </ng-container>

  <!-- date -->
  <ng-container matColumnDef="additionalInfo">

    <th
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef>
      <span
        class="__cell"
        translate="searchTable.date">
      </span>
    </th>

    <td
      mat-cell
      *matCellDef="let element">
      <span class="__cell">
        {{ (element.additionalInfo | date: 'medium') || '-' }}
      </span>
    </td>

  </ng-container>

  <!-- type -->
  <ng-container matColumnDef="entity">

    <th
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef>
      <span
        class="__cell"
        translate="searchTable.type">
      </span>
    </th>

    <td
      mat-cell
      *matCellDef="let element">
      <span class="__cell">
        <vshcz-entity-badge
          [entity]="entityTranslations[element.entity]"
          *ngIf="element.entity; else noEntityRef">
        </vshcz-entity-badge>
        <ng-template #noEntityRef>-</ng-template>
      </span>
    </td>

  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns">
  </tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
