var WrapComponent = /** @class */ (function () {
    function WrapComponent() {
        this.size = 'medium';
    }
    Object.defineProperty(WrapComponent.prototype, "classIsSmall", {
        get: function () {
            return this.size === 'small';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WrapComponent.prototype, "classIsMedium", {
        get: function () {
            return this.size === 'medium';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WrapComponent.prototype, "classIsBig", {
        get: function () {
            return this.size === 'big';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WrapComponent.prototype, "classIsFull", {
        get: function () {
            return this.size === 'full';
        },
        enumerable: true,
        configurable: true
    });
    return WrapComponent;
}());
export { WrapComponent };
