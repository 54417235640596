import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

@Injectable()
export class ErrorNormalizerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(request)
      .pipe(catchError((err: HttpErrorResponse) => {
        let normalizedError = err;

        if (!err || !err.error || !err.error.error || !err.error.error.code) {
          normalizedError = new HttpErrorResponse({
            error: {
              error: {
                code: 500,
                message: 'internal server error'
              }
            },
            status: 500,
            statusText: 'Internal Server Error',
            headers: err ? err.headers : undefined,
            url: err ? err.url : undefined
          });

          try {
            Sentry.captureMessage('Unhandled error: ' + JSON.stringify(err), Sentry.Severity.Critical);
          } catch (error) {
            Sentry.captureMessage('Unhandled error catch.', Sentry.Severity.Critical);
          }

        } else {
          normalizedError = {
            ...err,
            error: {
              ...err.error,
              error: {
                ...err.error.error,
                code: Number(err.error.error.code)
              }
            }
          };
        }

        return throwError(normalizedError);
      }));
  }
}

export const errorNormalizerInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorNormalizerInterceptor,
  multi: true
};
