import * as tslib_1 from "tslib";
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom, catchError, debounce, takeUntil, delay } from 'rxjs/operators';
import { FocusAction } from 'ngrx-forms';
import { authActiveUserClientId } from '@app/base/auth-base';
import { ErrorTranslationService } from '@app/services';
import { SearchApi } from './search.api';
import { ActionTypes, SuggestLocalSuccess, SuggestRequest, SuggestFail, SearchLocalSuccess, SearchFail, ResetSuggest } from './search.action';
import { searchFormValue } from './search.selector';
import { getFormKey } from './search.forms';
import { ModuleTokens } from './search.constant';
var SearchEffect = /** @class */ (function () {
    function SearchEffect(_api, _store, _actions$, _errorTranslation) {
        var _this = this;
        this._api = _api;
        this._store = _store;
        this._actions$ = _actions$;
        this._errorTranslation = _errorTranslation;
        this._formValue$ = this._store.pipe(select(searchFormValue), map(function (v) { return v.keyword; }));
        this._onKeywordChange = this._formValue$.pipe(distinctUntilChanged(), debounce(function () { return timer(100); }), map(function (keyword) {
            if (keyword && keyword.length) {
                return new SuggestRequest(keyword);
            }
            else {
                return new ResetSuggest();
            }
        }));
        this._onSuggestRequest$ = this._actions$.pipe(ofType(ActionTypes.SuggestRequest), withLatestFrom(this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var payload = _a[0].payload, clientId = _a[1];
            return _this._api
                .suggest$(payload, clientId)
                .pipe(takeUntil(_this._actions$.pipe(ofType(ActionTypes.ResetSuggest))), map(function (response) { return new SuggestLocalSuccess(response.fulltextSuggestList); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new SuggestFail(data); })); }));
        }));
        this._onSearchRequest$ = this._actions$.pipe(ofType(ActionTypes.SearchRequest), withLatestFrom(this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var payload = _a[0].payload, clientId = _a[1];
            return _this._api
                .search$(payload, clientId)
                .pipe(map(function (response) { return new SearchLocalSuccess(response.fulltextSearchList); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new SearchFail(data); })); }));
        }));
        this._onSearchOpen$ = this._actions$.pipe(ofType(ActionTypes.Open), delay(100), map(function () { return new FocusAction(getFormKey(ModuleTokens.Name) + ".keyword"); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchEffect.prototype, "_onKeywordChange", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchEffect.prototype, "_onSuggestRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchEffect.prototype, "_onSearchRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchEffect.prototype, "_onSearchOpen$", void 0);
    return SearchEffect;
}());
export { SearchEffect };
