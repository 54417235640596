import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './client-services-base.constant';
import { ClientServicesBaseState } from './client-services-base.model';
import {
  Actions,
  ActionTypes
} from './client-services-base.action';

const initialState = new ClientServicesBaseState();

export function reducer(
  state = initialState,
  action: Actions
): ClientServicesBaseState {
  state = applyEntities<ClientServicesBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === ActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  return state;
}
