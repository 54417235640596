<span
  [ngClass]="{
    'is-order': type === feeTypes.Order,
    'is-change-order': type === feeTypes.ChangeOrder
  }"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  matTooltipPosition="after"
  matTooltipClass="c-search-tooltip"
  matTooltipShowDelay="400"
  class="__type-icon">
</span>