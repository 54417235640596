import * as tslib_1 from "tslib";
import { DomainRecordSimpleBase } from './domains-base.model';
var DomainRecordBaseState = /** @class */ (function () {
    function DomainRecordBaseState() {
        this.recordsMap = {};
    }
    return DomainRecordBaseState;
}());
export { DomainRecordBaseState };
var DomainRecordsWithChangeStatus = /** @class */ (function (_super) {
    tslib_1.__extends(DomainRecordsWithChangeStatus, _super);
    function DomainRecordsWithChangeStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DomainRecordsWithChangeStatus;
}(DomainRecordSimpleBase));
export { DomainRecordsWithChangeStatus };
var DomainRecordEntityLight = /** @class */ (function () {
    function DomainRecordEntityLight() {
    }
    return DomainRecordEntityLight;
}());
export { DomainRecordEntityLight };
var DomainRecordEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(DomainRecordEntityBase, _super);
    function DomainRecordEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DomainRecordEntityBase;
}(DomainRecordEntityLight));
export { DomainRecordEntityBase };
