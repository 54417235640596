import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, merge } from 'rxjs';
import { filter, map, tap, switchMap, takeUntil, first, catchError, pairwise, retryWhen, delay, withLatestFrom } from 'rxjs/operators';
import { AuthActionTypes, getStoredAuth, authActiveUserClient, authorType, cloudDnsRoleCode } from '@app/base/auth-base';
import { WebsocketStatuses } from '@app/common/websockets';
import { ClouddnsWebsocketsApi } from './clouddns-websockets.api';
import { ActionTypes, Message, StatusChange, Connected, Reconnect } from './clouddns-websockets.action';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';
var ClouddnsWebsocketsEffect = /** @class */ (function () {
    function ClouddnsWebsocketsEffect(_actions$, _api, _store) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._store = _store;
        this._onAuthorize$ = merge(this._actions$.pipe(ofType(AuthActionTypes.Authorize), map(function (_a) {
            var payload = _a.payload;
            return payload.auth.accessToken;
        })), this._actions$.pipe(ofType(AuthActionTypes.RefreshTokenLocalSuccess), map((function (_a) {
            var payload = _a.payload;
            return payload;
        }))), this._actions$.pipe(ofType(ActionTypes.Reconnect), map((function (_a) {
            var payload = _a.payload;
            return payload;
        }))));
        this._takeUntil$ = this._actions$.pipe(ofType(AuthActionTypes.InvalidateAuthorization));
        this._onAuthorizeConnect$ = this._onAuthorize$.pipe(filter(function () { return toBoolean(environment.enableCDNS); }), switchMap(function (token) { return _this._store.pipe(select(authActiveUserClient), filter(function (user) { return !!user; }), first(), withLatestFrom(_this._store.pipe(select(authorType)), _this._store.pipe(select(cloudDnsRoleCode))), filter(function (_a) {
            var userClient = _a[0], author = _a[1], code = _a[2];
            return userClient.cloudDnsEnabled
                || (userClient.cloudDnsEnabled && author === 'BACKOFFICE' && (code === 'MODIFY_ALL_DNS' || code === 'MODIFY_CLIENT_DNS'));
        }), switchMap(function () { return _this._api
            .auth$(token)
            .pipe(tap(function (socketToken) { return _this._api.connect(socketToken.webSocketToken); }), map(function (socketToken) { return socketToken.webSocketToken; }), retryWhen(function (errors) { return errors.pipe(delay(5000)); }), catchError(function () { return of(undefined); })); }), filter(function (t) { return !!t; }), switchMap(function () { return _this._api
            .status$
            .pipe(filter(function (status) { return status === WebsocketStatuses.CONNECTING; }), first(), map(function () { return new Connected(); })); })); }));
        this._onMessage$ = this._actions$.pipe(ofType(ActionTypes.Connected), switchMap(function () { return _this._api
            .messages$
            .pipe(takeUntil(merge(_this._takeUntil$, 
        // check if previous status was 1 (connect)
        // and new status is 0 (not connect)
        // which means connection was closed and needs to be
        // reconnected
        _this._api.status$.pipe(pairwise(), filter(function (_a) {
            var a = _a[0], b = _a[1];
            return a === 1 && b === 0;
        }), tap(function () {
            var lsAuth = getStoredAuth();
            if (lsAuth) {
                var auth = getStoredAuth().auth;
                _this._store.dispatch(new Reconnect(auth.accessToken));
            }
        })))), map(function (message) {
            var parsed = JSON.parse(message);
            return new Message(parsed);
        })); }));
        this._onStatusChange$ = this._actions$.pipe(ofType(ActionTypes.StatusChange), switchMap(function () { return _this._api
            .status$
            .pipe(takeUntil(_this._takeUntil$), map(function (payload) { return new StatusChange(payload); })); }));
        this._onSend$ = this._actions$.pipe(ofType(ActionTypes.Send), tap(function (action) { return _this._api.send(action.payload); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClouddnsWebsocketsEffect.prototype, "_onAuthorizeConnect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClouddnsWebsocketsEffect.prototype, "_onMessage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClouddnsWebsocketsEffect.prototype, "_onStatusChange$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ClouddnsWebsocketsEffect.prototype, "_onSend$", void 0);
    return ClouddnsWebsocketsEffect;
}());
export { ClouddnsWebsocketsEffect };
