import { Params, RouterStateSnapshot } from '@angular/router';

import { StoreModule, ActionReducerMap } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  routerReducer,
  RouterReducerState,
  RouterStateSerializer
} from '@ngrx/router-store';
import { RouterStateUrl } from './ngrx-router.model';

export class RouterSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams } } = routerState;
    const { params, data } = route;

    return { url, params, queryParams, data };
  }
}

export const routerSerializerProvider = {
  provide: RouterStateSerializer,
  useClass: RouterSerializer
};
