import { Language } from '@app/common/settings';
import { ClientUserEntity } from '@app/base/client-user-base';
import { ModuleTokens } from './users-base.constant';

export class UsersBaseState {
  entities: UserEntityEntities;

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
  }
}

export class UserEntityEntities {
  [ModuleTokens.Name]: { [id: string]: UserEntityNormalized; };
}

export class UserEntityNormalizedData {
  entities: UserEntityEntities;
  result: string[];
}

export type UserStatus = 'WAITING_AUTHORIZATION' | 'ACTIVE' | 'BEING_DELETED';

export class UserLightEntity {
  id: string;
  email: string;
  fullName: string;
}

export class UserEntityBase extends UserLightEntity {
  countryCallingCode: number;
  created: string;
  firstName: string;
  language: Language;
  lastName: string;
  lastUpdate: string;
  loginMethods: {
    appleId: string;
    googleAccount: string;
    mojeId: string;
    password: boolean;
  };
  phoneNumber: number;
  smsNotifications: boolean;
  status: UserStatus;
}

export class UserEntityNormalized extends UserEntityBase {
  clientUserList: string[];
}

export class UserEntity extends UserEntityBase {
  clientUserList: ClientUserEntity[];
}
