import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DialogModule } from '@zerops/fe';
import { RecaptchaModule as NgRecaptchaModule } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { reducer } from './recaptcha.reducer';
import { ModuleTokens } from './recaptcha.constant';
import { recaptchaInterceptorProvider } from './recaptcha.interceptor';
import { RecaptchaComponent } from './recaptcha.component';
import { ProgressItemModule } from '../progress-item';

@NgModule({
  declarations: [ RecaptchaComponent ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    DialogModule,
    NgRecaptchaModule,
    TranslateModule.forChild(),
    ProgressItemModule
  ],
  exports: [ RecaptchaComponent ],
  providers: [
    recaptchaInterceptorProvider
  ]
})
export class RecaptchaModule {

}
