/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-pop-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../popover/popover-anchoring.service";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "../../../popover/popover-anchor.directive";
import * as i14 from "./filter-pop-button.component";
var styles_FilterPopButtonComponent = [i0.styles];
var RenderType_FilterPopButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterPopButtonComponent, data: {} });
export { RenderType_FilterPopButtonComponent as RenderType_FilterPopButtonComponent };
function View_FilterPopButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "__select-arrow mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_drop_down"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_FilterPopButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FilterPopButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_4)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupTemplate; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FilterPopButtonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FilterPopButtonComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_FilterPopButtonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "strong", [["class", "__filter-pop-active-button-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterPopButtonComponent_7)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_8)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = _co.itemTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 5, 0, currVal_2); }, null); }
function View_FilterPopButtonComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [["class", "__extras"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" +", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.extras; _ck(_v, 1, 0, currVal_0); }); }
function View_FilterPopButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_9)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.extras; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FilterPopButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_5)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.groupTemplate; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.groupTemplate; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prefix; _ck(_v, 1, 0, currVal_0); }); }
function View_FilterPopButtonComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "__reset-button"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.reset.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "__reset-button_icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["highlight_off"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_FilterPopButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "button", [["class", "__button"], ["mat-button", ""], ["type", "button"]], [[2, "is-active", null], [2, "has-reset-disabled", null], [2, "is-open", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.anchorForRef.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵprd(131584, null, i10.PopoverAnchoringService, i10.PopoverAnchoringService, [i11.Overlay, i1.NgZone, [2, i12.Directionality]]), i1.ɵdid(3, 212992, null, 0, i13.PopoverAnchorDirective, [i1.ElementRef, i1.ViewContainerRef, i10.PopoverAnchoringService], { attachedPopover: [0, "attachedPopover"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "div", [["class", "__item"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["activeRef", 2]], null, 0, null, View_FilterPopButtonComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopButtonComponent_10)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.anchorForRef; _ck(_v, 3, 0, currVal_5); var currVal_6 = !_co.items.length; var currVal_7 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_6, currVal_7); var currVal_8 = (_co.items.length && !_co.disableReset); _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.items.length; var currVal_1 = _co.disableReset; var currVal_2 = !!_co.anchorForRef.isOpen(); var currVal_3 = (i1.ɵnov(_v, 1).disabled || null); var currVal_4 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_FilterPopButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "vshcz-filter-pop-button", [], null, null, null, View_FilterPopButtonComponent_0, RenderType_FilterPopButtonComponent)), i1.ɵdid(1, 49152, null, 2, i14.FilterPopButtonComponent, [], null, null), i1.ɵqud(335544320, 1, { groupTemplate: 0 }), i1.ɵqud(335544320, 2, { itemTemplate: 0 })], null, null); }
var FilterPopButtonComponentNgFactory = i1.ɵccf("vshcz-filter-pop-button", i14.FilterPopButtonComponent, View_FilterPopButtonComponent_Host_0, { maxSize: "maxSize", prefix: "prefix", anchorForRef: "anchorForRef", disableReset: "disableReset", placeholder: "placeholder", activeGroup: "activeGroup", items: "items" }, { reset: "reset" }, ["*"]);
export { FilterPopButtonComponentNgFactory as FilterPopButtonComponentNgFactory };
