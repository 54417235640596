import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  getTicketEntityById,
  TicketsBaseActionTypes,
  TicketsBaseConfirmRealizationDateRequest,
  TicketsBaseRejectRealizationDateRequest
} from '@app/base/tickets-base';
import { select, Store } from '@ngrx/store';
import { State } from '@app/models';
import { ObservableInput } from 'observable-input';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { progressByKey } from '@zerops/fe/ngrx';
import { BaseClass } from '@zerops/fe/core';
import { activeUserClientId } from '@app/base/auth-base';
import {
  ticketRealizationDateFormState,
  ticketRealizationDateFormValue
} from './ticket-realization-date.selector';

@Component({
  selector: 'vshcz-ticket-realization-date',
  templateUrl: './ticket-realization-date.container.html',
  styleUrls: [ './ticket-realization-date.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketRealizationDateContainer extends BaseClass {

  // # Event Streams
  onConfirmRealizationDate$ = new Subject<void>();
  onRejectRealizationDate$ = new Subject<void>();

  // # Form States
  ticketRealizationDateFormState$ = this._store.pipe(select(ticketRealizationDateFormState));

  // # Data
  // -- angular
  @Input()
  active: boolean;

  @ObservableInput()
  @Input('id')
  id$!: Observable<string>;

  // -- async
  rejectRealizationDateRequestKey$ = this.id$.pipe(
    map((id) => `${TicketsBaseActionTypes.RejectRealizationDateRequest}:${id}`)
  );
  rejectRealizationDateFailKey$ = this.id$.pipe(
    map((id) => `${TicketsBaseActionTypes.RejectRealizationDateFail}:${id}`)
  );
  realizationDateConfirmLoading$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(select(progressByKey(`${TicketsBaseActionTypes.ConfirmRealizationDateRequest}:${id}`)))),
    map((loading) => !!loading)
  );
  realizationDateRejectLoading$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(select(progressByKey(`${TicketsBaseActionTypes.RejectRealizationDateRequest}:${id}`)))),
    map((loading) => !!loading)
  );
  activeClientId$ = this._store.pipe(select(activeUserClientId));
  ticket$ = this.id$.pipe(switchMap((id) => this._store.pipe(
    select(getTicketEntityById(id)),
    filter((t) => !!t)
  )));

  // # Action Streams
  private _confirmRealizationDateAction$ = this.onConfirmRealizationDate$.pipe(
    withLatestFrom(
      this.ticket$,
      this.activeClientId$,
    ),
    map(([ _, { id }, activeClientId ]) => {
      return new TicketsBaseConfirmRealizationDateRequest(
        id,
        {
          clientId: activeClientId
        }
      );
    })
  );
  private _rejectRealizationDateAction$ = this.onRejectRealizationDate$.pipe(
    withLatestFrom(
      this.ticket$,
      this.activeClientId$,
      this._store.pipe(select(ticketRealizationDateFormValue))
    ),
    map(([ _, { id }, activeClientId, { messageText } ]) => new TicketsBaseRejectRealizationDateRequest(
        id,
        {
          clientId: activeClientId,
          messageText
        }
    ))
  );

  constructor(private _store: Store<State>) {
    super();

    // # Action Dispatcher
    merge(
      this._confirmRealizationDateAction$,
      this._rejectRealizationDateAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }
}
