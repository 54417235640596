import * as tslib_1 from "tslib";
import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './client-user-base.constant';
import { ClientUserBaseState } from './client-user-base.model';
import { ActionTypes } from './client-user-base.action';
var initialState = new ClientUserBaseState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    state = applyEntities(state, [ModuleTokens.Name], action);
    if (action.type === ActionTypes.ListLocalSuccess) {
        return tslib_1.__assign({}, state, { list: action.payload.result });
    }
    if (action.type === ActionTypes.AddToList) {
        return tslib_1.__assign({}, state, { list: [
                action.payload
            ].concat(state.list) });
    }
    return state;
}
