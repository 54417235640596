import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AvatarSource } from '@app/common/avatar';
import { TicketFile } from '@app/base/tickets-base';

@Component({
  selector: 'vshcz-ticket-message',
  templateUrl: './ticket-message.component.html',
  styleUrls: [ './ticket-message.component.scss' ]
})
export class TicketMessageComponent {
  showHidden = false;

  @Input()
  text: string;

  @Input()
  hiddenText: string;

  @Input()
  created: string;

  @Input()
  signature: string;

  @Input()
  attachments: TicketFile[];

  @Input()
  avatarSource: AvatarSource;

  @Input()
  avatarMail: string;

  @Input()
  avatarImage: string;

  @Input()
  avatarSize = 40;

  @Input()
  isPrimary: boolean;

  @Input()
  uploadState: boolean;

  @Input()
  avatarTooltip = 40;

  @Input()
  align: 'left' | 'right';

  @Output()
  attachmentOpen = new EventEmitter<{
    files: TicketFile[],
    element: HTMLElement
  }>();
}
