import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vshcz-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: [ './payment-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentStatusComponent {
  @Input()
  tooltip: string;

  @Input()
  status: 'OK' | 'AMOUNT_NOT_MATCHED';
}
