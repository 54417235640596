<span
  [ngClass]="{
    '__status-icon--green': status === 'OK',
    '__status-icon--orange': status === 'AMOUNT_NOT_MATCHED'
  }"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  matTooltipPosition="after"
  matTooltipClass="c-search-tooltip"
  matTooltipShowDelay="400"
  class="__status-icon">
</span>
