import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '@app/common/logo';
import { DonutChartModule } from '@app/common/donut-chart';
import { ServiceItemModule } from '@app/common/service-item';
import { ClientServiceItemModule } from '@app/common/client-service-item';
import { KvmBadgeModule } from '@app/common/kvm-badge';
import { ServicesCardContainer } from './services-card.container';
import { SafeUrlModule } from '@app/common/safe-url';
import { MatSlideToggleModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ ServicesCardContainer ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatCardModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    LogoModule,
    DonutChartModule,
    ServiceItemModule,
    ClientServiceItemModule,
    KvmBadgeModule,
    SafeUrlModule
  ],
  exports: [ ServicesCardContainer ]
})
export class ServicesCardModule { }
