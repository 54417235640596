<ng-container *ngIf="(data$ | async) as data">
  <vshcz-scrollable [height]="height">
    <div
      class="__messages"
      [class.is-loaded]="_loaded">
      <div *ngFor="let item of data.ticketMessageList; trackBy: trackBy">
        <vshcz-ticket-message
          class="__item"
          (attachmentOpen)="attachmentOpen($event)"
          [class.is-loading]="messageAddTempMap[messageAddTempKey + ':' + item.id]"
          [created]="item.created"
          [avatarTooltip]="item.display === 'USER' ? item.author.fullName : 'VSHosting'"
          [avatarSource]="avatarSourceMap[item.display] ? avatarSourceMap[item.display] : 'clearbit'"
          [avatarMail]="item.author ? item.author.email : undefined"
          [avatarImage]="'/assets/images/logo.png'"
          [align]="item.display === 'USER' ? 'right' : 'left'"
          [isPrimary]="item.display === 'USER'"
          [attachments]="item.attachments"
          [hiddenText]="item.messageHiddenText"
          [signature]="item.signature"
          [text]="item.messageText">
        </vshcz-ticket-message>
      </div>
    </div>
  </vshcz-scrollable>
</ng-container>

<vshcz-popover
  hasBackdrop
  verticalAlign="end"
  (afterClose)="attachments = undefined">
  <mat-card
    class="__attachments-pop  mat-elevation-z5"
    *ngIf="attachments">
    <mat-list>
      <mat-list-item
        class="__attachments-pop_item"
        *ngFor="let file of attachments; let i = index">
        <h4 mat-line class="__attachments-file-name">{{ file.name }}</h4>
        <p
          mat-line
          [innerHTML]="'common.files.fileSize' | translate: { size: file.sizeFormatted }">
        </p>
        <button
          type="button"
          (click)="!!onFileDownload$.next(file.id)"
          class="__attachments-pop_download"
          mat-icon-button>
          <mat-icon>cloud_download</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-card>
</vshcz-popover>
