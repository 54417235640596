import {
  Component,
  Directive,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { PermissionService } from '@zerops/fe/permission';
import { Roles } from '@app/base/auth-base';
import { offset } from 'utils';
import { ItemCoordinates } from '../../menu.model';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';

@Directive({
  selector: '[vshczMenuItem]',
  exportAs: 'vshMenuItem'
})
export class MenuItemDirective implements AfterViewInit {
  @Output()
  entered = new EventEmitter<ItemCoordinates>();

  @Output()
  leave = new EventEmitter<HTMLElement>();

  private _width:  number;
  private _height:  number;
  private _offsetLeft: number;
  private _offsetTop: number;

  constructor(private _elementRef: ElementRef<HTMLElement>) { }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(_: MouseEvent) {
    const coor = {
      x: this._offsetLeft,
      y: this._offsetTop,
      xCenter: this._offsetLeft + (this._width / 2),
      yCenter: this._offsetTop + (this._height / 2)
    };

    this.entered.emit(coor);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e: any) {
    this.leave.emit(e.toElement || e.relatedTarget);
  }

  @HostListener('window:resize')
  onResize() {
    this.refresh();
  }

  ngAfterViewInit() {
    this._setSizes();
  }

  refresh() {
    this._setSizes();
  }

  private _setSizes() {
    const { left, top } = offset(this._elementRef.nativeElement);

    this._width = this._elementRef.nativeElement.clientWidth;
    this._height = this._elementRef.nativeElement.clientHeight;
    this._offsetLeft = left;
    this._offsetTop = top;
  }
}

@Component({
  selector: 'vshcz-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent {
  // # Data
  // -- sync
  coordinates: ItemCoordinates;
  activeIndex: number;
  menuPopOpen: boolean;

  companySelectOpened = false;

  // -- angular
  @ViewChildren(MenuItemDirective)
  menuItems: QueryList<MenuItemDirective>;

  // -- async
  showBilling$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Financial ]
  });

  showServices$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Technical, Roles.Financial ]
  });

  showCompany$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Technical, Roles.Financial ]
  });

  showGraphsSettingsAndExternal$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Technical, Roles.Financial ]
  });

  showFinancialAndStatus$ = this._permissions.authorize({
    only: [ Roles.Manager, Roles.Financial ]
  });

  currentLang$ = this._translate.onLangChange.pipe(
    startWith(this._translate.currentLang),
    map(() => this._translate.currentLang)
  );

  @Input()
  overdueInvoicesCount: number;

  @Input()
  servicesCount: number;

  private _leaveTimer: any;
  private _enterTimer: any;

  constructor(
    public media: ObservableMedia,
    private _cdRef: ChangeDetectorRef,
    private _permissions: PermissionService,
    private _translate: TranslateService,
  ) { }

  onEntered(e: ItemCoordinates, index: number, delay = false) {
    if (this._leaveTimer) {
      clearTimeout(this._leaveTimer);
    }

    this.coordinates = e;
    this.activeIndex = index;

    // optionally give some time to pass over without pop opening
    if (!!delay) {
      this._enterTimer = setTimeout(() => {
        this.menuPopOpen = true;
        this._cdRef.markForCheck();
      }, 200);
    } else {
      this.menuPopOpen = true;
    }
  }

  // give user 100ms to return to the pop before closing it
  onLeave() {
    this._leaveTimer = setTimeout(() => {
      this.menuPopOpen = false;
      this._cdRef.markForCheck();
    }, 100);
  }

  onButtonLeave() {
    if (this._enterTimer) {
      clearTimeout(this._enterTimer);
    }
  }

  refresh() {
    if (this.menuItems) {
      this.menuItems.forEach((item) => item.refresh());
    }
  }
}
