/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./content-title.component";
var styles_ContentTitleComponent = [i0.styles];
var RenderType_ContentTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentTitleComponent, data: {} });
export { RenderType_ContentTitleComponent as RenderType_ContentTitleComponent };
function View_ContentTitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ContentTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "__title  __title--big"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentTitleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ContentTitleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "__title  __title--medium"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentTitleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ContentTitleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "__title  __title--small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_6)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentTitleComponent_7(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ContentTitleComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentTitleComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["contentTpl", 2]], null, 0, null, View_ContentTitleComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size === "big"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.size === "medium"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.size === "small"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ContentTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-content-title", [], [[4, "text-align", null]], null, null, View_ContentTitleComponent_0, RenderType_ContentTitleComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContentTitleComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).textAlignStyle; _ck(_v, 0, 0, currVal_0); }); }
var ContentTitleComponentNgFactory = i1.ɵccf("vshcz-content-title", i3.ContentTitleComponent, View_ContentTitleComponent_Host_0, { position: "position", size: "size" }, {}, ["*"]);
export { ContentTitleComponentNgFactory as ContentTitleComponentNgFactory };
