import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, delay } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { AuthorizedRunEffect } from '../auth-base/services';
import { activeUserClientId } from '../auth-base/auth-base.selector';
import { ActionTypes, EntityLocalSuccess, EntityFail, ListRequest, ListLocalSuccess, ListFail } from './client-user-base.action';
import { ClientUserBaseApi } from './client-user-base.api';
var ClientUserBaseEffect = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserBaseEffect, _super);
    function ClientUserBaseEffect(_actions$, _api, _store, _errorTranslation) {
        var _this = _super.call(this, _actions$, _store) || this;
        _this._actions$ = _actions$;
        _this._api = _api;
        _this._store = _store;
        _this._errorTranslation = _errorTranslation;
        _this._onInitListRequest$ = _this.onAuthorizedInit$.pipe(delay(0), map(function () { return new ListRequest(); }));
        _this._onEntityRequest$ = _this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        }));
        _this._onListRequest$ = _this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(_this._store.pipe(select(activeUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .list$(id)
                .pipe(map(function (_a) {
                var clientUserList = _a.clientUserList;
                return new ListLocalSuccess(clientUserList);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        return _this;
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClientUserBaseEffect.prototype, "_onInitListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClientUserBaseEffect.prototype, "_onEntityRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClientUserBaseEffect.prototype, "_onListRequest$", void 0);
    return ClientUserBaseEffect;
}(AuthorizedRunEffect));
export { ClientUserBaseEffect };
