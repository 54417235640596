import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RemoveError } from '@zerops/fe/ngrx';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom, switchMap, catchError } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { ServerParkAccessApi } from './server-park-access-base.api';
import { ActionTypes, ListLocalSuccess, ListFail, DeleteLocalSuccess, DeleteFail } from './server-park-access-base.action';
var ServerParkAccessListEffect = /** @class */ (function () {
    function ServerParkAccessListEffect(_actions$, _store, _api, _snack, _errorTranslation) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._snack = _snack;
        this._errorTranslation = _errorTranslation;
        this._onListRequest$ = this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .list$(id)
                .pipe(map(function (_a) {
                var clientPersonServerParkAccess = _a.clientPersonServerParkAccess;
                return new ListLocalSuccess(clientPersonServerParkAccess);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        this._onDeleteRequest$ = this._actions$.pipe(ofType(ActionTypes.DeleteRequest), switchMap(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return _this._api
                .delete$(payload)
                .pipe(map(function () { return new DeleteLocalSuccess(payload, meta); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new DeleteFail(data, payload); })); }));
        }));
        this._onDeleteFail$ = this._actions$.pipe(ofType(ActionTypes.DeleteFail), switchMap(function (action) { return _this._snack.translatedFail$(action.meta.message, 'common.close'); }), map(function () { return new RemoveError(ActionTypes.DeleteFail); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerParkAccessListEffect.prototype, "_onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerParkAccessListEffect.prototype, "_onDeleteRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerParkAccessListEffect.prototype, "_onDeleteFail$", void 0);
    return ServerParkAccessListEffect;
}());
export { ServerParkAccessListEffect };
