import { Component, Input } from '@angular/core';
import { DEFAULT_TRANSLATION_KEYS } from './email-form.translations';
import { EmailFormState } from './email-form.model';

@Component({
  selector: 'vshcz-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: [ './email-form.component.scss' ]
})
export class EmailFormComponent {
  @Input()
  state: EmailFormState;

  @Input()
  translations = DEFAULT_TRANSLATION_KEYS;

  @Input()
  floatLabel = 'auto';

  @Input()
  showHint = true;

  @Input()
  showPlaceholder = true;
}
