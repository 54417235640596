import * as tslib_1 from "tslib";
import { EntitiesMergeStrategy } from '@zerops/fe/core';
import { normalizeList, expandIPv6Address } from './servers-base.utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["AddIpV4Request"] = "[Servers Base IP] Add IpV4 Request";
    ActionTypes["AddIpV4Fail"] = "[Servers Base IP] Add IpV4 Fail";
    ActionTypes["AddIpV4LocalSuccess"] = "[Servers Base IP] Add IpV4 Local Success";
    ActionTypes["AddIpV6Request"] = "[Servers Base IP] Add IpV6 Request";
    ActionTypes["AddIpV6Fail"] = "[Servers Base IP] Add IpV6 Fail";
    ActionTypes["AddIpV6LocalSuccess"] = "[Servers Base IP] Add IpV6 Local Success";
    ActionTypes["DeleteIpRequest"] = "[Servers Base IP] Delete Ip Request";
    ActionTypes["DeleteIpFail"] = "[Servers Base IP] Delete Ip Fail";
    ActionTypes["DeleteIpLocalSuccess"] = "[Servers Base IP] Delete Ip Local Success";
    ActionTypes["SavePtrRequest"] = "[Servers Base IP] Set PTR Request";
    ActionTypes["SavePtrFail"] = "[Servers Base IP] Set PTR Fail";
    ActionTypes["SavePtrLocalSuccess"] = "[Servers Base IP] Set PTR Local Success";
})(ActionTypes || (ActionTypes = {}));
var AddIpV4Request = /** @class */ (function () {
    function AddIpV4Request(payload) {
        this.payload = payload;
        this.type = ActionTypes.AddIpV4Request;
        this.errors = {
            remove: ActionTypes.AddIpV4Fail
        };
        this.progress = {
            add: {
                key: ActionTypes.AddIpV4Request + ":" + payload.serverId,
                type: 'local'
            }
        };
    }
    return AddIpV4Request;
}());
export { AddIpV4Request };
var AddIpV4Fail = /** @class */ (function () {
    function AddIpV4Fail(data, id) {
        this.type = ActionTypes.AddIpV4Fail;
        this.errors = {
            add: {
                key: ActionTypes.AddIpV4Fail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.AddIpV4Request + ":" + id
        };
    }
    return AddIpV4Fail;
}());
export { AddIpV4Fail };
var AddIpV4LocalSuccess = /** @class */ (function () {
    function AddIpV4LocalSuccess(data, id, ptr, ipId, ip) {
        this.type = ActionTypes.AddIpV4LocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.meta = { data: data, id: ipId, ip: ip };
        if (ptr && ipId) {
            this.meta = tslib_1.__assign({}, this.meta, { serverId: data.id, ptr: ptr, id: ipId });
        }
        this.progress = {
            remove: ActionTypes.AddIpV4Request + ":" + id
        };
    }
    return AddIpV4LocalSuccess;
}());
export { AddIpV4LocalSuccess };
var AddIpV6Request = /** @class */ (function () {
    function AddIpV6Request(payload) {
        this.payload = payload;
        this.type = ActionTypes.AddIpV6Request;
        this.errors = {
            remove: ActionTypes.AddIpV6Fail
        };
        this.progress = {
            add: {
                key: ActionTypes.AddIpV6Request + ":" + payload.serverId,
                type: 'local'
            }
        };
    }
    return AddIpV6Request;
}());
export { AddIpV6Request };
var AddIpV6Fail = /** @class */ (function () {
    function AddIpV6Fail(data, id) {
        this.type = ActionTypes.AddIpV6Fail;
        this.errors = {
            add: {
                key: ActionTypes.AddIpV6Fail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.AddIpV6Request + ":" + id
        };
    }
    return AddIpV6Fail;
}());
export { AddIpV6Fail };
var AddIpV6LocalSuccess = /** @class */ (function () {
    function AddIpV6LocalSuccess(data, id, ptr, ip) {
        this.type = ActionTypes.AddIpV6LocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        var foundIp = data.serverIpV6List
            .map(function (s) { return (tslib_1.__assign({}, s, { expandedIpV6: expandIPv6Address(s.ipV6) })); })
            .find(function (s) { return s.expandedIpV6 === ip; });
        this.meta = {
            data: data,
            ip: ip,
            id: foundIp ? foundIp.id : undefined
        };
        if (ptr && ip) {
            this.meta = tslib_1.__assign({}, this.meta, { serverId: data.id, ptr: ptr,
                ip: ip, id: foundIp ? foundIp.id : undefined });
        }
        this.progress = {
            remove: ActionTypes.AddIpV6Request + ":" + id
        };
    }
    return AddIpV6LocalSuccess;
}());
export { AddIpV6LocalSuccess };
var DeleteIpRequest = /** @class */ (function () {
    function DeleteIpRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.DeleteIpRequest;
        this.errors = {
            remove: ActionTypes.DeleteIpFail
        };
        this.progress = {
            add: {
                key: ActionTypes.DeleteIpRequest + ":" + this.payload.id,
                type: 'local',
                group: ActionTypes.DeleteIpRequest
            }
        };
    }
    return DeleteIpRequest;
}());
export { DeleteIpRequest };
var DeleteIpFail = /** @class */ (function () {
    function DeleteIpFail(data, id) {
        this.type = ActionTypes.DeleteIpFail;
        this.meta = data;
        this.errors = {
            add: {
                key: ActionTypes.DeleteIpFail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.DeleteIpRequest + ":" + id
        };
    }
    return DeleteIpFail;
}());
export { DeleteIpFail };
var DeleteIpLocalSuccess = /** @class */ (function () {
    function DeleteIpLocalSuccess(data, id, ip, type) {
        this.type = ActionTypes.DeleteIpLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.meta = {
            id: id,
            ip: ip,
            type: type,
            _mergeStrategy: {
                serverIpV4List: EntitiesMergeStrategy.KeepNew,
                serverIpV6List: EntitiesMergeStrategy.KeepNew
            }
        };
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.DeleteIpRequest + ":" + id
        };
    }
    return DeleteIpLocalSuccess;
}());
export { DeleteIpLocalSuccess };
var SavePtrRequest = /** @class */ (function () {
    function SavePtrRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.SavePtrRequest;
        this.errors = {
            remove: ActionTypes.SavePtrFail
        };
        this.progress = {
            add: {
                key: ActionTypes.SavePtrRequest + ":" + this.payload.id,
                type: 'local',
                group: ActionTypes.SavePtrRequest
            }
        };
    }
    return SavePtrRequest;
}());
export { SavePtrRequest };
var SavePtrFail = /** @class */ (function () {
    function SavePtrFail(data, id) {
        this.type = ActionTypes.SavePtrFail;
        this.errors = {
            add: {
                key: ActionTypes.SavePtrFail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.SavePtrRequest + ":" + id
        };
    }
    return SavePtrFail;
}());
export { SavePtrFail };
var SavePtrLocalSuccess = /** @class */ (function () {
    function SavePtrLocalSuccess(data, id, meta) {
        this.meta = meta;
        this.type = ActionTypes.SavePtrLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.SavePtrRequest + ":" + id
        };
    }
    return SavePtrLocalSuccess;
}());
export { SavePtrLocalSuccess };
