import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, switchMap, withLatestFrom, catchError, filter, take } from 'rxjs/operators';
import { of } from 'rxjs';
import omit from 'lodash-es/omit';
import { RemoveError } from '@zerops/fe/ngrx';
import { DialogOpen } from '@zerops/fe/dialog';
import { AuthorizedRunEffect, authActiveUserClientId } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { DomainRoutingInfoDialogKey } from '@app/common/domain-routing-info-dialog';
import { ErrorTranslationService } from '@app/services';
import { ofWsType } from '@app/common/websockets';
import { CloudDnsWebsocketActionTypes } from '@app/common/clouddns-websockets';
import { DomainsBaseApi } from './domains-base.api';
import { ListLocalSuccess, ListFail, ActionTypes, EntityFail, EntityLocalSuccess, PublishLocalSuccess, PublishFail, ResetChangesLocalSuccess, ResetChangesFail, AddLocalSuccess, AddFail, DeleteLocalSuccess, DeleteFail, RestoreFail, RestoreLocalSuccess, DeactivateLocalSuccess, DeactivateFail, ActivateLocalSuccess, ActivateFail, SoaRequestLocalSuccess, SoaRequestFail, SoaUpdateLocalSuccess, SoaUpdateFail, DnssecKeyLocalSuccess, DnssecKeyFail } from './domains-base.action';
import { getDomainEntityById } from './domains-base.selector';
import { DomainStatuses } from './domains-base.constant';
var DomainsBaseEffect = /** @class */ (function (_super) {
    tslib_1.__extends(DomainsBaseEffect, _super);
    function DomainsBaseEffect(_actions$, _store, _api, _errorTranslation, _snack) {
        var _this = _super.call(this, _actions$, _store) || this;
        _this._actions$ = _actions$;
        _this._store = _store;
        _this._api = _api;
        _this._errorTranslation = _errorTranslation;
        _this._snack = _snack;
        // list request
        _this._onListRequest$ = _this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], clientId = _a[1];
            return _this._api
                .list$(clientId)
                .pipe(map(function (response) { return new ListLocalSuccess(response.items); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        // entity request
        _this._onEntityRequest$ = _this._actions$.pipe(ofType(ActionTypes.EntityRequest));
        _this._onDetailRequest$ = _this._onEntityRequest$.pipe(switchMap((function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        })));
        _this._onEntityRequestRegisterSearch$ = _this._onEntityRequest$.pipe(withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var payload = _a[0].payload, clientId = _a[1];
            return _this._api
                .registerSearchForEntity$(payload, clientId)
                .pipe(map(function () { return of(undefined); }), catchError(function () { return of(undefined); }));
        }));
        // add request
        _this._onAddRequest$ = _this._actions$.pipe(ofType(ActionTypes.AddRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap((function (_a) {
            var payload = _a[0].payload, clientId = _a[1];
            return _this._api
                .add$(_this._normalizeDomainNamePayload(payload), clientId)
                .pipe(map(function (response) { return new AddLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new AddFail(data); })); }));
        })));
        // publish request
        _this._onPublishRequest$ = _this._actions$.pipe(ofType(ActionTypes.PublishRequest), map(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return ({ payload: payload, meta: meta });
        }), switchMap(function (_a) {
            var payload = _a.payload, meta = _a.meta;
            return _this._store.pipe(select(getDomainEntityById(payload.id))).pipe(take(1), map(function (domain) { return ({ payload: payload, meta: meta, domain: domain }); }));
        }), switchMap((function (_a) {
            var payload = _a.payload, meta = _a.meta, domain = _a.domain;
            return _this._api
                .publish$(payload.id, payload.soaTtl)
                .pipe(map(function (response) { return new PublishLocalSuccess(response, meta, domain.status, domain.domainName); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new PublishFail(data); })); }));
        })));
        _this._onFirstPublishRequestOpenDomainRoutingInfoDialog$ = _this._actions$.pipe(ofType(ActionTypes.PublishLocalSuccess), filter(function (_a) {
            var originalDomainStatus = _a.originalDomainStatus;
            return originalDomainStatus === DomainStatuses.New;
        }), map(function (_a) {
            var originalDomainName = _a.originalDomainName;
            return new DialogOpen(DomainRoutingInfoDialogKey, originalDomainName);
        }));
        // reset changes request
        _this._onResetChangesRequest$ = _this._actions$.pipe(ofType(ActionTypes.ResetChangesRequest), map(function (_a) {
            var payload = _a.payload;
            return payload;
        }), switchMap((function (id) { return _this._api
            .resetChanges$(id)
            .pipe(map(function (response) { return new ResetChangesLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (data) { return new ResetChangesFail(data); })); })); })));
        // reset changes fail snack
        _this._onResetChangesFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.ResetChangesFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.ResetChangesFail); }));
        // delete
        _this._onDeleteRequest$ = _this._actions$.pipe(ofType(ActionTypes.DeleteRequest), map(function (action) { return action.payload; }), switchMap(function (id) { return _this._api
            .delete$(id)
            .pipe(map(function () { return new DeleteLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new DeleteFail(errData, id); })); })); }));
        // delete fail snack
        _this._onDeleteFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.DeleteFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.DeleteFail); }));
        // delete success snack
        _this._onDeleteSuccessSnack$ = _this._actions$.pipe(ofType(ActionTypes.DeleteLocalSuccess), switchMap(function () { return _this._snack.translatedWarning$('domainsBase.deleteSuccessSnack', 'common.close'); }));
        // restore request
        _this._onRestoreRequest$ = _this._actions$.pipe(ofType(ActionTypes.RestoreRequest), map(function (action) { return action.payload; }), switchMap(function (id) { return _this._api
            .restore$(id)
            .pipe(map(function () { return new RestoreLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new RestoreFail(errData, id); })); })); }));
        // restore fail
        _this._onRestoreFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.RestoreFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.RestoreFail); }));
        // restore success snack
        _this._onRestoreSuccessSnack$ = _this._actions$.pipe(ofType(ActionTypes.RestoreLocalSuccess), switchMap(function () { return _this._snack.translatedSuccess$('domainsBase.restoreSuccessSnack', 'common.close'); }));
        // deactivate request
        _this._onDeactivateRequest$ = _this._actions$.pipe(ofType(ActionTypes.DeactivateRequest), map(function (action) { return action.payload; }), switchMap(function (id) { return _this._api
            .deactivate$(id)
            .pipe(map(function () { return new DeactivateLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new DeactivateFail(errData, id); })); })); }));
        // deactivate fail
        _this._onDeactivateFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.DeactivateFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.DeactivateFail); }));
        // deactivate success snack
        _this._onDeactivateSuccessSnack$ = _this._actions$.pipe(ofType(ActionTypes.DeactivateLocalSuccess), switchMap(function () { return _this._snack.translatedWarning$('domainsBase.deactivateDomainSuccessSnack', 'common.close'); }));
        // activate
        _this._onActivateRequest$ = _this._actions$.pipe(ofType(ActionTypes.ActivateRequest), map(function (action) { return action.payload; }), switchMap(function (id) { return _this._api
            .activate$(id)
            .pipe(map(function () { return new ActivateLocalSuccess(id); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new ActivateFail(errData, id); })); })); }));
        // activate fail snack
        _this._onActivateFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.ActivateFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.ActivateFail); }));
        // activate success snack
        _this._onActivateSuccessSnack$ = _this._actions$.pipe(ofType(ActionTypes.ActivateLocalSuccess), switchMap(function () { return _this._snack.translatedSuccess$('domainsBase.activateDomainSuccessSnack', 'common.close'); }));
        // soa request
        _this._onSoaRequest$ = _this._actions$.pipe(ofType(ActionTypes.SoaRequest), map(function (action) { return action.payload; }), switchMap(function (id) { return _this._api
            .soaRequest$(id)
            .pipe(map(function (response) { return new SoaRequestLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new SoaRequestFail(errData, id); })); })); }));
        // soa update request
        _this._onSoaUpdateRequest$ = _this._actions$.pipe(ofType(ActionTypes.SoaUpdateRequest), map(function (action) { return action.payload; }), switchMap(function (payload) { return _this._api
            .soaUpdate$(payload.id, omit(payload, 'id'))
            .pipe(map(function (response) { return new SoaUpdateLocalSuccess(response); }), catchError(function (err) { return _this._errorTranslation
            .get$(err)
            .pipe(map(function (errData) { return new SoaUpdateFail(errData); })); })); }));
        // websocket update
        _this._websocketUpdate$ = _this._actions$.pipe(ofWsType('search', CloudDnsWebsocketActionTypes.Message), map(function (data) { return new EntityLocalSuccess(data.items[0]); }));
        // -- dnssec key
        _this._onDnssecKeyRequest$ = _this._actions$.pipe(ofType(ActionTypes.DnssecKeyRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .getDnssecKey$(payload)
                .pipe(map(function (response) { return new DnssecKeyLocalSuccess(response.cryptokey[0], payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (errData) { return new DnssecKeyFail(errData, payload); })); }));
        }));
        _this._onDnssecKeyFailSnack$ = _this._actions$.pipe(ofType(ActionTypes.DnssecKeyFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta, 'common.close');
        }), map(function () { return new RemoveError(ActionTypes.DnssecKeyFail); }));
        return _this;
    }
    DomainsBaseEffect.prototype._normalizeDomainNamePayload = function (_a) {
        var domainName = _a.domainName;
        domainName = domainName.endsWith('.')
            ? domainName
            : domainName + ".";
        return { domainName: domainName };
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDetailRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onEntityRequestRegisterSearch$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onAddRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onPublishRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onFirstPublishRequestOpenDomainRoutingInfoDialog$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onResetChangesRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onResetChangesFailSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeleteRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeleteFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeleteSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onRestoreRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onRestoreFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onRestoreSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeactivateRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeactivateFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDeactivateSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onActivateRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onActivateFailSnack$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onActivateSuccessSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onSoaRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onSoaUpdateRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_websocketUpdate$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDnssecKeyRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DomainsBaseEffect.prototype, "_onDnssecKeyFailSnack$", void 0);
    return DomainsBaseEffect;
}(AuthorizedRunEffect));
export { DomainsBaseEffect };
