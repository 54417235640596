import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { SearchEntityMapped } from '../../search.model';
import { SearchEntities } from '../../search.constant';
import { searchLinkGenerator } from '../../search.utils';
var SearchItemComponent = /** @class */ (function () {
    function SearchItemComponent() {
        this.entities = SearchEntities;
        this.clicked = new EventEmitter();
    }
    Object.defineProperty(SearchItemComponent.prototype, "data", {
        // -- angular
        set: function (v) {
            this._data = v;
            if (this._data) {
                this.dataWithLink = tslib_1.__assign({}, v, { _link: searchLinkGenerator(v.entity, v.entityId) });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchItemComponent.prototype, "classIsActive", {
        get: function () {
            return this.isActive;
        },
        enumerable: true,
        configurable: true
    });
    SearchItemComponent.prototype.getLabel = function () {
        return '';
        // return this.data.title;
    };
    SearchItemComponent.prototype.focus = function () { };
    SearchItemComponent.prototype.onClick = function () {
        this.clicked.emit();
    };
    return SearchItemComponent;
}());
export { SearchItemComponent };
