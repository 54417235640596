import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { ModuleTokens } from './ticket-price-offer.constant';
import { priceOfferRejectFormState } from './modules';

export class TicketPriceOfferState implements HasNgrxForm {
  forms = {
    ...priceOfferRejectFormState(ModuleTokens.Name)(
      undefined,
      {
        messageText: validate(required)
      }
    )
  };
}
