import differenceInDays from 'date-fns/esm/differenceInDays';
import startOfDay from 'date-fns/esm/startOfDay';
import { InvoiceStatuses } from '@app/base/invoices-base';
var InvoiceDatesInfoComponent = /** @class */ (function () {
    function InvoiceDatesInfoComponent() {
        this.invoiceStatuses = InvoiceStatuses;
    }
    Object.defineProperty(InvoiceDatesInfoComponent.prototype, "dueDate", {
        get: function () {
            return this._dueDate;
        },
        set: function (v) {
            this._dueDate = v;
            this.diffDays = differenceInDays(startOfDay(new Date(v)), startOfDay(new Date()));
        },
        enumerable: true,
        configurable: true
    });
    return InvoiceDatesInfoComponent;
}());
export { InvoiceDatesInfoComponent };
