<vshcz-form
  #ticketAddFormRef
  class="__form"
  [state]="ticketAddFormState$ | async"
  (submit)="onAdd$.next()">

  <vshcz-ticket-add-form [state]="ticketAddFormState$ | async">
  </vshcz-ticket-add-form>

  <vshcz-files
    class="__files"
    [maxCount]="6"
    [maxSize]="4000000"
    [label]="'ticketAddContainer.filesLabel' | translate"
    [instanceId]="uploadInstanceId">
  </vshcz-files>

  <vshcz-progress-error
    [errorCloseLabel]="'common.close' | translate"
    [errorRetryLabel]="'common.retry' | translate"
    [errorShowDetailsLabel]="'common.showMore' | translate"
    [errorHideDetailsLabel]="'common.hideMore' | translate"
    [errorKey]="addFailKey"
    (retry)="ticketAddFormRef.triggerSubmit()"
    [progressKey]="addRequestKey">
    <button
      mat-raised-button
      color="primary"
      type="submit">
      <span translate="ticketAddContainer.addTicket"></span> <mat-icon>send</mat-icon>
    </button>
  </vshcz-progress-error>

</vshcz-form>
