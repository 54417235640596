import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { PermissionService } from '@zerops/fe/permission';
import { interval, Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import differenceInMinutes from 'date-fns/esm/differenceInMinutes';
import uniqBy from 'lodash-es/uniqBy';
import { LocalStorageService } from '@app/services';
import { Roles } from '@app/base/auth-base';
import { KvmKeepaliveRequest } from './kvm-console-connection.action';
import { STORAGE_NAMESPACE, STORAGE_KEY, KvmConsoleConnectionService } from './kvm-console-connection.service';
import { KvmConsoleStartRequest } from '../kvm-settings/kvm-settings.action';
var KvmConsoleConnectionContainer = /** @class */ (function (_super) {
    tslib_1.__extends(KvmConsoleConnectionContainer, _super);
    function KvmConsoleConnectionContainer(_store, _kvmConsoleConnectionService, _localStorage, _permission) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._kvmConsoleConnectionService = _kvmConsoleConnectionService;
        _this._localStorage = _localStorage;
        _this._permission = _permission;
        // Event Streams
        _this.onKvmKeepalive$ = new Subject();
        _this.onKvmConsoleStart$ = new Subject();
        // # Data
        // -- sync
        _this.isExpanded = true;
        // -- angular
        // -- async
        _this.activeConsoles$ = _this._localStorage.get$(STORAGE_NAMESPACE, STORAGE_KEY).pipe(map(function (d) { return (!!d ? Object.values(d) : []); }));
        _this.isAuthorized$ = _this._permission.authorize({
            only: [Roles.Authorized]
        });
        // # Actions Streams
        _this._kvmKeepaliveRequestAction$ = _this.onKvmKeepalive$.pipe(map(function (key) { return new KvmKeepaliveRequest(key); }));
        _this._kvmConsoleStartRequestAction$ = _this.onKvmConsoleStart$.pipe(filter(function (d) { return !!d; }), map(function (_a) {
            var consoleType = _a.consoleType, serverId = _a.serverId;
            return new KvmConsoleStartRequest(consoleType, { serverId: serverId });
        }));
        // # Store Dispatcher
        merge(_this._kvmKeepaliveRequestAction$, _this._kvmConsoleStartRequestAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    KvmConsoleConnectionContainer.prototype.ngAfterViewInit = function () {
        var _this = this;
        interval(60000).pipe(withLatestFrom(this.activeConsoles$), distinctUntilChanged(), takeUntil(this._ngOnDestroy$), filter(function (_a) {
            var _ = _a[0], activeConsoles = _a[1];
            return !!activeConsoles && !!activeConsoles.length;
        })).subscribe(function (_a) {
            var _ = _a[0], activeConsoles = _a[1];
            uniqBy(activeConsoles, 'serverId').forEach(function (item) {
                // Terminate keepalive connection longer than one day
                if (differenceInMinutes(new Date(), item.created) < 1440) {
                    _this.onKvmKeepalive$.next(item.keepaliveKey);
                }
                else {
                    _this._kvmConsoleConnectionService.removeConsole(item.consoleType, item.serverId);
                }
            });
        });
    };
    KvmConsoleConnectionContainer.prototype.trackBy = function (index) {
        return index;
    };
    KvmConsoleConnectionContainer.prototype.removeConsole = function (consoleType, serverId) {
        this._kvmConsoleConnectionService.removeConsole(consoleType, serverId);
    };
    return KvmConsoleConnectionContainer;
}(BaseClass));
export { KvmConsoleConnectionContainer };
