import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { extractErrorData } from '@zerops/fe/ngrx';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  Authorize,
  AuthActionTypes
} from '@app/base/auth-base';
import { State } from '@app/models';
import { SettingsApi } from './settings.api';
import {
  LoadRequest,
  LoadLocalSuccess,
  LoadFail,
  ActionTypes
} from './settings.action';

@Injectable()
export class SettingsEffect {
  private _onAuthorize$ = this._actions$.pipe(
    ofType<Authorize>(AuthActionTypes.Authorize)
  );

  @Effect()
  private _onAuthorizeLoadRequest$ = this._onAuthorize$.pipe(
    map(() => new LoadRequest())
  );

  @Effect()
  private _onLoadRequest$ = this._actions$.pipe(
    ofType<LoadRequest>(ActionTypes.LoadRequest),
    switchMap(() => this._api
      .settings$()
      .pipe(
        map((response) => new LoadLocalSuccess(response)),
        catchError((err: HttpErrorResponse) => of(new LoadFail(
          extractErrorData(err)
        )))
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: SettingsApi
  ) { }
}
