import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '@app/common/price';
import { FeeTypeModule } from '@app/common/fee-type';
import { FeeItemComponent } from './fee-item.component';

@NgModule({
  declarations: [ FeeItemComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule.forChild(),
    PriceModule,
    FeeTypeModule
  ],
  exports: [ FeeItemComponent ]
})
export class FeeItemModule {}
