/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scrollable.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./scrollable.component";
var styles_ScrollableComponent = [i0.styles];
var RenderType_ScrollableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollableComponent, data: {} });
export { RenderType_ScrollableComponent as RenderType_ScrollableComponent };
export function View_ScrollableComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { areaRef: 0 }), i1.ɵqud(402653184, 2, { areaRefScrollable: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "__edge"]], [[2, "has-top-edge", null], [2, "has-bottom-edge", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["areaRef", 1]], null, 4, "div", [["cdkScrollable", ""], ["class", "__area"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "height": 0, "maxHeight": 1, "minHeight": 2 }), i1.ɵdid(6, 212992, [[2, 4]], 0, i3.CdkScrollable, [i1.ElementRef, i3.ScrollDispatcher, i1.NgZone, [2, i4.Directionality]], null, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, _co.heightFormatted, _co.maxHeightFormatted, _co.minHeightFormatted); _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.enableEdges && _co.onTopEdge); var currVal_1 = (_co.enableEdges && _co.onBottomEdge); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ScrollableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-scrollable", [], null, null, null, View_ScrollableComponent_0, RenderType_ScrollableComponent)), i1.ɵdid(1, 245760, null, 0, i5.ScrollableComponent, [i1.ElementRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollableComponentNgFactory = i1.ɵccf("vshcz-scrollable", i5.ScrollableComponent, View_ScrollableComponent_Host_0, { height: "height", maxHeight: "maxHeight", minHeight: "minHeight", maxScrollDuration: "maxScrollDuration", enableEdges: "enableEdges" }, {}, ["*"]);
export { ScrollableComponentNgFactory as ScrollableComponentNgFactory };
