import * as tslib_1 from "tslib";
import { ModuleTokens } from './users-base.constant';
var UsersBaseState = /** @class */ (function () {
    function UsersBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
    }
    return UsersBaseState;
}());
export { UsersBaseState };
var UserEntityEntities = /** @class */ (function () {
    function UserEntityEntities() {
    }
    return UserEntityEntities;
}());
export { UserEntityEntities };
ModuleTokens.Name;
var UserEntityNormalizedData = /** @class */ (function () {
    function UserEntityNormalizedData() {
    }
    return UserEntityNormalizedData;
}());
export { UserEntityNormalizedData };
var UserLightEntity = /** @class */ (function () {
    function UserLightEntity() {
    }
    return UserLightEntity;
}());
export { UserLightEntity };
var UserEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(UserEntityBase, _super);
    function UserEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserEntityBase;
}(UserLightEntity));
export { UserEntityBase };
var UserEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(UserEntityNormalized, _super);
    function UserEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserEntityNormalized;
}(UserEntityBase));
export { UserEntityNormalized };
var UserEntity = /** @class */ (function (_super) {
    tslib_1.__extends(UserEntity, _super);
    function UserEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserEntity;
}(UserEntityBase));
export { UserEntity };
