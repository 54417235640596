import * as tslib_1 from "tslib";
import { FilesState } from './files.model';
import { ActionTypes } from './files.action';
var initialState = new FilesState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c, _d, _e, _f, _g;
    switch (action.type) {
        case ActionTypes.AddToQueue:
            var existingQueue = state.queue[action.payload.instanceId]
                ? state.queue[action.payload.instanceId]
                : [];
            return tslib_1.__assign({}, state, { queue: tslib_1.__assign({}, state.queue, (_a = {}, _a[action.payload.instanceId] = existingQueue.concat(action.payload.files), _a)) });
        case ActionTypes.RemoveFromQueue:
            return tslib_1.__assign({}, state, { queue: tslib_1.__assign({}, state.queue, (_b = {}, _b[action.payload.instanceId] = state.queue[action.payload.instanceId].filter(function (itm) { return itm.uuid !== action.payload.uuid; }), _b)) });
        case ActionTypes.UploadSingleLocalSuccess:
            var existinUploadedFiles = state.uploadedFiles[action.payload.instanceId]
                ? state.uploadedFiles[action.payload.instanceId]
                : [];
            return tslib_1.__assign({}, state, { uploadedFiles: (_c = {},
                    _c[action.payload.instanceId] = existinUploadedFiles.concat([
                        action.payload.fileId
                    ]),
                    _c) });
        case ActionTypes.MarkQueueItemAsDone:
            var arr = state.queue[action.payload.instanceId].slice();
            return tslib_1.__assign({}, state, { queue: tslib_1.__assign({}, state.queue, (_d = {}, _d[action.payload.instanceId] = arr.map(function (item) {
                    if (item.uuid !== action.payload.uuid) {
                        return item;
                    }
                    return tslib_1.__assign({}, item, { done: true });
                }), _d)) });
        case ActionTypes.ClearInstanceQueue:
            return tslib_1.__assign({}, state, { queue: tslib_1.__assign({}, state.queue, (_e = {}, _e[action.payload] = [], _e)) });
        case ActionTypes.EmptyQueue:
            return tslib_1.__assign({}, state, { queue: tslib_1.__assign({}, state.queue, (_f = {}, _f[action.payload] = [], _f)) });
        case ActionTypes.ClearUploadedFiles:
            return tslib_1.__assign({}, state, { uploadedFiles: tslib_1.__assign({}, state.uploadedFiles, (_g = {}, _g[action.payload] = [], _g)) });
        // case ActionTypes.UploadSingleFail:
        //   return {
        //     ...state,
        //     queue: {
        //       ...state.queue,
        //       [action.payload.instanceId]: []
        //     }
        //   };
    }
    return state;
}
