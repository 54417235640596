<div
  *ngIf="(activeConsoles$ | async)?.length"
  @inOut
  class="__wrap  mat-elevation-z3"
  [class.is-minimized]="!isExpanded">

  <button
    class="__minimize-button"
    mat-icon-button
    type="button"
    (click)="isExpanded = !isExpanded">
    <mat-icon>{{ isExpanded ? 'expand_more' : 'expand_less' }}</mat-icon>
  </button>

  <h3
    class="__title"
    [class.is-minimized]="!isExpanded"
    translate="common.kvmConsole">
  </h3>

  <div class="__content">

    <ng-container *ngIf="(activeConsoles$ | async)?.length; else emptyRef">

      <div class="__header-grid">

        <div>Spojení</div>

        <div>Datum vytvoření</div>

        <div>Akce</div>

      </div>

      <div
        *ngFor="let item of (activeConsoles$ | async); let last = last; trackBy: trackBy;"
        class="__item"
        [class.is-last]="last">
        <div class="__content-grid">

          <div>

            <strong>{{ item.consoleType }}</strong>

            <span
              class="__item-server-name">
              {{ item.serverName }}
            </span>

          </div>

          <div>{{ item.created | date: 'medium' }}</div>

          <div>

            <button
              *ngIf="isAuthorized$ | async"
              class="__button is-use"
              mat-icon-button
              type="button"
              (click)="onKvmConsoleStart$.next({ consoleType: item.consoleType, serverId: item.serverId })">
              <mat-icon class="__icon">touch_app</mat-icon>
            </button>

            <button
              class="__button is-cancel"
              mat-icon-button
              type="button"
              (click)="removeConsole(item.consoleType, item.serverId)">
              <mat-icon class="__icon is-cancel">clear</mat-icon>
            </button>

          </div>

        </div>
      </div>

    </ng-container>

    <ng-template #emptyRef>
      <span class="__empty">Žádné akivní KVM konzole...</span>
    </ng-template>

  </div>

</div>
