import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { switchMap, withLatestFrom, catchError, map, filter } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from '@app/base/tickets-base';
import { SnackService } from '@app/common/snack';
import { authActiveUserClientId } from '@app/base/auth-base';
import { ActionTypes, AuthorizeLocalSuccess, AuthorizeFail } from './ticket-authorization.action';
import { SetState } from '../tickets-trigger/tickets-trigger.action';
import { TicketsTriggerStates } from '../tickets-trigger/tickets-trigger.constant';
import { ticketAuthorizationFormValue } from './ticket-authorization.selector';
var TicketAuthorizationEffect = /** @class */ (function () {
    function TicketAuthorizationEffect(_actions$, _store, _api, _errorTranslation, _snack) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._api = _api;
        this._errorTranslation = _errorTranslation;
        this._snack = _snack;
        this._onAuthorizeSuccessDelete$ = this._actions$.pipe(ofType(ActionTypes.AuthorizeLocalSuccess), filter(function (action) { return !!action.meta; }));
        this._onAuthorizeRequest$ = this._actions$.pipe(ofType(ActionTypes.AuthorizeRequest), withLatestFrom(this._store.pipe(select(ticketAuthorizationFormValue)), this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var payload = _a[0].payload, formVal = _a[1], activeClientId = _a[2];
            return _this._api
                .authorize$(payload.id, payload.clientId)
                .pipe(map(function (response) { return new AuthorizeLocalSuccess(payload.id, response.clientId !== activeClientId); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new AuthorizeFail(data, payload.id); })); }));
        }));
        this._onAuthorizeSuccessTriggerState$ = this._onAuthorizeSuccessDelete$.pipe(map(function () { return new SetState({
            state: TicketsTriggerStates.List,
            meta: undefined
        }); }));
        this._onAuthorizeSuccessSnack$ = this._onAuthorizeSuccessDelete$.pipe(switchMap(function () { return _this._snack.translatedSuccess$('ticketAuthorization.authorizeSuccess', 'common.close'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAuthorizationEffect.prototype, "_onAuthorizeRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketAuthorizationEffect.prototype, "_onAuthorizeSuccessTriggerState$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], TicketAuthorizationEffect.prototype, "_onAuthorizeSuccessSnack$", void 0);
    return TicketAuthorizationEffect;
}());
export { TicketAuthorizationEffect };
