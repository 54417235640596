import { ModuleTokens } from './server-park-access-base.constant';

export class ServerParkAccessBaseState {
  entities: ServerParkAccessEntityEntities;

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
  }
}

export class ServerParkAccessEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ServerParkAccessUserEntityNormalized; };
}

export class ServerParkAccessEntityNormalizedData {
  entities: ServerParkAccessEntityEntities;
  result: string[];
}

export class ServerParkAccessUserEntityBase {
  id: string;
  email: string;
  phoneNumber: number;
  fullName: string;
  countryCallingCode: number;
  status: string;
  lastUpdate: string;
  idCardNumber: string;
  created: string;
  clientId: string;
  birthDate: string;
}

export class ServerParkAccessUserEntityNormalized extends ServerParkAccessUserEntityBase {

}

export class ServerParkAccessUserEntity extends ServerParkAccessUserEntityBase {

}
