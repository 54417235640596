import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TicketTopicGroup, TicketTopicEntity } from '@app/base/tickets-base';

interface TicketTopicEntityNamed extends TicketTopicEntity {
  _name: string;
}

interface TicketTopicGroupNamed extends TicketTopicGroup {
  items: TicketTopicEntityNamed[];
}

@Component({
  selector: 'vshcz-ticket-topic-list',
  templateUrl: './ticket-topic-list.component.html',
  styleUrls: [ './ticket-topic-list.component.scss' ]
})
export class TicketTopicListComponent {
  @Input()
  groupedTopics: TicketTopicGroupNamed[];

  @Output()
  selected = new EventEmitter<string>();
}
