import * as tslib_1 from "tslib";
import isArray from 'lodash-es/isArray';
import uniq from 'lodash-es/uniq';
import { TicketsBaseActionTypes } from '@app/base/tickets-base';
import { TicketAddActionTypes } from '@app/common/ticket-add';
import { TicketsListState } from './tickets-list.model';
import { ActionTypes } from './tickets-list.action';
var initialState = new TicketsListState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TicketsBaseActionTypes.ListLocalSuccess:
            return tslib_1.__assign({}, state, { list: action.payload.result });
        case ActionTypes.AddToFilter:
            var bag = isArray(action.payload)
                ? action.payload
                : [action.payload];
            var existing = action.meta ? [] : state.filter;
            return tslib_1.__assign({}, state, { filter: existing.concat(bag) });
        case ActionTypes.RemoveFromFilter:
            return tslib_1.__assign({}, state, { filter: isArray(action.payload)
                    ? state.filter.filter(function (key) { return action.payload.indexOf(key) >= 0; })
                    : state.filter.filter(function (key) { return key !== action.payload; }) });
        case ActionTypes.ResetFilter:
            return tslib_1.__assign({}, state, { filter: [] });
        case TicketAddActionTypes.AddLocalSuccess:
            return tslib_1.__assign({}, state, { list: uniq(action.payload.result.concat(state.list)) });
        case TicketsBaseActionTypes.MessageSuccess:
            return tslib_1.__assign({}, state, { list: uniq(action.payload.result.concat(state.list)) });
    }
    return state;
}
