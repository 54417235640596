import { MatChipList } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
var CustomErrorStateMatcherDirective = /** @class */ (function () {
    function CustomErrorStateMatcherDirective(input, select, chipList) {
        this.input = input;
        this.select = select;
        this.chipList = chipList;
    }
    Object.defineProperty(CustomErrorStateMatcherDirective.prototype, "ngrxFormControlState", {
        set: function (state) {
            var errorsAreShown = state.isInvalid && (state.isTouched || state.isSubmitted);
            if (this.input) {
                this.input.errorState = errorsAreShown;
                this.input.stateChanges.next();
            }
            if (this.select) {
                this.select.errorState = errorsAreShown;
                this.select.stateChanges.next();
            }
            if (this.chipList) {
                this.chipList.errorState = errorsAreShown;
                this.chipList.stateChanges.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    return CustomErrorStateMatcherDirective;
}());
export { CustomErrorStateMatcherDirective };
