import {
  Component,
  forwardRef,
  Input
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'vshcz-star-field',
  templateUrl: './star-field.component.html',
  styleUrls: [ './star-field.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarFieldComponent),
      multi: true
    }
  ]
})
export class StarFieldComponent implements ControlValueAccessor {

  stars = new Array(5);
  private _required = true;

  @Input()
  title: string;

  @Input()
  set required(v) {
    this._required = coerceBooleanProperty(v);
  }
  get required() { return this._required; }

  set value(v) {
    this._value = v;

    this._propagateChange(this.value);
  }

  get value() {
    return this._value;
  }

  private _value: number;

  writeValue(val: number) {
    this.value = val;
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any) { }

  private _propagateChange = (_: any) => {};

  trackBy(index: number) {
    return index;
  }

}
