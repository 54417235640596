import * as tslib_1 from "tslib";
import { extractErrorData } from '@zerops/fe/ngrx';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var ErrorTranslationService = /** @class */ (function () {
    function ErrorTranslationService(_translate) {
        this._translate = _translate;
    }
    ErrorTranslationService.prototype.get$ = function (err, data) {
        var errData = extractErrorData(err);
        return this._translate
            .get("error." + errData.code, data)
            .pipe(map(function (message) { return (tslib_1.__assign({}, errData, { message: message })); }));
    };
    ErrorTranslationService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorTranslationService_Factory() { return new ErrorTranslationService(i0.inject(i1.TranslateService)); }, token: ErrorTranslationService, providedIn: "root" });
    return ErrorTranslationService;
}());
export { ErrorTranslationService };
