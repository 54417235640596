import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CompanySelectModule } from '@app/common/company-select';
import { NoClientUserDialogContainer } from './no-client-user-dialog.container';

@NgModule({
  declarations: [ NoClientUserDialogContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DialogModule,
    TranslateModule.forChild(),
    CompanySelectModule
  ],
  exports: [ NoClientUserDialogContainer ]
})
export class NoClientUserDialogModule { }
