import { PriceOfferRejectFormTranslations } from './price-offer-reject-form.model';

const _prefix = 'price-offer-reject-form_';

export const DEFAULT_TRANSLATION_KEYS: PriceOfferRejectFormTranslations = {
  labels: {
    messageText: _prefix + 'labels_messageText'
  },
  errors: {
    messageText: {
      required: _prefix + 'errors_messageText_required'
    }
  }
};
