import { ModuleTokens } from './tickets-base.constant';
import { TicketStatuses } from '@app/common/tickets';

export type TicketState
  = 'CLOSED'
  | 'PLANNED'
  | 'RESOLVED'
  | 'ACCEPTED'
  | 'IN_REALISATION'
  | 'WAITING_FOR_CLIENT'
  | 'WAITING_AUTHORIZATION';

export class TicketsBaseState {
  entities: TicketsBaseEntityEntities;
  ticketTopics: TicketTopicEntity[];
  list: string[];

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { },
      [ModuleTokens.Messages]: { }
    };
    this.ticketTopics = [];
    this.list = [];
  }
}

export class TicketsBaseEntityEntities {
  [ModuleTokens.Name]: { [id: string]: TicketsEntityNormalized; };
  [ModuleTokens.Messages]: { [id: string]: TicketsEntityNormalized; };
}

export class TicketsEntityNormalizedData {
  entities: TicketsBaseEntityEntities;
  result: string[];
}

export class TicketMessageEntity {
  created: string;
  display: string;
  id: string;
  messageText: string;
  signature: string;
  attachments: TicketFile[];
  ticketId: string;
  extId: string;
  messageHiddenText: string;
  author: any;
}

export class TicketsEntityLight {
  id: string;
  clientId: string;
  title: string;
  created: string;
  lastUpdate: string;
  copyList: string[];
  code: string;
  publicMessageCount: number;
  attachedPriceOffer: AttachedPriceOfferStates;
  clientZoneStatus: TicketStatuses;
  adminUserAssignLock: boolean;
  lastTicketMessageText?: string;
  lastTicketMessageId?: string;
  // TODO: entity
  lastTicketMessageAuthor?: {
    email: string;
    fullName: string;
    id: string;
  };
  realizationDateWaitingConfirmation: boolean;
  assignedUserEmail: string;
}

export enum AttachedPriceOfferStates {
  None = 'NONE',
  Unconfirmed = 'UNCONFIRMED',
  Confirmed = 'CONFIRMED'
}

export class TicketsEntityBase extends TicketsEntityLight {

}

export class TicketsEntityNormalized extends TicketsEntityBase {
  ticketMessageList: string[];
}

export class TicketsEntity extends TicketsEntityBase {
  ticketMessageList: TicketMessageEntity[];
}

export class TicketFile {
  contentType: string;
  created: string;
  id: string;
  name: string;
  sizeFormatted: string;
  sizeKilobytes: number;
  ticketMessageId: string;
}

export class TicketTopicEntity {
  group: string;
  id: string;
  name: { [lang: string]: string; };
  order: number;
  supportDepartmentId: string;
  template: { [lang: string]: string; };
}

export class TicketTopicGroup {
  key: string;
  items: TicketTopicEntity[];
}

export type TicketRating = 1 | 2 | 3 | 4;
