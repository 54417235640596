import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressErrorModule } from '@app/common/progress-error';
import { ProgressItemModule } from '@app/common/progress-item';
import { PopoverModule } from '@app/common/popover';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { FormsModule } from '@app/common/forms';
import { PriceOfferRejectFormModule } from './modules';
import { TicketPriceOfferContainer } from './ticket-price-offer.container';
import { StoreModule } from '@ngrx/store';
import { ModuleTokens } from './ticket-price-offer.constant';
import { reducer } from './ticket-price-offer.reducer';

@NgModule({
  declarations: [ TicketPriceOfferContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ProgressItemModule,
    PopoverModule,
    PriceOfferRejectFormModule,
    ProgressErrorModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    NgrxFormsModule,
    FormsModule,
    NgrxFormsExtensionsModule
  ],
  exports: [ TicketPriceOfferContainer ]
})
export class TicketPriceOfferModule { }
