import { FilesState } from './files.model';
import { ActionTypes, Actions } from './files.action';

const initialState = new FilesState();

export function reducer(
  state = initialState,
  action: Actions
): FilesState {
  switch (action.type) {

    case ActionTypes.AddToQueue:
      const existingQueue = state.queue[action.payload.instanceId]
        ? state.queue[action.payload.instanceId]
        : [];

      return {
        ...state,
        queue: {
          ...state.queue,
          [action.payload.instanceId]: [
            ...existingQueue,
            ...action.payload.files
          ]
        }
      };

    case ActionTypes.RemoveFromQueue:
      return {
        ...state,
        queue: {
          ...state.queue,
          [action.payload.instanceId]: state.queue[action.payload.instanceId].filter(
            (itm) => itm.uuid !== action.payload.uuid
          )
        }
      };

    case ActionTypes.UploadSingleLocalSuccess:
      const existinUploadedFiles = state.uploadedFiles[action.payload.instanceId]
        ? state.uploadedFiles[action.payload.instanceId]
        : [];

      return {
        ...state,
        uploadedFiles: {
          [action.payload.instanceId]: [
            ...existinUploadedFiles,
            action.payload.fileId
          ]
        }
      };

    case ActionTypes.MarkQueueItemAsDone:
      const arr = [ ...state.queue[action.payload.instanceId] ];

      return {
        ...state,
        queue: {
          ...state.queue,
          [action.payload.instanceId]: arr.map((item) => {
            if (item.uuid !== action.payload.uuid) { return item; }

            return {
              ...item,
              done: true
            };

          })
        }
      };

    case ActionTypes.ClearInstanceQueue:
      return {
        ...state,
        queue: {
          ...state.queue,
          [action.payload]: []
        }
      };

    case ActionTypes.EmptyQueue:
      return {
        ...state,
        queue: {
          ...state.queue,
          [action.payload]: []
        }
      };

    case ActionTypes.ClearUploadedFiles:
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          [action.payload]: []
        }
      };

    // case ActionTypes.UploadSingleFail:
    //   return {
    //     ...state,
    //     queue: {
    //       ...state.queue,
    //       [action.payload.instanceId]: []
    //     }
    //   };

  }

  return state;

}
