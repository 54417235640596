import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { ProgressErrorModule } from '@app/common/progress-error';
import { FormsModule } from '@app/common/forms';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { TicketAuthorizationContainer } from './ticket-authorization.container';
import { ModuleTokens } from './ticket-authorization.constant';
import { TicketAuthorizationEffect } from './ticket-authorization.effect';
import { reducer } from './ticket-authorization.reducer';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ TicketAuthorizationContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ TicketAuthorizationEffect ]),
    NgrxFormsModule,
    NgrxFormsExtensionsModule,
    ProgressErrorModule,
    FormsModule,
    MatInputModule
  ],
  exports: [ TicketAuthorizationContainer ]
})
export class TicketAuthorizationModule { }
