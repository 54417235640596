import { createFeatureSelector, createSelector } from '@ngrx/store';
import orderBy from 'lodash-es/orderBy';
import {
  ticketsBaseEntities,
  ticketsBaseDenormalizeList,
  TicketsBaseModuleTokens,
  TicketsEntity
} from '@app/base/tickets-base';
import { TicketStatusesPriorityMap } from '../tickets';
import { TicketsListState } from './tickets-list.model';
import { ModuleTokens } from './tickets-list.constant';

export const slice = createFeatureSelector<TicketsListState>(ModuleTokens.Name);

export const listIds = createSelector(slice, (s) => s.list);

export const filters = createSelector(slice, (s) => s.filter);

export const listEntities = createSelector(
  listIds,
  ticketsBaseEntities,
  filters,
  (ids, entities, fltrs) => {
    const data = ticketsBaseDenormalizeList(
      ids,
      entities[TicketsBaseModuleTokens.Name],
      entities[TicketsBaseModuleTokens.Messages]
    ) as TicketsEntity[];

    const orderedData = orderBy(
      data,
      [ (t) => (t && t.clientZoneStatus && TicketStatusesPriorityMap[t.clientZoneStatus])
        ? TicketStatusesPriorityMap[t.clientZoneStatus] : 'lastUpdate', 'lastUpdate' ],
      [ 'desc', 'desc' ]
    );

    return fltrs.length
      ? orderedData.filter((item) => fltrs.includes(item && item.clientZoneStatus))
      : orderedData;

});
