import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressItemComponent } from './progress-item.component';

@NgModule({
  declarations: [ ProgressItemComponent ],
  imports: [ CommonModule, MatProgressSpinnerModule ],
  exports: [ ProgressItemComponent ]
})
export class ProgressItemModule { }
