<div class="__filters">
  <!-- TODO: translate -->
  <vshcz-filter-pop-button
    [prefix]="'ticketsFilter.buttonPrefix' | translate"
    [placeholder]="'ticketsFilter.buttonPlaceholder' | translate"
    [anchorForRef]="filterPopoverRef.popoverRef"
    [maxSize]="maxSize"
    [items]="activeFilters$ | async"
    (reset)="onResetFilter$.next();filterPopoverRef.close()">

    <vshcz-ticket-state
      *vshczFilterPopButtonItem="let item"
      class="__state"
      [stickerOnly]="stickerOnly"
      [state]="item">
    </vshcz-ticket-state>

    <ng-container *ngIf="(activeGroup$ | async) as activeGroup">
      <ng-container *vshczFilterPopButtonGroup>

        <strong *ngIf="activeGroup === 'open'">

          <span
            class="__group-text"
            translate="ticketsTrigger.openGroupHeading"
            *ngIf="!stickerOnly">
          </span>

          <ng-container *ngIf="(groupedFiltersMap$ | async) as filterMap">
            <vshcz-ticket-state
              *ngFor="let state of filterMap.open"
              class="__group-state"
              stickerOnly
              [state]="state.id">
            </vshcz-ticket-state>
          </ng-container>

        </strong>

        <strong *ngIf="activeGroup === 'closed'">

          <span
            class="__group-text"
            translate="ticketsTrigger.closedGroupHeading"
            *ngIf="!stickerOnly">
          </span>

          <ng-container *ngIf="(groupedFiltersMap$ | async) as filterMap">
            <vshcz-ticket-state
              *ngFor="let state of filterMap.closed"
              class="__group-state"
              stickerOnly
              [state]="state.id">
            </vshcz-ticket-state>
          </ng-container>

        </strong>

      </ng-container>
    </ng-container>
  </vshcz-filter-pop-button>
</div>

<!-- popover -->
<!-- TODO: translate -->
<vshcz-filter-pop-popover
  [heading]="'ticketsFilter.buttonPrefix' | translate"
  [horAlignment]="popHorizontalAlignment"
  #filterPopoverRef>

  <vshcz-filter-pop-multiselect
    (selection)="onToggleFilter$.next($event)"
    [selected]="activeFilters$ | async"
    [items]="filters$ | async">

    <div *vshczFilterPopMultiselectItem="let item">
      <vshcz-ticket-state [state]="item.id"></vshcz-ticket-state>
    </div>

    <div *vshczFilterPopMultiselectGroup="let group">

      <div *ngIf="group.key === 'closed'">

        <span translate="ticketsTrigger.closedGroupHeading"></span>

        <a
          class="__select-all"
          href=""
          (click)="!!selectAll(group.items)"
          translate="common.selectAll">
        </a>

      </div>

      <div *ngIf="group.key === 'open'">

        <span translate="ticketsTrigger.openGroupHeading"></span>

        <a
          class="__select-all"
          href=""
          (click)="!!selectAll(group.items)"
          translate="common.selectAll">
        </a>

      </div>

    </div>

  </vshcz-filter-pop-multiselect>

</vshcz-filter-pop-popover>
