import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { DomainStatuses } from '@app/base/domains-base';

@Component({
  selector: 'vshcz-domain-status',
  templateUrl: './domain-status.component.html',
  styleUrls: [ './domain-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainStatusComponent {

  statuses = DomainStatuses;

  @Input()
  tooltip: string;

  @Input()
  label: string;

  @Input()
  size: 'small' | 'regular' = 'regular';

  @Input()
  status: DomainStatuses;
}
