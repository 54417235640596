<!-- title -->
<vshcz-form-field>
  <mat-form-field>

    <mat-label>
      <span [translate]="translations.labels.title"></span>
    </mat-label>

    <input
      matInput
      [ngrxEnableFocusTracking]="true"
      [ngrxFormControlState]="state.controls.title"
      type="text"
    />

    <mat-hint>
      <span [translate]="translations.hints.title"></span>
    </mat-hint>

    <mat-error *ngIf="state.errors._title?.required">
      <span [translate]="translations.errors.title.required"></span>
    </mat-error>

  </mat-form-field>
</vshcz-form-field>

<!-- text -->
<vshcz-form-field>
  <mat-form-field>

    <mat-label>
      <span [translate]="translations.labels.ticketMessage"></span>
    </mat-label>

    <textarea
      matInput
      vshczTextareaAutosize
      cdkAutosizeMaxRows="6"
      cdkAutosizeMinRows="6"
      [ngrxEnableFocusTracking]="true"
      [ngrxFormControlState]="state.controls.ticketMessage"></textarea>

    <mat-hint>
      <span [translate]="translations.hints.ticketMessage"></span>
    </mat-hint>

    <mat-error *ngIf="state.errors._ticketMessage?.required">
      <span [translate]="translations.errors.ticketMessage.required"></span>
    </mat-error>

  </mat-form-field>
</vshcz-form-field>
