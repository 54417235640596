import * as tslib_1 from "tslib";
import { normalize, denormalize } from 'normalizr';
import { unbox } from 'ngrx-forms';
import { domainSchema, domainListSchema } from '@app/schemas';
import { ModuleTokens } from './domains-base.constant';
import orderBy from 'lodash-es/orderBy';
export function normalizeList(data) {
    return normalize(data, domainListSchema);
}
export function denormalizeEntity(id, domainsEntities, domainActiveRecordsEntities, domainLastRecordsEntities) {
    var _a;
    return denormalize(id, domainSchema, (_a = {},
        _a[ModuleTokens.Name] = domainsEntities,
        _a[ModuleTokens.ActiveRecords] = domainActiveRecordsEntities,
        _a[ModuleTokens.LastRecords] = domainLastRecordsEntities,
        _a));
}
export function denormalizeList(ids, domainsEntities, domainActiveRecordsEntities, domainLastRecordsEntities) {
    var _a;
    return denormalize(ids, domainListSchema, (_a = {},
        _a[ModuleTokens.Name] = domainsEntities,
        _a[ModuleTokens.ActiveRecords] = domainActiveRecordsEntities,
        _a[ModuleTokens.LastRecords] = domainLastRecordsEntities,
        _a));
}
export function inRange(x, y) {
    if ((x === null || y === null) || (x === undefined || y === undefined)) {
        throw new Error("The inRange Validation function requires the x & y parameters to be a non-null numbers!");
    }
    return function (value) {
        value = unbox(value);
        if (value === null || value === undefined) {
            return {};
        }
        if (value >= x && value <= y && Number.isInteger(value)) {
            return {};
        }
        return {
            inRange: {
                x: x,
                y: y,
                actual: value,
            },
        };
    };
}
export var getLastDomainRecordListWithChanges = function (lastDomainRecordList, activeDomainRecordMap, checkForDeleted) {
    if (checkForDeleted === void 0) { checkForDeleted = false; }
    var active = activeDomainRecordMap;
    var listWithChanges = lastDomainRecordList.map(function (item) {
        var _status;
        var _activeRecord;
        if (item.status === 'DELETED') {
            _status = 'DELETED';
        }
        else if (!item.originalDomainRecordId) {
            _status = 'INSERTED';
        }
        else if (item.originalDomainRecordId) {
            var activeEntity = activeDomainRecordMap[item.originalDomainRecordId];
            if ((!!activeEntity && item.value !== activeEntity.value)
                || (!!activeEntity && item.name !== activeEntity.name)) {
                _status = 'UPDATED';
                _activeRecord = {
                    value: activeEntity.value,
                    name: activeEntity.name
                };
            }
            else {
                _status = 'ACTIVE';
            }
        }
        if (checkForDeleted) {
            if (active[item.originalDomainRecordId]) {
                delete active[item.originalDomainRecordId];
            }
        }
        return tslib_1.__assign({}, item, { _status: _status,
            _activeRecord: _activeRecord });
    });
    if (checkForDeleted) {
        var deletedArr = Object.values(active).map(function (item) { return (tslib_1.__assign({}, item, { _status: 'DELETED', _activeRecord: undefined })); });
        listWithChanges = listWithChanges.concat(deletedArr);
    }
    return orderBy(listWithChanges, ['type', 'name', 'value'], ['asc', 'asc', 'asc']);
};
