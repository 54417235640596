import * as tslib_1 from "tslib";
import { CurrencyPipe } from '@angular/common';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import { map, withLatestFrom, switchMap, catchError, delay, filter, first } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId, AuthorizedRunEffect, Roles } from '@app/base/auth-base';
import { InvoicesBaseApi } from './invoices-base.api';
import { ListRequest, ActionTypes, ListLocalSuccess, ListFail, EntityLocalSuccess, EntityFail, LiabilityInvoiceRequest, LiabilityInvoiceLocalSuccess, LiabilityInvoiceFail, LiabilityFeeRequest, LiabilityFeeLocalSuccess, LiabilityFeeFail, PaymentIntentLocalSuccess, PaymentIntentFail, ConfirmPaymentRequest } from './invoices-base.action';
import { SnackService } from '@app/common/snack';
import { identity } from '@app/base/auth-base/auth-base.selector';
var InvoicesBaseEffect = /** @class */ (function (_super) {
    tslib_1.__extends(InvoicesBaseEffect, _super);
    function InvoicesBaseEffect(_actions$, _store, _api, _snack, _errorTranslation, _permission) {
        var _this = _super.call(this, _actions$, _store) || this;
        _this._actions$ = _actions$;
        _this._store = _store;
        _this._api = _api;
        _this._snack = _snack;
        _this._errorTranslation = _errorTranslation;
        _this._permission = _permission;
        _this._onInit$ = _this.onAuthorizedInit$.pipe(delay(1), switchMap(function () { return _this._permission
            .authorize({
            only: [Roles.Manager, Roles.Financial]
        })
            .pipe(first()); }), filter(function (authorized) { return !!authorized; }));
        // trigger liability invoice request on load
        _this.onInitLiabilityInvoice$ = _this._onInit$.pipe(map(function () { return new LiabilityInvoiceRequest(); }));
        // trigger liability fee request on load
        _this.onInitLiabilityFee$ = _this._onInit$.pipe(map(function () { return new LiabilityFeeRequest(); }));
        // trigger list request on load
        _this.onInitList$ = _this._onInit$.pipe(map(function () { return new ListRequest(); }));
        _this.onLiabilityInvoiceRequest$ = _this._actions$.pipe(ofType(ActionTypes.LiabilityInvoiceRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .liabilityInvoice$(id)
                .pipe(map(function (liabilityInvoice) { return new LiabilityInvoiceLocalSuccess(liabilityInvoice); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new LiabilityInvoiceFail(data); })); }));
        }));
        _this.onLiabilityFeeRequest$ = _this._actions$.pipe(ofType(ActionTypes.LiabilityFeeRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .liabilityFee$(id)
                .pipe(map(function (liabilityFee) { return new LiabilityFeeLocalSuccess(liabilityFee); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new LiabilityFeeFail(data); })); }));
        }));
        _this.onPaymentIntentRequest$ = _this._actions$.pipe(ofType(ActionTypes.PaymentIntentRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .paymentStripeIntent$(payload.clientId, payload.type, payload.amount)
                .pipe(map(function (_a) {
                var secret = _a.secret;
                return new PaymentIntentLocalSuccess(secret);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new PaymentIntentFail(data); })); }));
        }));
        _this.onPaymentIntentRequestLocalSuccess$ = _this._actions$.pipe(ofType(ActionTypes.PaymentIntentLocalSuccess), map(function () { return new ConfirmPaymentRequest(); }));
        _this.onConfirmPaymentLocalSuccessShowSnack$ = _this._actions$.pipe(ofType(ActionTypes.ConfirmPaymentLocalSuccess), withLatestFrom(_this._store.pipe(select(identity))), switchMap(function (_a) {
            var payload = _a[0].payload, user = _a[1];
            var currencyPipe = new CurrencyPipe(user.language.id);
            var _b = payload.paymentIntent, amount = _b.amount, currency = _b.currency;
            return _this._snack.translatedSuccess$('bulkPayment.paymentOnlineSuccessSnack', 'common.close', { amount: currencyPipe.transform(amount / 100, currency.toUpperCase(), 'symbol', '1.2-2') });
        }));
        _this.onListRequest$ = _this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .list$(id)
                .pipe(map(function (_a) {
                var invoiceList = _a.invoiceList;
                return new ListLocalSuccess(invoiceList);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        _this._onEntityRequest$ = _this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        }));
        return _this;
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onInitLiabilityInvoice$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onInitLiabilityFee$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onInitList$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onLiabilityInvoiceRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onLiabilityFeeRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onPaymentIntentRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onPaymentIntentRequestLocalSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onConfirmPaymentLocalSuccessShowSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "_onEntityRequest$", void 0);
    return InvoicesBaseEffect;
}(AuthorizedRunEffect));
export { InvoicesBaseEffect };
