/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popover.component";
import * as i4 from "@angular/cdk/a11y";
var styles_PopoverComponent = [i0.styles];
var RenderType_PopoverComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PopoverComponent, data: { "animation": [{ type: 7, name: "transformPopover", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(-10px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0)" }, offset: null }, timings: "{{openTransition}}" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(3px)" }, offset: null }, timings: "{{closeTransition}}" }], options: null }], options: {} }] } });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
function View_PopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["focusTrapElement", 1]], null, 2, "div", [["class", "vscz-popover-container"]], [[24, "@transformPopover", 0]], [[null, "@transformPopover.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@transformPopover.done" === en)) {
        var pd_0 = (_co._onAnimationDone($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "vscz-popover-container"; var currVal_2 = _co._classList; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._getAnimation(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _templateRef: 0 }), i1.ɵqud(671088640, 2, { _focusTrapElement: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_PopoverComponent_1))], null, null); }
export function View_PopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i1.ɵdid(1, 245760, null, 0, i3.PopoverComponent, [i4.FocusTrapFactory, [2, i2.DOCUMENT]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverComponentNgFactory = i1.ɵccf("vshcz-popover", i3.PopoverComponent, View_PopoverComponent_Host_0, { horizontalAlign: "horizontalAlign", xAlign: "xAlign", verticalAlign: "verticalAlign", yAlign: "yAlign", forceAlignment: "forceAlignment", lockAlignment: "lockAlignment", autoFocus: "autoFocus", scrollStrategy: "scrollStrategy", hasBackdrop: "hasBackdrop", interactiveClose: "interactiveClose", openTransition: "openTransition", closeTransition: "closeTransition", backdropClass: "backdropClass" }, { opened: "opened", closed: "closed", afterOpen: "afterOpen", afterClose: "afterClose", backdropClicked: "backdropClicked", overlayKeydown: "overlayKeydown" }, ["*"]);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
