import * as tslib_1 from "tslib";
import { AttachedPriceOfferStates, getTicketEntityById, TicketsBaseActionTypes, TicketsBaseApprovePriceOfferRequest, TicketsBaseRejectPriceOfferRequest } from '@app/base/tickets-base';
import { select, Store } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { progressByKey } from '@zerops/fe/ngrx';
import { ticketPriceOferRejectFormState, ticketPriceOferRejectFormValue } from './ticket-price-offer.selector';
import { activeUserClientId } from '@app/base/auth-base';
import { BaseClass } from '@zerops/fe/core';
var TicketPriceOfferContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketPriceOfferContainer, _super);
    function TicketPriceOfferContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onApprovePriceOffer$ = new Subject();
        _this.onRejectPriceOffer$ = new Subject();
        // # Form States
        _this.priceOfferRejectFormState$ = _this._store.pipe(select(ticketPriceOferRejectFormState));
        // # Data
        // -- sync
        _this.attachedPriceOfferStates = AttachedPriceOfferStates;
        // -- aync
        _this.rejectPriceOfferRequestKey$ = _this.id$.pipe(map(function (id) { return TicketsBaseActionTypes.RejectPriceOfferRequest + ":" + id; }));
        _this.rejectPriceOfferFailKey$ = _this.id$.pipe(map(function (id) { return TicketsBaseActionTypes.RejectPriceOfferFail + ":" + id; }));
        _this.activeClientId$ = _this._store.pipe(select(activeUserClientId));
        _this.priceOfferApproveLoading$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(progressByKey(TicketsBaseActionTypes.ApprovePriceOfferRequest + ":" + id))); }), map(function (loading) { return !!loading; }));
        _this.priceOfferRejectLoading$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(progressByKey(TicketsBaseActionTypes.RejectPriceOfferRequest + ":" + id))); }), map(function (loading) { return !!loading; }));
        _this.ticket$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id)), filter(function (t) { return !!t; })); }));
        // # Action Streams
        _this._approvePriceOfferAction$ = _this.onApprovePriceOffer$.pipe(withLatestFrom(_this.ticket$, _this.activeClientId$), map(function (_a) {
            var _ = _a[0], _b = _a[1], ticketMessageList = _b.ticketMessageList, id = _b.id, activeClientId = _a[2];
            return new TicketsBaseApprovePriceOfferRequest(id, {
                lastAdminTicketMessageId: ticketMessageList.reverse().find((function (d) { return d.display === 'ADMIN'; })).id,
                clientId: activeClientId
            });
        }));
        _this._rejectPriceOfferAction$ = _this.onRejectPriceOffer$.pipe(withLatestFrom(_this.ticket$, _this.activeClientId$, _this._store.pipe(select(ticketPriceOferRejectFormValue))), map(function (_a) {
            var _ = _a[0], id = _a[1].id, activeClientId = _a[2], messageText = _a[3].messageText;
            return new TicketsBaseRejectPriceOfferRequest(id, {
                clientId: activeClientId,
                messageText: messageText
            });
        }));
        // # Action Dispatcher
        merge(_this._approvePriceOfferAction$, _this._rejectPriceOfferAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketPriceOfferContainer.prototype, "id$", void 0);
    return TicketPriceOfferContainer;
}(BaseClass));
export { TicketPriceOfferContainer };
