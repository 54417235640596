import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HtmlEntitiesPipeModule } from '@zerops/fe/common';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { AvatarModule } from '@app/common/avatar';
import { TicketMessageComponent } from './ticket-message.component';

@NgModule({
  declarations: [ TicketMessageComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    TranslateModule.forChild(),
    HtmlEntitiesPipeModule,
    Nl2BrPipeModule,
    AvatarModule
  ],
  exports: [ TicketMessageComponent ]
})
export class TicketMessageModule {

}
