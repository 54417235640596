/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./upload.component";
var styles_UploadComponent = [i0.styles];
var RenderType_UploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadComponent, data: {} });
export { RenderType_UploadComponent as RenderType_UploadComponent };
export function View_UploadComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "__trigger"]], [[2, "is-dragover", null]], [[null, "click"], [null, "drop"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (_co.drop($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragenter" === en)) {
        var pd_2 = (_co.dragenter($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragover" === en)) {
        var pd_3 = (_co.dragover($event) !== false);
        ad = (pd_3 && ad);
    } if (("dragleave" === en)) {
        var pd_4 = (_co.dragleave($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["class", "__input"], ["type", "file"]], [[1, "multiple", 0], [8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDragover; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.multiple ? true : undefined); var currVal_2 = _co.accept; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_UploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-upload", [], null, null, null, View_UploadComponent_0, RenderType_UploadComponent)), i1.ɵdid(1, 49152, null, 0, i2.UploadComponent, [], null, null)], null, null); }
var UploadComponentNgFactory = i1.ɵccf("vshcz-upload", i2.UploadComponent, View_UploadComponent_Host_0, { multiple: "multiple", disable: "disable", imageAcceptExtensions: "imageAcceptExtensions", accept: "accept" }, { select: "select" }, ["*"]);
export { UploadComponentNgFactory as UploadComponentNgFactory };
