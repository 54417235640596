/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-detail.container.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ticket-message/ticket-message.component.ngfactory";
import * as i3 from "../ticket-message/ticket-message.component";
import * as i4 from "../scrollable/scrollable.component.ngfactory";
import * as i5 from "../scrollable/scrollable.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i8 from "@angular/material/list";
import * as i9 from "@angular/material/core";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "@angular/platform-browser/animations";
import * as i16 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i17 from "@angular/material/icon";
import * as i18 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i19 from "@angular/material/card";
import * as i20 from "../popover/popover.component.ngfactory";
import * as i21 from "../popover/popover.component";
import * as i22 from "./ticket-detail.container";
import * as i23 from "../../base/tickets-base/tickets-base.api";
import * as i24 from "../popover/popover-anchoring.service";
import * as i25 from "@ngrx/store";
var styles_TicketDetailContainer = [i0.styles];
var RenderType_TicketDetailContainer = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketDetailContainer, data: {} });
export { RenderType_TicketDetailContainer as RenderType_TicketDetailContainer };
function View_TicketDetailContainer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vshcz-ticket-message", [["class", "__item"]], [[2, "is-loading", null]], [[null, "attachmentOpen"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("attachmentOpen" === en)) {
        var pd_0 = (_co.attachmentOpen($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TicketMessageComponent_0, i2.RenderType_TicketMessageComponent)), i1.ɵdid(2, 49152, null, 0, i3.TicketMessageComponent, [], { text: [0, "text"], hiddenText: [1, "hiddenText"], created: [2, "created"], signature: [3, "signature"], attachments: [4, "attachments"], avatarSource: [5, "avatarSource"], avatarMail: [6, "avatarMail"], avatarImage: [7, "avatarImage"], isPrimary: [8, "isPrimary"], avatarTooltip: [9, "avatarTooltip"], align: [10, "align"] }, { attachmentOpen: "attachmentOpen" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.messageText; var currVal_2 = _v.context.$implicit.messageHiddenText; var currVal_3 = _v.context.$implicit.created; var currVal_4 = _v.context.$implicit.signature; var currVal_5 = _v.context.$implicit.attachments; var currVal_6 = (_co.avatarSourceMap[_v.context.$implicit.display] ? _co.avatarSourceMap[_v.context.$implicit.display] : "clearbit"); var currVal_7 = (_v.context.$implicit.author ? _v.context.$implicit.author.email : undefined); var currVal_8 = "/assets/images/logo.png"; var currVal_9 = (_v.context.$implicit.display === "USER"); var currVal_10 = ((_v.context.$implicit.display === "USER") ? _v.context.$implicit.author.fullName : "VSHosting"); var currVal_11 = ((_v.context.$implicit.display === "USER") ? "right" : "left"); _ck(_v, 2, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageAddTempMap[((_co.messageAddTempKey + ":") + _v.context.$implicit.id)]; _ck(_v, 1, 0, currVal_0); }); }
function View_TicketDetailContainer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "vshcz-scrollable", [], null, null, null, i4.View_ScrollableComponent_0, i4.RenderType_ScrollableComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i5.ScrollableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { height: [0, "height"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "__messages"]], [[2, "is-loaded", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketDetailContainer_2)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.ngIf.ticketMessageList; var currVal_3 = _co.trackBy; _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._loaded; _ck(_v, 3, 0, currVal_1); }); }
function View_TicketDetailContainer_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "mat-list-item", [["class", "__attachments-pop_item mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, [2, i8.MatNavList], [2, i8.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 3, { _lines: 1 }), i1.ɵqud(335544320, 4, { _avatar: 0 }), i1.ɵqud(335544320, 5, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "h4", [["class", "__attachments-file-name mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, [[3, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 1, 3, "p", [["class", "mat-line"], ["mat-line", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(9, 16384, [[3, 4]], 0, i9.MatLine, [], null, null), i1.ɵpod(10, { size: 0 }), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 2, 4, "button", [["class", "__attachments-pop_download"], ["mat-icon-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.onFileDownload$.next(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.Platform, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i16.View_MatIcon_0, i16.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i17.MatIcon, [i1.ElementRef, i17.MatIconRegistry, [8, null], [2, i17.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["cloud_download"]))], function (_ck, _v) { _ck(_v, 15, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 11).transform("common.files.fileSize", _ck(_v, 10, 0, _v.context.$implicit.sizeFormatted))); _ck(_v, 8, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 15).inline; var currVal_7 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_6, currVal_7); }); }
function View_TicketDetailContainer_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "__attachments-pop  mat-elevation-z5 mat-card"]], null, null, null, i18.View_MatCard_0, i18.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i19.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i7.View_MatList_0, i7.RenderType_MatList)), i1.ɵdid(3, 704512, null, 0, i8.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TicketDetailContainer_4)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachments; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_TicketDetailContainer_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrollableRef: 0 }), i1.ɵqud(402653184, 2, { attachementPopRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TicketDetailContainer_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "vshcz-popover", [["hasBackdrop", ""], ["verticalAlign", "end"]], null, [[null, "afterClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("afterClose" === en)) {
        var pd_0 = ((_co.attachments = undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_PopoverComponent_0, i20.RenderType_PopoverComponent)), i1.ɵdid(6, 245760, [[2, 4]], 0, i21.PopoverComponent, [i14.FocusTrapFactory, [2, i6.DOCUMENT]], { verticalAlign: [0, "verticalAlign"], hasBackdrop: [1, "hasBackdrop"] }, { afterClose: "afterClose" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TicketDetailContainer_3)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.data$)); _ck(_v, 3, 0, currVal_0); var currVal_1 = "end"; var currVal_2 = ""; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.attachments; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_TicketDetailContainer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "vshcz-ticket-detail", [], null, null, null, View_TicketDetailContainer_0, RenderType_TicketDetailContainer)), i1.ɵdid(1, 245760, null, 0, i22.TicketDetailContainer, [i23.TicketsBaseApi, i24.PopoverAnchoringService, i1.ViewContainerRef, i25.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketDetailContainerNgFactory = i1.ɵccf("vshcz-ticket-detail", i22.TicketDetailContainer, View_TicketDetailContainer_Host_0, { height: "height", id$: "id" }, {}, []);
export { TicketDetailContainerNgFactory as TicketDetailContainerNgFactory };
