import * as tslib_1 from "tslib";
import { ModuleTokens } from './servers-base.constant';
var ServersBaseState = /** @class */ (function () {
    function ServersBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
        this.list = [];
        this.graphs = {};
        this.ipRangesLoaded = false;
    }
    return ServersBaseState;
}());
export { ServersBaseState };
var ServersEntityEntities = /** @class */ (function () {
    function ServersEntityEntities() {
    }
    return ServersEntityEntities;
}());
export { ServersEntityEntities };
ModuleTokens.Name;
var ServersEntityNormalizedData = /** @class */ (function () {
    function ServersEntityNormalizedData() {
    }
    return ServersEntityNormalizedData;
}());
export { ServersEntityNormalizedData };
var ServerEntityLight = /** @class */ (function () {
    function ServerEntityLight() {
    }
    return ServerEntityLight;
}());
export { ServerEntityLight };
var ServerEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ServerEntityBase, _super);
    function ServerEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerEntityBase;
}(ServerEntityLight));
export { ServerEntityBase };
var ServerEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(ServerEntityNormalized, _super);
    function ServerEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerEntityNormalized;
}(ServerEntityBase));
export { ServerEntityNormalized };
var ServerEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ServerEntity, _super);
    function ServerEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerEntity;
}(ServerEntityBase));
export { ServerEntity };
var ServerStatsEntity = /** @class */ (function () {
    function ServerStatsEntity() {
    }
    return ServerStatsEntity;
}());
export { ServerStatsEntity };
var ServerGraph = /** @class */ (function () {
    function ServerGraph() {
    }
    return ServerGraph;
}());
export { ServerGraph };
var IpV4Address = /** @class */ (function () {
    function IpV4Address() {
    }
    return IpV4Address;
}());
export { IpV4Address };
var IpV6Address = /** @class */ (function () {
    function IpV6Address() {
    }
    return IpV6Address;
}());
export { IpV6Address };
var ServerIpV4 = /** @class */ (function () {
    function ServerIpV4() {
    }
    return ServerIpV4;
}());
export { ServerIpV4 };
var ServerIpV6 = /** @class */ (function () {
    function ServerIpV6() {
    }
    return ServerIpV6;
}());
export { ServerIpV6 };
var ServerGraphFormatted = /** @class */ (function () {
    function ServerGraphFormatted() {
    }
    return ServerGraphFormatted;
}());
export { ServerGraphFormatted };
var IpRangeApiResponse = /** @class */ (function () {
    function IpRangeApiResponse() {
    }
    return IpRangeApiResponse;
}());
export { IpRangeApiResponse };
var IpV4Range = /** @class */ (function () {
    function IpV4Range() {
    }
    return IpV4Range;
}());
export { IpV4Range };
var IpV6Range = /** @class */ (function () {
    function IpV6Range() {
    }
    return IpV6Range;
}());
export { IpV6Range };
var IpV6RangeExpanded = /** @class */ (function (_super) {
    tslib_1.__extends(IpV6RangeExpanded, _super);
    function IpV6RangeExpanded() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IpV6RangeExpanded;
}(IpV6Range));
export { IpV6RangeExpanded };
var IpV6RangeExpandedStripped = /** @class */ (function (_super) {
    tslib_1.__extends(IpV6RangeExpandedStripped, _super);
    function IpV6RangeExpandedStripped() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IpV6RangeExpandedStripped;
}(IpV6RangeExpanded));
export { IpV6RangeExpandedStripped };
export var KvmStatuses;
(function (KvmStatuses) {
    KvmStatuses["on"] = "ON";
    KvmStatuses["off"] = "OFF";
    KvmStatuses["unknown"] = "UNKNOWN";
})(KvmStatuses || (KvmStatuses = {}));
var KvmStatus = /** @class */ (function () {
    function KvmStatus() {
    }
    return KvmStatus;
}());
export { KvmStatus };
var KvmLogMessage = /** @class */ (function () {
    function KvmLogMessage() {
    }
    return KvmLogMessage;
}());
export { KvmLogMessage };
export var KvmCommands;
(function (KvmCommands) {
    KvmCommands["Reboot"] = "REBOOT";
    KvmCommands["On"] = "ON";
    KvmCommands["Off"] = "OFF";
    KvmCommands["SoftOff"] = "SOFT_OFF";
    KvmCommands["Cycle"] = "CYCLE";
})(KvmCommands || (KvmCommands = {}));
