import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { timer } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  withLatestFrom,
  catchError,
  debounce,
  takeUntil,
  delay
} from 'rxjs/operators';
import { FocusAction } from 'ngrx-forms';
import { authActiveUserClientId } from '@app/base/auth-base';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { SearchApi } from './search.api';
import {
  ActionTypes,
  SuggestLocalSuccess,
  SuggestRequest,
  SuggestFail,
  SearchRequest,
  SearchLocalSuccess,
  SearchFail,
  ResetSuggest,
  Open
} from './search.action';
import { searchFormValue } from './search.selector';
import { getFormKey } from './search.forms';
import { ModuleTokens } from './search.constant';

@Injectable()
export class SearchEffect {
  private _formValue$ = this._store.pipe(
    select(searchFormValue),
    map((v) => v.keyword)
  );

  @Effect()
  private _onKeywordChange = this._formValue$.pipe(
    distinctUntilChanged(),
    debounce(() => timer(100)),
    map((keyword) => {
      if (keyword && keyword.length) {
        return new SuggestRequest(keyword);
      } else {
        return new ResetSuggest();
      }
    })
  );

  @Effect()
  private _onSuggestRequest$ = this._actions$.pipe(
    ofType<SuggestRequest>(ActionTypes.SuggestRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ { payload }, clientId ]) => this._api
      .suggest$(payload, clientId)
      .pipe(
        takeUntil(this._actions$.pipe(ofType(ActionTypes.ResetSuggest))),
        map((response) => new SuggestLocalSuccess(response.fulltextSuggestList)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new SuggestFail(data)))
        )
      )
    )
  );

  @Effect()
  private _onSearchRequest$ = this._actions$.pipe(
    ofType<SearchRequest>(ActionTypes.SearchRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ { payload }, clientId ]) => this._api
      .search$(payload, clientId)
      .pipe(
        map((response) => new SearchLocalSuccess(response.fulltextSearchList)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new SearchFail(data)))
        )
      )
    )
  );

  @Effect()
  private _onSearchOpen$ = this._actions$.pipe(
    ofType<Open>(ActionTypes.Open),
    delay(100),
    map(() => new FocusAction(`${getFormKey(ModuleTokens.Name)}.keyword`))
  );

  constructor(
    private _api: SearchApi,
    private _store: Store<State>,
    private _actions$: Actions,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
