import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { ModuleTokens } from './ticket-realization-date.constant';
import { realizationDateRejectFormState } from './modules';

export class TicketRealizationDateState implements HasNgrxForm {
  forms = {
    ...realizationDateRejectFormState(ModuleTokens.Name)(
      undefined,
      {
        messageText: validate(required)
      }
    )
  };
}
