import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DomainRecordBaseApi = /** @class */ (function () {
    function DomainRecordBaseApi(_http) {
        this._http = _http;
    }
    // TODO: interface
    DomainRecordBaseApi.prototype.add$ = function (type, domainId, data) {
        // TODO: interface
        return this._http.post("/clouddns/record-" + type.toLowerCase(), tslib_1.__assign({}, data, { domainId: domainId, type: type.toUpperCase() }));
    };
    // TODO: interface
    DomainRecordBaseApi.prototype.update$ = function (type, id, data) {
        // TODO: interface
        return this._http.put("/clouddns/record-" + type.toLowerCase() + "/" + id, data);
    };
    DomainRecordBaseApi.prototype.delete$ = function (id) {
        return this._http.delete("/clouddns/record/" + id);
    };
    DomainRecordBaseApi.prototype.restore$ = function (id) {
        return this._http.put("/clouddns/record/" + id + "/recover", {});
    };
    // TODO: interface
    DomainRecordBaseApi.prototype.entity$ = function (id, type) {
        // TODO: interface
        return this._http.get("/clouddns/record-" + type.toLowerCase() + "/" + id);
    };
    DomainRecordBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function DomainRecordBaseApi_Factory() { return new DomainRecordBaseApi(i0.inject(i1.HttpClient)); }, token: DomainRecordBaseApi, providedIn: "root" });
    return DomainRecordBaseApi;
}());
export { DomainRecordBaseApi };
