import { UserEntity } from '../users-base';

export class AuthBaseState {
  authorized: boolean;
  userId: string;
  clientUserId: string;
  accessToken: string;
  refreshToken: string;
  authorType: 'BACKOFFICE' | 'CLIENT';
  cloudDnsRoleCode: string;
  referer: string;

  constructor() {
    this.authorized = false;
    this.userId = undefined;
    this.clientUserId = undefined;
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.authorType = undefined;
    this.referer = undefined;
  }
}

export type AuthorType = 'BACKOFFICE' | 'CLIENT';

export class AuthEntity {
  accessToken: string;
  refreshToken: string;
  author: {
    authorType: AuthorType;
    cloudDnsRoleCode: string;
  };
}

export interface ExternalAuthLoginValidateResponse {
  auth: AuthEntity;
  user: UserEntity;
  showHint?: string;
}
