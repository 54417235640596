import { EventEmitter, ElementRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ActionsSubject } from '@ngrx/store';
import { MarkAsSubmittedAction } from 'ngrx-forms';
var FormComponent = /** @class */ (function () {
    function FormComponent(_actions, elementRef) {
        this._actions = _actions;
        this.elementRef = elementRef;
        this.submit = new EventEmitter();
        this.submitError = new EventEmitter();
        this._preventEnterSubmit = false;
    }
    Object.defineProperty(FormComponent.prototype, "preventEnterSubmit", {
        get: function () {
            return this._preventEnterSubmit;
        },
        set: function (v) {
            this._preventEnterSubmit = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    FormComponent.prototype.triggerSubmit = function () {
        var e = new Event('submit', {
            bubbles: true,
            cancelable: true
        });
        this.formElRef.nativeElement.dispatchEvent(e);
    };
    FormComponent.prototype._onSubmit = function (e) {
        var _this = this;
        e.stopPropagation();
        e.preventDefault();
        this.submit.emit(e);
        if (this.state && this.state.isUnsubmitted) {
            this._actions.next(new MarkAsSubmittedAction(this.state.id));
        }
        setTimeout(function () {
            var errs = _this._getFirstErrorInput();
            if (errs) {
                _this.submitError.emit(errs);
            }
        });
    };
    FormComponent.prototype._onEnter = function (e) {
        if (this.preventEnterSubmit) {
            e.preventDefault();
        }
    };
    FormComponent.prototype._getFirstErrorInput = function () {
        var errFields = this.elementRef.nativeElement.getElementsByClassName('mat-form-field');
        var errField;
        for (var i = 0; i < errFields.length; ++i) {
            var field = errFields[i];
            if (field.classList.contains('mat-form-field-invalid')) {
                errField = field;
                break;
            }
        }
        if (errField) {
            var _inputFields = errField.getElementsByTagName('input');
            var _input = void 0;
            if (_inputFields) {
                _input = _inputFields[0];
            }
            return {
                offset: errField.offsetTop,
                element: _input
            };
        }
    };
    return FormComponent;
}());
export { FormComponent };
