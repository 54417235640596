import { Actions } from 'ngrx-forms';
import { SearchState, SearchContents } from './search.model';
import { Actions as SearchActions } from './search.action';
import { applyFormReducer } from './search.forms';
import { ModuleTokens, SearchModes } from './search.constant';
import { ActionTypes } from './search.action';
import { hashMap } from 'utils';

const initialState = new SearchState();

export function reducer(
  state = initialState,
  action: SearchActions | Actions<any>
): SearchState {
  state = applyFormReducer(state, action, ModuleTokens.Name);

  switch (action.type) {
    case ActionTypes.SearchRequest:
      return {
        ...state,
        mode: SearchModes.Search,
        suggest: undefined,
        search: undefined
      };

    case ActionTypes.SearchLocalSuccess:
      return {
        ...state,
        search: action.payload
      };

    case ActionTypes.SuggestRequest:
      return {
        ...state,
        mode: SearchModes.Suggest,
        suggest: undefined,
        search: undefined
      };

    case ActionTypes.SuggestLocalSuccess:
      return {
        ...state,
        suggest: action.payload.map((item) => ({
          ...item,
          _highlight: hashMap<SearchContents>(item.highlight, 'parameter'),
          _infoContent: hashMap<SearchContents>(item.infoContent, 'parameter'),
          _searchContent: hashMap<SearchContents>(item.searchContent, 'parameter')
        }))
      };

    case ActionTypes.ResetSuggest:
      return {
        ...state,
        suggest: []
      };

    case ActionTypes.Open:
      return {
        ...state,
        open: true,
        forms: { ...initialState.forms },
        suggest: undefined,
        search: undefined,
        mode: SearchModes.Suggest
      };

    case ActionTypes.Close:
      return {
        ...state,
        open: false
      };

  }

  return state;
}
