import { Action } from '@zerops/fe/core';
import { TicketsTriggerStates } from './tickets-trigger.constant';

export enum ActionTypes {
  Open = '[Tickets Trigger] Open',
  Close = '[Tickets Trigger] Close',

  SetState = '[Tickets Trigger] Set State',
  SetDetailId = '[Tickets Trigger] Set Detail ID',
  ResetDetailId = '[Tickets Trigger] Reset Detail ID',
  ResetMessageForm = '[Tickets Trigger] Reset Message Form',

  Reset = '[Tickets Trigger] Reset',

  ShowTrigger = '[Tickets Trigger] Show Trigger',
  HideTrigger = '[Tickets Trigger] Hide Trigger'
}

export class Open implements Action {
  readonly type = ActionTypes.Open;

  /**
   * @param payload Optional Ticket ID
   */
  constructor(public payload?: string) { }
}

export class Close implements Action {
  readonly type = ActionTypes.Close;
}

export class SetState implements Action {
  readonly type = ActionTypes.SetState;

  constructor(public payload: {
    state: TicketsTriggerStates,
    meta: any
  }) { }
}

export class SetDetailId implements Action {
  readonly type = ActionTypes.SetDetailId;

  constructor(public payload: string) { }
}

export class ResetDetailId implements Action {
  readonly type = ActionTypes.ResetDetailId;
}

export class ResetMessageForm implements Action {
  readonly type = ActionTypes.ResetMessageForm;
}

export class Reset implements Action {
  readonly type = ActionTypes.Reset;
}

export class ShowTrigger implements Action {
  readonly type = ActionTypes.ShowTrigger;
}

export class HideTrigger implements Action {
  readonly type = ActionTypes.HideTrigger;
}

export type Actions
  = Open
  | Close
  | SetState
  | SetDetailId
  | ResetDetailId
  | ResetMessageForm
  | Reset
  | ShowTrigger
  | HideTrigger;
