<h2
  *ngIf="size === 'big'"
  class="__title  __title--big">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</h2>
<h3
  *ngIf="size === 'medium'"
  class="__title  __title--medium">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</h3>
<h3
  *ngIf="size === 'small'"
  class="__title  __title--small">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</h3>


<ng-template #contentTpl><ng-content></ng-content></ng-template>
