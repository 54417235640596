/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./ticket-state.component";
var styles_TicketStateComponent = [i0.styles];
var RenderType_TicketStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketStateComponent, data: {} });
export { RenderType_TicketStateComponent as RenderType_TicketStateComponent };
function View_TicketStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("ticketState." + _co.state); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TicketStateComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "span", [["class", "__sticker"]], [[2, "has-sticker-only", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "is-close": 0, "is-planned": 1, "is-resolved": 2, "is-accepted": 3, "is-in-realisation": 4, "is-waiting-for-client": 5, "is-waiting-for-authorisation": 6 }), i1.ɵdid(3, 147456, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { disabled: [0, "disabled"], message: [1, "message"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketStateComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "__sticker"; var currVal_2 = _ck(_v, 2, 0, (_co.state === "CLOSED"), (_co.state === "PLANNED"), (_co.state === "RESOLVED"), (_co.state === "ACCEPTED"), (_co.state === "IN_REALISATION"), (_co.state === "WAITING_FOR_CLIENT"), (_co.state === "WAITING_AUTHORIZATION")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = !_co.stickerOnly; var currVal_4 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform(("ticketState." + _co.state))); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = !_co.stickerOnly; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stickerOnly; _ck(_v, 0, 0, currVal_0); }); }
export function View_TicketStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-ticket-state", [], null, null, null, View_TicketStateComponent_0, RenderType_TicketStateComponent)), i1.ɵdid(1, 49152, null, 0, i11.TicketStateComponent, [], null, null)], null, null); }
var TicketStateComponentNgFactory = i1.ɵccf("vshcz-ticket-state", i11.TicketStateComponent, View_TicketStateComponent_Host_0, { state: "state", stickerOnly: "stickerOnly" }, {}, []);
export { TicketStateComponentNgFactory as TicketStateComponentNgFactory };
