import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerEntityLight, ServerEntity, ServerGraph, IpRangeApiResponse } from './servers-base.model';

@Injectable({
  providedIn: 'root'
})
export class ServersBaseApi {
  constructor(private _http: HttpClient) { }

  entity$(id: string) {
    return this._http.get<ServerEntity>(
      `/api/public/server/${id}`
    );
  }

  list$(clientId: string) {
    return this._http.get<{ serverList: ServerEntityLight[]}>(
      `/api/public/client/${clientId}/server`
    );
  }

  graph$(serverId: string, graphType: string[], graphInterval: string) {
    return this._http.post<{ serverGraphList: ServerGraph[]; }>(
      `/api/public/server/${serverId}/graph`,
      { graphType, graphInterval, factor: 5 }
    );
  }

  ipRange$(clientId: string) {
    return this._http.get<IpRangeApiResponse>(
      `/api/public/client/${clientId}/ip-range`
    );
  }

  addIpV4$(serverId: string, serverIpId: string) {
    return this._http.post<ServerEntity>(
      `/api/public/server/${serverId}/ipv4`,
      { serverIpId }
    );
  }

  addIpV6$(serverId: string, ipV6: string) {
    return this._http.post<ServerEntity>(
      `/api/public/server/${serverId}/ipv6`,
      { ipV6 }
    );
  }

  removeIp$(serverId: string, serverIpId: string) {
    return this._http.request<ServerEntity>(
      'delete',
      `/api/public/server/${serverId}/ip`,
      { body: { serverIpId } }
    );
  }

  savePtr$(serverId: string, serverIpId: string, ptr: string) {
    const _ptr = ptr === '' ? null : ptr;

    return this._http.put<ServerEntity>(
      `/api/public/server/${serverId}/ptr`,
      { serverIpId, ptr: _ptr }
    );
  }

}
