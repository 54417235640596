import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emailFormModel, EmailFormState } from '@app/common/email-form';
import { TicketCopyListState } from './ticket-copy-list.model';
import { ModuleTokens } from './ticket-copy-list.constant';

export const slice = createFeatureSelector<TicketCopyListState>(ModuleTokens.Name);

export const ticketCopyListFormState = createSelector(
  slice,
  emailFormModel.selectState<EmailFormState>(ModuleTokens.Name)
);

export const ticketCopyListFormValue = createSelector(
  slice,
  emailFormModel.selectValue(ModuleTokens.Name)
);
