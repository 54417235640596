import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, merge } from 'rxjs';
import {
  filter,
  map,
  tap,
  switchMap,
  takeUntil,
  first,
  catchError,
  pairwise,
  retryWhen,
  delay,
  withLatestFrom
} from 'rxjs/operators';
import {
  AuthActionTypes,
  Authorize,
  InvalidateAuthorization,
  RefreshTokenLocalSuccess,
  getStoredAuth,
  authActiveUserClient,
  authorType,
  cloudDnsRoleCode
} from '@app/base/auth-base';
import { State } from '@app/models';
import { WebsocketStatuses, ParsedFrame } from '@app/common/websockets';
import { ClouddnsWebsocketsApi } from './clouddns-websockets.api';
import {
  ActionTypes,
  Message,
  StatusChange,
  Send,
  Connected,
  Reconnect
} from './clouddns-websockets.action';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';

@Injectable()
export class ClouddnsWebsocketsEffect {
  private _onAuthorize$ = merge(
    this._actions$.pipe(
      ofType<Authorize>(AuthActionTypes.Authorize),
      map(({ payload }) => payload.auth.accessToken)
    ),
    this._actions$.pipe(
      ofType<RefreshTokenLocalSuccess>(AuthActionTypes.RefreshTokenLocalSuccess),
      map((({ payload }) => payload))
    ),
    this._actions$.pipe(
      ofType<Reconnect>(ActionTypes.Reconnect),
      map((({ payload }) => payload))
    )
  );
  private _takeUntil$ = this._actions$.pipe(ofType<InvalidateAuthorization>(AuthActionTypes.InvalidateAuthorization));

  @Effect()
  private _onAuthorizeConnect$ = this._onAuthorize$.pipe(
    filter(() => toBoolean(environment.enableCDNS)),
    switchMap((token) => this._store.pipe(
      select(authActiveUserClient),
      filter((user) => !!user),
      first(),
      withLatestFrom(
        this._store.pipe(select(authorType)),
        this._store.pipe(select(cloudDnsRoleCode))
      ),
      filter(([ userClient, author, code ]) => userClient.cloudDnsEnabled
        || (userClient.cloudDnsEnabled && author === 'BACKOFFICE' && (code === 'MODIFY_ALL_DNS' || code === 'MODIFY_CLIENT_DNS'))),
      switchMap(() => this._api
        .auth$(token)
        .pipe(
          tap((socketToken) => this._api.connect(socketToken.webSocketToken)),
          map((socketToken) => socketToken.webSocketToken),
          retryWhen((errors) => errors.pipe(delay(5000))),
          catchError(() => of(undefined))
        )
      ),
      filter((t) => !!t),
      switchMap(() => this._api
        .status$
        .pipe(
          filter((status) => status === WebsocketStatuses.CONNECTING),
          first(),
          map(() => new Connected())
        )
      )
    ))
  );

  @Effect()
  private _onMessage$ = this._actions$.pipe(
    ofType<Connected>(ActionTypes.Connected),
    switchMap(() => this._api
      .messages$
      .pipe(
        takeUntil(
          merge(
            this._takeUntil$,
            // check if previous status was 1 (connect)
            // and new status is 0 (not connect)
            // which means connection was closed and needs to be
            // reconnected
            this._api.status$.pipe(
              pairwise(),
              filter(([ a, b ]) => a === 1 && b === 0),
              tap(() => {
                const lsAuth = getStoredAuth();

                if (lsAuth) {
                  const { auth } = getStoredAuth();
                  this._store.dispatch(new Reconnect(auth.accessToken));
                }

              })
            )
          )
        ),
        map((message) => {
          const parsed: ParsedFrame = JSON.parse(message);

          return new Message(parsed);
        })
      )
    )
  );

  @Effect()
  private _onStatusChange$ = this._actions$.pipe(
    ofType(ActionTypes.StatusChange),
    switchMap(() => this._api
      .status$
      .pipe(
        takeUntil(this._takeUntil$),
        map((payload) => new StatusChange(payload))
      )
    )
  );

  @Effect({ dispatch: false })
  private _onSend$ = this._actions$.pipe(
    ofType<Send>(ActionTypes.Send),
    tap((action) => this._api.send(action.payload))
  );

  constructor(
    private _actions$: Actions,
    private _api: ClouddnsWebsocketsApi,
    private _store: Store<State>
  ) {
  }
}
