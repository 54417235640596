import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import sortBy from 'lodash-es/sortBy';

interface CountryCodes {
  codes: string[];
  phones: { [code: string]: string; };
  names: { [code: string]: string; };
}

export interface FormattedPhoneCode {
  phonePrefix: string | number;
  name: string;
  code: string;
  _phoneCode?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CountryCodesService {
  private _data$: Observable<CountryCodes>;

  constructor(private _http: HttpClient) { }

  load$() {
    if (!this._data$) {
      this._data$ = this._http
        .get<CountryCodes>('/assets/country-codes.json')
        .pipe(shareReplay(1));
    }

    return this._data$;
  }

  phoneCodes$() {
    return this.load$().pipe(
      map((data) => sortBy(data.codes.reduce((arr, code) => {
        arr.push({
          phonePrefix: data.phones[code],
          name: data.names[code],
          code: code.toLowerCase()
        });
        return arr;
      }, []), ['name']) as FormattedPhoneCode[])
    );
  }
}
