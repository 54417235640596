/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./empty-state.component";
var styles_EmptyStateComponent = [i0.styles];
var RenderType_EmptyStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyStateComponent, data: {} });
export { RenderType_EmptyStateComponent as RenderType_EmptyStateComponent };
export function View_EmptyStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h3", [["class", "mat-h2  __heading"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [["class", "mat-body  __desc"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.desc; _ck(_v, 1, 0, currVal_1); }); }
export function View_EmptyStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-empty-state", [], null, null, null, View_EmptyStateComponent_0, RenderType_EmptyStateComponent)), i1.ɵdid(1, 49152, null, 0, i2.EmptyStateComponent, [], null, null)], null, null); }
var EmptyStateComponentNgFactory = i1.ɵccf("vshcz-empty-state", i2.EmptyStateComponent, View_EmptyStateComponent_Host_0, { heading: "heading", desc: "desc" }, {}, ["*"]);
export { EmptyStateComponentNgFactory as EmptyStateComponentNgFactory };
