import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityBadgeComponent } from './entity-badge.component';

@NgModule({
  declarations: [ EntityBadgeComponent ],
  imports: [ CommonModule ],
  exports: [ EntityBadgeComponent ]
})
export class EntityBadgeModule { }
