import { Component, Input } from '@angular/core';
import {
  TicketAddFormState,
  TicketAddFormTranslations
} from './ticket-add-form.model';
import { DEFAULT_TRANSLATION_KEYS } from './ticket-add-form.translations';

@Component({
  selector: 'vshcz-ticket-add-form',
  templateUrl: './ticket-add-form.component.html',
  styleUrls: [ './ticket-add-form.component.scss' ]
})
export class TicketAddFormComponent {
  @Input()
  state: TicketAddFormState;

  @Input()
  translations: TicketAddFormTranslations = DEFAULT_TRANSLATION_KEYS;

}
