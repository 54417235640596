import { ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
var StarFieldComponent = /** @class */ (function () {
    function StarFieldComponent() {
        this.stars = new Array(5);
        this._required = true;
        this._propagateChange = function (_) { };
    }
    Object.defineProperty(StarFieldComponent.prototype, "required", {
        get: function () { return this._required; },
        set: function (v) {
            this._required = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StarFieldComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (v) {
            this._value = v;
            this._propagateChange(this.value);
        },
        enumerable: true,
        configurable: true
    });
    StarFieldComponent.prototype.writeValue = function (val) {
        this.value = val;
    };
    StarFieldComponent.prototype.registerOnChange = function (fn) {
        this._propagateChange = fn;
    };
    StarFieldComponent.prototype.registerOnTouched = function (fn) { };
    StarFieldComponent.prototype.trackBy = function (index) {
        return index;
    };
    return StarFieldComponent;
}());
export { StarFieldComponent };
