/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-pop-multiselect.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/list";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i5 from "../../../scrollable/scrollable.component.ngfactory";
import * as i6 from "../../../scrollable/scrollable.component";
import * as i7 from "@angular/forms";
import * as i8 from "./filter-pop-multiselect.component";
var styles_FilterPopMultiselectComponent = [i0.styles];
var RenderType_FilterPopMultiselectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterPopMultiselectComponent, data: {} });
export { RenderType_FilterPopMultiselectComponent as RenderType_FilterPopMultiselectComponent };
function View_FilterPopMultiselectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FilterPopMultiselectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h4", [["class", "mat-subheader"], ["mat-subheader", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatListSubheaderCssMatStyler, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterPopMultiselectComponent_3)), i1.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(4, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _v.parent.context.$implicit); var currVal_1 = _co.groupTemplate; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_FilterPopMultiselectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FilterPopMultiselectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-option", [["class", "__item mat-list-item mat-list-option"], ["role", "option"], ["tabindex", "-1"]], [[2, "mat-list-item-disabled", null], [2, "mat-list-item-with-avatar", null], [1, "aria-selected", 0], [1, "aria-disabled", 0]], [[null, "focus"], [null, "blur"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MatListOption_0, i4.RenderType_MatListOption)), i1.ɵdid(1, 1294336, [[2, 4]], 3, i2.MatListOption, [i1.ElementRef, i1.ChangeDetectorRef, i2.MatSelectionList], { checkboxPosition: [0, "checkboxPosition"], value: [1, "value"] }, null), i1.ɵqud(335544320, 3, { _avatar: 0 }), i1.ɵqud(335544320, 4, { _icon: 0 }), i1.ɵqud(603979776, 5, { _lines: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_FilterPopMultiselectComponent_5)), i1.ɵdid(6, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(7, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.checkboxPosition; var currVal_5 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 7, 0, _v.context.$implicit); var currVal_7 = _co.itemTemplate; _ck(_v, 6, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).disabled; var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = i1.ɵnov(_v, 1).selected.toString(); var currVal_3 = i1.ɵnov(_v, 1).disabled.toString(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_FilterPopMultiselectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterPopMultiselectComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "vshcz-scrollable", [], null, null, null, i5.View_ScrollableComponent_0, i5.RenderType_ScrollableComponent)), i1.ɵdid(4, 245760, null, 0, i6.ScrollableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { maxHeight: [0, "maxHeight"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FilterPopMultiselectComponent_4)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.key !== "_"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.maxHeight; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.items; var currVal_3 = _co.trackBy; _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_FilterPopMultiselectComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { selectionRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "mat-selection-list", [["aria-multiselectable", "true"], ["class", "mat-selection-list mat-list-base"], ["role", "listbox"]], [[8, "tabIndex", 0], [1, "aria-disabled", 0]], [[null, "selectionChange"], [null, "blur"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._onTouched() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectionChange" === en)) {
        var pd_2 = (_co.onSelect($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MatSelectionList_0, i4.RenderType_MatSelectionList)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MatSelectionList]), i1.ɵdid(3, 1753088, [[1, 4]], 1, i2.MatSelectionList, [i1.ElementRef, [8, null]], null, { selectionChange: "selectionChange" }), i1.ɵqud(603979776, 2, { options: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FilterPopMultiselectComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.groupedItems; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).tabIndex; var currVal_1 = i1.ɵnov(_v, 3).disabled.toString(); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FilterPopMultiselectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "vshcz-filter-pop-multiselect", [], null, null, null, View_FilterPopMultiselectComponent_0, RenderType_FilterPopMultiselectComponent)), i1.ɵdid(1, 4767744, null, 2, i8.FilterPopMultiselectComponent, [], null, null), i1.ɵqud(335544320, 1, { itemTemplate: 0 }), i1.ɵqud(335544320, 2, { groupTemplate: 0 })], null, null); }
var FilterPopMultiselectComponentNgFactory = i1.ɵccf("vshcz-filter-pop-multiselect", i8.FilterPopMultiselectComponent, View_FilterPopMultiselectComponent_Host_0, { maxHeight: "maxHeight", items: "items", checkboxPosition: "checkboxPosition", selected: "selected" }, { selection: "selection" }, []);
export { FilterPopMultiselectComponentNgFactory as FilterPopMultiselectComponentNgFactory };
