import { Action } from '@zerops/fe/core';
import { ProgressActionMeta, ErrorsActionMeta, ProgressAction, ErrorsAction, ApiError } from '@zerops/fe/ngrx/public_api';

export enum ActionTypes {
  AuthorizeRequest = '[Ticket Authorization] Authorize Request',
  AuthorizeRequestFail = '[Ticket Authorization] Authorize Request Fail',
  AuthorizeFail = '[Ticket Authorization] Authorize Fail',
  AuthorizeLocalSuccess = '[Ticket Authorization] Authorize Local Success',

  RequireClientSelector = '[Ticket Authorization] Require Client Selector',
  DontRequireClientSelector = '[Ticket Authorization] Dont Require Client Selector',
}

export class AuthorizeRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AuthorizeRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AuthorizeFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: { id: string; clientId?: string; }) {
    this.progress = {
      add: {
        key: `${ActionTypes.AuthorizeRequest}:${payload.id}`,
        type: 'local',
        group: ActionTypes.AuthorizeRequest
      }
    };
  }
}

export class AuthorizeRequestFail implements Action {
  readonly type = ActionTypes.AuthorizeRequestFail;
}

export class AuthorizeFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AuthorizeFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: number;

  constructor(
    data: ApiError,
    id: string
  ) {
    this.meta = data.code;

    this.errors = {
      add: {
        key: ActionTypes.AuthorizeFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.AuthorizeRequest}:${id}`
    };
  }
}

export class AuthorizeLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AuthorizeLocalSuccess;
  progress: ProgressActionMeta;

  /**
    @param payload ticketId
  */

  /**
    @param meta Should be deleted from list?
  */

  constructor(public payload: string, public meta: boolean) {
    this.progress = {
      remove: `${ActionTypes.AuthorizeRequest}:${payload}`
    };
  }
}

export class RequireClientSelector implements Action {
  readonly type = ActionTypes.RequireClientSelector;
}

export class DontRequireClientSelector implements Action {
  readonly type = ActionTypes.DontRequireClientSelector;
}

export type Actions
  = AuthorizeRequest
  | AuthorizeRequestFail
  | AuthorizeFail
  | AuthorizeLocalSuccess
  | RequireClientSelector
  | DontRequireClientSelector;
