var _a;
export var TicketStatuses;
(function (TicketStatuses) {
    TicketStatuses["Accepted"] = "ACCEPTED";
    TicketStatuses["Closed"] = "CLOSED";
    // Planned = 'PLANNED',
    TicketStatuses["Resolved"] = "RESOLVED";
    TicketStatuses["InRealisation"] = "IN_REALISATION";
    TicketStatuses["WaitingForClient"] = "WAITING_FOR_CLIENT";
    TicketStatuses["WaitingForAuthorisation"] = "WAITING_AUTHORIZATION";
})(TicketStatuses || (TicketStatuses = {}));
export var TicketStatusesPriorityMap = (_a = {},
    _a[TicketStatuses.WaitingForAuthorisation] = 4,
    _a[TicketStatuses.WaitingForClient] = 3,
    _a[TicketStatuses.InRealisation] = 2,
    _a[TicketStatuses.Accepted] = 1,
    // [TicketStatuses.Planned]: 1,
    _a[TicketStatuses.Resolved] = 1,
    _a[TicketStatuses.Closed] = 1,
    _a);
