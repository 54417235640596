import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientServicesBaseApi = /** @class */ (function () {
    function ClientServicesBaseApi(_http) {
        this._http = _http;
    }
    ClientServicesBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/client-service");
    };
    ClientServicesBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function ClientServicesBaseApi_Factory() { return new ClientServicesBaseApi(i0.inject(i1.HttpClient)); }, token: ClientServicesBaseApi, providedIn: "root" });
    return ClientServicesBaseApi;
}());
export { ClientServicesBaseApi };
