import { normalize, denormalize } from 'normalizr';
import { clientListSchema, clientSchema } from '@app/schemas';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import { ModuleTokens } from './clients-base.constant';
export function normalizeList(data) {
    return normalize(data, clientListSchema);
}
export function denormalizeEntity(id, clientEnts, servicesEnts) {
    var _a;
    return denormalize(id, clientSchema, (_a = {},
        _a[ModuleTokens.Name] = clientEnts,
        _a[ServicesBaseModuleTokens.Name] = servicesEnts,
        _a));
}
