import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'vshcz-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: [ './wrap.component.scss' ]
})
export class WrapComponent {
  @Input()
  size: 'small' | 'medium' | 'big' | 'full' = 'medium';

  @HostBinding('class.is-small')
  get classIsSmall() {
    return this.size === 'small';
  }

  @HostBinding('class.is-medium')
  get classIsMedium() {
    return this.size === 'medium';
  }

  @HostBinding('class.is-big')
  get classIsBig() {
    return this.size === 'big';
  }

  @HostBinding('class.is-full')
  get classIsFull() {
    return this.size === 'full';
  }
}
