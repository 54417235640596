import * as tslib_1 from "tslib";
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { Observable, Subject, merge } from 'rxjs';
import { switchMap, map, filter, withLatestFrom, takeUntil } from 'rxjs/operators';
import { getTicketEntityById } from '@app/base/tickets-base';
import { identity } from '@app/base/auth-base';
import { ticketAuthorizationFormState } from './ticket-authorization.selector';
import { ActionTypes, AuthorizeRequest, AuthorizeRequestFail } from './ticket-authorization.action';
var TicketAuthorizationContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketAuthorizationContainer, _super);
    function TicketAuthorizationContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Form States
        _this.formState$ = _this._store.pipe(select(ticketAuthorizationFormState));
        // # Event Streams
        _this.onAuthorize$ = new Subject();
        // # Data
        // -- sync
        _this.failKey = ActionTypes.AuthorizeFail;
        _this.progressKey = ActionTypes.AuthorizeRequest;
        // -- async
        _this.progressKey$ = _this.id$.pipe(map(function (id) { return _this.progressKey + ":" + id; }));
        _this.failKey$ = _this.id$.pipe(map(function (id) { return _this.failKey + ":" + id; }));
        _this.data$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id))); }));
        _this.identity$ = _this._store.pipe(select(identity));
        _this.clients$ = _this.identity$.pipe(filter(function (idt) { return !!idt; }), map(function (idt) { return idt.clientUserList; }));
        _this.selectedClientCompanyName$ = _this.formState$.pipe(map(function (s) { return s.value.clientId; }), withLatestFrom(_this.clients$), map(function (_a) {
            var clientId = _a[0], clients = _a[1];
            if (clients.length > 1) {
                var clientUser = clients.find(function (c) { return c.clientId === clientId; });
                if (clientUser) {
                    return clientUser.client.companyName;
                }
                else {
                    return undefined;
                }
            }
            else {
                return clients[0].client.companyName;
            }
        }));
        _this.hasMultiClients$ = _this.clients$.pipe(map(function (clients) { return clients.length > 1; }));
        // # Action Streams
        _this._authorizeAction$ = _this.onAuthorize$.pipe(withLatestFrom(_this.formState$, _this.hasMultiClients$, _this.id$, _this.clients$), map(function (_a) {
            var _ = _a[0], formState = _a[1], hasMultiClients = _a[2], id = _a[3], clients = _a[4];
            if (hasMultiClients && !formState.isValid) {
                return new AuthorizeRequestFail();
            }
            return new AuthorizeRequest({
                id: id,
                clientId: hasMultiClients
                    ? formState.value.clientId
                    : clients[0].clientId
            });
        }));
        merge(_this._authorizeAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketAuthorizationContainer.prototype, "id$", void 0);
    return TicketAuthorizationContainer;
}(BaseClass));
export { TicketAuthorizationContainer };
