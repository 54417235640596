import { Component } from '@angular/core';

@Component({
  selector: 'vshcz-kvm-badge',
  templateUrl: './kvm-badge.component.html',
  styleUrls: ['./kvm-badge.component.scss']
})
export class KvmBadgeComponent {

}
