import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, EventEmitter } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { errorsByKeys, progressesByKeys, RemoveError } from '@zerops/fe/ngrx';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { Observable, Subject, merge } from 'rxjs';
import { switchMap, map, distinctUntilChanged, delay, withLatestFrom, startWith, takeUntil, share } from 'rxjs/operators';
import isArray from 'lodash-es/isArray';
var ProgressErrorContainer = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressErrorContainer, _super);
    function ProgressErrorContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event Streams
        _this.onResetError$ = new Subject();
        _this.retry = new EventEmitter();
        // -- async
        _this.errors$ = _this.errorKeys$.pipe(switchMap(function (keys) {
            var errs = isArray(keys) ? keys : [keys];
            return _this._store.pipe(select(errorsByKeys(errs)));
        }), map(function (res) { return res && res.length ? res[0] : undefined; }), distinctUntilChanged(), share());
        _this.showAdditionalErrorInfo = false;
        _this._hideProgressSpinner = false;
        _this._denseButton = false;
        // # Actions
        _this._resetAction$ = _this.onResetError$.pipe(withLatestFrom(_this.errorKeys$), map(function (_a) {
            var _ = _a[0], key = _a[1];
            return new RemoveError(key);
        }));
        return _this;
    }
    Object.defineProperty(ProgressErrorContainer.prototype, "hideProgressSpinner", {
        get: function () {
            return this._hideProgressSpinner;
        },
        set: function (v) {
            this._hideProgressSpinner = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressErrorContainer.prototype, "denseButton", {
        get: function () {
            return this._denseButton;
        },
        set: function (v) {
            this._denseButton = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    ProgressErrorContainer.prototype.ngOnInit = function () {
        // # Action Dispatcher
        merge(this._resetAction$)
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe(this._store);
    };
    ProgressErrorContainer.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.progressKeys$
            .pipe(switchMap(function (keys) {
            var _keys = isArray(keys) ? keys : [keys];
            return _this._store.pipe(select(progressesByKeys(_keys)));
        }), delay(1), withLatestFrom(this.progressDisabled$.pipe(startWith(false))), takeUntil(this._ngOnDestroy$))
            .subscribe(function (_a) {
            var state = _a[0], disabled = _a[1];
            if (!disabled) {
                if (_this.disabled === undefined) {
                    _this.buttonRef.disabled = !!state.length;
                }
                _this.progressState = !!state.length;
            }
            else {
                _this.buttonRef.disabled = true;
                _this.progressState = false;
            }
        });
    };
    ProgressErrorContainer.prototype.toggleAdditionalErrorInfo = function () {
        this.showAdditionalErrorInfo = !this.showAdditionalErrorInfo;
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], ProgressErrorContainer.prototype, "errorKeys$", void 0);
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], ProgressErrorContainer.prototype, "progressDisabled$", void 0);
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], ProgressErrorContainer.prototype, "progressKeys$", void 0);
    return ProgressErrorContainer;
}(BaseClass));
export { ProgressErrorContainer };
