<mat-selection-list (selectionChange)="onSelect($event)">
  <ng-container *ngFor="let group of groupedItems">

    <h4
      mat-subheader
      *ngIf="group.key !== '_'">
      <ng-template
        [ngTemplateOutletContext]="{ $implicit: group }"
        [ngTemplateOutlet]="groupTemplate">
      </ng-template>
    </h4>

    <vshcz-scrollable [maxHeight]="maxHeight">
      <mat-list-option
        class="__item"
        [checkboxPosition]="checkboxPosition"
        [value]="item.id"
        *ngFor="let item of group.items; trackBy: trackBy">
        <ng-template
          [ngTemplateOutletContext]="{ $implicit: item }"
          [ngTemplateOutlet]="itemTemplate">
        </ng-template>
      </mat-list-option>
    </vshcz-scrollable>

  </ng-container>
</mat-selection-list>
