import { Component, Input } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { Observable, Subject, merge } from 'rxjs';
import {
  switchMap,
  map,
  filter,
  withLatestFrom,
  takeUntil
} from 'rxjs/operators';
import { getTicketEntityById } from '@app/base/tickets-base';
import { State } from '@app/models';
import { identity } from '@app/base/auth-base';
import { ticketAuthorizationFormState } from './ticket-authorization.selector';
import {
  ActionTypes,
  AuthorizeRequest,
  AuthorizeRequestFail
} from './ticket-authorization.action';

@Component({
  selector: 'vshcz-ticket-authorization',
  templateUrl: './ticket-authorization.container.html',
  styleUrls: ['./ticket-authorization.container.scss']
})
export class TicketAuthorizationContainer extends BaseClass {
  // # Form States
  formState$ = this._store.pipe(select(ticketAuthorizationFormState));

  // # Event Streams
  onAuthorize$ = new Subject<any>();

  // # Data
  // -- sync
  failKey = ActionTypes.AuthorizeFail;
  progressKey = ActionTypes.AuthorizeRequest;

  // -- angular
  @ObservableInput()
  @Input('id')
  id$: Observable<string>;

  // -- async
  progressKey$ = this.id$.pipe(
    map((id) => `${this.progressKey}:${id}`)
  );
  failKey$ = this.id$.pipe(
    map((id) => `${this.failKey}:${id}`)
  );
  data$ = this.id$.pipe(switchMap(
    (id) => this._store.pipe(select(getTicketEntityById(id))),
  ));
  identity$ = this._store.pipe(select(identity));
  clients$ = this.identity$.pipe(
    filter((idt) => !!idt),
    map((idt) => idt.clientUserList)
  );
  selectedClientCompanyName$ = this.formState$.pipe(
    map((s) => s.value.clientId),
    withLatestFrom(this.clients$),
    map(([ clientId, clients ]) => {
      if (clients.length > 1) {
        const clientUser = clients.find((c) => c.clientId === clientId);

        if (clientUser) {
          return clientUser.client.companyName;
        } else {
          return undefined;
        }
      } else {
        return clients[0].client.companyName;
      }
    })
  );
  hasMultiClients$ = this.clients$.pipe(map((clients) => clients.length > 1));

  // # Action Streams
  private _authorizeAction$ = this.onAuthorize$.pipe(
    withLatestFrom(
      this.formState$,
      this.hasMultiClients$,
      this.id$,
      this.clients$
    ),
    map(([ _, formState, hasMultiClients, id, clients ]) => {
      if (hasMultiClients && !formState.isValid) {
        return new AuthorizeRequestFail();
      }
      return new AuthorizeRequest({
        id,
        clientId: hasMultiClients
          ? formState.value.clientId
          : clients[0].clientId
      });
    })
  );

  constructor(private _store: Store<State>) {
    super();

    merge(this._authorizeAction$)
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }
}
