import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  switchMap,
  withLatestFrom,
  catchError,
  map,
  filter
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from '@app/base/tickets-base';
import { SnackService } from '@app/common/snack';
import { authActiveUserClientId } from '@app/base/auth-base';
import {
  ActionTypes,
  AuthorizeRequest,
  AuthorizeLocalSuccess,
  AuthorizeFail
} from './ticket-authorization.action';
import { SetState } from '../tickets-trigger/tickets-trigger.action';
import { TicketsTriggerStates } from '../tickets-trigger/tickets-trigger.constant';
import { ticketAuthorizationFormValue } from './ticket-authorization.selector';

@Injectable()
export class TicketAuthorizationEffect {
  private _onAuthorizeSuccessDelete$ = this._actions$.pipe(
    ofType<AuthorizeLocalSuccess>(ActionTypes.AuthorizeLocalSuccess),
    filter((action) => !!action.meta)
  );

  @Effect()
  private _onAuthorizeRequest$ = this._actions$.pipe(
    ofType<AuthorizeRequest>(ActionTypes.AuthorizeRequest),
    withLatestFrom(
      this._store.pipe(select(ticketAuthorizationFormValue)),
      this._store.pipe(select(authActiveUserClientId))
    ),
    switchMap(([ { payload }, formVal, activeClientId ]) => this._api
      .authorize$(payload.id, payload.clientId)
      .pipe(
        map((response) => new AuthorizeLocalSuccess(
          payload.id,
          response.clientId !== activeClientId
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AuthorizeFail(
            data,
            payload.id
          )))
        )
      )
    )
  );

@Effect()
private _onAuthorizeSuccessTriggerState$ = this._onAuthorizeSuccessDelete$.pipe(
  map(() => new SetState({
    state: TicketsTriggerStates.List,
    meta: undefined
  }))
);

@Effect({ dispatch: false })
private _onAuthorizeSuccessSnack$ = this._onAuthorizeSuccessDelete$.pipe(
  switchMap(() => this._snack.translatedSuccess$(
    'ticketAuthorization.authorizeSuccess',
    'common.close'
  ))
);

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: TicketsBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) { }
}
