<div
  class="__item"
  [class.has-left-align]="align === 'left'"
  [class.has-right-align]="align === 'right'">

  <div
    [style.paddingLeft.px]="align === 'left' ? (avatarSize + 6) : 0"
    [style.paddingRight.px]="align === 'right' ? (avatarSize + 6) : 0"
    class="__wrap">

    <div
      class="__message"
      [class.is-primary]="isPrimary">

      <div
        *ngIf="uploadState"
        [style.left.px]="align === 'right' ? -35 : undefined"
        [style.right.px]="align === 'left' ? -35 : undefined"
        class="__upload">

        <mat-spinner
          class="__upload_spinner"
          [diameter]="25"
          [value]="uploadState"
          mode="determinate"
          [strokeWidth]="2">
        </mat-spinner>

        <mat-icon class="__upload_icon">cloud_upload</mat-icon>

      </div>

      <div [innerHTML]="text | htmlEntities:true"></div>

      <div *ngIf="showHidden" [innerHTML]="hiddenText | htmlEntities:true"></div>

      <button
        mat-button
        (click)="!!(showHidden = !showHidden)"
        type="button"
        class="__more-button"
        *ngIf="hiddenText">
        <span *ngIf="!showHidden" translate="common.showMore"></span>
        <span *ngIf="showHidden" translate="common.hideMore"></span>
      </button>

      <vshcz-avatar
        *ngIf="avatarSource"
        class="__avatar"
        [matTooltip]="avatarTooltip"
        [matTooltipDisabled]="!avatarTooltip"
        [style.left.px]="align === 'left' ? -(avatarSize + 6) : undefined"
        [style.right.px]="align === 'right' ? -(avatarSize + 6) : undefined"
        [size]="avatarSize"
        [source]="avatarSource"
        [mail]="avatarMail"
        [image]="avatarImage">
      </vshcz-avatar>

    </div>

    <div class="__additional-info">

      <ng-container *ngIf="attachments && attachments?.length">

        <button
          mat-button
          type="button"
          (click)="attachmentOpen.emit({
            files: attachments,
            element: $event.target
          })"
          class="__attachments">
          <mat-icon
            [matBadge]="attachments?.length"
            matBadgeSize="small"
            matBadgePosition="below before"
            class="__attachments_icon">
            attachment
          </mat-icon>
        </button>

        <span class="__additional-info_separator"></span>

      </ng-container>

      <span class="__date">

        {{ created | date: 'medium' }}

        <span
          *ngIf="!isPrimary && !!signature"
          class="__signature">
          - {{ signature }}
        </span>

      </span>

    </div>

  </div>
</div>
