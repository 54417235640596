import { NgModule,  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import {
  FilterPopMultiselectComponent,
  FilterPopMultiselectItemDirective,
  FilterPopMultiselectGroupDirective
} from './filter-pop-multiselect.component';
import { ScrollableModule } from '@app/common/scrollable';

@NgModule({
  declarations: [
    FilterPopMultiselectComponent,
    FilterPopMultiselectItemDirective,
    FilterPopMultiselectGroupDirective
  ],
  imports: [
    CommonModule,
    MatListModule,
    ScrollableModule
  ],
  exports: [
    FilterPopMultiselectComponent,
    FilterPopMultiselectItemDirective,
    FilterPopMultiselectGroupDirective
  ]
})
export class FilterPopMultiselectModule {

}
