import { Actions, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { TicketRatingState } from './ticket-rating.model';
import { applyFormReducer } from './ticket-rating.forms';
import { ModuleTokens } from './ticket-rating.constant';
import {
  Actions as TicketRatingAction,
  ActionTypes
} from './ticket-rating.action';

const initialState = new TicketRatingState();

export function reducer(
  state = initialState,
  action: Actions<any> | TicketRatingAction
): TicketRatingState {
  state = applyFormReducer(
    state,
    action,
    ModuleTokens.Name,
    {
      speed: validate(required),
      expertise: validate(required),
      communication: validate(required)
    }
  );

  if (action.type === ActionTypes.Reset) {
    return { ...initialState };
  }

  return state;
}
