<vshcz-progress-item
  class="__progress-item"
  [spinnerSize]="20"
  [active]="loading">
  <button
    class="__button"
    mat-button
    (click)="clicked.emit()"
    [disabled]="ticketAssignedUserEmail === authUserEmail"
    [matTooltip]="ticketAssignedUserEmail === authUserEmail || copyListContainsUserEmail
      ? ('ticketsTrigger.watchingTooltip' | translate)
      : ('ticketsTrigger.notWatchingTooltip' | translate)">
    {{
      ticketAssignedUserEmail === authUserEmail || copyListContainsUserEmail
        ? ('ticketsTrigger.unwatch' | translate)
        : ('ticketsTrigger.watch' | translate)
    }}
  </button>
</vshcz-progress-item>
