import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlEntitiesPipeModule } from '@zerops/fe/common';
import { AvatarModule } from '@app/common/avatar';
import { EntityBadgeModule } from '@app/common/entity-badge';
import { TicketStateModule } from '@app/common/ticket-state';
import { PriceModule } from '@app/common/price';
import { DomainStatusModule } from '@app/common/domain-status';
import { SearchItemComponent } from './search-item.component';

@NgModule({
  declarations: [ SearchItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    AvatarModule,
    EntityBadgeModule,
    TicketStateModule,
    PriceModule,
    HtmlEntitiesPipeModule,
    DomainStatusModule
  ],
  exports: [ SearchItemComponent ]
})
export class SearchItemModule {

}
