import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { combineLatest, Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { State } from '@app/models';
import {
  isAuthenticated,
  activeUserClientId,
  identity,
  activeUserClientUsers
} from '../../auth-base.selector';
import {
  SetActiveClient,
  InvalidateAuthorization
} from '../../auth-base.action';

@Component({
  selector: 'vshcz-no-client-user-dialog',
  templateUrl: './no-client-user-dialog.container.html',
  styleUrls: [ './no-client-user-dialog.container.scss' ]
})
export class NoClientUserDialogContainer extends BaseClass {
  // # Event Streams
  onSetClientId$ = new Subject<string>();
  onLogout$ = new Subject<void>();

  // # Data
  // -- async
  _isAuthenticated$ = this._store.pipe(select(isAuthenticated));

  activeClientId$ = this._store.pipe(select(activeUserClientId));
  userCompanies$ = this._store.pipe(select(activeUserClientUsers));

  dialogOpen$ = combineLatest(this._isAuthenticated$, this.activeClientId$).pipe(
    map(([ isAuth, id ]) => (isAuth && id === undefined))
  );

  // # Action Streams
  private _setClientIdAction$ = this.onSetClientId$.pipe(
    map((id) => new SetActiveClient(id))
  );
  private _logoutAction$ = this.onLogout$.pipe(
    map((id) => new InvalidateAuthorization())
  );

  constructor(private _store: Store<State>) {
    super();

    // # Action Dispatcher
    merge(
      this._setClientIdAction$,
      this._logoutAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

    super.ngOnInit();

  }
}
