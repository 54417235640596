import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FeeTypes } from '@app/base/invoices-base';

@Component({
  selector: 'vshcz-fee-type',
  templateUrl: './fee-type.component.html',
  styleUrls: [ './fee-type.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeeTypeComponent {

  feeTypes = FeeTypes;

  @Input()
  tooltip: string;

  @Input()
  type: FeeTypes;
}
