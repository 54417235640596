import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TicketStateComponent } from './ticket-state.component';

@NgModule({
  declarations: [ TicketStateComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule
  ],
  exports: [ TicketStateComponent ]
})
export class TicketStateModule { }
