import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerParkAccessUserEntity } from '@app/base/server-park-access-base';

@Injectable({
  providedIn: 'root'
})
export class ServerParkAccessApi {

  constructor(private _http: HttpClient) { }

  list$(clientId: string) {
    return this._http.get<{ clientPersonServerParkAccess: ServerParkAccessUserEntity[] }>(
      `/api/public/client/${clientId}/serverpark-access`
    );
  }

  delete$(id: string) {
    return this._http.delete<{ success: boolean; }>(
      `/api/public/serverpark-access/${id}`
    );
  }

}
