import { applyEntities } from '@zerops/fe/core';
import { ServerParkAccessBaseState } from './server-park-access-base.model';
import { ModuleTokens } from './server-park-access-base.constant';

const initialState = new ServerParkAccessBaseState();

export function reducer(
  state = initialState,
  actions: any
): ServerParkAccessBaseState {
  return applyEntities<ServerParkAccessBaseState>(
    state,
    [ ModuleTokens.Name ],
    actions
  );
}
