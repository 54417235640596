import { StateUpdateFns } from 'ngrx-forms';
import { NgrxFormExtensionModel } from '@app/common/ngrx-forms-extensions';
import { formKey } from './email-form.constant';
import { EmailFormValue } from './email-form.model';

export const formModel = new NgrxFormExtensionModel<EmailFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<EmailFormValue> = {},
  updateFns: StateUpdateFns<EmailFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      email: undefined
    },
    defaultValues,
    updateFns
  )
});
