import { ElementRef, EventEmitter, TemplateRef, OnDestroy, OnInit, } from '@angular/core';
import { FocusTrapFactory } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NotificationAction, PopoverNotification, } from './notification.service';
import { getUnanchoredPopoverError, getInvalidHorizontalAlignError, getInvalidVerticalAlignError, getInvalidScrollStrategyError, } from './popover.errors';
import { VALID_SCROLL, VALID_HORIZ_ALIGN, VALID_VERT_ALIGN, } from './types';
// See http://cubic-bezier.com/#.25,.8,.25,1 for reference.
var DEFAULT_TRANSITION = '500ms cubic-bezier(0.25, 0.8, 0.25, 1)';
var PopoverComponent = /** @class */ (function () {
    function PopoverComponent(_focusTrapFactory, _document) {
        this._focusTrapFactory = _focusTrapFactory;
        this._document = _document;
        this._horizontalAlign = 'center';
        this._verticalAlign = 'center';
        this._forceAlignment = false;
        this._lockAlignment = false;
        this._autoFocus = true;
        this._scrollStrategy = 'reposition';
        this._hasBackdrop = false;
        this._interactiveClose = true;
        this._openTransition = DEFAULT_TRANSITION;
        this._closeTransition = DEFAULT_TRANSITION;
        /** Optional backdrop class. */
        this.backdropClass = '';
        /** Emits when the popover is opened. */
        this.opened = new EventEmitter();
        /** Emits when the popover is closed. */
        this.closed = new EventEmitter();
        /** Emits when the popover has finished opening. */
        this.afterOpen = new EventEmitter();
        /** Emits when the popover has finished closing. */
        this.afterClose = new EventEmitter();
        /** Emits when the backdrop is clicked. */
        this.backdropClicked = new EventEmitter();
        /** Emits when a keydown event is targeted to this popover's overlay. */
        this.overlayKeydown = new EventEmitter();
        /** Classes to be added to the popover for setting the correct transform origin. */
        this._classList = {};
        /** Whether the popover is presently open. */
        this._open = false;
    }
    Object.defineProperty(PopoverComponent.prototype, "horizontalAlign", {
        /** Alignment of the popover on the horizontal axis. */
        get: function () { return this._horizontalAlign; },
        set: function (val) {
            this._validateHorizontalAlign(val);
            if (this._horizontalAlign !== val) {
                this._horizontalAlign = val;
                this._dispatchConfigNotification(new PopoverNotification(NotificationAction.REPOSITION));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "xAlign", {
        /** Alignment of the popover on the x axis. Alias for `horizontalAlign`. */
        get: function () { return this.horizontalAlign; },
        set: function (val) { this.horizontalAlign = val; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "verticalAlign", {
        /** Alignment of the popover on the vertical axis. */
        get: function () { return this._verticalAlign; },
        set: function (val) {
            this._validateVerticalAlign(val);
            if (this._verticalAlign !== val) {
                this._verticalAlign = val;
                this._dispatchConfigNotification(new PopoverNotification(NotificationAction.REPOSITION));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "yAlign", {
        /** Alignment of the popover on the y axis. Alias for `verticalAlign`. */
        get: function () { return this.verticalAlign; },
        set: function (val) { this.verticalAlign = val; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "forceAlignment", {
        /** Whether the popover always opens with the specified alignment. */
        get: function () { return this._forceAlignment; },
        set: function (val) {
            var coercedVal = coerceBooleanProperty(val);
            if (this._forceAlignment !== coercedVal) {
                this._forceAlignment = coercedVal;
                this._dispatchConfigNotification(new PopoverNotification(NotificationAction.REPOSITION));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "lockAlignment", {
        /**
         * Whether the popover's alignment is locked after opening. This prevents the popover
         * from changing its alignement when scrolling or changing the size of the viewport.
         */
        get: function () { return this._lockAlignment; },
        set: function (val) {
            var coercedVal = coerceBooleanProperty(val);
            if (this._lockAlignment !== coercedVal) {
                this._lockAlignment = coerceBooleanProperty(val);
                this._dispatchConfigNotification(new PopoverNotification(NotificationAction.REPOSITION));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "autoFocus", {
        /** Whether the first focusable element should be focused on open. */
        get: function () { return this._autoFocus; },
        set: function (val) {
            this._autoFocus = coerceBooleanProperty(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "scrollStrategy", {
        /** How the popover should handle scrolling. */
        get: function () { return this._scrollStrategy; },
        set: function (val) {
            this._validateScrollStrategy(val);
            if (this._scrollStrategy !== val) {
                this._scrollStrategy = val;
                this._dispatchConfigNotification(new PopoverNotification(NotificationAction.UPDATE_CONFIG));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "hasBackdrop", {
        /** Whether the popover should have a backdrop (includes closing on click). */
        get: function () { return this._hasBackdrop; },
        set: function (val) {
            this._hasBackdrop = coerceBooleanProperty(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "interactiveClose", {
        /** Whether the popover should close when the user clicks the backdrop or presses ESC. */
        get: function () { return this._interactiveClose; },
        set: function (val) {
            this._interactiveClose = coerceBooleanProperty(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "openTransition", {
        /** Custom transition to use while opening. */
        get: function () { return this._openTransition; },
        set: function (val) {
            if (val) {
                this._openTransition = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopoverComponent.prototype, "closeTransition", {
        /** Custom transition to use while closing. */
        get: function () { return this._closeTransition; },
        set: function (val) {
            if (val) {
                this._closeTransition = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    PopoverComponent.prototype.ngOnInit = function () {
        this._setAlignmentClasses();
    };
    PopoverComponent.prototype.ngOnDestroy = function () {
        if (this._notifications) {
            this._notifications.dispose();
        }
    };
    /** Open this popover. */
    PopoverComponent.prototype.open = function () {
        var notification = new PopoverNotification(NotificationAction.OPEN);
        this._dispatchActionNotification(notification);
    };
    /** Close this popover. */
    PopoverComponent.prototype.close = function (value) {
        var notification = new PopoverNotification(NotificationAction.CLOSE, value);
        this._dispatchActionNotification(notification);
    };
    /** Toggle this popover open or closed. */
    PopoverComponent.prototype.toggle = function () {
        var notification = new PopoverNotification(NotificationAction.TOGGLE);
        this._dispatchActionNotification(notification);
    };
    /** Gets whether the popover is presently open. */
    PopoverComponent.prototype.isOpen = function () {
        return this._open;
    };
    /** Gets an animation config with customized (or default) transition values. */
    PopoverComponent.prototype._getAnimation = function () {
        return {
            value: 'visible',
            params: { openTransition: this.openTransition, closeTransition: this.closeTransition }
        };
    };
    /** Callback for when the popover is finished animating in or out. */
    PopoverComponent.prototype._onAnimationDone = function (event) {
        if (event.toState === 'visible') {
            this._trapFocus();
            this.afterOpen.emit();
        }
        else if (event.toState === 'void') {
            this._restoreFocus();
            this.afterClose.emit();
        }
    };
    /** Apply alignment classes based on alignment inputs. */
    PopoverComponent.prototype._setAlignmentClasses = function (horizAlign, vertAlign) {
        if (horizAlign === void 0) { horizAlign = this.horizontalAlign; }
        if (vertAlign === void 0) { vertAlign = this.verticalAlign; }
        this._classList['sat-popover-before'] = horizAlign === 'before' || horizAlign === 'end';
        this._classList['sat-popover-after'] = horizAlign === 'after' || horizAlign === 'start';
        this._classList['sat-popover-above'] = vertAlign === 'above' || vertAlign === 'end';
        this._classList['sat-popover-below'] = vertAlign === 'below' || vertAlign === 'start';
        this._classList['sat-popover-center'] = horizAlign === 'center' || vertAlign === 'center';
    };
    /** Move the focus inside the focus trap and remember where to return later. */
    PopoverComponent.prototype._trapFocus = function () {
        this._savePreviouslyFocusedElement();
        // There won't be a focus trap element if the close animation starts before open finishes
        if (!this._focusTrapElement) {
            return;
        }
        if (!this._focusTrap && this._focusTrapElement) {
            this._focusTrap = this._focusTrapFactory.create(this._focusTrapElement.nativeElement);
        }
        if (this.autoFocus) {
            this._focusTrap.focusInitialElementWhenReady();
        }
    };
    /** Restore focus to the element focused before the popover opened. Also destroy trap. */
    PopoverComponent.prototype._restoreFocus = function () {
        var toFocus = this._previouslyFocusedElement;
        // Must check active element is focusable for IE sake
        if (toFocus && 'focus' in toFocus) {
            this._previouslyFocusedElement.focus();
        }
        this._previouslyFocusedElement = null;
        if (this._focusTrap) {
            this._focusTrap.destroy();
            this._focusTrap = undefined;
        }
    };
    /** Save a reference to the element focused before the popover was opened. */
    PopoverComponent.prototype._savePreviouslyFocusedElement = function () {
        if (this._document) {
            this._previouslyFocusedElement = this._document.activeElement;
        }
    };
    /** Dispatch a notification to the notification service, if possible. */
    PopoverComponent.prototype._dispatchConfigNotification = function (notification) {
        if (this._notifications) {
            this._notifications.dispatch(notification);
        }
    };
    /** Dispatch a notification to the notification service and throw if unable to. */
    PopoverComponent.prototype._dispatchActionNotification = function (notification) {
        if (!this._notifications) {
            throw getUnanchoredPopoverError();
        }
        this._notifications.dispatch(notification);
    };
    /** Throws an error if the alignment is not a valid horizontalAlign. */
    PopoverComponent.prototype._validateHorizontalAlign = function (pos) {
        if (VALID_HORIZ_ALIGN.indexOf(pos) === -1) {
            throw getInvalidHorizontalAlignError(pos);
        }
    };
    /** Throws an error if the alignment is not a valid verticalAlign. */
    PopoverComponent.prototype._validateVerticalAlign = function (pos) {
        if (VALID_VERT_ALIGN.indexOf(pos) === -1) {
            throw getInvalidVerticalAlignError(pos);
        }
    };
    /** Throws an error if the scroll strategy is not a valid strategy. */
    PopoverComponent.prototype._validateScrollStrategy = function (strategy) {
        if (VALID_SCROLL.indexOf(strategy) === -1) {
            throw getInvalidScrollStrategyError(strategy);
        }
    };
    return PopoverComponent;
}());
export { PopoverComponent };
