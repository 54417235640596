import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vshcz-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: [ './service-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceItemComponent {

  @Input()
  itemsCount: number;

  @Input()
  itemKey: string;

  @Input()
  index: number;

  @Input()
  isExternal: boolean;
}
