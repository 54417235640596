import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '@app/common/logo';
import { MenuModule } from '@app/common/menu';
import { WrapModule } from '@app/common/wrap';
import { KeyChipModule } from '@app/common/key-chip';
import { LoggedUserModule } from '@app/common/logged-user';
import { AppBarContainer } from './app-bar.container';

@NgModule({
  declarations: [ AppBarContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LogoModule,
    MenuModule,
    WrapModule,
    LoggedUserModule,
    KeyChipModule
  ],
  exports: [ AppBarContainer ]
})
export class AppBarModule { }
