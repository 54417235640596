import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientUserEntity } from './client-user-base.model';

@Injectable({
  providedIn: 'root'
})
export class ClientUserBaseApi {
  constructor(private _http: HttpClient) { }

  entity$(id: string) {
    return this._http.get<ClientUserEntity>(
      `/api/public/client-user/${id}`
    );
  }

  list$(clientId: string) {
    return this._http.get<{ clientUserList: ClientUserEntity[] }>(
      `/api/public/client/${clientId}/user`
    );
  }

}
