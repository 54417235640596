<vshcz-popover
  #popRef
  horizontalAlign="center"
  verticalAlign="below"
  hasBackdrop>
  <mat-card class="__pop  mat-elevation-z7">

    <h3
      class="mat-h3"
      [innerHTML]="heading">
    </h3>

    <ng-content></ng-content>

    <mat-card-actions class="__actions" align="end">

      <button
        mat-raised-button
        [color]="confirmColor"
        type="button"
        (click)="confirm.emit(); popRef.close();">
        <mat-icon *ngIf="confirmIcon">{{ confirmIcon }}</mat-icon>
        {{ confirmButton }}
      </button>

      <button
        mat-button
        type="button"
        (click)="close.emit(); popRef.close();">
        {{ closeButton }}
      </button>

    </mat-card-actions>

  </mat-card>
</vshcz-popover>
