<span
  [ngClass]="{
    'is-active': status === statuses.active,
    'is-terminated': status === statuses.terminated
  }"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  matTooltipPosition="after"
  matTooltipClass="c-search-tooltip"
  matTooltipShowDelay="400"
  class="__status-icon">
</span>
