import { Action } from '@app/models';

export enum ActionTypes {
  SetActive = '[Recaptcha] Set Active',
  SetInactive = '[Recaptcha] Set Inactive'
}

export class SetActive implements Action {
  readonly type = ActionTypes.SetActive;
}

export class SetInactive implements Action {
  readonly type = ActionTypes.SetInactive;

  constructor(public payload: string) { }
}

export type Actions
  = SetActive
  | SetInactive;
