import { Action, EntitiesMergeStrategy } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import { normalizeList } from './domains-base.utils';
import {
  DomainEntityNormalizedData,
  DomainEntityLight,
  DomainsBaseEntityEntities,
  DomainEntity,
  DomainRecordSoa,
  DnsSecKeyEntityBase
} from './domains-base.model';
import { DomainStatuses } from './domains-base.constant';

export enum ActionTypes {
  ListRequest = '[Domains Base] List Request',
  ListFail = '[Domains Base] List Fail',
  ListLocalSuccess = '[Domains Base] List Local Success',

  EntityRequest = '[Domains Base] Entity Request',
  EntityFail = '[Domains Base] Entity Fail',
  EntityLocalSuccess = '[Domains Base] Entity Local Success',

  AddRequest = '[Domains Base] Add Request',
  AddFail = '[Domains Base] Add Fail',
  AddLocalSuccess = '[Domains Base] Add Local Success',

  PublishRequest = '[Domains Base] Publish Request',
  PublishFail = '[Domains Base] Publish Fail',
  PublishLocalSuccess = '[Domains Base] Publish Local Success',

  ResetChangesRequest = '[Domains Base] ResetChanges Request',
  ResetChangesFail = '[Domains Base] ResetChanges Fail',
  ResetChangesLocalSuccess = '[Domains Base] ResetChanges Local Success',

  DeleteRequest = '[Domain Base] Delete Request',
  DeleteFail = '[Domain Base] Delete Fail',
  DeleteLocalSuccess = '[Domain Base] Delete Local Success',

  RestoreRequest = '[Domain Base] Restore Request',
  RestoreFail = '[Domain Base] Restore Fail',
  RestoreLocalSuccess = '[Domain Base] Restore Local Success',

  DeactivateRequest = '[Domain Base] Deactivate Request',
  DeactivateFail = '[Domain Base] Deactivate Fail',
  DeactivateLocalSuccess = '[Domain Base] Deactivate Local Success',

  ActivateRequest = '[Domain Base] Activate Request',
  ActivateFail = '[Domain Base] Activate Fail',
  ActivateLocalSuccess = '[Domain Base] Activate Local Success',

  SoaRequest = '[Domains Base] Soa Request Request',
  SoaRequestFail = '[Domains Base] Soa Request Fail',
  SoaRequestLocalSuccess = '[Domains Base] Soa Request Local Success',

  SoaUpdateRequest = '[Domains Base] Soa Update Request',
  SoaUpdateFail = '[Domains Base] Soa Update Fail',
  SoaUpdateLocalSuccess = '[Domains Base] Soa Update Local Success',

  DnssecKeyRequest = '[Domain Base] Dnssec Key Request',
  DnssecKeyFail = '[Domain Base] Dnssec Key Fail',
  DnssecKeyLocalSuccess = '[Domain Base] Dnssec Key Local Success'
}

export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }
}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: DomainEntityNormalizedData;

  constructor(data: DomainEntityLight[]) {
    this.payload = normalizeList(data);
  }
}

export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: DomainsBaseEntityEntities };
  meta = {
    _mergeStrategy: {
      lastDomainRecordList: EntitiesMergeStrategy.KeepNew,
      activeDomainRecordList: EntitiesMergeStrategy.KeepNew
    }
  };

  constructor(data: DomainEntity, id?: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };

    if (id) {
      this.progress = {
        remove: `${ActionTypes.EntityRequest}:${id}`
      };
    }
  }
}

export class AddRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.AddRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AddFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.AddRequest,
      type: 'local'
    }
  };

  // TODO: interface
  constructor(public payload: any) { }
}

export class AddFail implements Action, ErrorsAction {
  readonly type = ActionTypes.AddFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.AddFail,
        type: 'local',
        data
      }
    };
  }
}

export class AddLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AddLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };

  constructor(public payload: DomainEntity) { }
}

export class PublishRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.PublishRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.PublishFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.PublishRequest,
      type: 'local'
    }
  };

  constructor(
    public payload: {
      id: string;
      soaTtl?: number;
    },
    public meta?: boolean
  ) { }
}

export class PublishFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.PublishFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.PublishRequest
  };
  meta: string;

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.PublishFail,
        type: 'local',
        data
      }
    };
    this.meta = data.message;
  }

}

export class PublishLocalSuccess implements Action {
  readonly type = ActionTypes.PublishLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.PublishRequest
  };
  // TODO: handle with websockets
  payload: { entities: DomainsBaseEntityEntities };

  constructor(
    data: DomainEntity,
    public meta?: boolean,
    public originalDomainStatus?: DomainStatuses,
    public originalDomainName?: string
  ) {
    const { entities } = normalizeList([ data ]);
    this.payload = { entities };
  }
}

export class ResetChangesRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ResetChangesRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ResetChangesFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ResetChangesRequest,
      type: 'local'
    }
  };

  constructor(public payload: string) { }
}

export class ResetChangesFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ResetChangesFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ResetChangesRequest
  };
  meta: string;

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ResetChangesFail,
        type: 'local',
        data
      }
    };
    this.meta = data.message;
  }

}

export class ResetChangesLocalSuccess implements Action {
  readonly type = ActionTypes.ResetChangesLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ResetChangesRequest
  };
  // TODO: handle with websockets
  // payload: { entities: DomainsBaseEntityEntities };

  constructor(data: DomainEntity) {
    // const { entities } = normalizeList([ data ]);
    // this.payload =  { entities };
  }
}

export class DeleteRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeleteRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DeleteFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.DeleteRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.DeleteRequest
      }
    };
  }
}

export class DeleteFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeleteFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.DeleteFail,
        type: 'local',
        data
      }
    };

    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${id}`
    };

  }

}

export class DeleteLocalSuccess implements Action {
  readonly type = ActionTypes.DeleteLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${id}`
    };
  }
}

export class RestoreRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.RestoreRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.RestoreFail
  };

  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.RestoreRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.RestoreRequest
      }
    };
  }
}

export class RestoreFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.RestoreFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.RestoreFail,
        type: 'local',
        data
      }
    };
    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.RestoreRequest}:${id}`
    };
  }

}

export class RestoreLocalSuccess implements Action {
  readonly type = ActionTypes.RestoreLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.RestoreRequest}:${id}`
    };
  }
}

export class DeactivateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeactivateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DeactivateFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.DeactivateRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.DeactivateRequest
      }
    };
  }
}

export class DeactivateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeactivateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.DeactivateFail,
        type: 'local',
        data
      }
    };
    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.DeactivateRequest}:${id}`
    };

  }

}

export class DeactivateLocalSuccess implements Action {
  readonly type = ActionTypes.DeactivateLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.DeactivateRequest}:${id}`
    };
  }
}

export class ActivateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ActivateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ActivateFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.ActivateRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.ActivateRequest
      }
    };
  }
}

export class ActivateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ActivateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.ActivateFail,
        type: 'local',
        data
      }
    };

    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.ActivateRequest}:${id}`
    };

  }

}

export class ActivateLocalSuccess implements Action {
  readonly type = ActionTypes.ActivateLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.ActivateRequest}:${id}`
    };
  }
}

export class SoaRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.SoaRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SoaRequestFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.SoaRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.SoaRequest
      }
    };
  }
}

export class SoaRequestFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.SoaRequestFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.SoaRequestFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.SoaRequest}:${id}`
    };
  }
}

export class SoaRequestLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.SoaRequestLocalSuccess;
  progress: ProgressActionMeta;

  constructor(public payload: DomainRecordSoa) {
    this.progress = {
      remove: `${ActionTypes.SoaRequest}:${payload.domainId}`
    };
  }
}

export class SoaUpdateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SoaUpdateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SoaUpdateFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.SoaUpdateRequest,
      type: 'local'
    }
  };

  constructor(public payload: {
    id: string;
    soaMname: string;
    soaRname: string;
    soaRefresh: number;
    soaRetry: number;
    soaExpire: number;
  }) { }
}

export class SoaUpdateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SoaUpdateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SoaUpdateRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.SoaUpdateFail,
        type: 'local',
        data
      }
    };
  }
}

export class SoaUpdateLocalSuccess implements Action {
  readonly type = ActionTypes.SoaUpdateLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SoaUpdateRequest
  };

  constructor(public payload: DomainRecordSoa) { }
}

export class DnssecKeyRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DnssecKeyRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DnssecKeyFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.DnssecKeyRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.DnssecKeyRequest
      }
    };
  }
}

export class DnssecKeyFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DnssecKeyFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.DnssecKeyFail,
        type: 'local',
        data
      }
    };

    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.DnssecKeyRequest}:${id}`
    };
  }
}

export class DnssecKeyLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.DnssecKeyLocalSuccess;
  progress: ProgressActionMeta;

  constructor(public payload: DnsSecKeyEntityBase, public meta: string) {
    this.progress = {
      remove: `${ActionTypes.DnssecKeyRequest}:${meta}`
    };
  }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess
  | EntityRequest
  | EntityFail
  | EntityLocalSuccess
  | PublishRequest
  | PublishFail
  | PublishLocalSuccess
  | DeleteRequest
  | DeleteFail
  | DeleteLocalSuccess
  | RestoreRequest
  | RestoreFail
  | RestoreLocalSuccess
  | DeactivateRequest
  | DeactivateFail
  | DeactivateLocalSuccess
  | ActivateRequest
  | ActivateFail
  | ActivateLocalSuccess
  | SoaRequest
  | SoaRequestFail
  | SoaRequestLocalSuccess
  | SoaUpdateRequest
  | SoaUpdateFail
  | SoaUpdateLocalSuccess
  | DnssecKeyRequest
  | DnssecKeyFail
  | DnssecKeyLocalSuccess;
