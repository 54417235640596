import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientUserBaseApi = /** @class */ (function () {
    function ClientUserBaseApi(_http) {
        this._http = _http;
    }
    ClientUserBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/api/public/client-user/" + id);
    };
    ClientUserBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/user");
    };
    ClientUserBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function ClientUserBaseApi_Factory() { return new ClientUserBaseApi(i0.inject(i1.HttpClient)); }, token: ClientUserBaseApi, providedIn: "root" });
    return ClientUserBaseApi;
}());
export { ClientUserBaseApi };
