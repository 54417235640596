import { NgModule } from '@angular/core';

import { UploadComponent } from './upload.component';

@NgModule({
  declarations: [ UploadComponent ],
  exports: [ UploadComponent ]
})
export class UploadModule {
}
