import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { State } from '@app/models';
import { AuthorizedRunEffect } from '../auth-base/services';
import { activeUserClientId } from '../auth-base/auth-base.selector';
import {
  ActionTypes,
  EntityRequest,
  EntityLocalSuccess,
  EntityFail,
  ListRequest,
  ListLocalSuccess,
  ListFail
} from './client-user-base.action';
import { ClientUserBaseApi } from './client-user-base.api';

@Injectable()
export class ClientUserBaseEffect extends AuthorizedRunEffect {

  @Effect()
  private _onInitListRequest$ = this.onAuthorizedInit$.pipe(
    delay(0),
    map(() => new ListRequest())
  );

  @Effect()
  private _onEntityRequest$ = this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap(({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    )
  );

  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(activeUserClientId))),
    switchMap(([ _, id ]) => this._api
      .list$(id)
      .pipe(
        map(({ clientUserList }) => new ListLocalSuccess(clientUserList)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _api: ClientUserBaseApi,
    private _store: Store<State>,
    private _errorTranslation: ErrorTranslationService
  ) {
    super(_actions$, _store);
  }
}
