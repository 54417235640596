import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
// intentional explicit imports due to cycling dep error
import {
  TicketsEntity,
  TicketsBaseEntityEntities
} from '@app/base/tickets-base/tickets-base.model';
import {
  normalizeList as ticketsBaseNormalizeList
} from '@app/base/tickets-base/tickets-base.utils';

export enum ActionTypes {
  AddRequest = '[Ticket Add] Add Request',
  AddFail = '[Ticket Add] Add Fail',
  AddLocalSuccess = '[Ticket Add] Add Local Success',
  Reset = '[Ticket Add] Reset'
}

export class AddRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.AddRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AddFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.AddRequest,
      type: 'local'
    }
  };
}

export class AddFail implements Action, ErrorsAction {
  readonly type = ActionTypes.AddFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.AddFail,
        type: 'local',
        data
      }
    };
  }
}

export class AddLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AddLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };
  payload: {
    entities: TicketsBaseEntityEntities;
    result: string[];
  };
  meta: TicketsEntity;

  constructor(data: TicketsEntity) {
    this.meta = data;
    const { entities, result } = ticketsBaseNormalizeList([ data ]);
    this.payload = { entities, result };
  }
}

export class Reset implements Action {
  readonly type = ActionTypes.Reset;
}

export type Actions
  = AddRequest
  | AddFail
  | AddLocalSuccess
  | Reset;
