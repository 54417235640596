import { schema } from 'normalizr';
// imports are explicit because of
// Circular dependency compilation warnings
import {
  ModuleTokens as ClientUserBaseModuleTokens
} from '@app/base/client-user-base/client-user-base.constant';
import {
  ModuleTokens as UsersBaseModuleTokens
} from '@app/base/users-base/users-base.constant';
import {
  ModuleTokens as ClientsBaseModuleTokens
} from '@app/base/clients-base/clients-base.constant';
import {
  ModuleTokens as ServicesBaseModuleTokens
} from '@app/base/services-base/services-base.constant';
import {
  ModuleTokens as ServerParkAccessModuleTokens
} from '@app/base/server-park-access-base/server-park-access-base.constant';
import {
  ModuleTokens as TicketsModuleTokens
} from '@app/base/tickets-base/tickets-base.constant';
import {
  ModuleTokens as ServersModuleTokens
} from '@app/base/servers-base/servers-base.constant';
import {
  ModuleTokens as InvoicesModuleTokens
} from '@app/base/invoices-base/invoices-base.constant';
import {
  ModuleTokens as PaymentsModuleTokens
} from '@app/base/payments-base/payments-base.constant';
import {
  ModuleTokens as ClientServicesModuleTokens
} from '@app/base/client-services-base/client-services-base.constant';
import {
  ModuleTokens as DomainsModuleTokens
} from '@app/base/domains-base/domains-base.constant';

// # Schemas
// -- client user
export const clientUserSchema = new schema.Entity(ClientUserBaseModuleTokens.Name);
export const clientUserListSchema = new schema.Array(clientUserSchema);

// -- users
export const userSchema = new schema.Entity(UsersBaseModuleTokens.Name);
export const userListSchema = new schema.Array(userSchema);

// -- clients
export const clientSchema = new schema.Entity(ClientsBaseModuleTokens.Name);
export const clientListSchema = new schema.Array(clientSchema);

// -- services
export const servicesSchema = new schema.Entity(ServicesBaseModuleTokens.Name);
export const servicesListSchema = new schema.Array(servicesSchema);

// -- servers
export const serversSchema = new schema.Entity(ServersModuleTokens.Name);
export const serversListSchema = new schema.Array(serversSchema);

// -- server park access
export const serverParkAccessSchema = new schema.Entity(ServerParkAccessModuleTokens.Name);
export const serverParkAccessListSchema = new schema.Array(serverParkAccessSchema);

// -- tickets
export const ticketsSchema = new schema.Entity(TicketsModuleTokens.Name);
export const ticketsListSchema = new schema.Array(ticketsSchema);

// -- invoices
export const invoicesSchema = new schema.Entity(InvoicesModuleTokens.Name);
export const invoicesListSchema = new schema.Array(invoicesSchema);

// -- payments
export const paymentsSchema = new schema.Entity(PaymentsModuleTokens.Name);
export const paymentsListSchema = new schema.Array(paymentsSchema);

// -- client services
export const clientServicesSchema = new schema.Entity(ClientServicesModuleTokens.Name);
export const clientServicesListSchema = new schema.Array(clientServicesSchema);

// -- ticket message
export const ticketMessageSchema = new schema.Entity(TicketsModuleTokens.Messages, undefined, {
  idAttribute: 'extId'
});
export const ticketMessageListSchema = new schema.Array(ticketMessageSchema);

// --  domains
export const domainSchema = new schema.Entity(DomainsModuleTokens.Name);
export const domainListSchema = new schema.Array(domainSchema);

// -- domain active records
export const domainActiveRecordSchema = new schema.Entity(DomainsModuleTokens.ActiveRecords);
export const domainActiveRecordListSchema = new schema.Array(domainActiveRecordSchema);

// -- domain last records
export const domainLastRecordSchema = new schema.Entity(DomainsModuleTokens.LastRecords);
export const domainLastRecordListSchema = new schema.Array(domainLastRecordSchema);

// # Definitions
// -- team
clientUserSchema.define({
  user: userSchema,
  client: clientSchema
});

// -- users
userSchema.define({
  clientUserList: clientUserListSchema
});

// -- client
clientSchema.define({
  connectedServiceList: servicesListSchema,
  connectedUserList: clientUserListSchema
});

// -- tickets
ticketsSchema.define({
  ticketMessageList: ticketMessageListSchema
});

// -- payments
paymentsSchema.define({
  connectedInvoiceList: invoicesListSchema
});

// -- invoices
invoicesSchema.define({
  invoiceItemList: invoicesListSchema
});

// -- domains
domainSchema.define({
  activeDomainRecordList: domainActiveRecordListSchema,
  lastDomainRecordList: domainLastRecordListSchema
});
