import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FilesApi = /** @class */ (function () {
    function FilesApi(_http) {
        this._http = _http;
    }
    FilesApi.prototype.upload$ = function (file, clientId, reportProgress, observe) {
        if (reportProgress === void 0) { reportProgress = false; }
        if (observe === void 0) { observe = 'events'; }
        var formData = new FormData();
        formData.append('file', file);
        return this._http.post("/api/public/client/" + clientId + "/file", formData, {
            reportProgress: reportProgress,
            observe: observe
        });
    };
    FilesApi.ngInjectableDef = i0.defineInjectable({ factory: function FilesApi_Factory() { return new FilesApi(i0.inject(i1.HttpClient)); }, token: FilesApi, providedIn: "root" });
    return FilesApi;
}());
export { FilesApi };
