import * as tslib_1 from "tslib";
import { OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { progressGroupMap } from '@zerops/fe/ngrx';
import { ObservableInput } from 'observable-input';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { getTicketEntityById, TicketsBaseActionTypes } from '@app/base/tickets-base';
import { ScrollableComponent } from '@app/common/scrollable';
import { TicketsBaseApi } from '@app/base/tickets-base';
import { PopoverComponent, PopoverAnchoringService } from '@app/common/popover';
var TicketDetailContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketDetailContainer, _super);
    function TicketDetailContainer(_ticketApi, _anchoringService, _viewContainerRef, _store) {
        var _this = _super.call(this) || this;
        _this._ticketApi = _ticketApi;
        _this._anchoringService = _anchoringService;
        _this._viewContainerRef = _viewContainerRef;
        _this._store = _store;
        // # Event Streams
        _this.onFileDownload$ = new Subject();
        // # Data
        // -- sync
        _this._loaded = false;
        _this.messageAddTempKey = TicketsBaseActionTypes.MessageAddTemp;
        _this.avatarSourceMap = {
            USER: 'gravatar',
            ADMIN: 'image'
        };
        // -- async
        _this.data$ = _this.id$.pipe(switchMap(function (id) { return _this._store.pipe(select(getTicketEntityById(id))); }));
        _this.messageAddTempMap$ = _this._store.pipe(select(progressGroupMap(_this.messageAddTempKey)));
        _this.onFileDownload$
            .pipe(switchMap(function (id) { return _this._ticketApi.attachment$(id); }))
            .subscribe(function (_a) {
            var token = _a.token;
            return window.location.replace("/api/public/file/" + token);
        });
        // # Data Resolvers
        _this.messageAddTempMap$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (d) { return _this.messageAddTempMap = d; });
        return _this;
    }
    TicketDetailContainer.prototype.scrollTo = function (offset, duration) {
        if (!this.scrollableRef) {
            return;
        }
        if (offset === 'bottom') {
            this.scrollableRef.scrollToBottom(duration);
            return;
        }
        this.scrollableRef.scrollTo(offset, undefined, duration);
    };
    TicketDetailContainer.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this._loaded = true;
        }, 300);
    };
    TicketDetailContainer.prototype.trackBy = function (index) {
        return index;
    };
    TicketDetailContainer.prototype.attachmentOpen = function (_a) {
        var element = _a.element, files = _a.files;
        this.attachments = files;
        this._anchoringService.anchor(this.attachementPopRef, this._viewContainerRef, new ElementRef(element));
        this._anchoringService.openPopover();
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], TicketDetailContainer.prototype, "id$", void 0);
    return TicketDetailContainer;
}(BaseClass));
export { TicketDetailContainer };
