var ProgressItemComponent = /** @class */ (function () {
    function ProgressItemComponent() {
        this.spinnerSize = 40;
        this.strokeWidth = 3;
    }
    Object.defineProperty(ProgressItemComponent.prototype, "active", {
        get: function () {
            return this._active;
        },
        set: function (v) {
            this._active = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressItemComponent.prototype, "isActiveClass", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    return ProgressItemComponent;
}());
export { ProgressItemComponent };
