import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import { map, audit } from 'rxjs/operators';
import { combineLatest, interval } from 'rxjs';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { MatIconRegistry } from '@angular/material/icon';
import { CUSTOM_ICONS } from '../assets/custom-icons/custom-icons.constant';
import { environment } from 'environments/environment';
import { Roles, CheckSavedToken, authActiveClientUser } from '@app/base/auth-base';
import { showTicketsTrigger } from '@app/common/tickets-trigger';
import { wsReconnecting } from '@app/common/websockets';
import { cloudnsWsReconnecting } from '@app/common/clouddns-websockets';
var AppComponent = /** @class */ (function () {
    function AppComponent(_sanitizer, _permission, _store, _angulartics, _iconRegistry) {
        var _this = this;
        this._sanitizer = _sanitizer;
        this._permission = _permission;
        this._store = _store;
        this._angulartics = _angulartics;
        this._iconRegistry = _iconRegistry;
        this.isAuthorized$ = this._permission.authorize({
            only: [Roles.Authorized]
        });
        this.activeUserClient$ = this._store.pipe(select(authActiveClientUser));
        this.showTicketsTrigger$ = this._store.pipe(select(showTicketsTrigger));
        this.showTrigger$ = combineLatest(this.activeUserClient$, this.showTicketsTrigger$)
            .pipe(map(function (_a) {
            var userClient = _a[0], showTrigger = _a[1];
            return userClient
                && !!userClient.authorizedToSendTickets
                && showTrigger;
        }));
        this.wsReconnecting$ = this._store.pipe(select(wsReconnecting));
        this.cloudnsWsReconnecting$ = this._store.pipe(select(cloudnsWsReconnecting));
        this.reconnecting$ = combineLatest(this.wsReconnecting$, this.cloudnsWsReconnecting$).pipe(map(function (_a) {
            var wsR = _a[0], cdnsWsR = _a[1];
            return wsR || cdnsWsR;
        }));
        this.reconnectState$ = this.reconnecting$.pipe(map(function (reconnecting) { return reconnecting ? '1' : '0'; }));
        this.wsReconnectingShow$ = this.reconnecting$.pipe(audit(function (val) { return interval(!!val ? 10000 : 2000); }));
        if (environment.production) {
            this._angulartics.startTracking();
        }
        // custom icons
        CUSTOM_ICONS.forEach(function (name) {
            _this._iconRegistry.addSvgIcon(name, _this._sanitizer.bypassSecurityTrustResourceUrl("/assets/custom-icons/" + name + ".svg"));
        });
        this._store.dispatch(new CheckSavedToken());
    }
    return AppComponent;
}());
export { AppComponent };
