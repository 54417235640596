import * as tslib_1 from "tslib";
import { ModuleTokens } from './clients-base.constant';
var ClientsBaseState = /** @class */ (function () {
    function ClientsBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
    }
    return ClientsBaseState;
}());
export { ClientsBaseState };
var ClientsEntityEntities = /** @class */ (function () {
    function ClientsEntityEntities() {
    }
    return ClientsEntityEntities;
}());
export { ClientsEntityEntities };
ModuleTokens.Name;
var ClientsEntityNormalizedData = /** @class */ (function () {
    function ClientsEntityNormalizedData() {
    }
    return ClientsEntityNormalizedData;
}());
export { ClientsEntityNormalizedData };
var InvoiceAdress = /** @class */ (function () {
    function InvoiceAdress() {
    }
    return InvoiceAdress;
}());
export { InvoiceAdress };
var DeliveryAddress = /** @class */ (function () {
    function DeliveryAddress() {
    }
    return DeliveryAddress;
}());
export { DeliveryAddress };
// TODO: interface
var Salesman = /** @class */ (function () {
    function Salesman() {
    }
    return Salesman;
}());
export { Salesman };
var ClientEntityLight = /** @class */ (function () {
    function ClientEntityLight() {
    }
    return ClientEntityLight;
}());
export { ClientEntityLight };
var ClientEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ClientEntityBase, _super);
    function ClientEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientEntityBase;
}(ClientEntityLight));
export { ClientEntityBase };
var ClientEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(ClientEntityNormalized, _super);
    function ClientEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientEntityNormalized;
}(ClientEntityBase));
export { ClientEntityNormalized };
var ClientEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ClientEntity, _super);
    function ClientEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClientEntity;
}(ClientEntityBase));
export { ClientEntity };
