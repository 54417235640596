import {
  Component,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { TicketState } from '@app/base/tickets-base';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'vshcz-ticket-state',
  templateUrl: './ticket-state.component.html',
  styleUrls: ['./ticket-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketStateComponent {
  @Input()
  state: TicketState;

  @Input()
  set stickerOnly(v) {
    this._stickerOnly = coerceBooleanProperty(v);
  }
  get stickerOnly() {
    return this._stickerOnly;
  }

  private _stickerOnly: boolean;
}
