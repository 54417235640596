import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  query,
  trigger,
  state,
  style,
  transition,
  animate,
  stagger,
  sequence
} from '@angular/animations';

@Component({
  selector: 'vshcz-logo',
  templateUrl: './logo.component.html',
  styleUrls: [ './logo.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('letters', [

      transition(':enter', [
        query('.__letter-group', [
          style({
            opacity: 0,
            transform: 'translate3d(0, -4px, 0)'
          }),
          stagger(20, [
            animate(150, style({
              opacity: 1,
              transform: 'translate3d(0, 0, 0)'
            }))
          ])
        ])
      ]),

      transition(':leave', [
        query('.__letter-group', [
          style({
            opacity: 1
          }),
          stagger(-20, [
            animate(100, style({
              opacity: 0,
              transform: 'translate3d(0, 4px, 0)'
            }))
          ])
        ])
      ])
    ]),

    trigger('tilde', [

      state('condensed', style({
        transform: 'translate3d(-89px, 0, 0)'
      })),

      transition('full => condensed', [
        sequence([
          animate(300, style({
            opacity: 0,
            transform: 'translate3d(0, 0, 0)'
          })),
          style({
            transform: 'translate3d(-89px, 0, 0)'
          }),
          animate(300, style({ opacity: 1 }))
        ])
      ]),

      transition('condensed => full', [
        sequence([
          animate(50, style({
            opacity: 0,
            transform: 'translate3d(-89px, 0, 0)'
          })),
          style({
            transform: 'translate3d(0, 0, 0)'
          }),
          animate('300ms 300ms', style({ opacity: 1 }))
        ])
      ])

    ])
  ]
})
export class LogoComponent {
  @Input()
  full = false;
}
