import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import {
  ClientServiceEntityBase,
  ClientServiceEntityNormalizedData
} from './client-services-base.model';
import { normalizeList } from './client-services-base.utils';

export enum ActionTypes {
  // -- list
  ListRequest = '[Client Services Base] List Request',
  ListFail = '[Client Services Base] List Fail',
  ListLocalSuccess = '[Client Services Base] List Local Success'
}

// -- list
export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }

}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: ClientServiceEntityNormalizedData;

  constructor(data: ClientServiceEntityBase[]) {
    this.payload = normalizeList(data);
  }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess;
