import { normalize, denormalize } from 'normalizr';
import { paymentsListSchema, paymentsSchema } from '@app/schemas';
import { ModuleTokens } from './payments-base.constant';
import { InvoicesBaseModuleTokens } from '../invoices-base';
export function normalizeList(data) {
    return normalize(data, paymentsListSchema);
}
export function denormalizeList(ids, paymentsEntities, invoicesEntities) {
    var _a;
    return denormalize(ids, paymentsListSchema, (_a = {},
        _a[ModuleTokens.Name] = paymentsEntities,
        _a[InvoicesBaseModuleTokens.Name] = invoicesEntities,
        _a));
}
export function denormalizeEntity(id, paymentsEntities, invoicesEntities) {
    var _a;
    return denormalize(id, paymentsSchema, (_a = {},
        _a[ModuleTokens.Name] = paymentsEntities,
        _a[InvoicesBaseModuleTokens.Name] = invoicesEntities,
        _a));
}
export function getPaymentQR(iban, amount, currencyId, variableSymbol, swift, message) {
    if (!iban || !amount || !currencyId) {
        return '';
    }
    var acc = !!swift ? (iban + '+' + swift) : iban;
    var msg = !!message ? "*MSG:" + message : '';
    var vs = !!variableSymbol ? "*X-VS:" + variableSymbol : '';
    return "SPD*1.0*ACC:" + acc + "*AM:" + amount.toString() + "*CC:" + currencyId + vs + msg;
}
