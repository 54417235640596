import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import sortBy from 'lodash-es/sortBy';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CountryCodesService = /** @class */ (function () {
    function CountryCodesService(_http) {
        this._http = _http;
    }
    CountryCodesService.prototype.load$ = function () {
        if (!this._data$) {
            this._data$ = this._http
                .get('/assets/country-codes.json')
                .pipe(shareReplay(1));
        }
        return this._data$;
    };
    CountryCodesService.prototype.phoneCodes$ = function () {
        return this.load$().pipe(map(function (data) { return sortBy(data.codes.reduce(function (arr, code) {
            arr.push({
                phonePrefix: data.phones[code],
                name: data.names[code],
                code: code.toLowerCase()
            });
            return arr;
        }, []), ['name']); }));
    };
    CountryCodesService.ngInjectableDef = i0.defineInjectable({ factory: function CountryCodesService_Factory() { return new CountryCodesService(i0.inject(i1.HttpClient)); }, token: CountryCodesService, providedIn: "root" });
    return CountryCodesService;
}());
export { CountryCodesService };
