import * as tslib_1 from "tslib";
import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './servers-base.constant';
import { ServersBaseState } from './servers-base.model';
import { ActionTypes as BaseActionTypes } from './servers-base.action';
import { ActionTypes as IpActionTypes } from './servers-base-ip.action';
var initialState = new ServersBaseState();
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    state = applyEntities(state, [ModuleTokens.Name], action);
    if (action.type === BaseActionTypes.ListLocalSuccess) {
        return tslib_1.__assign({}, state, { list: action.payload.result });
    }
    if (action.type === BaseActionTypes.GraphRequest) {
        if (!action.meta) {
            return tslib_1.__assign({}, state, { graphs: {} });
        }
    }
    if (action.type === BaseActionTypes.GraphLocalSuccess) {
        return tslib_1.__assign({}, state, { graphs: tslib_1.__assign({}, state.graphs, (_a = {}, _a[action.payload.id] = tslib_1.__assign({}, state.graphs[action.payload.id], action.payload.graphType.reduce(function (obj, _, i) {
                obj[action.payload.graphType[i]] = action.payload.data[i];
                return obj;
            }, {})), _a)) });
    }
    if (action.type === BaseActionTypes.IpRangeLocalSuccess) {
        return tslib_1.__assign({}, state, { ipV4Range: action.payload.ipV4RangeList, ipV6Range: action.payload.ipV6RangeList, freeIpV4List: action.payload.clientFreeIpV4AddressList, usedIpV6List: action.payload.clientUsedIpV6AddressList });
    }
    if (action.type === IpActionTypes.AddIpV4LocalSuccess) {
        return tslib_1.__assign({}, state, { freeIpV4List: state.freeIpV4List.filter(function (d) { return d.serverIpV4Id !== action.meta.id; }) });
    }
    if (action.type === IpActionTypes.AddIpV6LocalSuccess) {
        return tslib_1.__assign({}, state, { usedIpV6List: state.usedIpV6List.concat([
                {
                    ipV6: action.meta.ip,
                    serverIpV6Id: action.meta.id
                }
            ]) });
    }
    if (action.type === IpActionTypes.DeleteIpLocalSuccess) {
        if (action.meta.type === 'ipv4') {
            return tslib_1.__assign({}, state, { freeIpV4List: state.freeIpV4List.concat([
                    { serverIpV4Id: action.meta.id, ipV4: action.meta.ip }
                ]) });
        }
        if (action.meta.type === 'ipv6') {
            return tslib_1.__assign({}, state, { usedIpV6List: state.usedIpV6List.filter(function (d) { return d.serverIpV6Id !== action.meta.id; }) });
        }
    }
    return state;
}
