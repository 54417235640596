import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TicketsEntity } from '@app/base/tickets-base/tickets-base.model';

@Injectable({
  providedIn: 'root'
})
export class TicketAddApi {
  constructor(private _http: HttpClient) { }

  add$(data: any, clientId: string) {
    return this._http.post<TicketsEntity>(
      `/api/public/ticket`,
      {
        ...this._cleanupAdd$Data(data),
        clientId
      }
    );
  }

  private _cleanupAdd$Data(data: any) {
    return {
      ...data,
      attachments: data.attachments
        ? data.attachments
        : []
    };
  }

}
