import { applyEntities } from '@zerops/fe/core';
import { DomainsBaseState } from './domains-base.model';
import { ModuleTokens } from './domains-base.constant';
import { Actions, ActionTypes } from './domains-base.action';

const initialState = new DomainsBaseState();

export function reducer(
  state = initialState,
  action: Actions
): DomainsBaseState {
  state = applyEntities<DomainsBaseState>(
    state,
    [
      ModuleTokens.Name,
      ModuleTokens.ActiveRecords,
      ModuleTokens.LastRecords
    ],
    action
  );

  switch (action.type) {

    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        list: action.payload.result
      };

    case ActionTypes.SoaRequestLocalSuccess:
      return {
        ...state,
        soaMap: {
          ...state.soaMap,
          [action.payload.domainId]: action.payload
        }
      };

    case ActionTypes.SoaUpdateLocalSuccess:
      return {
        ...state,
        soaMap: {
          ...state.soaMap,
          [action.payload.domainId]: action.payload
        }
      };

    case ActionTypes.DnssecKeyLocalSuccess:
      return {
        ...state,
        dnssecKeys: {
          ...state.dnssecKeys,
          [action.meta]: action.payload
        }
      };

  }

  return state;
}
