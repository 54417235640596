import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarModule } from '@app/common/avatar';
import { UserComponent } from './user.component';

@NgModule({
  declarations: [ UserComponent ],
  imports: [
    FlexLayoutModule,
    AvatarModule
  ],
  exports: [ UserComponent ]
})
export class UserModule { }
