import { Action } from '@zerops/fe/core';

export enum ActionTypes {
  AddToFilter = '[Tickets List] Add To Filter',
  RemoveFromFilter = '[Tickets List] Remove From Filter',
  ResetFilter = '[Tickets List] Reset'
}

export class AddToFilter implements Action {
  readonly type = ActionTypes.AddToFilter;

  /**
    @param payload Key
    @param meta Rewrite existing filters
  */
  constructor(public payload: string | string[], public meta = false) { }
}

export class RemoveFromFilter implements Action {
  readonly type = ActionTypes.RemoveFromFilter;

  /**
    @param payload Key
  */
 constructor(public payload: string | string[]) { }
}

export class ResetFilter implements Action {
  readonly type = ActionTypes.ResetFilter;
}

export type Actions
  = AddToFilter
  | RemoveFromFilter
  | ResetFilter;
