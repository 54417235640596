import { emailFormModel } from '@app/common/email-form';
import { TicketsBaseActions, TicketsBaseActionTypes } from '@app/base/tickets-base';
import { TicketCopyListState } from './ticket-copy-list.model';
import { ModuleTokens } from './ticket-copy-list.constant';
import { validate, Actions } from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';

const initialState = new TicketCopyListState();

export function reducer(
  state = initialState,
  action: Actions<any> | TicketsBaseActions
) {
  state = emailFormModel.reduceAction(
    state,
    action,
    ModuleTokens.Name,
    {
      email: validate(required, email)
    }
  );

  if (action.type === TicketsBaseActionTypes.UpdateCopyListLocalSuccess) {
    return { ...initialState };
  }

  return state;
}
