import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ErrorTranslationService } from 'app/services';
import { KvmConsoleConnectionApi } from './kvm-console-connection.api';
import {
  ActionTypes,
  KvmKeepaliveFail,
  KvmKeepaliveLocalSuccess,
  KvmKeepaliveRequest
} from './kvm-console-connection.action';

@Injectable()
export class KvmConsoleConnectionEffect {

  @Effect()
  private _onKvmKeepaliveRequest$ = this._actions$.pipe(
    ofType<KvmKeepaliveRequest>(ActionTypes.KvmKeepaliveRequest),
    switchMap((action) => this._api
      .kvmKeepalive$(action.payload)
      .pipe(
        map(() => new KvmKeepaliveLocalSuccess()),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(
            map((message) => new KvmKeepaliveFail(message))
          )
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _api: KvmConsoleConnectionApi,
    private _errorTranslation: ErrorTranslationService
  ) {}
}
