import { Action, EntitiesMergeStrategy } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import { ServersEntityEntities, ServerEntity } from './servers-base.model';
import { normalizeList, expandIPv6Address } from './servers-base.utils';

export enum ActionTypes {
  AddIpV4Request = '[Servers Base IP] Add IpV4 Request',
  AddIpV4Fail = '[Servers Base IP] Add IpV4 Fail',
  AddIpV4LocalSuccess = '[Servers Base IP] Add IpV4 Local Success',

  AddIpV6Request = '[Servers Base IP] Add IpV6 Request',
  AddIpV6Fail = '[Servers Base IP] Add IpV6 Fail',
  AddIpV6LocalSuccess = '[Servers Base IP] Add IpV6 Local Success',

  DeleteIpRequest = '[Servers Base IP] Delete Ip Request',
  DeleteIpFail = '[Servers Base IP] Delete Ip Fail',
  DeleteIpLocalSuccess = '[Servers Base IP] Delete Ip Local Success',

  SavePtrRequest = '[Servers Base IP] Set PTR Request',
  SavePtrFail = '[Servers Base IP] Set PTR Fail',
  SavePtrLocalSuccess = '[Servers Base IP] Set PTR Local Success'
}

export class AddIpV4Request implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AddIpV4Request;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AddIpV4Fail
  };
  progress: ProgressActionMeta;

  constructor(public payload: {
    serverId: string;
    ipId: string;
    ip: string;
    ptr?: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.AddIpV4Request}:${payload.serverId}`,
        type: 'local'
      }
    };
  }
}

export class AddIpV4Fail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AddIpV4Fail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.AddIpV4Fail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.AddIpV4Request}:${id}`
    };
  }

}

export class AddIpV4LocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AddIpV4LocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ServersEntityEntities };
  meta?: {
    data: ServerEntity,
    id?: string;
    ptr?: string;
    ip: string;
    serverId?: string;
  };

  constructor(data: ServerEntity, id: string, ptr?: string, ipId?: string, ip?: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };

    this.meta = { data, id: ipId, ip };

    if (ptr && ipId) {
      this.meta = {
        ...this.meta,
        serverId: data.id,
        ptr,
        id: ipId
      };
    }

    this.progress = {
      remove: `${ActionTypes.AddIpV4Request}:${id}`
    };
  }
}

export class AddIpV6Request implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AddIpV6Request;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AddIpV6Fail
  };
  progress: ProgressActionMeta;

  constructor(public payload: { ip: string; serverId: string; ptr?: string; }) {
    this.progress = {
      add: {
        key: `${ActionTypes.AddIpV6Request}:${payload.serverId}`,
        type: 'local'
      }
    };
  }
}

export class AddIpV6Fail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.AddIpV6Fail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.AddIpV6Fail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.AddIpV6Request}:${id}`
    };
  }

}

export class AddIpV6LocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AddIpV6LocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ServersEntityEntities };
  meta?: {
    data: ServerEntity;
    id?: string;
    ip?: string;
    ptr?: string;
    serverId?: string;
  };

  constructor(data: ServerEntity, id: string, ptr?: string, ip?: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };

    const foundIp = data.serverIpV6List
      .map((s) => ({
        ...s,
        expandedIpV6: expandIPv6Address(s.ipV6)
      }))
      .find((s) => s.expandedIpV6 === ip);

    this.meta = {
      data,
      ip,
      id: foundIp ? foundIp.id : undefined
    };

    if (ptr && ip) {

      this.meta = {
        ...this.meta,
        serverId: data.id,
        ptr,
        ip,
        id: foundIp ? foundIp.id : undefined
      };
    }

    this.progress = {
      remove: `${ActionTypes.AddIpV6Request}:${id}`
    };
  }
}

export class DeleteIpRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DeleteIpRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DeleteIpFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: {
    id: string;
    serverId: string;
    ip: string;
    type: 'ipv4' | 'ipv6'
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.DeleteIpRequest}:${this.payload.id}`,
        type: 'local',
        group: ActionTypes.DeleteIpRequest
      }
    };
  }
}

export class DeleteIpFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DeleteIpFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;
  constructor(data: ApiError, id: string) {
    this.meta = data;
    this.errors = {
      add: {
        key: ActionTypes.DeleteIpFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.DeleteIpRequest}:${id}`
    };
  }

}

export class DeleteIpLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.DeleteIpLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ServersEntityEntities };
  meta: {
    id: string;
    ip: string;
    type: 'ipv4' | 'ipv6';
    _mergeStrategy: { [key: string]: EntitiesMergeStrategy; };
  };

  constructor(data: ServerEntity, id: string, ip: string, type: 'ipv6' | 'ipv4') {
    const { entities } = normalizeList([ data ]);

    this.meta = {
      id,
      ip,
      type,
      _mergeStrategy: {
        serverIpV4List: EntitiesMergeStrategy.KeepNew,
        serverIpV6List: EntitiesMergeStrategy.KeepNew
      }
    };

    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.DeleteIpRequest}:${id}`
    };
  }
}

export class SavePtrRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.SavePtrRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SavePtrFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: {
    ptr: string;
    serverId: string;
    id: string;
    ip: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.SavePtrRequest}:${this.payload.id}`,
        type: 'local',
        group: ActionTypes.SavePtrRequest
      }
    };
  }
}

export class SavePtrFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.SavePtrFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.SavePtrFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.SavePtrRequest}:${id}`
    };
  }

}

export class SavePtrLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.SavePtrLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ServersEntityEntities };

  constructor(data: ServerEntity, id: string, public meta: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.SavePtrRequest}:${id}`
    };
  }
}

export type Actions
  = AddIpV4Request
  | AddIpV4Fail
  | AddIpV4LocalSuccess

  | AddIpV6Request
  | AddIpV6Fail
  | AddIpV6LocalSuccess

  | DeleteIpRequest
  | DeleteIpFail
  | DeleteIpLocalSuccess

  | SavePtrRequest
  | SavePtrFail
  | SavePtrLocalSuccess;
