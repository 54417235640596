import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import {
  map,
  withLatestFrom,
  switchMap,
  catchError,
  delay,
  filter,
  first
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import {
  authActiveUserClientId,
  AuthorizedRunEffect,
  Roles
} from '@app/base/auth-base';
import { PaymentsBaseApi } from './payments-base.api';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail,
  EntityRequest,
  EntityLocalSuccess,
  EntityFail
} from './payments-base.action';

@Injectable()
export class PaymentsBaseEffect extends AuthorizedRunEffect {

  // trigger list request on load
  @Effect()
  private _onInitList$ = this.onAuthorizedInit$.pipe(
    delay(1),
    switchMap(() => this._permission
      .authorize({
        only: [ Roles.Manager, Roles.Financial ]
      })
      .pipe(
        first()
      )
    ),
    filter((authorized) => !!authorized),
    map(() => new ListRequest())
  );

  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .list$(id)
      .pipe(
        map(({ paymentList }) => new ListLocalSuccess(
          paymentList
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  );

  @Effect()
  private _onEntityRequest$ = this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap(({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: PaymentsBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _permission: PermissionService
  ) {
    super(_actions$, _store);
  }
}
