/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-topic-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/core";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i8 from "@angular/material/divider";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i12 from "@angular/material/card";
import * as i13 from "./ticket-topic-list.component";
var styles_TicketTopicListComponent = [i0.styles];
var RenderType_TicketTopicListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketTopicListComponent, data: {} });
export { RenderType_TicketTopicListComponent as RenderType_TicketTopicListComponent };
function View_TicketTopicListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "__ticket-topic-pop-button mat-list-item mat-ripple"], ["mat-list-item", ""], ["matRipple", ""], ["type", "button"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null], [2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.selected.emit(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), i1.ɵdid(5, 212992, null, 0, i4.MatRipple, [i1.ElementRef, i1.NgZone, i5.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(6, 2, [" ", " "]))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = i1.ɵnov(_v, 5).unbounded; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit._name; _ck(_v, 6, 0, currVal_3); }); }
function View_TicketTopicListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i7.View_MatDivider_0, i7.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i8.MatDivider, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_TicketTopicListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h3", [["class", "__ticket-topic-pop-subheader mat-subheader"], ["mat-subheader", ""]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.MatListSubheaderCssMatStyler, [], null, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketTopicListComponent_2)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketTopicListComponent_3)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("ticketTopics." + _v.context.$implicit.key))); _ck(_v, 3, 0, currVal_0); }); }
export function View_TicketTopicListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "__ticket-topic-pop-card  mat-elevation-z5 mat-card"]], null, null, null, i11.View_MatCard_0, i11.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i12.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-action-list", [["class", "mat-list mat-list-base"], ["dense", ""]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(3, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TicketTopicListComponent_1)), i1.ɵdid(5, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupedTopics; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_TicketTopicListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-ticket-topic-list", [], null, null, null, View_TicketTopicListComponent_0, RenderType_TicketTopicListComponent)), i1.ɵdid(1, 49152, null, 0, i13.TicketTopicListComponent, [], null, null)], null, null); }
var TicketTopicListComponentNgFactory = i1.ɵccf("vshcz-ticket-topic-list", i13.TicketTopicListComponent, View_TicketTopicListComponent_Host_0, { groupedTopics: "groupedTopics" }, { selected: "selected" }, []);
export { TicketTopicListComponentNgFactory as TicketTopicListComponentNgFactory };
