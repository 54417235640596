<div
  class="__layout"
  [class.has-multi-clients]="hasMultiClients$ | async"
  fxLayout="row"
  fxLayoutAlign="center center">
  <div fxFlex>

    <h4 class="mat-h4  __title">
      <span translate="ticketAuthorization.heading"></span>
    </h4>

    <p class="mat-body  __text">

      <span translate="ticketAuthorization.descSingle"></span>

      <strong
        class="__multi-desc"
        *ngIf="hasMultiClients$ | async"
        translate="ticketAuthorization.descMulti">
      </strong>

    </p>

    <vshcz-form
      (submit)="onAuthorize$.next()"
      [state]="formState$ | async">

      <vshcz-form-field
        last
        class="__form-field"
        *ngIf="hasMultiClients$ | async">
        <mat-form-field class="__clients-select">
          <mat-label translate="ticketAuthorization.clientSelectLabel">
          </mat-label>
          <mat-select
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="(formState$ | async).controls.clientId">
            <mat-option
              [value]="item.client?.id"
              *ngFor="let item of (clients$ | async)">
              {{ item.client?.companyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </vshcz-form-field>

      <vshcz-progress-error
        class="__progress"
        [errorCloseLabel]="'common.close' | translate"
        [errorRetryLabel]="'common.retry' | translate"
        [errorShowDetailsLabel]="'common.showMore' | translate"
        [errorHideDetailsLabel]="'common.hideMore' | translate"
        [errorKey]="failKey$ | async"
        [progressKey]="progressKey$ | async">
        <button
          mat-raised-button
          class="__button"
          color="primary"
          type="submit">

          <mat-icon>thumb_up_alt</mat-icon>
          <span translate="ticketAuthorization.authorize"></span>
           <span
            *ngIf="(selectedClientCompanyName$ | async) as companyName">
            {{ 'ticketAuthorization.assign' | translate: { company: companyName } }}
          </span>

        </button>
      </vshcz-progress-error>

    </vshcz-form>

  </div>
</div>
