import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsLoad$ApiResponse } from './settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsApi {
  constructor(private _http: HttpClient) { }

  settings$() {
    return this._http.get<SettingsLoad$ApiResponse>(
      `/api/public/settings`
    );
  }
}
