import { coerceNumberProperty } from '@angular/cdk/coercion';
import { TicketsEntity } from '@app/base/tickets-base';
var TicketItemComponent = /** @class */ (function () {
    function TicketItemComponent() {
        this._truncateMessage = 100;
    }
    Object.defineProperty(TicketItemComponent.prototype, "truncateMessage", {
        get: function () {
            return this._truncateMessage;
        },
        set: function (v) {
            if (v) {
                this._truncateMessage = coerceNumberProperty(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    return TicketItemComponent;
}());
export { TicketItemComponent };
