import { RecaptchaState } from './recaptcha.model';
import { ActionTypes, Actions } from './recaptcha.action';

const initialState = new RecaptchaState();

export function reducer(
  state = initialState,
  action: Actions
): RecaptchaState {

  switch (action.type) {
    case ActionTypes.SetActive:
      return {
        ...state,
        active: true
      };

    case ActionTypes.SetInactive:
      return {
        ...state,
        active: false
      };
  }

  return state;
}
