import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimals'
})
export class DecimalsPipe implements PipeTransform {
  transform(number: number, decimalCount = 2): string {
    return number.toFixed(decimalCount).toString().split('.')[1];
  }
}
