import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RemoveError } from '@zerops/fe/ngrx';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { SnackService } from '@app/common/snack';
import { ErrorTranslationService } from '@app/services';
import { TicketsBaseApi } from './tickets-base.api';
import {
  ActionTypes,
  UnlockRequest,
  UnlockLocalSuccess,
  UnlockFail,
  ApprovePriceOfferRequest,
  ApprovePriceOfferLocalSuccess,
  ApprovePriceOfferFail,
  RejectPriceOfferRequest,
  RejectPriceOfferLocalSuccess,
  RejectPriceOfferFail,
  ConfirmRealizationDateRequest,
  ConfirmRealizationDateLocalSuccess,
  ConfirmRealizationDateFail,
  RejectRealizationDateRequest,
  RejectRealizationDateLocalSuccess,
  RejectRealizationDateFail
} from './tickets-base.action';
import { TranslateService } from '@ngx-translate/core';

// separated effect file containing effects listening to actions fired even if user not authorized (ticket public pages)
@Injectable()
export class TicketsBaseNotAuthorizedEffect {

  // unlock
  @Effect()
  private _onUnlockRequest$ = this._actions$.pipe(
    ofType<UnlockRequest>(ActionTypes.UnlockRequest),
    switchMap((({ payload }) => this._api
      .unlock$(payload)
      .pipe(
        map((response) => new UnlockLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new UnlockFail(data, payload)))
        )
      )
    ))
  );

  // price offer
  @Effect()
  private _onApprovePriceOfferRequest$ = this._actions$.pipe(
    ofType<ApprovePriceOfferRequest>(ActionTypes.ApprovePriceOfferRequest),
    switchMap((({ payload, meta: { lastAdminTicketMessageId, clientId } }) => this._api
      .approvePriceOffer$(payload, lastAdminTicketMessageId, clientId)
      .pipe(
        map((response) => new ApprovePriceOfferLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ApprovePriceOfferFail(data, payload)))
        )
      )
    ))
  );

  @Effect({ dispatch: false })
  private _onApprovePriceOfferLocalSuccess$ = this._actions$.pipe(
    ofType<ApprovePriceOfferLocalSuccess>(ActionTypes.ApprovePriceOfferLocalSuccess),
    switchMap(() => this._translate
      .get([
        'common.close',
        'ticketBase.approvePriceOfferSuccess'
      ])
      .pipe(
        switchMap((translations) => this._snack.success$(
          translations['ticketBase.approvePriceOfferSuccess'],
          translations['common.close']
        ))
      )
    )
  );

  @Effect()
  private _onApprovePriceOfferFail$ = this._actions$.pipe(
    ofType<ApprovePriceOfferFail>(ActionTypes.ApprovePriceOfferFail),
    switchMap((action) => this._snack.translatedFail$(
      action.meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ApprovePriceOfferFail))
  );

  @Effect()
  private _onRejectPriceOfferRequest$ = this._actions$.pipe(
    ofType<RejectPriceOfferRequest>(ActionTypes.RejectPriceOfferRequest),
    switchMap((({ payload, meta: { clientId, messageText } }) => this._api
      .rejectPriceOffer$(payload, clientId, messageText)
      .pipe(
        map((response) => new RejectPriceOfferLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new RejectPriceOfferFail(data, payload)))
        )
      )
    ))
  );

  @Effect({ dispatch: false })
  private _onRejectPriceOfferLocalSuccess$ = this._actions$.pipe(
    ofType<RejectPriceOfferLocalSuccess>(ActionTypes.RejectPriceOfferLocalSuccess),
    switchMap(() => this._translate
      .get([
        'common.close',
        'ticketBase.rejectPriceOfferSuccess'
      ])
      .pipe(
        switchMap((translations) => this._snack.success$(
          translations['ticketBase.rejectPriceOfferSuccess'],
          translations['common.close']
        ))
      )
    )
  );

  @Effect()
  private _onRejectPriceOfferFail$ = this._actions$.pipe(
    ofType<RejectPriceOfferFail>(ActionTypes.RejectPriceOfferFail),
    switchMap((action) => this._snack.translatedFail$(
      action.meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.RejectPriceOfferFail))
  );

  // realization date
  @Effect()
  private _onConfirmRealizationDateRequest$ = this._actions$.pipe(
    ofType<ConfirmRealizationDateRequest>(ActionTypes.ConfirmRealizationDateRequest),
    switchMap((({ payload, meta: { clientId } }) => this._api
      .confirmRealizationDate$(payload, clientId)
      .pipe(
        map((response) => new ConfirmRealizationDateLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ConfirmRealizationDateFail(data, payload)))
        )
      )
    ))
  );

  @Effect({ dispatch: false })
  private _onConfirmRealizationDateLocalSuccess$ = this._actions$.pipe(
    ofType<ConfirmRealizationDateLocalSuccess>(ActionTypes.ConfirmRealizationDateLocalSuccess),
    switchMap(() => this._translate
      .get([
        'common.close',
        'ticketBase.confirmRealizationDateSuccess'
      ])
      .pipe(
        switchMap((translations) => this._snack.success$(
          translations['ticketBase.confirmRealizationDateSuccess'],
          translations['common.close']
        ))
      )
    )
  );

  @Effect()
  private _onConfirmRealizationDateFail$ = this._actions$.pipe(
    ofType<ConfirmRealizationDateFail>(ActionTypes.ConfirmRealizationDateFail),
    switchMap((action) => this._snack.translatedFail$(
      action.meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ConfirmRealizationDateFail))
  );

  @Effect()
  private _onRejectRealizationDateRequest$ = this._actions$.pipe(
    ofType<RejectRealizationDateRequest>(ActionTypes.RejectRealizationDateRequest),
    switchMap((({ payload, meta: { clientId, messageText } }) => this._api
      .rejectRealizationDate$(payload, clientId, messageText)
      .pipe(
        map((response) => new RejectRealizationDateLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new RejectRealizationDateFail(data, payload)))
        )
      )
    ))
  );

  @Effect({ dispatch: false })
  private _onRejectRealizationDateLocalSuccess$ = this._actions$.pipe(
    ofType<RejectRealizationDateLocalSuccess>(ActionTypes.RejectRealizationDateLocalSuccess),
    switchMap(() => this._translate
      .get([
        'common.close',
        'ticketBase.rejectRealizationDateSuccess'
      ])
      .pipe(
        switchMap((translations) => this._snack.success$(
          translations['ticketBase.rejectRealizationDateSuccess'],
          translations['common.close']
        ))
      )
    )
  );

  @Effect()
  private _onRejectRealizationDateFail$ = this._actions$.pipe(
    ofType<RejectRealizationDateFail>(ActionTypes.RejectRealizationDateFail),
    switchMap((action) => this._snack.translatedFail$(
      action.meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.RejectRealizationDateFail))
  );

  constructor(
    private _actions$: Actions,
    private _api: TicketsBaseApi,
    private _translate: TranslateService,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) { }
}
