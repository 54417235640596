import { RealizationDateRejectFormTranslations } from './realization-date-reject-form.model';

const _prefix = 'realization-date-reject-form_';

export const DEFAULT_TRANSLATION_KEYS: RealizationDateRejectFormTranslations = {
  labels: {
    messageText: _prefix + 'labels_messageText'
  },
  errors: {
    messageText: {
      required: _prefix + 'errors_messageText_required'
    }
  }
};
