import * as tslib_1 from "tslib";
var SettingsState = /** @class */ (function () {
    function SettingsState() {
        this.countryList = [];
        this.currencyList = [];
        this.languageList = [];
        this.billingPeriodList = [];
        this.standardServiceList = [];
    }
    return SettingsState;
}());
export { SettingsState };
var SettingsLoad$ApiResponse = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsLoad$ApiResponse, _super);
    function SettingsLoad$ApiResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SettingsLoad$ApiResponse;
}(SettingsState));
export { SettingsLoad$ApiResponse };
var Country = /** @class */ (function () {
    function Country() {
    }
    return Country;
}());
export { Country };
var Currency = /** @class */ (function () {
    function Currency() {
    }
    return Currency;
}());
export { Currency };
var Language = /** @class */ (function () {
    function Language() {
    }
    return Language;
}());
export { Language };
var BillingPeriod = /** @class */ (function () {
    function BillingPeriod() {
    }
    return BillingPeriod;
}());
export { BillingPeriod };
var StandardService = /** @class */ (function () {
    function StandardService() {
    }
    return StandardService;
}());
export { StandardService };
