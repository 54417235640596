import { coerceBooleanProperty } from '@angular/cdk/coercion';
var TicketStateComponent = /** @class */ (function () {
    function TicketStateComponent() {
    }
    Object.defineProperty(TicketStateComponent.prototype, "stickerOnly", {
        get: function () {
            return this._stickerOnly;
        },
        set: function (v) {
            this._stickerOnly = coerceBooleanProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    return TicketStateComponent;
}());
export { TicketStateComponent };
