import { ApiError } from '@zerops/fe/ngrx';
import { Action } from '@zerops/fe/core';
import * as uuidv4 from 'uuid/v4';
import { FilePayload, FileRawPayload } from './files.model';

export enum ActionTypes {
  AddToQueue = '[Files] Add File to Queue',
  RefusedToQueue = '[Files] Refused to Add File to Queue',
  MarkQueueItemAsDone = '[Files] Mark Queue Item as Done',
  RemoveFromQueue = '[Files] Remove File to Queue',
  ClearInstanceQueue = '[Files] Clear Instance Queue',
  EmptyQueue = '[Files] Empty Queue',

  UploadRequest = '[Files] Upload Request',
  UploadEmpty = '[Files] Upload Empty',
  UploadProgress = '[Files] Upload Progress',
  UploadSingleFail = '[Files] Upload Single Fail',
  UploadSingleLocalSuccess = '[Files] Upload Single Local Success',
  UploadAllLocalSuccess = '[Files] Upload All Local Success',

  ClearUploadedFiles = '[Files] Clear Uploaded Files'
}

export class AddToQueue implements Action {
  readonly type = ActionTypes.AddToQueue;
  payload: FilePayload;

  constructor(data: FileRawPayload) {
    this.payload = {
      ...data,
      files: data.files.map((file) => ({
        ...file,
        uuid: uuidv4()
      }))
    };
  }
}

export class RefusedToQueue implements Action {
  readonly type = ActionTypes.RefusedToQueue;

  constructor(public payload: 'size' | 'count') { }
}

export class RemoveFromQueue implements Action {
  readonly type = ActionTypes.RemoveFromQueue;

  constructor(public payload: { instanceId: string; uuid: string; }) { }
}

export class ClearInstanceQueue implements Action {
  readonly type = ActionTypes.ClearInstanceQueue;

  /**
   * @param payload instance id
   */
  constructor(public payload: string) { }
}

export class MarkQueueItemAsDone implements Action {
  readonly type = ActionTypes.MarkQueueItemAsDone;

  constructor(public payload: { instanceId: string; uuid: string; }) { }
}

export class EmptyQueue implements Action {
  readonly type = ActionTypes.EmptyQueue;

  /**
   * @param payload instance id
   */
  constructor(public payload: string) { }
}

export class UploadRequest implements Action {
  readonly type = ActionTypes.UploadRequest;

  /**
   * @param payload instance id
   */
  constructor(public payload: string) { }
}

export class UploadSingleFail implements Action {
  readonly type = ActionTypes.UploadSingleFail;

  constructor(
    public payload: {
      fileUuid: string,
      instanceId: string
    },
    public meta?: ApiError
  ) { }
}

export class UploadProgress implements Action {
  readonly type = ActionTypes.UploadProgress;

  constructor(public payload: number) { }
}

export class UploadSingleLocalSuccess implements Action {
  readonly type = ActionTypes.UploadSingleLocalSuccess;

  constructor(public payload: {
    instanceId: string;
    fileId: string;
  }) { }
}

export class UploadAllLocalSuccess implements Action {
  readonly type = ActionTypes.UploadAllLocalSuccess;

  constructor(public payload: {
    fileIds: string[];
    instanceId: string;
  }) { }
}

export class UploadEmpty implements Action {
  readonly type = ActionTypes.UploadEmpty;

  constructor(public payload: {
    instanceId: string
  }) { }
}

export class ClearUploadedFiles implements Action {
  readonly type = ActionTypes.ClearUploadedFiles;

  /**
   * @param payload instance id
   */
  constructor(public payload: string) { }
}

export type Actions
  = AddToQueue
  | RefusedToQueue
  | RemoveFromQueue
  | MarkQueueItemAsDone
  | ClearInstanceQueue
  | EmptyQueue
  | UploadRequest
  | UploadSingleFail
  | UploadProgress
  | UploadEmpty
  | UploadSingleLocalSuccess
  | UploadAllLocalSuccess
  | ClearUploadedFiles;
