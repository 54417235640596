import { Component, ChangeDetectorRef } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { Subject, of, interval } from 'rxjs';
import {
  map,
  takeUntil,
  delayWhen,
  distinctUntilChanged
} from 'rxjs/operators';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { State } from '@app/models';
import { environment } from 'environments/environment';
import { state } from './recaptcha.selector';
import { SetInactive } from './recaptcha.action';

@Component({
  selector: 'vshcz-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: [ './recaptcha.component.scss' ]
})
export class RecaptchaComponent extends BaseClass {
  // # Event Streams
  onResolve$ = new Subject<string>();

  // # Data
  // -- sync
  isLoading = true;

  // -- async
  isActive$ = this._store.pipe(select(state));
  siteKey = environment.googleSiteKey;
  isLoading$ = this._recaptcha.ready.pipe(
    map((v) => !v),
    delayWhen((v) => !v ? interval(600) : of(undefined)),
    distinctUntilChanged()
  );

  // # Action Streams
  private _resolveAction$ = this.onResolve$.pipe(
    map((d) => new SetInactive(d))
  );

  constructor(
    private _store: Store<State>,
    private _recaptcha: RecaptchaLoaderService,
    private _cdRef: ChangeDetectorRef
  ) {
    super();

    this.isLoading$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((s) => {
        setTimeout(() => {
          this.isLoading = s;
          this._cdRef.markForCheck();
          this._cdRef.detectChanges();
        });
      });

    this._resolveAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }
}
