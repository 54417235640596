import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/services';
import { KvmConsoleData, KvmConsoleTypes, KvmConsoleDataExtended } from '../kvm-settings/kvm-settings.model';

export const STORAGE_NAMESPACE = 'kvm';
export const STORAGE_KEY = 'consoles';

@Injectable()
export class KvmConsoleConnectionService {

  constructor(private _localStorage: LocalStorageService) {}

  start(consoleType: KvmConsoleTypes, serverId: string, serverName: string, data: KvmConsoleData) {
    if (!!consoleType && !!serverId && !!data) {
      if (!this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY)) {
        // set new localStorage space and console
        this._setConsole(consoleType, serverId, serverName, data);
      } else {
        const consoleData = this._getConsoleData(`${consoleType}:${serverId}`);
        // console exists
        if (!!consoleData) {
          this._useConsole({
            ...consoleData,
            kvmAccessLink: data.kvmAccessLink,
            kvmFileLink: data.kvmFileLink
          });
        } else {
          // console does not exist, create new
          this._setConsole(consoleType, serverId, serverName, data);
        }
      }
    }
  }

  removeConsole(consoleType: KvmConsoleTypes, serverId: string) {
    const consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY) as any;
    if (!!consolesMap && consolesMap[`${consoleType}:${serverId}`]) {
      delete consolesMap[`${consoleType}:${serverId}`];
      this._localStorage.set(
        STORAGE_NAMESPACE,
        STORAGE_KEY,
        { ...consolesMap }
      );
    }
  }

  private _setConsole(consoleType: KvmConsoleTypes, serverId: string, serverName: string, data: KvmConsoleData) {
    const consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY) as any;
    this._localStorage.set(
      STORAGE_NAMESPACE,
      STORAGE_KEY,
      {
        ...consolesMap,
        [`${consoleType}:${serverId}`]: {
          ...data,
          consoleType,
          created: new Date(),
          serverId,
          serverName
        }
      }
    );
    const consoleData = this._getConsoleData(`${consoleType}:${serverId}`);
    this._useConsole(consoleData);
  }

  private _getConsoleData(key: string): KvmConsoleDataExtended {
    const consolesMap = this._localStorage.get(STORAGE_NAMESPACE, STORAGE_KEY);
    if (!!consolesMap && consolesMap[key]) {
      return consolesMap[key];
    } else {
      return undefined;
    }
  }

  private _useConsole(consoleData: KvmConsoleDataExtended) {
    if (!!consoleData) {
      if (!!consoleData.kvmFileLink) {
        location.replace(consoleData.kvmFileLink);
      } else {
        if (!!consoleData.kvmAccessLink) {
          window.open(consoleData.kvmAccessLink);
        }
      }
    }
  }
}
