import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ticketAddFormSelector,
  ticketAddFormValueSelector
} from '@app/common/ticket-add-form';
import { ModuleTokens } from './ticket-add.constant';
import { TicketAddState } from './ticket-add.model';

export const slice = createFeatureSelector<TicketAddState>(ModuleTokens.Name);

export const ticketAddFormState = createSelector(
  slice,
  ticketAddFormSelector(ModuleTokens.Name)
);

export const ticketAddFormValue = createSelector(
  slice,
  ticketAddFormValueSelector(ModuleTokens.Name)
);
