import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import {
  ServerParkAccessEntityNormalizedData,
  ServerParkAccessUserEntity
} from './server-park-access-base.model';
import { normalizeList } from './server-park-access-base.utils';

export enum ActionTypes {
  ListRequest = '[Server Park Access Base] List Request',
  ListFail = '[Server Park Access Base] List Fail',
  ListLocalSuccess = '[Server Park Access Base] List Local Success',

  DeleteRequest = '[Server Park Access Base] Delete Request',
  DeleteFail = '[Server Park Access Base] Delete Fail',
  DeleteLocalSuccess = '[Server Park Access Base] Delete Local Success'
}

export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }

}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: ServerParkAccessEntityNormalizedData;

  constructor(data: ServerParkAccessUserEntity[]) {
    this.payload = normalizeList(data);
  }
}

export class DeleteRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DeleteRequest;
  progress: ProgressActionMeta;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DeleteFail
  };

  /**
    @param payload Persons ID
    @param meta Should redirect to list
  */
  constructor(public payload: string, public meta = false) {
    this.progress = {
      add: {
        key: `${ActionTypes.DeleteRequest}:${payload}`,
        type: 'local',
        group: ActionTypes.DeleteRequest
      }
    };
  }
}

export class DeleteFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.DeleteFail;
  progress: ProgressActionMeta;
  errors: ErrorsActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.DeleteFail,
        type: 'local',
        data
      }
    };
    this.meta = data;
    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${id}`
    };
  }
}

export class DeleteLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.DeleteLocalSuccess;
  progress: ProgressActionMeta;

  /**
    @param payload Persons ID
    @param meta Should redirect to list
  */
  constructor(public payload: string, public meta = false) {
    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${payload}`
    };
  }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess
  | DeleteRequest
  | DeleteFail
  | DeleteLocalSuccess;
