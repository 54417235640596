import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ticketAddFormState } from '@app/common/ticket-add-form';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { ModuleTokens } from './ticket-add.constant';

export class TicketAddState implements HasNgrxForm {
  forms = {
    ...ticketAddFormState(ModuleTokens.Name)(
      {
        title: null,
        ticketMessage: null,
        ticketTopicId: null
      },
      {
        title: validate(required),
        ticketMessage: validate(required)
      }
    )
  };
}
