import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateCompiler } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { FormsModule } from '@app/common/forms';
import { AutosizeModule } from '@app/common/autosize';
import { TicketTextFormComponent } from './ticket-text-form.component';

@NgModule({
  declarations: [ TicketTextFormComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    NgrxFormsModule,
    FormsModule,
    NgrxFormsExtensionsModule,
    AutosizeModule
  ],
  exports: [ TicketTextFormComponent ]
})
export class TicketTextFormModule {

}
