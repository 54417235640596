import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TicketStateModule } from '@app/common/ticket-state';
import { FilterPopModule } from '@app/common/filter-pop';
import { TicketsFilterContainer } from './tickets-filter.container';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ TicketsFilterContainer ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    FilterPopModule,
    TicketStateModule
  ],
  exports: [ TicketsFilterContainer ]
})
export class TicketsFilterModule {

}
