import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from '@app/common/popover';
import { UserModule } from '@app/common/user';
import { AvatarModule } from '@app/common/avatar';
import { ConfirmModule } from '@app/common/confirm';
import { CompanySelectModule } from '../company-select';
import { LoggedUserContainer } from './logged-user.container';

@NgModule({
  declarations: [ LoggedUserContainer ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    TruncateModule,
    TranslateModule.forChild(),
    PopoverModule,
    UserModule,
    AvatarModule,
    CompanySelectModule,
    ConfirmModule
  ],
  exports: [ LoggedUserContainer ]
})
export class LoggedUserModule { }
