/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../avatar/avatar.component.ngfactory";
import * as i3 from "../avatar/avatar.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/material/tooltip";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/scrolling";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/common";
import * as i12 from "./avatar-group.component";
var styles_AvatarGroupComponent = [i0.styles];
var RenderType_AvatarGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarGroupComponent, data: {} });
export { RenderType_AvatarGroupComponent as RenderType_AvatarGroupComponent };
function View_AvatarGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "vshcz-avatar", [["class", "__avatar"]], [[2, "is-first", null], [4, "height", "px"], [4, "width", "px"]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.itemClicked.emit(_v.context.$implicit.id) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(1, 114688, null, 0, i3.AvatarComponent, [i4.DomSanitizer, i1.ChangeDetectorRef], { mail: [0, "mail"], source: [1, "source"], size: [2, "size"] }, null), i1.ɵdid(2, 147456, null, 0, i5.MatTooltip, [i6.Overlay, i1.ElementRef, i7.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i8.Platform, i9.AriaDescriber, i9.FocusMonitor, i5.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i5.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i4.HAMMER_LOADER]], { disabled: [0, "disabled"], message: [1, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.mail; var currVal_4 = "gravatar"; var currVal_5 = 40; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = !_v.context.$implicit.title; var currVal_7 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.first; var currVal_1 = i1.ɵnov(_v, 1).styleHeight; var currVal_2 = i1.ɵnov(_v, 1).styleWidth; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AvatarGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "__more"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.overflowClicked.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" +", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overflow; _ck(_v, 1, 0, currVal_0); }); }
export function View_AvatarGroupComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarGroupComponent_1)), i1.ɵdid(1, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarGroupComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co._trackBy; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.overflow; _ck(_v, 3, 0, currVal_2); }, null); }
export function View_AvatarGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-avatar-group", [], null, null, null, View_AvatarGroupComponent_0, RenderType_AvatarGroupComponent)), i1.ɵdid(1, 573440, null, 0, i12.AvatarGroupComponent, [], null, null)], null, null); }
var AvatarGroupComponentNgFactory = i1.ɵccf("vshcz-avatar-group", i12.AvatarGroupComponent, View_AvatarGroupComponent_Host_0, { data: "data", max: "max" }, { overflowClicked: "overflowClicked", itemClicked: "itemClicked" }, []);
export { AvatarGroupComponentNgFactory as AvatarGroupComponentNgFactory };
