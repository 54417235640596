import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { SearchEntityWithLink } from '../../search.model';

@Component({
  selector: 'vshcz-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: [ './search-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTableComponent {
  @Input()
  data: SearchEntityWithLink[];

  @Input()
  displayedColumns: string[];

  @Input()
  entityTranslations: { [key: string]: string; };

  @Output()
  clicked = new EventEmitter<void>();

  @Output()
  sorted = new EventEmitter<Sort>();
}
