import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import { DialogClose, DialogActionTypes } from '@zerops/fe/dialog';
import { switchMap, withLatestFrom, map, catchError, filter } from 'rxjs/operators';
import { TicketsBaseActionTypes, TicketsBaseRateFail, TicketsBaseRateLocalSuccess, TicketsBaseApi } from '@app/base/tickets-base';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { Reset } from './ticket-rating.action';
import { formValue } from './ticket-rating.selector';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../../base/tickets-base/tickets-base.api";
import * as i3 from "@ngrx/store";
import * as i4 from "../../../services/error-translation.service";
import * as i5 from "../snack/snack.service";
var TicketRatingEffect = /** @class */ (function () {
    function TicketRatingEffect(_actions$, _api, _store, _errorTranslation, _snack) {
        var _this = this;
        this._actions$ = _actions$;
        this._api = _api;
        this._store = _store;
        this._errorTranslation = _errorTranslation;
        this._snack = _snack;
        this._onLocalSuccess$ = this._actions$.pipe(ofType(TicketsBaseActionTypes.RateLocalSuccess));
        this._onRateRequest$ = this._actions$.pipe(ofType(TicketsBaseActionTypes.RateRequest), withLatestFrom(this._store.pipe(select(authActiveUserClientId)), this._store.pipe(select(formValue))), switchMap(function (_a) {
            var payload = _a[0].payload, clientId = _a[1], ratingData = _a[2];
            return _this._api
                .rate$(payload.id, payload.clientId ? payload.clientId : clientId, payload.rating === 4
                ? {
                    speed: 5,
                    expertise: 5,
                    communication: 5
                }
                : ratingData)
                .pipe(map(function () { return new TicketsBaseRateLocalSuccess(payload.id); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new TicketsBaseRateFail(data, payload.id, {
                errorMessage: data.message,
                errorCode: data.code
            }); })); }));
        }));
        this._onRateGoodFailSnack$ = this._actions$.pipe(ofType(TicketsBaseActionTypes.RateFail), switchMap(function (_a) {
            var meta = _a.meta;
            return _this._snack.translatedFail$(meta.errorMessage, 'common.close');
        }), map(function () { return new RemoveError(TicketsBaseActionTypes.RateFail); }));
        // close dialog on success
        this._onRateSuccessCloseDialog$ = this._onLocalSuccess$.pipe(map(function () { return new DialogClose(TicketsBaseActionTypes.RateRequest); }));
        // reset ticket rating state on success / dialog close
        this._onRateSuccessReset$ = this._onLocalSuccess$.pipe(map(function () { return new Reset(); }));
        this._onDialogCloseReset$ = this._actions$.pipe(ofType(DialogActionTypes.Close), filter(function (_a) {
            var payload = _a.payload;
            return payload.key === TicketsBaseActionTypes.RateRequest;
        }), map(function () { return new Reset(); }));
    }
    TicketRatingEffect.ngInjectableDef = i0.defineInjectable({ factory: function TicketRatingEffect_Factory() { return new TicketRatingEffect(i0.inject(i1.Actions), i0.inject(i2.TicketsBaseApi), i0.inject(i3.Store), i0.inject(i4.ErrorTranslationService), i0.inject(i5.SnackService)); }, token: TicketRatingEffect, providedIn: "root" });
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketRatingEffect.prototype, "_onRateRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketRatingEffect.prototype, "_onRateGoodFailSnack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketRatingEffect.prototype, "_onRateSuccessCloseDialog$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketRatingEffect.prototype, "_onRateSuccessReset$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], TicketRatingEffect.prototype, "_onDialogCloseReset$", void 0);
    return TicketRatingEffect;
}());
export { TicketRatingEffect };
