/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./displacer.component";
import * as i2 from "@angular/cdk/overlay";
var styles_DisplacerComponent = [];
var RenderType_DisplacerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DisplacerComponent, data: {} });
export { RenderType_DisplacerComponent as RenderType_DisplacerComponent };
function View_DisplacerComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_DisplacerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { _portal: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisplacerComponent_1)), i0.ɵdid(2, 16384, [[1, 4]], 0, i1.DisplacerPortalDirective, [i0.TemplateRef, i0.ViewContainerRef], null, null)], null, null); }
export function View_DisplacerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vshcz-displacer", [], null, null, null, View_DisplacerComponent_0, RenderType_DisplacerComponent)), i0.ɵdid(1, 4374528, null, 0, i1.DisplacerComponent, [i2.Overlay], null, null)], null, null); }
var DisplacerComponentNgFactory = i0.ɵccf("vshcz-displacer", i1.DisplacerComponent, View_DisplacerComponent_Host_0, {}, { initialized: "initialized" }, ["*"]);
export { DisplacerComponentNgFactory as DisplacerComponentNgFactory };
