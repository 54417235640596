import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { KvmConsoleConnectionContainer } from './kvm-console-connection.container';
import { KvmConsoleConnectionEffect } from './kvm-console-connection.effect';
import { KvmConsoleConnectionService } from './kvm-console-connection.service';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    EffectsModule.forFeature([ KvmConsoleConnectionEffect ])
  ],
  declarations: [ KvmConsoleConnectionContainer ],
  exports: [ KvmConsoleConnectionContainer ],
  providers: [ KvmConsoleConnectionService ]
})
export class KvmConsoleConnectionModule {
}
