export enum ModuleTokens {
  Name = 'search'
}

export enum SearchModes {
  Search,
  Suggest
}

export enum SearchEntities {
  User = 'clientUser',
  ServerParkAccess = 'clientPersonServerParkAccess',
  Ticket = 'ticket',
  Invoice = 'invoice',
  CloudManagedServer = 'cloudManagedServer',
  ManagedCluster = 'managedCluster',
  ManagedServer = 'managedServer',
  M1ManagedServer = 'm1ManagedServer',
  M1ManagedCluster = 'm1ManagedCluster',
  M2ManagedServer = 'm2ManagedServer',
  M2ManagedCluster = 'm2ManagedCluster',
  windowsManagedServer = 'windowsManagedServer',
  windowsManagedCluster = 'windowsManagedCluster',
  windowsManagedBasic = 'windowsManagedBasic',
  windowsManagedCloud = 'windowsManagedCloud',
  ServerHosting = 'serverHosting',
  RackHosting = 'rackHosting',
  DedicatedServer = 'dedicatedServer',
  GpuDedicatedServer = 'gpuDedicatedServer',
  Vds = 'vds',
  Domain = 'domain'
}

export const formKey = '[Search] Form';
