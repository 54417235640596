import * as tslib_1 from "tslib";
import { ModuleTokens } from './invoices-base.constant';
// invoice
var InvoicesBaseState = /** @class */ (function () {
    function InvoicesBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a);
        this.list = undefined;
        this.invoiceLiabilities = undefined;
        this.feeLiabilities = undefined;
        this.activePayment = undefined;
    }
    return InvoicesBaseState;
}());
export { InvoicesBaseState };
var InvoiceEntityEntities = /** @class */ (function () {
    function InvoiceEntityEntities() {
    }
    return InvoiceEntityEntities;
}());
export { InvoiceEntityEntities };
ModuleTokens.Name;
var InvoiceEntityNormalizedData = /** @class */ (function () {
    function InvoiceEntityNormalizedData() {
    }
    return InvoiceEntityNormalizedData;
}());
export { InvoiceEntityNormalizedData };
var InvoiceEntityLight = /** @class */ (function () {
    function InvoiceEntityLight() {
    }
    return InvoiceEntityLight;
}());
export { InvoiceEntityLight };
var InvoiceEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(InvoiceEntityBase, _super);
    function InvoiceEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InvoiceEntityBase;
}(InvoiceEntityLight));
export { InvoiceEntityBase };
var InvoiceEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(InvoiceEntityNormalized, _super);
    function InvoiceEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InvoiceEntityNormalized;
}(InvoiceEntityBase));
export { InvoiceEntityNormalized };
var InvoiceEntity = /** @class */ (function (_super) {
    tslib_1.__extends(InvoiceEntity, _super);
    function InvoiceEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InvoiceEntity;
}(InvoiceEntityBase));
export { InvoiceEntity };
// invoice item
var InvoiceItemEntityLight = /** @class */ (function () {
    function InvoiceItemEntityLight() {
    }
    return InvoiceItemEntityLight;
}());
export { InvoiceItemEntityLight };
var InvoiceItemEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(InvoiceItemEntityBase, _super);
    function InvoiceItemEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InvoiceItemEntityBase;
}(InvoiceItemEntityLight));
export { InvoiceItemEntityBase };
// contractor
var ContractorEntityLight = /** @class */ (function () {
    function ContractorEntityLight() {
    }
    return ContractorEntityLight;
}());
export { ContractorEntityLight };
var ContractorEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(ContractorEntityBase, _super);
    function ContractorEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ContractorEntityBase;
}(ContractorEntityLight));
export { ContractorEntityBase };
