import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import { SearchEntity } from './search.model';

export enum ActionTypes {
  Open = '[Search] Open',
  Close = '[Search] Close',

  SetMode = '[Search] Set Mode',

  SuggestRequest = '[Search] Suggest Request',
  SuggestFail = '[Search] Suggest Fail',
  SuggestLocalSuccess = '[Search] Suggest Local Success',
  ResetSuggest = '[Search] Reset Suggest',

  SearchRequest = '[Search] Search Request',
  SearchFail = '[Search] Search Fail',
  SearchLocalSuccess = '[Search] Search Local Success'
}

export class Open implements Action {
  readonly type = ActionTypes.Open;
}

export class Close implements Action {
  readonly type = ActionTypes.Close;
}

export class SuggestRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SuggestRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SuggestFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.SuggestRequest,
      type: 'local'
    }
  };

  /**
   * @param payload Keyword
   */
  constructor(public payload: string) { }
}

export class SuggestFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SuggestFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SuggestRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.SuggestFail,
        type: 'local',
        data
      }
    };
  }
}

export class SuggestLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.SuggestLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SuggestRequest
  };

  constructor(public payload: SearchEntity[]) { }
}

export class ResetSuggest implements Action, ProgressAction {
  readonly type = ActionTypes.ResetSuggest;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SuggestRequest
  };
}

export class SearchRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SearchRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SearchFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.SearchRequest,
      type: 'local'
    }
  };

  /**
   * @param payload Keyword
   */
  constructor(public payload: string) { }
}

export class SearchFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SearchFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SearchRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.SearchFail,
        type: 'local',
        data
      }
    };
  }
}

export class SearchLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.SearchLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SearchRequest
  };

  constructor(public payload: SearchEntity[]) { }
}

export type Actions
  = Open
  | Close

  | SuggestRequest
  | SuggestFail
  | SuggestLocalSuccess
  | ResetSuggest

  | SearchRequest
  | SearchFail
  | SearchLocalSuccess;
