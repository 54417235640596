import { FormGroupState, validate } from 'ngrx-forms';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { formState } from './ticket-authorization.forms';
import { ModuleTokens } from './ticket-authorization.constant';
import { required } from 'ngrx-forms/validation';

export class TicketAuthorizationState implements HasNgrxForm {
  forms = {
    ...formState(ModuleTokens.Name)(
      undefined,
      { clientId: validate(required) }
    )
  };

  constructor() { }
}

export class TicketAuthorizationFormValue {
  clientId: string;
}

export type TicketAuthorizationFormState = FormGroupState<TicketAuthorizationFormValue>;
