import { PasswordFormTranslations } from './password-form.model';

const _prefix = 'password-form_';

export const DEFAULT_TRANSLATION_KEYS: PasswordFormTranslations = {
  labels: {
    password: _prefix + 'labels_password'
  },
  errors: {
    password: {
      required: _prefix + 'errors_password_required'
    }
  }
};
