import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'vshcz-watch-ticket',
  templateUrl: './watch-ticket.component.html',
  styleUrls: ['./watch-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatchTicketComponent {

  copyListContainsUserEmail = false;

  @Input()
  loading = false;

  @Input()
  authUserEmail: string;

  @Input()
  ticketAssignedUserEmail: string;

  @Input()
  set ticketCopyList(v: string[]) {
    if (v && v.length) {
      this.copyListContainsUserEmail = v.includes(this.authUserEmail);
    } else {
      this.copyListContainsUserEmail = false;
    }
  }

  @Output()
  clicked = new EventEmitter<void>();

}
