import * as tslib_1 from "tslib";
import { ModuleTokens } from './domains-base.constant';
var DomainsBaseState = /** @class */ (function () {
    function DomainsBaseState() {
        var _a;
        this.entities = (_a = {},
            _a[ModuleTokens.Name] = {},
            _a[ModuleTokens.ActiveRecords] = {},
            _a[ModuleTokens.LastRecords] = {},
            _a);
        this.list = [];
        this.soaMap = {};
        this.dnssecKeys = {};
    }
    return DomainsBaseState;
}());
export { DomainsBaseState };
var DomainsBaseEntityEntities = /** @class */ (function () {
    function DomainsBaseEntityEntities() {
    }
    return DomainsBaseEntityEntities;
}());
export { DomainsBaseEntityEntities };
ModuleTokens.Name, ModuleTokens.ActiveRecords, ModuleTokens.LastRecords;
var DomainEntityNormalizedData = /** @class */ (function () {
    function DomainEntityNormalizedData() {
    }
    return DomainEntityNormalizedData;
}());
export { DomainEntityNormalizedData };
var DomainEntityLight = /** @class */ (function () {
    function DomainEntityLight() {
    }
    return DomainEntityLight;
}());
export { DomainEntityLight };
var DomainEntityBase = /** @class */ (function (_super) {
    tslib_1.__extends(DomainEntityBase, _super);
    function DomainEntityBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DomainEntityBase;
}(DomainEntityLight));
export { DomainEntityBase };
var DomainEntityNormalized = /** @class */ (function (_super) {
    tslib_1.__extends(DomainEntityNormalized, _super);
    function DomainEntityNormalized() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DomainEntityNormalized;
}(DomainEntityBase));
export { DomainEntityNormalized };
var DomainEntity = /** @class */ (function (_super) {
    tslib_1.__extends(DomainEntity, _super);
    function DomainEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DomainEntity;
}(DomainEntityBase));
export { DomainEntity };
var DomainRecordSimpleBase = /** @class */ (function () {
    function DomainRecordSimpleBase() {
    }
    return DomainRecordSimpleBase;
}());
export { DomainRecordSimpleBase };
var DnsClientAppEntityBase = /** @class */ (function () {
    function DnsClientAppEntityBase() {
    }
    return DnsClientAppEntityBase;
}());
export { DnsClientAppEntityBase };
var DnsSecEntityBase = /** @class */ (function () {
    function DnsSecEntityBase() {
    }
    return DnsSecEntityBase;
}());
export { DnsSecEntityBase };
var DomainRecordSoa = /** @class */ (function () {
    function DomainRecordSoa() {
    }
    return DomainRecordSoa;
}());
export { DomainRecordSoa };
var DnsSecKeyEntityBase = /** @class */ (function () {
    function DnsSecKeyEntityBase() {
    }
    return DnsSecKeyEntityBase;
}());
export { DnsSecKeyEntityBase };
var DomainVersionEntityBase = /** @class */ (function () {
    function DomainVersionEntityBase() {
    }
    return DomainVersionEntityBase;
}());
export { DomainVersionEntityBase };
