import { HttpClient } from '@angular/common/http';
import { QueueingSubject } from 'queueing-subject';
import { environment } from 'environments/environment';
import { connect } from './websockets.utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WebsocketsApi = /** @class */ (function () {
    function WebsocketsApi(_http) {
        this._http = _http;
        this._input = new QueueingSubject();
    }
    WebsocketsApi.prototype.connect = function (token) {
        var endpoint = environment.production
            ? window.location.hostname
            : environment.wsUrl;
        var wsProtocol = !environment.production && environment.forceDevWsProtocol ?
            environment.forceDevWsProtocol
            : window.location.protocol === 'https:'
                ? 'wss'
                : 'ws';
        var _a = connect(wsProtocol + "://" + endpoint + "/api/public/web-socket/" + token, this._input), messages = _a.messages, connectionStatus = _a.connectionStatus;
        this.messages$ = messages;
        this.status$ = connectionStatus;
    };
    WebsocketsApi.prototype.send = function (value) {
        this._input.next(value);
    };
    WebsocketsApi.prototype.auth$ = function (accessToken) {
        return this._http.post('/api/public/web-socket/login', { accessToken: accessToken });
    };
    WebsocketsApi.ngInjectableDef = i0.defineInjectable({ factory: function WebsocketsApi_Factory() { return new WebsocketsApi(i0.inject(i1.HttpClient)); }, token: WebsocketsApi, providedIn: "root" });
    return WebsocketsApi;
}());
export { WebsocketsApi };
