import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { InvoiceStatuses } from '@app/base/invoices-base';

@Component({
  selector: 'vshcz-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: [ './invoice-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceStatusComponent {

  invoiceStatuses = InvoiceStatuses;

  @Input()
  tooltip: string;

  @Input()
  status: InvoiceStatuses;
}
