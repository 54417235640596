import { OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import * as md5 from 'blueimp-md5';
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(_sanitizer, _cdRef) {
        this._sanitizer = _sanitizer;
        this._cdRef = _cdRef;
        this.isLoading = true;
        this.extension = 'jpg';
        this.source = 'image';
        this._size = 200;
    }
    Object.defineProperty(AvatarComponent.prototype, "mail", {
        get: function () {
            return this._mail;
        },
        set: function (v) {
            this._mail = v;
            this._hash = md5(v).trim().toLocaleLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        set: function (v) {
            this._size = coerceNumberProperty(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "styleHeight", {
        get: function () {
            return this.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "styleWidth", {
        get: function () {
            return this.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "imageUrl", {
        get: function () {
            switch (this.source) {
                case 'image':
                    return this.image;
                case 'gravatar':
                    return "https://www.gravatar.com/avatar/" + this._hash + "." + this.extension + "?s=" + this.size + "&d=identicon";
                case 'clearbit':
                    var web = this.web;
                    if (this.web && this.web[this.web.length - 1] === '/') {
                        web = this.web.slice(0, -1);
                    }
                    var webWithoutHttp = web;
                    if (this.web) {
                        webWithoutHttp = web.replace(/(^\w+:|^)\/\//, '');
                    }
                    return this.web
                        ? "//logo.clearbit.com/" + webWithoutHttp
                        : undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.ngOnInit = function () {
        var self = this;
        function load() {
            if (self.imageUrl) {
                var img = new Image();
                img.src = self.imageUrl;
                img.onload = function () {
                    self.isLoading = false;
                    self._cdRef.markForCheck();
                };
                img.onerror = function () {
                    self.isLoading = false;
                    self._cdRef.markForCheck();
                };
            }
            else {
                self.isLoading = false;
                self._cdRef.markForCheck();
            }
        }
        load();
    };
    return AvatarComponent;
}());
export { AvatarComponent };
