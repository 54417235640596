import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersBaseState } from './users-base.model';
import { ModuleTokens } from './users-base.constant';

export const slice = createFeatureSelector<UsersBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);
