<div
  class="__main-button"
  [class.is-hidden]="progressState === true"
  [class.is-dense]="denseButton"
  [class.has-errors]="errors$ | async">
  <ng-content></ng-content>
</div>

<div
  class="__errors"
  *ngIf="(errors$ | async) as error">

  <div
    class="__errors_content"
    [class.is-open]="error">

    <ng-container *ngIf="error">
      <p class="__errors_message">
        <span [innerHTML]="error.data.message"></span>
        <a
          href=""
          class="__errors_more"
          *ngIf="error.data?.meta?.length"
          (click)="!!toggleAdditionalErrorInfo()">

          <span *ngIf="!showAdditionalErrorInfo">
            {{ errorShowDetailsLabel }}?
          </span>

          <span *ngIf="showAdditionalErrorInfo">
            {{ errorHideDetailsLabel }}?
          </span>

        </a>
      </p>

      <div
        class="__errors_details-wrap"
        *ngIf="error.data?.meta?.length && showAdditionalErrorInfo">
        <ul class="__errors_details">
          <li *ngFor="let meta of error.data.meta">
            <strong *ngIf="meta.parameter">{{ meta.parameter }}: </strong>
            {{ meta.message }}
          </li>
        </ul>
      </div>
    </ng-container>

    <div class="__errors_buttons">

      <button
        *ngIf="errorCloseLabel"
        class="__errors_button  __errors_button--close"
        tabindex="-1"
        mat-stroked-button
        type="button"
        (click)="!!onResetError$.next()">
        {{ errorCloseLabel }}
      </button>

      <button
        *ngIf="errorRetryLabel"
        class="__errors_button  __errors_button--retry"
        tabindex="-2"
        mat-button
        type="button"
        (click)="!!retry.emit()">
        <mat-icon>loop</mat-icon> {{ errorRetryLabel }}
      </button>

    </div>

  </div>
</div>

<div class="__progress" *ngIf="progressState && !hideProgressSpinner">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
