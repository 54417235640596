import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KvmConsoleConnectionApi = /** @class */ (function () {
    function KvmConsoleConnectionApi(_http) {
        this._http = _http;
    }
    KvmConsoleConnectionApi.prototype.kvmKeepalive$ = function (key) {
        return this._http.put("/api/public/server/" + key + "/kvm-keepalive", {});
    };
    KvmConsoleConnectionApi.ngInjectableDef = i0.defineInjectable({ factory: function KvmConsoleConnectionApi_Factory() { return new KvmConsoleConnectionApi(i0.inject(i1.HttpClient)); }, token: KvmConsoleConnectionApi, providedIn: "root" });
    return KvmConsoleConnectionApi;
}());
export { KvmConsoleConnectionApi };
