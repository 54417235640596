import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormFieldComponent,
  FormLabelDirective,
  FormModule,
  FormHintsDirective
} from './components';

@NgModule({
  declarations: [
    FormFieldComponent,
    FormLabelDirective,
    FormHintsDirective
  ],
  imports: [
    CommonModule,
    FormModule
  ],
  exports: [
    FormFieldComponent,
    FormLabelDirective,
    FormHintsDirective,
    FormModule
  ]
})
export class FormsModule { }
