import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { ProgressItemModule } from '@app/common/progress-item';
import { ContentTitleModule } from '@app/common/content-title';
import { ProgressErrorModule } from '@app/common/progress-error';
import { FormsModule } from '@app/common/forms';
import { TicketRatingContainer } from './ticket-rating.container';
import { ModuleTokens } from './ticket-rating.constant';
import { reducer } from './ticket-rating.reducer';
import { TicketRatingEffect } from './ticket-rating.effect';
import { StarFieldModule } from './modules';

@NgModule({
  declarations: [ TicketRatingContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    TranslateModule.forChild(),
    EffectsModule.forFeature([ TicketRatingEffect ]),
    NgrxFormsModule,
    DialogModule,
    ProgressItemModule,
    ContentTitleModule,
    FormsModule,
    ProgressErrorModule,
    StarFieldModule
  ],
  exports: [ TicketRatingContainer ]
})
export class TicketRatingModule { }
