import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeeTypeComponent } from './fee-type.component';

@NgModule({
  declarations: [ FeeTypeComponent ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [ FeeTypeComponent ]
})
export class FeeTypeModule {
}
