import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material';
import { PopoverModule } from '@app/common/popover';
import { ConfirmComponent } from './confirm.component';

@NgModule({
  declarations: [ ConfirmComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    PopoverModule
  ],
  exports: [ ConfirmComponent ]
})
export class ConfirmModule { }
