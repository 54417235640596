import { NgrxFormExtensionModel } from '@app/common/ngrx-forms-extensions';
var formKey = '[Rating] Form';
var formModel = new NgrxFormExtensionModel(formKey);
export var getFormKey = function (id) { return formModel.getKey(id); };
export var formState = function (id) { return function (defaultValues, updateFns) {
    if (defaultValues === void 0) { defaultValues = {}; }
    if (updateFns === void 0) { updateFns = {}; }
    var _a;
    return (_a = {},
        _a[getFormKey(id)] = formModel.createFormState(id, {
            speed: undefined,
            expertise: undefined,
            communication: undefined,
            message: undefined
        }, defaultValues, updateFns),
        _a);
}; };
export var applyFormReducer = function (state, action, id, defaultSettings) { return formModel.reduceAction(state, action, id, defaultSettings); };
export var markAsSubmitted = function (id, state) { return formModel.markAsSubmitted(id, state); };
export var markAsUnsubmitted = function (id, state) { return formModel.markAsUnsubmitted(id, state); };
export var formSelector = function (id) { return formModel.selectState(id); };
export var formValueSelector = function (id) { return formModel.selectValue(id); };
export var updateGroupAction = function (id, value) { return formModel.updateGroupValue(id, value); };
