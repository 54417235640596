import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  realizationDateRejectFormStateSelector,
  realizationDateRejectFormValueSelector
} from './modules';
import { TicketRealizationDateState } from './ticket-realization-date.model';
import { ModuleTokens } from './ticket-realization-date.constant';

export const slice = createFeatureSelector<TicketRealizationDateState>(ModuleTokens.Name);

export const ticketRealizationDateFormState = createSelector(
  slice,
  realizationDateRejectFormStateSelector(ModuleTokens.Name)
);

export const ticketRealizationDateFormValue = createSelector(
  slice,
  realizationDateRejectFormValueSelector(ModuleTokens.Name)
);
