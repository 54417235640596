import * as tslib_1 from "tslib";
import { EntitiesMergeStrategy } from '@zerops/fe/core';
import * as uuidv4 from 'uuid/v4';
import { normalizeList, createTempTicketEntities } from './tickets-base.utils';
import { ModuleTokens } from './tickets-base.constant';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ListRequest"] = "[Tickets Base] List Request";
    ActionTypes["ListFail"] = "[Tickets Base] List Fail";
    ActionTypes["ListLocalSuccess"] = "[Tickets Base] List Local Success";
    ActionTypes["TicketTopicListRequest"] = "[Tickets Base] Ticket Topic List Request";
    ActionTypes["TicketTopicListFail"] = "[Tickets Base] Ticket Topic List Fail";
    ActionTypes["TicketTopicListLocalSuccess"] = "[Tickets Base] Ticket Topic List Local Success";
    ActionTypes["EntityRequest"] = "[Tickets Base] Entity Request";
    ActionTypes["EntityFail"] = "[Tickets Base] Entity Fail";
    ActionTypes["EntityLocalSuccess"] = "[Tickets Base] Entity Local Success";
    ActionTypes["MessageRequest"] = "[Tickets Base] Message Request";
    ActionTypes["MessageFail"] = "[Tickets Base] Message Fail";
    ActionTypes["MessageLocalSuccess"] = "[Tickets Base] Message Local Success";
    ActionTypes["MessageSuccess"] = "[Tickets Base] Message Success";
    ActionTypes["MessageAddTemp"] = "[Tickets Base] Message Add Temp";
    ActionTypes["UnlockRequest"] = "[Tickets Base] Unlock Request";
    ActionTypes["UnlockFail"] = "[Tickets Base] Unlock Fail";
    ActionTypes["UnlockLocalSuccess"] = "[Tickets Base] Unlock Local Success";
    ActionTypes["UpdateCopyListCheckRequest"] = "[Tickets Base] Update Copy List Check Request";
    ActionTypes["UpdateCopyListCheckLocalSuccess"] = "[Tickets Base] Update Copy List Check Local Success";
    ActionTypes["UpdateCopyListRequest"] = "[Tickets Base] Update Copy List Request";
    ActionTypes["UpdateCopyListDenied"] = "[Tickets Base] Update Copy List Denied";
    ActionTypes["UpdateCopyListFail"] = "[Tickets Base] Update Copy List Fail";
    ActionTypes["UpdateCopyListLocalSuccess"] = "[Tickets Base] Update Copy List Local Success";
    ActionTypes["RateRequest"] = "[Tickets Base] Rate Request";
    ActionTypes["RateFail"] = "[Tickets Base] Rate Fail";
    ActionTypes["RateLocalSuccess"] = "[Tickets Base] Rate Local Success";
    ActionTypes["ApprovePriceOfferRequest"] = "[Tickets Base] Approve Price Offer Request";
    ActionTypes["ApprovePriceOfferFail"] = "[Tickets Base] Approve Price Offer Fail";
    ActionTypes["ApprovePriceOfferLocalSuccess"] = "[Tickets Base] Approve Price Offer Local Success";
    ActionTypes["RejectPriceOfferRequest"] = "[Tickets Base] Reject Price Offer Request";
    ActionTypes["RejectPriceOfferFail"] = "[Tickets Base] Reject Price Offer Fail";
    ActionTypes["RejectPriceOfferLocalSuccess"] = "[Tickets Base] Reject Price Offer Local Success";
    ActionTypes["ConfirmRealizationDateRequest"] = "[Tickets Base] Confirm Realization Date Request";
    ActionTypes["ConfirmRealizationDateFail"] = "[Tickets Base] Confirm Realization Date Fail";
    ActionTypes["ConfirmRealizationDateLocalSuccess"] = "[Tickets Base] Confirm Realization Date Local Success";
    ActionTypes["RejectRealizationDateRequest"] = "[Tickets Base] Reject Realization Date Request";
    ActionTypes["RejectRealizationDateFail"] = "[Tickets Base] Reject Realization Date Fail";
    ActionTypes["RejectRealizationDateLocalSuccess"] = "[Tickets Base] Reject Realization Date Local Success";
})(ActionTypes || (ActionTypes = {}));
var ListRequest = /** @class */ (function () {
    function ListRequest() {
        this.type = ActionTypes.ListRequest;
        this.errors = {
            remove: ActionTypes.ListFail
        };
        this.progress = {
            add: {
                key: ActionTypes.ListRequest,
                type: 'local'
            }
        };
    }
    return ListRequest;
}());
export { ListRequest };
var ListFail = /** @class */ (function () {
    function ListFail(data) {
        this.type = ActionTypes.ListFail;
        this.progress = {
            remove: ActionTypes.ListRequest
        };
        this.errors = {
            add: {
                key: ActionTypes.ListFail,
                type: 'global',
                data: data
            }
        };
    }
    return ListFail;
}());
export { ListFail };
var ListLocalSuccess = /** @class */ (function () {
    function ListLocalSuccess(data) {
        this.type = ActionTypes.ListLocalSuccess;
        this.progress = {
            remove: ActionTypes.ListRequest
        };
        var dataManaged = data.map(function (ticket) {
            if (ticket.clientZoneStatus !== 'WAITING_AUTHORIZATION') {
                return ticket;
            }
            return tslib_1.__assign({}, ticket, { ticketMessageList: [
                    {
                        attachments: [],
                        display: 'USER',
                        id: ticket.lastTicketMessageId,
                        extId: ticket.lastTicketMessageId,
                        ticketId: ticket.id,
                        messageHiddenText: null,
                        signature: ticket.lastTicketMessageAuthor.fullName,
                        messageText: ticket.lastTicketMessageText,
                        author: ticket.lastTicketMessageAuthor,
                        created: ticket.created
                    }
                ] });
        });
        this.payload = normalizeList(dataManaged);
    }
    return ListLocalSuccess;
}());
export { ListLocalSuccess };
var TicketTopicListRequest = /** @class */ (function () {
    function TicketTopicListRequest() {
        this.type = ActionTypes.TicketTopicListRequest;
        this.errors = {
            remove: ActionTypes.TicketTopicListFail
        };
        this.progress = {
            add: {
                key: ActionTypes.TicketTopicListRequest,
                type: 'local'
            }
        };
    }
    return TicketTopicListRequest;
}());
export { TicketTopicListRequest };
var TicketTopicListFail = /** @class */ (function () {
    function TicketTopicListFail(data) {
        this.type = ActionTypes.TicketTopicListFail;
        this.progress = {
            remove: ActionTypes.TicketTopicListRequest
        };
        this.errors = {
            add: {
                key: ActionTypes.TicketTopicListFail,
                type: 'global',
                data: data
            }
        };
    }
    return TicketTopicListFail;
}());
export { TicketTopicListFail };
var TicketTopicListLocalSuccess = /** @class */ (function () {
    function TicketTopicListLocalSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.TicketTopicListLocalSuccess;
        this.progress = {
            remove: ActionTypes.TicketTopicListRequest
        };
    }
    return TicketTopicListLocalSuccess;
}());
export { TicketTopicListLocalSuccess };
var EntityRequest = /** @class */ (function () {
    function EntityRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.EntityRequest;
        this.errors = {
            remove: ActionTypes.EntityFail
        };
        this.progress = {
            add: {
                key: ActionTypes.EntityRequest + ":" + this.payload,
                type: 'local',
                group: ActionTypes.EntityRequest
            }
        };
    }
    return EntityRequest;
}());
export { EntityRequest };
var EntityFail = /** @class */ (function () {
    function EntityFail(data, id) {
        this.type = ActionTypes.EntityFail;
        this.errors = {
            add: {
                key: ActionTypes.EntityFail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.EntityRequest + ":" + id
        };
    }
    return EntityFail;
}());
export { EntityFail };
var EntityLocalSuccess = /** @class */ (function () {
    function EntityLocalSuccess(data, id) {
        this.type = ActionTypes.EntityLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.EntityRequest + ":" + id
        };
    }
    return EntityLocalSuccess;
}());
export { EntityLocalSuccess };
var MessageRequest = /** @class */ (function () {
    function MessageRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageRequest;
        this.meta = uuidv4();
    }
    return MessageRequest;
}());
export { MessageRequest };
var MessageFail = /** @class */ (function () {
    function MessageFail(tempId, ticketId, err) {
        var _a, _b;
        this.type = ActionTypes.MessageFail;
        this.meta = {
            _mergeStrategy: {
                ticketMessageList: EntitiesMergeStrategy.Remove
            }
        };
        if (err) {
            this.meta = tslib_1.__assign({}, this.meta, { err: err });
        }
        this.payload = {
            entities: (_a = {},
                _a[ModuleTokens.Name] = (_b = {},
                    _b[ticketId] = {
                        id: ticketId,
                        ticketMessageList: [tempId]
                    },
                    _b),
                _a)
        };
        this.progress = {
            remove: ActionTypes.MessageAddTemp + ":" + tempId
        };
    }
    return MessageFail;
}());
export { MessageFail };
var MessageLocalSuccess = /** @class */ (function () {
    function MessageLocalSuccess(data, tempId) {
        this.type = ActionTypes.MessageLocalSuccess;
        this.meta = {
            _mergeStrategy: {
                ticketMessageList: EntitiesMergeStrategy.KeepNew
            }
        };
        this.progress = {
            remove: ActionTypes.MessageAddTemp + ":" + tempId
        };
        var _a = normalizeList([data]), entities = _a.entities, result = _a.result;
        this.payload = { entities: entities, result: result };
    }
    return MessageLocalSuccess;
}());
export { MessageLocalSuccess };
var MessageSuccess = /** @class */ (function () {
    function MessageSuccess(data) {
        this.type = ActionTypes.MessageSuccess;
        this.meta = {
            _mergeStrategy: {
                ticketMessageList: EntitiesMergeStrategy.MergeUniq,
                attachments: EntitiesMergeStrategy.KeepNew,
                copyList: EntitiesMergeStrategy.KeepNew
            }
        };
        var _a = normalizeList([data]), entities = _a.entities, result = _a.result;
        this.payload = { entities: entities, result: result };
    }
    return MessageSuccess;
}());
export { MessageSuccess };
var MessageAddTemp = /** @class */ (function () {
    function MessageAddTemp(ticketId, message, user, tempId) {
        this.type = ActionTypes.MessageAddTemp;
        this.meta = {
            _mergeStrategy: EntitiesMergeStrategy.Append
        };
        this.progress = {
            add: {
                key: ActionTypes.MessageAddTemp + ":" + tempId,
                type: 'local',
                group: ActionTypes.MessageAddTemp
            }
        };
        this.payload = {
            entities: createTempTicketEntities(ticketId, tempId, message, user)
        };
    }
    return MessageAddTemp;
}());
export { MessageAddTemp };
var UnlockRequest = /** @class */ (function () {
    /**
     * @param payload Ticket ID
     */
    function UnlockRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.UnlockRequest;
        this.errors = {
            remove: ActionTypes.UnlockFail
        };
        this.progress = {
            add: {
                key: ActionTypes.UnlockRequest + ":" + this.payload,
                type: 'local',
                group: ActionTypes.UnlockRequest
            }
        };
    }
    return UnlockRequest;
}());
export { UnlockRequest };
var UnlockFail = /** @class */ (function () {
    function UnlockFail(data, id) {
        this.type = ActionTypes.UnlockFail;
        this.errors = {
            add: {
                key: ActionTypes.UnlockFail,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.UnlockRequest + ":" + id
        };
    }
    return UnlockFail;
}());
export { UnlockFail };
var UnlockLocalSuccess = /** @class */ (function () {
    function UnlockLocalSuccess(data, id) {
        this.type = ActionTypes.UnlockLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.UnlockRequest + ":" + id
        };
    }
    return UnlockLocalSuccess;
}());
export { UnlockLocalSuccess };
var UpdateCopyListRequest = /** @class */ (function () {
    function UpdateCopyListRequest(id, data, mail, meta, isWatch) {
        if (isWatch === void 0) { isWatch = false; }
        this.meta = meta;
        this.type = ActionTypes.UpdateCopyListRequest;
        this.errors = {
            remove: ActionTypes.UpdateCopyListFail
        };
        this.payload = { id: id, data: data, isWatch: isWatch, mail: mail };
        var watchingProgressKey = isWatch ? ":watch" : '';
        this.progress = {
            add: {
                key: ActionTypes.UpdateCopyListRequest + ":" + id + watchingProgressKey,
                type: 'local',
                group: ActionTypes.UpdateCopyListRequest
            },
            remove: ActionTypes.UpdateCopyListCheckRequest + ":" + id + watchingProgressKey
        };
    }
    return UpdateCopyListRequest;
}());
export { UpdateCopyListRequest };
var UpdateCopyListDenied = /** @class */ (function () {
    function UpdateCopyListDenied(id, payload) {
        this.payload = payload;
        this.type = ActionTypes.UpdateCopyListDenied;
        this.progress = {
            remove: [
                ActionTypes.UpdateCopyListCheckRequest + ":" + id,
                ActionTypes.UpdateCopyListCheckRequest + ":" + id + ":watch",
                ActionTypes.UpdateCopyListRequest + ":" + id,
                ActionTypes.UpdateCopyListRequest + ":" + id + ":watch"
            ]
        };
    }
    return UpdateCopyListDenied;
}());
export { UpdateCopyListDenied };
var UpdateCopyListFail = /** @class */ (function () {
    function UpdateCopyListFail(data, id, isWatch) {
        this.type = ActionTypes.UpdateCopyListFail;
        this.meta = data;
        this.errors = {
            add: {
                key: ActionTypes.UpdateCopyListFail,
                type: 'local',
                data: data
            }
        };
        var watchingProgressKey = isWatch ? ":watch" : '';
        this.progress = {
            remove: ActionTypes.UpdateCopyListRequest + ":" + id + watchingProgressKey
        };
    }
    return UpdateCopyListFail;
}());
export { UpdateCopyListFail };
var UpdateCopyListLocalSuccess = /** @class */ (function () {
    function UpdateCopyListLocalSuccess(data, id, isWatch) {
        this.type = ActionTypes.UpdateCopyListLocalSuccess;
        this.meta = {
            _mergeStrategy: {
                copyList: EntitiesMergeStrategy.KeepNew
            }
        };
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        var watchingProgressKey = isWatch ? ":watch" : '';
        this.progress = {
            remove: ActionTypes.UpdateCopyListRequest + ":" + id + watchingProgressKey
        };
    }
    return UpdateCopyListLocalSuccess;
}());
export { UpdateCopyListLocalSuccess };
var UpdateCopyListCheckRequest = /** @class */ (function () {
    function UpdateCopyListCheckRequest(id, data, mail, isWatch) {
        if (isWatch === void 0) { isWatch = false; }
        this.type = ActionTypes.UpdateCopyListCheckRequest;
        this.payload = { id: id, data: data, mail: mail, isWatch: isWatch };
        var watchingProgressKey = isWatch ? ":watch" : '';
        this.progress = {
            add: {
                key: ActionTypes.UpdateCopyListCheckRequest + ":" + id + watchingProgressKey,
                type: 'local',
                group: ActionTypes.UpdateCopyListCheckRequest
            }
        };
    }
    return UpdateCopyListCheckRequest;
}());
export { UpdateCopyListCheckRequest };
var UpdateCopyListCheckLocalSuccess = /** @class */ (function () {
    function UpdateCopyListCheckLocalSuccess(pass, payload, isWatch) {
        if (isWatch === void 0) { isWatch = false; }
        this.type = ActionTypes.UpdateCopyListCheckLocalSuccess;
        this.payload = { pass: pass, payload: payload };
        var watchingProgressKey = isWatch ? ":watch" : '';
        this.progress = {
            remove: ActionTypes.UpdateCopyListCheckRequest + ":" + payload.id + watchingProgressKey
        };
    }
    return UpdateCopyListCheckLocalSuccess;
}());
export { UpdateCopyListCheckLocalSuccess };
// rate
var RateRequest = /** @class */ (function () {
    function RateRequest(payload) {
        this.payload = payload;
        this.type = ActionTypes.RateRequest;
        this.progress = {
            add: {
                key: ActionTypes.RateRequest + ":" + payload.id,
                type: 'local',
                group: ActionTypes.RateRequest
            }
        };
        this.errors = {
            remove: ActionTypes.RateFail + ":" + payload.id
        };
    }
    return RateRequest;
}());
export { RateRequest };
var RateFail = /** @class */ (function () {
    function RateFail(data, id, meta) {
        this.meta = meta;
        this.type = ActionTypes.RateFail;
        this.errors = {
            add: {
                key: ActionTypes.RateFail + ":" + id,
                type: 'local',
                data: data
            }
        };
        this.progress = {
            remove: ActionTypes.RateRequest + ":" + id
        };
    }
    return RateFail;
}());
export { RateFail };
var RateLocalSuccess = /** @class */ (function () {
    function RateLocalSuccess(id) {
        this.type = ActionTypes.RateLocalSuccess;
        this.progress = {
            remove: ActionTypes.RateRequest + ":" + id
        };
    }
    return RateLocalSuccess;
}());
export { RateLocalSuccess };
// price offer
var ApprovePriceOfferRequest = /** @class */ (function () {
    function ApprovePriceOfferRequest(payload, meta) {
        this.payload = payload;
        this.meta = meta;
        this.type = ActionTypes.ApprovePriceOfferRequest;
        this.progress = {
            add: {
                key: ActionTypes.ApprovePriceOfferRequest + ":" + payload,
                type: 'local',
                group: ActionTypes.ApprovePriceOfferRequest
            }
        };
        this.errors = {
            remove: ActionTypes.RateFail + ":" + payload
        };
    }
    return ApprovePriceOfferRequest;
}());
export { ApprovePriceOfferRequest };
var ApprovePriceOfferFail = /** @class */ (function () {
    function ApprovePriceOfferFail(data, id) {
        this.type = ActionTypes.ApprovePriceOfferFail;
        this.errors = {
            add: {
                key: ActionTypes.ApprovePriceOfferFail,
                type: 'local',
                data: data
            }
        };
        this.meta = data;
        this.progress = {
            remove: ActionTypes.ApprovePriceOfferRequest + ":" + id
        };
    }
    return ApprovePriceOfferFail;
}());
export { ApprovePriceOfferFail };
var ApprovePriceOfferLocalSuccess = /** @class */ (function () {
    function ApprovePriceOfferLocalSuccess(data, id) {
        this.type = ActionTypes.ApprovePriceOfferLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.ApprovePriceOfferRequest + ":" + id
        };
    }
    return ApprovePriceOfferLocalSuccess;
}());
export { ApprovePriceOfferLocalSuccess };
var RejectPriceOfferRequest = /** @class */ (function () {
    function RejectPriceOfferRequest(payload, meta) {
        this.payload = payload;
        this.meta = meta;
        this.type = ActionTypes.RejectPriceOfferRequest;
        this.progress = {
            add: {
                key: ActionTypes.RejectPriceOfferRequest + ":" + payload,
                type: 'local',
                group: ActionTypes.RejectPriceOfferRequest
            }
        };
        this.errors = {
            remove: ActionTypes.RejectPriceOfferFail + ":" + payload
        };
    }
    return RejectPriceOfferRequest;
}());
export { RejectPriceOfferRequest };
var RejectPriceOfferFail = /** @class */ (function () {
    function RejectPriceOfferFail(data, id) {
        this.type = ActionTypes.RejectPriceOfferFail;
        this.errors = {
            add: {
                key: ActionTypes.RejectPriceOfferFail,
                type: 'local',
                data: data
            }
        };
        this.meta = data;
        this.progress = {
            remove: ActionTypes.RejectPriceOfferRequest + ":" + id
        };
    }
    return RejectPriceOfferFail;
}());
export { RejectPriceOfferFail };
var RejectPriceOfferLocalSuccess = /** @class */ (function () {
    function RejectPriceOfferLocalSuccess(data, id) {
        this.type = ActionTypes.RejectPriceOfferLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.RejectPriceOfferRequest + ":" + id
        };
    }
    return RejectPriceOfferLocalSuccess;
}());
export { RejectPriceOfferLocalSuccess };
// realization date
var ConfirmRealizationDateRequest = /** @class */ (function () {
    function ConfirmRealizationDateRequest(payload, meta) {
        this.payload = payload;
        this.meta = meta;
        this.type = ActionTypes.ConfirmRealizationDateRequest;
        this.progress = {
            add: {
                key: ActionTypes.ConfirmRealizationDateRequest + ":" + payload,
                type: 'local',
                group: ActionTypes.ConfirmRealizationDateRequest
            }
        };
        this.errors = {
            remove: ActionTypes.RateFail + ":" + payload
        };
    }
    return ConfirmRealizationDateRequest;
}());
export { ConfirmRealizationDateRequest };
var ConfirmRealizationDateFail = /** @class */ (function () {
    function ConfirmRealizationDateFail(data, id) {
        this.type = ActionTypes.ConfirmRealizationDateFail;
        this.errors = {
            add: {
                key: ActionTypes.ConfirmRealizationDateFail,
                type: 'local',
                data: data
            }
        };
        this.meta = data;
        this.progress = {
            remove: ActionTypes.ConfirmRealizationDateRequest + ":" + id
        };
    }
    return ConfirmRealizationDateFail;
}());
export { ConfirmRealizationDateFail };
var ConfirmRealizationDateLocalSuccess = /** @class */ (function () {
    function ConfirmRealizationDateLocalSuccess(data, id) {
        this.type = ActionTypes.ConfirmRealizationDateLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.ConfirmRealizationDateRequest + ":" + id
        };
    }
    return ConfirmRealizationDateLocalSuccess;
}());
export { ConfirmRealizationDateLocalSuccess };
var RejectRealizationDateRequest = /** @class */ (function () {
    function RejectRealizationDateRequest(payload, meta) {
        this.payload = payload;
        this.meta = meta;
        this.type = ActionTypes.RejectRealizationDateRequest;
        this.progress = {
            add: {
                key: ActionTypes.RejectRealizationDateRequest + ":" + payload,
                type: 'local',
                group: ActionTypes.RejectRealizationDateRequest
            }
        };
        this.errors = {
            remove: ActionTypes.RejectRealizationDateFail + ":" + payload
        };
    }
    return RejectRealizationDateRequest;
}());
export { RejectRealizationDateRequest };
var RejectRealizationDateFail = /** @class */ (function () {
    function RejectRealizationDateFail(data, id) {
        this.type = ActionTypes.RejectRealizationDateFail;
        this.errors = {
            add: {
                key: ActionTypes.RejectRealizationDateFail,
                type: 'local',
                data: data
            }
        };
        this.meta = data;
        this.progress = {
            remove: ActionTypes.RejectRealizationDateRequest + ":" + id
        };
    }
    return RejectRealizationDateFail;
}());
export { RejectRealizationDateFail };
var RejectRealizationDateLocalSuccess = /** @class */ (function () {
    function RejectRealizationDateLocalSuccess(data, id) {
        this.type = ActionTypes.RejectRealizationDateLocalSuccess;
        var entities = normalizeList([data]).entities;
        this.payload = { entities: entities };
        this.progress = {
            remove: ActionTypes.RejectRealizationDateRequest + ":" + id
        };
    }
    return RejectRealizationDateLocalSuccess;
}());
export { RejectRealizationDateLocalSuccess };
