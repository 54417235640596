import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  Input,
  HostBinding
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FormHintsDirective } from '@app/common/forms/components/form-hints/form-hints.directive';
import { FormLabelDirective } from '../form-label/form-label.directive';

type Appearances
  = 'regular'
  | 'radio'
  | 'button-toggle'
  | 'slide-toggle';

@Component({
  selector: 'vshcz-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: [ './form-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent {
  @Input()
  appearance: Appearances = 'regular';

  @Input()
  set last(v) {
    this._last = coerceBooleanProperty(v);
  }
  get last() {
    return this._last;
  }

  @ContentChild(FormLabelDirective)
  label: FormLabelDirective;

  @ContentChild(FormHintsDirective)
  hints: FormHintsDirective;

  @HostBinding('class.is-last')
  get classIsLast() {
    return this.last;
  }

  @HostBinding('class.has-appearance-radio')
  get classHasAppearanceRadio() {
    return this.appearance === 'radio';
  }

  @HostBinding('class.has-appearance-button-toggle')
  get classHasAppearanceButtonToggle() {
    return this.appearance === 'button-toggle';
  }

  @HostBinding('class.has-appearance-slide-toggle')
  get classHasAppearanceSliceToggle() {
    return this.appearance === 'slide-toggle';
  }

  get hasLabel() {
    return !!this.label;
  }

  get hasHints() {
    return !!this.hints;
  }

  private _last: boolean;

}
