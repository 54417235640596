/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-error.container.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-bar";
import * as i12 from "./progress-error.container";
import * as i13 from "@ngrx/store";
var styles_ProgressErrorContainer = [i0.styles];
var RenderType_ProgressErrorContainer = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressErrorContainer, data: {} });
export { RenderType_ProgressErrorContainer as RenderType_ProgressErrorContainer };
function View_ProgressErrorContainer_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "? "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorShowDetailsLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressErrorContainer_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "? "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorHideDetailsLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressErrorContainer_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "__errors_more"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.toggleAdditionalErrorInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showAdditionalErrorInfo; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showAdditionalErrorInfo; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProgressErrorContainer_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.parameter; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressErrorContainer_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.parameter; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.message; _ck(_v, 3, 0, currVal_1); }); }
function View_ProgressErrorContainer_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "__errors_details-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "__errors_details"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_7)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf.data.meta; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProgressErrorContainer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "__errors_message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_v.parent.context.ngIf.data == null) ? null : ((_v.parent.context.ngIf.data.meta == null) ? null : _v.parent.context.ngIf.data.meta.length)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_v.parent.context.ngIf.data == null) ? null : ((_v.parent.context.ngIf.data.meta == null) ? null : _v.parent.context.ngIf.data.meta.length)) && _co.showAdditionalErrorInfo); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.data.message; _ck(_v, 2, 0, currVal_0); }); }
function View_ProgressErrorContainer_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "__errors_button  __errors_button--close"], ["mat-stroked-button", ""], ["tabindex", "-1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.onResetError$.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.errorCloseLabel; _ck(_v, 2, 0, currVal_2); }); }
function View_ProgressErrorContainer_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "__errors_button  __errors_button--retry"], ["mat-button", ""], ["tabindex", "-2"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (!!_co.retry.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["loop"])), (_l()(), i1.ɵted(5, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.errorRetryLabel; _ck(_v, 5, 0, currVal_4); }); }
function View_ProgressErrorContainer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "__errors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "__errors_content"]], [[2, "is-open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "__errors_buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_10)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.ngIf; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.errorCloseLabel; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.errorRetryLabel; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressErrorContainer_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "__progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i10.View_MatProgressBar_0, i10.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i11.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i7.ANIMATION_MODULE_TYPE], [2, i11.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).value; var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProgressErrorContainer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "__main-button"]], [[2, "is-hidden", null], [2, "is-dense", null], [2, "has-errors", null]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProgressErrorContainer_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressErrorContainer_11)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.errors$)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.progressState && !_co.hideProgressSpinner); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.progressState === true); var currVal_1 = _co.denseButton; var currVal_2 = i1.ɵunv(_v, 0, 2, i1.ɵnov(_v, 1).transform(_co.errors$)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProgressErrorContainer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vshcz-progress-error", [], null, null, null, View_ProgressErrorContainer_0, RenderType_ProgressErrorContainer)), i1.ɵdid(1, 4440064, null, 1, i12.ProgressErrorContainer, [i13.Store], null, null), i1.ɵqud(335544320, 1, { buttonRef: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressErrorContainerNgFactory = i1.ɵccf("vshcz-progress-error", i12.ProgressErrorContainer, View_ProgressErrorContainer_Host_0, { errorKeys$: "errorKey", progressDisabled$: "progressDisabled", progressKeys$: "progressKey", hideProgressSpinner: "hideProgressSpinner", errorCloseLabel: "errorCloseLabel", disabled: "disabled", errorRetryLabel: "errorRetryLabel", errorShowDetailsLabel: "errorShowDetailsLabel", errorHideDetailsLabel: "errorHideDetailsLabel", denseButton: "denseButton" }, { retry: "retry" }, ["*"]);
export { ProgressErrorContainerNgFactory as ProgressErrorContainerNgFactory };
