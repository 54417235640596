import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HtmlEntitiesPipeModule } from '@zerops/fe/common';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '@app/common/price';
import { ClientServiceItemComponent } from './client-service-item.component';
import { ClientServiceStatusModule } from '../client-service-status';

@NgModule({
  declarations: [ ClientServiceItemComponent ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    HtmlEntitiesPipeModule,
    PriceModule,
    ClientServiceStatusModule
  ],
  exports: [ ClientServiceItemComponent ]
})
export class ClientServiceItemModule { }
