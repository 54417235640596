import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WebsocketsEffect } from './websockets.effect';
import { ModuleTokens } from './websockets.constant';
import { reducer } from './websockets.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ WebsocketsEffect ])
  ]
})
export class WebsocketsModule {
}
