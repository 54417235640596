import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '@app/common/price';
import { InvoiceStatusModule } from '@app/common/invoice-status';
import { InvoiceDatesInfoModule } from '@app/common/invoice-dates-info';
import { InvoiceItemComponent } from './invoice-item.component';

@NgModule({
  declarations: [ InvoiceItemComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule.forChild(),
    PriceModule,
    InvoiceStatusModule,
    InvoiceDatesInfoModule
  ],
  exports: [ InvoiceItemComponent ]
})
export class InvoiceItemModule { }
